const codeSamples = {
    Python: [
      "from collections import deque",
      "  ",
      "class Graph:",
      "    def __init__(self):",
      "        self.graph = {}",
      "  ",
      "    def bfs(self, start):",
      "        visited = set()",
      "        queue = deque([start])",
      "        visited.add(start)",
      "        while queue:",
      "            vertex = queue.popleft()",
      "            print(vertex, end=' ')",
      "            for neighbor in self.graph[vertex]:",
      "                if neighbor not in visited:",
      "                    visited.add(neighbor)",
      "                    queue.append(neighbor)",
      "  ",
      "  ",
      "if __name__ == '__main__':",
      "    graph = Graph()",
      "  ",
      "    graph.bfs(10)"
    ],
    Java: [
      "import java.util.*;",
      "  ",
      "class Graph {",
      "    private Map<Integer, List<Integer>> graph;",
      "  ",
      "    public Graph() {",
      "        graph = new HashMap<>();",
      "    }",
      "  ",
      "    public void bfs(int start) {",
      "        Set<Integer> visited = new HashSet<>();",
      "        Queue<Integer> queue = new LinkedList<>();",
      "        queue.add(start);",
      "        visited.add(start);",
      "        while (!queue.isEmpty()) {",
      "            int vertex = queue.poll();",
      "            System.out.print(vertex + ' ');",
      "            for (int neighbor : graph.get(vertex)) {",
      "                if (!visited.contains(neighbor)) {",
      "                    visited.add(neighbor);",
      "                    queue.add(neighbor);",
      "                }",
      "            }",
      "        }",
      "    }",
      "}",
      "  ",
      "public class Main {",
      "    public static void main(String[] args) {",
      "        Graph graph = new Graph();",
      "  ",
      "        graph.bfs(10);",
      "    }",
      "}"
    ],
    C: [
      "#include <stdio.h>",
      "#include <stdlib.h>",
      "  ",
      "#define MAX_VERTICES 6  // Maximum number of vertices",
      "  ",
      "void bfs(Graph* graph, int start) {",
      "    int visited[MAX_VERTICES] = {0};",
      "    int queue[MAX_VERTICES];",
      "    int front = 0, rear = 0;",
      "  ",
      "    visited[start] = 1;",
      "    queue[rear++] = start;",
      "  ",
      "    while (front < rear) {",
      "        int vertex = queue[front++];",
      "        printf(\"%d \", vertex);",
      "  ",
      "        Node* temp = graph->adjLists[vertex];",
      "        while (temp) {",
      "            if (!visited[temp->vertex]) {",
      "                visited[temp->vertex] = 1;",
      "                queue[rear++] = temp->vertex;",
      "            }",
      "            temp = temp->next;",
      "        }",
      "    }",
      "}",
      "  ",
      "int main() {",
      "    Graph* graph = createGraph();",
      "  ",
      "    bfs(graph, 1);",
      "  ",
      "    return 0;",
      "}"
    ],
    "C++": [
      "#include <iostream>",
      "#include <unordered_map>",
      "#include <vector>",
      "#include <queue>",
      "#include <set>",
      "  ",
      "using namespace std;",
      "  ",
      "class Graph {",
      "private:",
      "    unordered_map<int, vector<int>> graph;",
      "  ",
      "public:",
      "    // BFS using a queue",
      "    void bfs(int start) {",
      "        set<int> visited;",
      "        queue<int> q;",
      "        q.push(start);",
      "        visited.insert(start);",
      "        while (!q.empty()) {",
      "            int vertex = q.front();",
      "            q.pop();",
      "            cout << vertex << \" \";",
      "            for (int neighbor : graph[vertex]) {",
      "                if (visited.find(neighbor) == visited.end()) {",
      "                    visited.insert(neighbor);",
      "                    q.push(neighbor);",
      "                }",
      "            }",
      "        }",
      "    }",
      "};",
      "  ",
      "int main() {",
      "    Graph graph;",
      "  ",
      "    graph.bfs(10);",
      "    return 0;",
      "}"
    ]
  };
  
  export default codeSamples;
  