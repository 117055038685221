const codeSamples = {
    Python: [
        "import sys",
        "def partition(data, low, high):",
        "    pivot = data[low]",
        "    i = low",
        "    j = high",
        "    while i < j:",
        "        while pivot >= data[i]:",
        "            i += 1",
        "        while pivot < data[j]:",
        "            j -= 1",
        "        if i < j:",
        "            temp = data[i]",
        "            data[i] = data[j]",
        "            data[j] = temp",
        "    temp = data[low]",
        "    data[low] = data[j]",
        "    data[j] = temp",
        "    return j",
        "  ",
        "def quick_sort(data, low, high):",
        "    if low < high:",
        "        j = partition(data, low, high)",
        "        quick_sort(data, low, j)",
        "        quick_sort(data, j + 1, high)",
        "    return data",
        "  ",
        "if __name__ == '__main__':",
        "    data = [5, 7, 4, 8, 3, sys.maxsize]",
        "    low = 0",
        "    high = len(data) - 1",
        "    sorted_data = quick_sort(data, low, high)",
        "    print(f'Sorted Data {sorted_data}')"
    ],
    Java: [
        "public class QuickSort {",
        "    int partition(int[] data, int low, int high) {",
        "        int pivot = data[low];",
        "        int i = low;",
        "        int j = high;",
        "        while (i < j) {",
        "            while (pivot >= data[i]) {",
        "                i++;",
        "            }",
        "            while (pivot < data[j]) {",
        "                j--;",
        "            }",
        "            if (i < j) {",
        "                int temp = data[i];",
        "                data[i] = data[j];",
        "                data[j] = temp;",
        "            }",
        "        }",
        "        int temp = data[low];",
        "        data[low] = data[j];",
        "        data[j] = temp;",
        "        return j;",
        "    }",
        "  ",
        "    int[] quickSort(int[] data, int low, int high) {",
        "        if (low < high) {",
        "            int j = partition(data, low, high);",
        "            quickSort(data, low, j);",
        "            quickSort(data, j + 1, high);",
        "        }",
        "        return data;",
        "    }",
        "  ",
        "    public static void main(String[] args) {",
        "        int data[] = {5, 7, 4, 8, 3, Integer.MAX_VALUE};",
        "        int low = 0;",
        "        int high = data.length - 1;",
        "        QuickSort sorter = new QuickSort();",
        "        int sortedData[] = sorter.quickSort(data, low, high);",
        "        System.out.println('Sorted Data: ' + Arrays.toString(sortedData));",
        "    }",
        "}"
    ],
    C: [
        "#include <stdio.h>",
        "int partition(int data[], int low, int high) {",
        "    int pivot = data[low];",
        "    int i = low;",
        "    int j = high;",
        "    while (i < j) {",
        "        while (pivot >= data[i]) {",
        "            i++;",
        "        }",
        "        while (pivot < data[j]) {",
        "            j--;",
        "        }",
        "        if (i < j) {",
        "            int temp = data[i];",
        "            data[i] = data[j];",
        "            data[j] = temp;",
        "        }",
        "    }",
        "    int temp = data[low];",
        "    data[low] = data[j];",
        "    data[j] = temp;",
        "    return j;",
        "}",
        "  ",
        "int[] quickSort(int data[], int low, int high) {",
        "    if (low < high) {",
        "        int j = partition(data, low, high);",
        "        quickSort(data, low, j);",
        "        quickSort(data, j + 1, high);",
        "    }",
        "    return data;",
        "}",
        "  ",
        "int main() {",
        "    int data[] = {5, 7, 4, 8, 3, INT_MAX};",
        "    int low = 0;",
        "    int high = sizeof(data) / sizeof(data[0]) - 1;",
        "    int[] sortedData = quickSort(data, low, high);",
        "    printf('Sorted Data: ');",
        "    for (int itr = 0; itr <= high; itr++) {",
        "        printf('%d ', sortedData[itr]);",
        "    }",
        "    return 0;",
        "}"
    ],
    "C++": [
        "#include <iostream>",
        "using namespace std;",
        "int partition(int data[], int low, int high) {",
        "    int pivot = data[low];",
        "    int i = low;",
        "    int j = high;",
        "    while (i < j) {",
        "        while (pivot >= data[i]) {",
        "            i++;",
        "        }",
        "        while (pivot < data[j]) {",
        "            j--;",
        "        }",
        "        if (i < j) {",
        "            int temp = data[i];",
        "            data[i] = data[j];",
        "            data[j] = temp;",
        "        }",
        "    }",
        "    int temp = data[low];",
        "    data[low] = data[j];",
        "    data[j] = temp;",
        "    return j;",
        "}",
        "  ",
        "int[] quickSort(int data[], int low, int high) {",
        "    if (low < high) {",
        "        int j = partition(data, low, high);",
        "        quickSort(data, low, j);",
        "        quickSort(data, j + 1, high);",
        "    }",
        "    retur data;",
        "}",
        "  ",
        "int main() {",
        "    int data[] = {5, 7, 4, 8, 3, INT_MAX};",
        "    int low = 0;",
        "    int high = sizeof(data) / sizeof(data[0]) - 1;",
        "    int[] sortedData = quickSort(data, low, high);",
        "    cout << 'Sorted Data: ';",
        "    for (int itr = 0; itr <= high; itr++) {",
        "        cout << sortedData[itr] << ' ';",
        "    }",
        "    return 0;",
        "}"
    ]
  };
  
  export default codeSamples;
  