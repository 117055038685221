const explanations = {
  Python: {
    1: "Defining the bubble_sort function that takes a list as an argument",
    2: "Starting the outer loop to iterate over the entire list",
    3: "Starting the inner loop to compare adjacent elements in the list",
    4: "Checking if the current element is greater than the next element",
    5: "Swapping the elements if the current element is greater than the next",
    6: "Returning the sorted list after all iterations",
    8: "Main execution block to run the script",
    9: "Initializing the list with unsorted elements",
    10: "Calling the bubble_sort function with the list as an argument",
    11: "Printing the sorted list"
  },
  Java: {
    1: "Defining the BubbleSort class",
    2: "Defining the bubbleSort method that takes an array as an argument",
    3: "Getting the length of the array",
    4: "Starting the outer loop to iterate over the array",
    5: "Starting the inner loop to compare adjacent elements in the array",
    6: "Checking if the current element is greater than the next element for swapping",
    7: "Storing the first element in a temp variable",
    8: "Replacing the current element with the next element",
    9: "Replacing the the next element with the temp varaible",
    13: "Returning the sorted array",
    16: "Main method to run the script",
    17: "Creating an instance of BubbleSort",
    18: "Initializing the array with unsorted elements",
    19: "Calling the bubbleSort method with the array as an argument",
    20: "Printing the sorted array",
    21: "Starting the loop to iterate over the sorted array",
    22: "Printing element form the sorted array",
    
  },
  C: {
    1: "Including the standard input-output library",
    2: "Defining the bubbleSort function that takes an array and its size as arguments",
    3: "Declaring variables for iteration and swapping",
    4: "Starting the outer loop to iterate over the array",
    5: "Starting the inner loop to compare adjacent elements in the array",
    6: "Checking if the current element is greater than the next element for swapping",
    7: "Storing the first element in a temp variable",
    8: "Replacing the current element with the next element",
    9: "Replacing the the next element with the temp varaible",
    13: "Returning the sorted array",
    16: "Main function to run the script",
    17: "Initializing the array with unsorted elements",
    18: "Calculating the size of the array",
    19: "Calling the bubbleSort function with the array and its size as arguments",
    20: "Printing the sorted array",
    21: "Starting the loop to iterate over the sorted array",
    22: "Printing element form the sorted array",
    17: "Returning 0 to indicate successful execution"
  },
  "C++": {
    1: "Including the input-output stream library",
    2: "Using the standard namespace",
    3: "Defining the bubbleSort function that takes an array and its size as arguments",
    4: "Declaring variables for iteration and swapping",
    5: "Starting the outer loop to iterate over the array",
    6: "Starting the inner loop to compare adjacent elements in the array",
    7: "Checking if the current element is greater than the next element",
    8: "Storing the first element in a temp variable",
    9: "Replacing the current element with the next element",
    10: "Replacing the the next element with the temp varaible",
    14: "Returning the sorted array",
    17: "Main function to run the script",
    18: "Initializing the array with unsorted elements",
    19: "Calculating the size of the array",
    20: "Calling the bubbleSort function with the array and its size as arguments",
    21: "Printing the sorted array",
    22: "Starting the loop to iterate over the sorted array",
    23: "Printing element form the sorted array",
    14: "Returning 0 to indicate successful execution"
  }
};

export default explanations;
