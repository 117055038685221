const codeSamples = {
    Python: [
      "if __name__ == '__main__':",
      "    count = 0",
      "    max_count = 5",
      " ",
      "    while count < max_count:",
      "        print('Count is:', count)",
      "        count += 1"
    ],
    Java: [
      "public class WhileLoop {",
      "    public static void main(String[] args) {",
      "        int count = 0;",
      "        int maxCount = 5;",
      " ",
      "        while (count < maxCount) {",
      "            System.out.println('Count is: ' + count);",
      "            count++;",
      "        }",
      "    }",
      "}"
    ],
    C: [
      "#include <stdio.h>",
      "int main() {",
      "    int count = 0;",
      "    int maxCount = 5;",
      " ",
      "    while (count < maxCount) {",
      "        printf('Count is: %d\\n', count);",
      "        count++;",
      "    }",
      "    return 0;",
      "}"
    ],
    "C++": [
      "#include <iostream>",
      "using namespace std;",
      "int main() {",
      "    int count = 0;",
      "    int maxCount = 5;",
      " ",
      "    while (count < maxCount) {",
      "        cout << 'Count is: ' << count << endl;",
      "        count++;",
      "    }",
      "    return 0;",
      "}"
    ]
  };
  
  export default codeSamples;
  