import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../LearningTopics.css';

const LearningTopics = ({ selectedLanguage, onSelectTopic }) => {
  const [topics, setTopics] = useState({});
  const [loading, setLoading] = useState(true);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchTopics = async () => {
      const token = localStorage.getItem('userToken');
      let headers = {};
      if (token) {
        headers = {
          Authorization: `token ${token}`
        };
      }
      try {
        const response = await axios.get(`${apiUrl}/api/topics-json/`, {
          headers: headers,
        });
        setTopics(response.data);
      } catch (error) {
        console.error('Error fetching topics:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTopics();
  }, []);

  const languageTopics = topics[selectedLanguage]?.topics || {};

  // Display spinner while loading
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="learning-topics container-fluid">
      <h1 className="text-center mb-5 mobile-header">{selectedLanguage} Learning Topics</h1>

      <div className="topics-container row">
        {Object.keys(languageTopics).map(topic => (
          <div key={topic} className="col-md-3 mb-3" style={{ padding: '10px' }}>
            <div className="card h-100 p-4 d-flex flex-column shadow-sm">
              <h2 className="mb-2">{topic}</h2>
              <hr className="divider my-2" />
              <p className="text-muted">
                Completed: {languageTopics[topic].completed} / {languageTopics[topic].total}
              </p>
              <div className="progress-bar my-2">
                <div
                  className="progress"
                  style={{
                    width: `${(languageTopics[topic].completed / languageTopics[topic].total) * 100}%`,
                  }}
                ></div>
              </div>
              <ul className="list-unstyled mt-2">
                {languageTopics[topic].subTopics
                  .filter(subTopic => subTopic.shouldDisplay)
                  .map(subTopic => (
                    <li
                      key={subTopic.name}
                      className={`sub-topic ${subTopic.isCompleted ? 'completed' : ''}`}
                      onClick={() => {
                        onSelectTopic(subTopic.name, subTopic.id);
                        window.scrollTo({
                          top: 0,
                          behavior: 'smooth',
                        });
                      }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {subTopic.displayName}
                      {subTopic.isCompleted && (
                        <i className="fa fa-check" style={{ fontSize: '11px' }}></i>
                      )}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>

  );
};

export default LearningTopics;
