import React from 'react';

const UpdateVariablesModal = ({ isVisible, onClose, input, setInput, onSave }) => {
  if (!isVisible) return null;

  return (
    <div className="modal show d-block" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content modal-glass-avatar">
          <div className="modal-header">
            <h5 className="modal-title-update-variable">Update Variables</h5>
          </div>
          <div className="modal-body">
            <label>
              Variable input:
              <select
                className="form-control custom-input custom-dropdown"
                required
                value={input}
                onChange={(e) => setInput(e.target.value)}
                style={{width: '200px'}}
              >
                <option value="MONDAY">MONDAY</option>
                <option value="TUESDAY">TUESDAY</option>
                <option value="WEDNESDAY">WEDNESDAY</option>
                <option value="THURSDAY">THURSDAY</option>
                <option value="FRIDAY">FRIDAY</option>
              </select>
            </label>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-dark" onClick={onClose}>
              Cancel
            </button>
            <button type="button" className="btn btn-success" onClick={onSave}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateVariablesModal;
