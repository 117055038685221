import React, { useState } from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvasForBinaryTree';
import AlgorithmDescription from '../AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';
import UpdateVariablesModal from './UpdateVariablesModal';

import { useBinaryTree } from './useBinaryTree';

const BinaryTree = ({ language, subTopicId }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [data, setData] = useState([1, 2, 3, 4, 5, 6, 7]);
  const [dataInput, setDataInput] = useState("1, 2, 3, 4, 5, 6, 7");

  const handleData = (e) => {
    const input = e.target.value;
    setDataInput(input);

    // Parse the input string into an array of numbers
    const newArray = input.split(",").map((item) => parseFloat(item) || 0); // Default to 0 for invalid numbers
    setData(newArray);
  };

  const {
    highlightedLine,
    callingLine,
    secondCallingLine,
    highlightedMultipleLines,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    rootVariables,
    nodeVariables,
    singleNodeVariable,
    highlightedVariables,
    highlightedRootVariables,
    highlightedNodeVariables,
    highlightedSingleNodeVariables,
    highlightSingleNodeLeftPart,
    highlightSingleNodeDataPart,
    highlightSingleNodeRightPart,
    highlightLeftPart,
    highlightDataPart,
    highlightRightPart,
    highlightNextAddressPart,
    highlightNodeAddress,
    stackVariables,
    highlightedStackVariables,
    highlightedStackIndex,
    strikeThroughStackIndex,
    isMuted,
    codeSamples,
    setDelay,
    setIsPaused,
    setIsMuted,
    updateVariables,
    runBinaryTree,
  } = useBinaryTree(language, data);

  
  const handleUpdateVariables = () => {
    updateVariables(data);
    setModalVisible(false);
  };

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6">
          <CodeDisplay
            codeLines={codeSamples}
            highlightedLine={highlightedLine}
            highlightedMultipleLines={highlightedMultipleLines}
            focusedEndLine={focusedEndLine}
            topic={"Binary Tree"}
            callingLine={callingLine}
            secondCallingLine={secondCallingLine}
            subTopicId={subTopicId}
            language={language}
            onOpenModal={() => setModalVisible(true)}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <h3 className="section-title">Canvas</h3>
            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runBinaryTree}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
            <VisualizationCanvas
              variables={variables}
              highlightedVariables={highlightedVariables}
              rootVariables={rootVariables}
              highlightedRootVariables={highlightedRootVariables}
              singleNodeVariable={singleNodeVariable}
              nodeVariables={nodeVariables}
              highlightedNodeVariables={highlightedNodeVariables}
              highlightedSingleNodeVariables={highlightedSingleNodeVariables}
              highlightSingleNodeLeftPart={highlightSingleNodeLeftPart}
              highlightSingleNodeDataPart={highlightSingleNodeDataPart}
              highlightSingleNodeRightPart={highlightSingleNodeRightPart}
              highlightLeftPart={highlightLeftPart}
              highlightDataPart={highlightDataPart}
              highlightRightPart={highlightRightPart}
              highlightNextAddressPart={highlightNextAddressPart}
              highlightNodeAddress={highlightNodeAddress}
              stackVariables={stackVariables}
              highlightedStackVariables={highlightedStackVariables}
              highlightedStackIndex={highlightedStackIndex}
              strikeThroughStackIndex={strikeThroughStackIndex}
              language={language}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
          <AlgorithmDescription />
      </div>
      <UpdateVariablesModal
        isVisible={modalVisible}
        onClose={() => setModalVisible(false)}
        dataInput={dataInput}
        handleData={handleData}
        handleUpdateVariables={handleUpdateVariables}
      />
    </div>
  );
};

export default BinaryTree;
