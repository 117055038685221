const codeSamples = {
    Java: [
      "public class CharTypes {",
      "    public static void main(String[] args) {",
      "        char letter = 'A';",
      "        char digit = '1';",
      "        char specialChar = '@';",
      "  ",
      "        System.out.println('Character: ' + letter);",
      "        System.out.println('Digit: ' + digit);",
      "        System.out.println('Special character: ' + specialChar);",
      "    }",
      "}"
    ],
    C: [
      "#include <stdio.h>",
      "int main() {",
      "    char letter = 'A';",
      "    char digit = '1';",
      "    char specialChar = '@';",
      "  ",
      "    printf('Character: %c\\n', letter);",
      "    printf('Digit: %c\\n', digit);",
      "    printf('Special character: %c\\n', specialChar);",
      "    return 0;",
      "}"
    ],
    "C++": [
      "#include <iostream>",
      "using namespace std;",
      "int main() {",
      "    char letter = 'A';",
      "    char digit = '1';",
      "    char specialChar = '@';",
      "  ",
      "    cout << 'Character: ' << letter << endl;",
      "    cout << 'Digit: ' << digit << endl;",
      "    cout << 'Special character: ' << specialChar << endl;",
      "    return 0;",
      "}"
    ]
  };
  
  export default codeSamples;
  