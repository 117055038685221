import React, { useState } from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvasForClass';
import AlgorithmDescription from '../AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';
import UpdateVariablesModal from './UpdateVariablesModal';

import { useClassType } from './useClassType';

const ClassType = ({ language, subTopicId }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [initialClass, setInitialClass] = useState({ name: "Buddy" });

  const {
    highlightedLine,
    callingLine,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    classVariables,
    stringVariables,
    highlightedVariables,
    highlightedClassVariables,
    highlightedClassMethods,
    highlightedStringVariables,
    isMuted,
    codeSamples,
    setDelay,
    setIsPaused,
    setIsMuted,
    updateVariables,
    runClassType
  } = useClassType(language, initialClass);

  const handleUpdateVariables = () => {
    updateVariables(initialClass);
    setModalVisible(false);
  };

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6">
          <CodeDisplay
            codeLines={codeSamples || []}
            callingLine={callingLine}
            highlightedLine={highlightedLine}
            focusedEndLine={focusedEndLine}
            topic={`Class Type Example`}
            subTopicId={subTopicId}
            language={language}
            onOpenModal={() => setModalVisible(true)}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <h3 className="section-title">Canvas</h3>
            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runClassType}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
            <VisualizationCanvas
              variables={variables}
              highlightedVariables={highlightedVariables}
              classVariables={classVariables}
              highlightedClassVariables={highlightedClassVariables}
              highlightedClassMethods={highlightedClassMethods}
              stringVariables={stringVariables}
              highlightedStringVariables={highlightedStringVariables}
              highlightedStringIndices={[]}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
        <AlgorithmDescription />
      </div>
      <UpdateVariablesModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        initialClass={initialClass}
        setInitialClass={setInitialClass}
        handleUpdateVariables={handleUpdateVariables}
      />
    </div>
  );
};

export default ClassType;
