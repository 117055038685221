const codeSamples = {
    Python: [
        "if __name__ == '__main__':",
        "    # String example",
        "    name = 'Alice'",
        "    print('String:', name)",
        "  ",
        "    # List example",
        "    fruits = ['apple', 'banana', 'cherry']",
        "    fruits.append('date')",
        "    print('List:', fruits)",
        "    print('First fruit:', fruits[0])",
        "  ",
        "    # Tuple example",
        "    coordinates = (10, 20, 30)",
        "    print('Tuple:', coordinates)",
        "    print('Second coordinate:', coordinates[1])",
        "  ",
        "    # Range example",
        "    numbers = range(1, 6)",
        "    print('Range as list:', list(numbers))"
    ]
};

export default codeSamples;
