const codeSamples = {
    Python: [
        "class Dog:",
        "    def __init__(self, name):",
        "        self.name = name",
        "  ",
        "    def bark(self):",
        "        print(f'{self.name} says: Woof!')",
        "  ",
        "    def sleep(self):",
        "        print(f'{self.name} is sleeping... Zzz...')",
        "  ",
        "if __name__ == '__main__':",
        "    buddy = Dog(\"Buddy\")",
        "  ",
        "    buddy.bark()",
        "    buddy.sleep()"
    ],
    Java: [
        "public class Dog {",
        "    private String name;",
        "  ",
        "    public Dog(String name) {",
        "        this.name = name;",
        "    }",
        "  ",
        "    public void bark() {",
        "        System.out.println(name + \" says: Woof!\");",
        "    }",
        "  ",
        "    public void sleep() {",
        "        System.out.println(name + \" is sleeping... Zzz...\");",
        "    }",
        "  ",
        "    public static void main(String[] args) {",
        "        Dog buddy = new Dog(\"Buddy\");",
        "  ",
        "        buddy.bark();",
        "        buddy.sleep();",
        "    }",
        "}"
    ],
    'C++': [
        "#include <iostream>",
        "#include <string>",
        "  ",
        "class Dog {",
        "private:",
        "    std::string name;",
        "  ",
        "public:",
        "    Dog(std::string name) : name(name) {}",
        "  ",
        "    void bark() {",
        "        std::cout << name << \" says: Woof!\" << std::endl;",
        "    }",
        "  ",
        "    void sleep() {",
        "        std::cout << name << \" is sleeping... Zzz...\" << std::endl;",
        "    }",
        "};",
        "  ",
        "int main() {",
        "    Dog buddy(\"Buddy\");",
        "  ",
        "    buddy.bark();",
        "    buddy.sleep();",
        "  ",
        "    return 0;",
        "}"
    ]
};

export default codeSamples;
