import React from "react";
import { ArcherElement } from "react-archer";
import "../../VisualizationCanvas.css";

// TreeNode component represents a single node in the binary tree
const TreeNode = ({
  id,
  data,
  address,
  left,
  right,
  language,
  highlightedNodeVariables,
  highlightLeftPart,
  highlightDataPart,
  highlightRightPart,
  highlightNodeAddress
}) => {
    let nullVariable = 'null';
    if (language === 'Python') {
        nullVariable = 'None';
    } else if(language == 'C++') {
      nullVariable = 'nullptr';
    } else if(language == 'C') {
      nullVariable = 'NULL';
    } 
  
    // Define the relations dynamically, only when left or right child exists
    const relations = [];
    if (left) {
      relations.push({
        targetId: left.id,
        targetAnchor: "top",
        sourceAnchor: "bottom",
        style: { strokeColor: "#00e676", strokeWidth: 2 },
      });
    }
    if (right) {
      relations.push({
        targetId: right.id,
        targetAnchor: "top",
        sourceAnchor: "bottom",
        style: { strokeColor: "#00e676", strokeWidth: 2 },
      });
    }

    return (
      <div className="tree-node">
        <ArcherElement
          id={id}
          key={id}
          relations={relations}
        >
          <div>
              <div
                className={`${highlightedNodeVariables.includes(address) ? 'highlighted-node-box' : 'node-box'}`}
              >
                <div
                  className={`${highlightLeftPart && highlightLeftPart.includes(address) ? 'highlighted-left-part' : 'left-part'}`}
                >
                  <div>{left ? left.address : nullVariable}</div>
                </div>
                <div
                  className={`${highlightDataPart.includes(address) ? 'highlighted-data-part' : 'data-part'}`}
                >
                  <div>{data}</div>
                </div>
                <div
                  className={`${highlightRightPart.includes(address) ? 'highlighted-right-part' : 'right-part'}`}
                >
                  <div>{right ? right.address : nullVariable}</div>
                </div>
              </div>
              <div
                className={`${highlightedNodeVariables.includes(address) || highlightNodeAddress.includes(address)  ? 'highlighted-tree-node-address' : 'tree-node-address'}`}
              >{address}</div>
          </div>
        </ArcherElement>

        <div className="child-nodes">
          {left && (
            <div className="left-child">
              <TreeNode
                {...left}
                language={language}
                highlightedNodeVariables={highlightedNodeVariables}
                highlightLeftPart={highlightLeftPart}
                highlightDataPart={highlightDataPart}
                highlightRightPart={highlightRightPart}
                highlightNodeAddress={highlightNodeAddress}
              />
            </div>
          )}
          {right && (
            <div className="right-child">
              <TreeNode
                {...right}
                language={language}
                highlightedNodeVariables={highlightedNodeVariables}
                highlightLeftPart={highlightLeftPart}
                highlightDataPart={highlightDataPart}
                highlightRightPart={highlightRightPart}
                highlightNodeAddress={highlightNodeAddress}
              />
            </div>
          )}
        </div>
      </div>
    );
};

export default TreeNode;
