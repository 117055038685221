const explanations = {
    Python: {
        3: "Creating a dictionary named 'student' with keys 'name', 'age', and 'graduated'",
        10: "Accessing and printing the value associated with the key 'name'",
        11: "Accessing and printing the value associated with the key 'age'",
        12: "Accessing and printing the value associated with the key 'graduated'",
        15: "Adding a new key-value pair to the dictionary",
        18: "Updating the value associated with the key 'age'",
        21: "Removing the key-value pair associated with the key 'graduated' from the dictionary",
        24: "Printing the message 'Updated student dictionary:'",
        25: "Iterating through the dictionary to print each key-value pair",
        26: "Printing each key-value pair in the dictionary"
    },
    Java: {
        6: "Creating a HashMap named 'student'",
        7: "Adding a new key-value pair to the HashMap with the key 'name'",
        8: "Adding a new key-value pair to the HashMap with the key 'age'",
        9: "Adding a new key-value pair to the HashMap with the key 'graduated'",
        12: "Accessing and printing the value associated with the key 'name'",
        13: "Accessing and printing the value associated with the key 'age'",
        14: "Accessing and printing the value associated with the key 'graduated'",
        17: "Adding a new key-value pair to the HashMap with the key 'GPA'",
        20: "Updating the value associated with the key 'age' in the HashMap",
        23: "Removing the key-value pair associated with the key 'graduated' from the HashMap",
        26: "Printing the message 'Updated student dictionary:'",
        27: "Iterating through the HashMap to print each key-value pair",
        28: "Printing each key-value pair in the HashMap using the entrySet"
    }
};

export default explanations;
