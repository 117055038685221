const codeSamples = {
    Python: [
      "def selection_sort(list):",
      "    for i in range(len(list)):",
      "        min_index = i",
      "        for j in range(i + 1, len(list)):",
      "            if list[j] < list[min_index]:",
      "                min_index = j",
      "        list[i], list[min_index] = list[min_index], list[i]",
      "    return list",
      "  ",
      "if __name__ == '__main__':",
      "    list = [64, 25, 12, 22, 11]",
      "    sorted_list = selection_sort(list)",
      "    print(f'Sorted list: {sorted_list}')"
    ],
    Java: [
      "public class SelectionSort {",
      "    int[] selectionSort(int list[]) {",
      "        int n = list.length;",
      "        for (int i = 0; i < n-1; i++) {",
      "            int minIndex = i;",
      "            for (int j = i+1; j < n; j++)",
      "                if (list[j] < list[minIndex])",
      "                    minIndex = j;",
      "            int temp = list[minIndex];",
      "            list[minIndex] = list[i];",
      "            list[i] = temp;",
      "        }",
      "        return list",
      "    }",
      "    public static void main(String args[]) {",
      "        SelectionSort ob = new SelectionSort();",
      "        int list[] = {64, 25, 12, 22, 11};",
      "        int sortedList[] = ob.selectionSort(list);",
      "        System.out.println('Sorted list:' + sortedList);",
      "    }",
      "}"
    ],
    C: [
      "#include <stdio.h>",
      "int[] selectionSort(int list[], int n) {",
      "    int i, j, minIndex, temp;",
      "    for (i = 0; i < n-1; i++) {",
      "        minIndex = i;",
      "        for (j = i+1; j < n; j++)",
      "            if (list[j] < list[minIndex])",
      "                minIndex = j;",
      "        temp = list[minIndex];",
      "        list[minIndex] = list[i];",
      "        list[i] = temp;",
      "    }",
      "    return list;",
      "}",
      "  ",
      "int main() {",
      "    int list[] = {64, 25, 12, 22, 11};",
      "    int n = sizeof(list)/sizeof(list[0]);",
      "    int sortedList[] = selectionSort(list, n);",
      "    printf('Sorted list: \\n');",
      "    for (int itr=0; itr < n; itr++)",
      "        printf('%d ', list[itr]);",
      "    printf('\\n');",
      "    return 0;",
      "}"
    ],
    "C++": [
      "#include <iostream>",
      "using namespace std;",
      "int[] selectionSort(int list[], int n) {",
      "    int i, j, minIndex, temp;",
      "    for (i = 0; i < n-1; i++) {",
      "        minIndex = i;",
      "        for (j = i+1; j < n; j++)",
      "            if (list[j] < list[minIndex])",
      "                minIndex = j;",
      "        temp = list[minIndex];",
      "        list[minIndex] = list[i];",
      "        list[i] = temp;",
      "    }",
      "    return list;",
      "}",
      "int main() {",
      "    int list[] = {64, 25, 12, 22, 11};",
      "    int n = sizeof(list)/sizeof(list[0]);",
      "    int sortedList[] = selectionSort(list, n);",
      "    cout << 'Sorted list: ' << sortedList;",
      "    return 0;",
      "}"
    ]
};
  
export default codeSamples;
  