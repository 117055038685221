const codeSamples = {
    Java: [
      "public class IntegerTypes {",
      "    public static void main(String[] args) {",
      "        int moonLandingYear = 1969;",
      "        int olympicGoldMedals = 23;",
      "        int highestMountainHeightMeters = 8848;",
      "        int lowestTemperatureRecordedCelsius = -89;",
      "  ",
      "        System.out.println('Year of the first moon landing: ' + moonLandingYear);",
      "  ",
      "        System.out.println('Olympic gold medals won by Michael Phelps: ' + olympicGoldMedals);",
      "  ",
      "        System.out.println('Height of Mount Everest in meters: ' + highestMountainHeightMeters);",
      "  ",
      "        System.out.println('Lowest temperature recorded on Earth (Celsius): ' + lowestTemperatureRecordedCelsius);",
      "    }",
      "}"
    ],
    C: [
      "#include <stdio.h>",
      "int main() {",
      "    int moonLandingYear = 1969;",
      "    int olympicGoldMedals = 23;",
      "    int highestMountainHeightMeters = 8848;",
      "    int lowestTemperatureRecordedCelsius = -89;",
      "  ",
      "    printf('Year of the first moon landing: %d\\n', moonLandingYear);",
      "  ",
      "    printf('Olympic gold medals won by Michael Phelps: %d\\n', olympicGoldMedals);",
      "  ",
      "    printf('Height of Mount Everest in meters: %d\\n', highestMountainHeightMeters);",
      "  ",
      "    printf('Lowest temperature recorded on Earth (Celsius): %d\\n', lowestTemperatureRecordedCelsius);",
      "  ",
      "    return 0;",
      "}"
    ],
    "C++": [
      "#include <iostream>",
      "using namespace std;",
      "int main() {",
      "    int moonLandingYear = 1969;",
      "    int olympicGoldMedals = 23;",
      "    int highestMountainHeightMeters = 8848;",
      "    int lowestTemperatureRecordedCelsius = -89;",
      "  ",
      "    cout << 'Year of the first moon landing: ' << moonLandingYear << endl;",
      "  ",
      "    cout << 'Olympic gold medals won by Michael Phelps: ' << olympicGoldMedals << endl;",
      "  ",
      "    cout << 'Height of Mount Everest in meters: ' << highestMountainHeightMeters << endl;",
      "  ",
      "    cout << 'Lowest temperature recorded on Earth (Celsius): ' << lowestTemperatureRecordedCelsius << endl;",
      "  ",
      "    return 0;",
      "}"
    ]
  };
  
  export default codeSamples;
  