const explanations = {
    Python: {
      1: "Defining the is_palindrome function",
      2: "Convert the string to lowercase",
      3: "Initialize left pointer",
      4: "Initialize right pointer",
      6: "Loop until the left pointer is less than the right pointer",
      7: "Check if characters at left and right pointers are different",
      8: "Returning False because the characters are different",
      9: "Increment left pointer",
      10: "Decrement right pointer",
      12: "Returning True because all characters are matched",
      14: "Main execution block",
      15: "Initialize the string variable",
      16: "Calling is_palindrome function to check if the string is a palindrome",
      17: "Print that the string is a palindrome",
      18: "The string is not palindrome so executing else block",
      19: "Print that the string is not a palindrome"
    },
    Java: {
      2: "Defining the isPalindrome method",
      3: "Convert the string to lowercase",
      4: "Initialize left and right pointers",
      6: "Loop until the left pointer is less than the right pointer",
      7: "Check if characters at left and right pointers are different",
      8: "Returning False because the characters are different",
      10: "Increment left pointer",
      11: "Decrement right pointer",
      13: "Returning True because all characters are matched",
      16: "Main method",
      17: "Initialize the string variable",
      18: "Calling isPalindrome method to check if the string is a palindrome",
      19: "Print that the string is a palindrome",
      20: "The string is not palindrome so executing else block",
      21: "Print that the string is not a palindrome"
    },
    C: {
      5: "Defining the isPalindrome function",
      6: "Initialize left pointer",
      7: "Initialize right pointer",
      9: "Loop until the left pointer is less than the right pointer",
      10: "Check if characters at left and right pointers are different",
      11: "Returning 0 because the characters are different",
      13: "Increment left pointer",
      14: "Decrement right pointer",
      16: "Return 1 because all characters are matched",
      19: "Main function",
      20: "Initialize the array of character variable 'str'",
      21: "Calling isPalindrome function to check if the string is a palindrome",
      22: "Print that the string is a palindrome",
      23: "The string is not palindrome so executing else block",
      24: "Print that the string is not a palindrome",
      26: "Returning 0 to indicate successful execution"
    },
    "C++": {
      6: "Defining the isPalindrome function",
      7: "Initialize left pointer",
      8: "Initialize right pointer",
      10: "Loop until the left pointer is less than the right pointer",
      11: "Check if characters at left and right pointers are different",
      12: "Returning False because the characters are different",
      14: "Increment left pointer",
      15: "Decrement right pointer",
      17: "Returning True because all characters are matched",
      20: "Main function",
      21: "Initialize the array of character variable 'str'",
      22: "Calling isPalindrome function to check if the string is a palindrome",
      23: "Print that the string is a palindrome",
      24: "The string is not palindrome so executing else block",
      25: "Print that the string is not a palindrome",
      27: "Returning 0 to indicate successful execution"
    }
  };
  
  export default explanations;
  