const explanations = {
    Java: {
        3: "Declaring and initializing variable 'count'",
        4: "Declaring and initializing variable 'maxCount'",
        6: "Executing the do-while loop block",
        7: "Printing the current count",
        8: "Incrementing the count",
        9: "Checking the loop condition to continue or exit"
    },
    C: {
        3: "Declaring and initializing variable 'count'",
        4: "Declaring and initializing variable 'maxCount'",
        6: "Executing the do-while loop block",
        7: "Printing the current count",
        8: "Incrementing the count",
        9: "Checking the loop condition to continue or exit",
        11: "Returning 0 to indicate successful execution"
    },
    "C++": {
        4: "Declaring and initializing variable 'count'",
        5: "Declaring and initializing variable 'maxCount'",
        7: "Executing the do-while loop block",
        8: "Printing the current count",
        9: "Incrementing the count",
        10: "Checking the loop condition to continue or exit",
        12: "Returning 0 to indicate successful execution"
    }
  };
  
  export default explanations;
  