const explanations = {
    Python: {
        1: "Imports the sys module which provides access to some variables used or maintained by the interpreter and to functions that interact strongly with the interpreter.",
        2: "Defines the partition function, which takes the array (data) and the indices low and high.",
        3: "Sets the pivot element as the first element of the current sub-array.",
        4: "Initializes the i variable to the low index.",
        5: "Initializes the j variable to the high index.",
        6: "Starts a while loop that runs until i is less than j.",
        7: "Starts a nested while loop to increment i until an element greater than or equal to the pivot is found.",
        8: "Increments the i variable.",
        9: "Starts another nested while loop to decrement j until an element less than the pivot is found.",
        10: "Decrements the j variable.",
        11: "Checks if i is less than j.",        
        12: "Performs the swap by temporarily storing the value at data[i].",
        13: "Assigns the value at data[j] to data[i].",
        14: "Assigns the temporarily stored value to data[j].",
        15: "Performs the swap by temporarily storing the value at data[low].",
        16: "Assigns the value at data[j] to data[low].",
        17: "Assigns the temporarily stored value to data[j].",
        18: "Returns the index j.",
        20: "Defines the quick_sort function, which sorts the array using the Quick Sort algorithm.",
        21: "Checks if low is less than high.",
        22: "Calls the partition function and stores the returned index in j.",
        23: "Recursively calls quick_sort on the left sub-array.",
        24: "Recursively calls quick_sort on the right sub-array.",
        25: "Returns the sorted array.",
        27: "Checks if the script is being run directly (not imported as a module).",
        28: "Initializes the data array with sample values and sys.maxsize as a sentinel value.",
        29: "Sets the low index to 0.",
        30: "Sets the high index to the length of the data array minus one.",
        31: "Calls the quick_sort function to sort the data array.",
        32: "Prints the sorted array."
    },
    Java: {
        1: "Defines the QuickSort class.",
        2: "Defines the partition method, which takes the array (data) and the indices low and high.",
        3: "Sets the pivot element as the first element of the current sub-array.",
        4: "Initializes the i variable to the low index.",
        5: "Initializes the j variable to the high index.",
        6: "Starts a while loop that runs until i is less than j.",
        7: "Starts a nested while loop to increment i until an element greater than or equal to the pivot is found.",
        8: "Increments the i variable.",
        10: "Starts another nested while loop to decrement j until an element less than the pivot is found.",
        11: "Decrements the j variable.",
        13: "Checks if i is less than j.",
        14: "Performs the swap by temporarily storing the value at data[i].",
        15: "Assigns the value at data[j] to data[i].",
        16: "Assigns the temporarily stored value to data[j].",
        19: "Performs the swap by temporarily storing the value at data[low].",
        20: "Assigns the value at data[j] to data[low].",
        21: "Assigns the temporarily stored value to data[j].",
        22: "Returns the index j.",
        25: "Defines the quickSort method, which sorts the array using the Quick Sort algorithm.",
        26: "Checks if low is less than high.",
        27: "Calls the partition method and stores the returned index in j.",
        28: "Recursively calls quickSort on the left sub-array.",
        29: "Recursively calls quickSort on the right sub-array.",
        31: "Returns the sorted array.",
        34: "Defines the main method, which is the entry point of the program.",
        35: "Initializes the data array with sample values and Integer.MAX_VALUE as a sentinel value.",
        36: "Sets the low index to 0.",
        37: "Sets the high index to the length of the data array minus one.",
        38: "Creates an instance of the QuickSort class.",
        39: "Calls the quickSort method to sort the data array.",
        40: "Prints the sorted array."
    },
    C: {
        1: "Includes the stdio.h header file for input and output functions.",
        2: "Defines the partition function, which takes the array (data) and the indices low and high.",
        3: "Sets the pivot element as the first element of the current sub-array.",
        4: "Initializes the i variable to the low index.",
        5: "Initializes the j variable to the high index.",
        6: "Starts a while loop that runs until i is less than j.",
        7: "Starts a nested while loop to increment i until an element greater than or equal to the pivot is found.",
        8: "Increments the i variable.",
        10: "Starts another nested while loop to decrement j until an element less than the pivot is found.",
        11: "Decrements the j variable.",
        13: "Checks if i is less than j.",
        14: "Performs the swap by temporarily storing the value at data[i].",
        15: "Assigns the value at data[j] to data[i].",
        16: "Assigns the temporarily stored value to data[j].",
        19: "Performs the swap by temporarily storing the value at data[low].",
        20: "Assigns the value at data[j] to data[low].",
        21: "Assigns the temporarily stored value to data[j].",
        22: "Returns the index j.",
        25: "Defines the quickSort function, which sorts the array using the Quick Sort algorithm.",
        26: "Checks if low is less than high.",
        27: "Calls the partition function and stores the returned index in j.",
        28: "Recursively calls quickSort on the left sub-array.",
        29: "Recursively calls quickSort on the right sub-array.",
        31: "Returns the sorted array.",
        34: "Defines the main function, which is the entry point of the program.",
        35: "Initializes the data array with sample values and INT_MAX as a sentinel value.",
        36: "Sets the low index to 0.",
        37: "Sets the high index to the length of the data array divided by the size of an element minus one.",
        38: "Calls the quickSort function to sort the data array.",
        39: "Prints the sorted array.",
        40: "Iterates through the sorted array to print each element.",
        41: "Printing element from the sorted array.",
        43: "Returns 0 to indicate successful execution."
    },
    'C++': {
        1: "Includes the iostream header file for input and output functions.",
        2: "Uses the standard namespace.",
        3: "Defines the partition function, which takes the array (data) and the indices low and high.",
        4: "Sets the pivot element as the first element of the current sub-array.",
        5: "Initializes the i variable to the low index.",
        6: "Initializes the j variable to the high index.",
        7: "Starts a while loop that runs until i is less than j.",
        8: "Starts a nested while loop to increment i until an element greater than or equal to the pivot is found.",
        9: "Increments the i variable.",
        11: "Starts another nested while loop to decrement j until an element less than the pivot is found.",
        12: "Decrements the j variable.",
        14: "Checks if i is less than j.",
        15: "Performs the swap by temporarily storing the value at data[i].",
        16: "Assigns the value at data[j] to data[i].",
        17: "Assigns the temporarily stored value to data[j].",
        20: "Performs the swap by temporarily storing the value at data[low].",
        21: "Assigns the value at data[j] to data[low].",
        22: "Assigns the temporarily stored value to data[j].",
        23: "Returns the index j.",
        26: "Defines the quickSort function, which sorts the array using the Quick Sort algorithm.",
        27: "Checks if low is less than high.",
        28: "Calls the partition function and stores the returned index in j.",
        29: "Recursively calls quickSort on the left sub-array.",
        30: "Recursively calls quickSort on the right sub-array.",
        32: "Returns the sorted array.",
        35: "Defines the main function, which is the entry point of the program.",
        36: "Initializes the data array with sample values and INT_MAX as a sentinel value.",
        37: "Sets the low index to 0.",
        38: "Sets the high index to the length of the data array divided by the size of an element minus one.",
        39: "Calls the quickSort function to sort the data array.",
        40: "Prints the sorted array.",
        41: "Iterates through the sorted array to print each element.",
        42: "Printing element from the sorted array.",
        44: "Returns 0 to indicate successful execution."
    }
};

export default explanations;
