import React from 'react';

const UpdateVariablesModal = ({
  isVisible,
  initialA,
  initialB,
  setInitialA,
  setInitialB,
  onSave,
  onClose,
}) => {
  if (!isVisible) return null;

  return (
    <div className="modal show d-block" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content modal-glass-avatar">
          <div className="modal-header">
            <h5 className="modal-title-update-variable">Update Variables</h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-6">
                <label>
                  Variable A:
                  <input
                    type="number"
                    className="form-control custom-input"
                    value={initialA}
                    onChange={(e) => setInitialA(parseInt(e.target.value))}
                  />
                </label>
              </div>
              <div className="col-md-6">
                <label>
                  Variable B:
                  <input
                    type="number"
                    className="form-control custom-input"
                    value={initialB}
                    onChange={(e) => setInitialB(parseInt(e.target.value))}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-dark"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={onSave}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateVariablesModal;
