const codeSamples = {
    Python: [
      "class Node:",
      "    def __init__(self, data):",
      "        self.data = data",
      "        self.next = None",
      "  ",
      "class LinkedList:",
      "    def __init__(self):",
      "        self.head = None",
      "  ",
      "    def display(self):",
      "        temp = self.head",
      "        if not temp:",
      "            print(\"The list is empty.\")",
      "        else:",
      "            while temp:",
      "                print(temp.data, end=\" -> \")",
      "                temp = temp.next",
      "  ",
      "if __name__ == '__main__':",
      "    list = LinkedList()",
      "  ",
      "    # Display the linked list",
      "    list.display()"
    ],
    Java: [
      "class Node {",
      "    int data;",
      "    Node next;",
      "  ",
      "    Node(int data) {",
      "        this.data = data;",
      "        this.next = null;",
      "    }",
      "}",
      "  ",
      "class LinkedList {",
      "    Node head;",
      "  ",
      "    public void display() {",
      "        Node temp = head;",
      "        if (temp == null) {",
      "            System.out.println(\"The list is empty.\");",
      "        } else {",
      "            while (temp != null) {",
      "                System.out.print(temp.data + \" -> \");",
      "                temp = temp.next;",
      "            }",
      "        }",
      "    }",
      "  ",
      "    public static void main(String[] args) {",
      "        LinkedList list = new LinkedList();",
      "  ",
      "        // Display the linked list",
      "        list.display();",
      "    }",
      "}"
    ],
    C: [
      "#include <stdio.h>",
      "#include <stdlib.h>",
      "  ",
      "struct Node {",
      "    int data;",
      "    struct Node* next;",
      "};",
      "  ",
      "struct Node* head = NULL;",
      "  ",
      "void display() {",
      "    struct Node* temp = head;",
      "    if (temp == NULL) {",
      "        printf(\"The list is empty.\\n\");",
      "    } else {",
      "        while (temp != NULL) {",
      "            printf(\"%d -> \", temp->data);",
      "            temp = temp->next;",
      "        }",
      "    }",
      "}",
      "  ",
      "int main() {",
      "    // Display the linked list",
      "    display();",
      "  ",
      "    return 0;",
      "}"
    ],
    "C++": [
      "#include <iostream>",
      "using namespace std;",
      "  ",
      "class Node {",
      "public:",
      "    int data;",
      "    Node* next;",
      "  ",
      "    Node(int data) {",
      "        this->data = data;",
      "        this->next = nullptr;",
      "    }",
      "};",
      "  ",
      "class LinkedList {",
      "public:",
      "    Node* head;",
      "  ",
      "    LinkedList() {",
      "        head = nullptr;",
      "    }",
      "  ",
      "    void display() {",
      "        Node* temp = head;",
      "        if (temp == nullptr) {",
      "            cout << \"The list is empty.\" << endl;",
      "        } else {",
      "            while (temp != nullptr) {",
      "                cout << temp->data << \" -> \";",
      "                temp = temp->next;",
      "            }",
      "        }",
      "    }",
      "};",
      "  ",
      "int main() {",
      "    LinkedList list;",
      "  ",
      "    // Display the linked list",
      "    list.display();",
      "  ",
      "    return 0;",
      "}"
    ]
  };
  
  export default codeSamples;
  