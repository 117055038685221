import { useState, useEffect, useRef } from 'react';
import explanations from './explanations';
import codeSamplesTemplate from './codeSamples';

export const useLongTypes = (language, minSignedLongValue, maxSignedLongValue, maxUnsignedLongValue) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [callingLine, setCallingLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const delayRef = useRef(delay);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [arrayVariables, setArrayVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedArrayVariables, setHighlightedArrayVariables] = useState([]);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const [isMuted, setIsMuted] = useState(true);
  const isMutedRef = useRef(isMuted);
  const synth = window.speechSynthesis;

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setCallingLine(null);
    setVariables({});
    setLogs([]);
    setHighlightedVariables([]);
    setArrayVariables({});
    setHighlightedArrayVariables([]);
  };

  const speak = (message) => {
    return new Promise((resolve) => {
      if (isMutedRef.current) {
        resolve();
        return;
      }
      const utterance = new SpeechSynthesisUtterance(message);
      utterance.onend = resolve;
      synth.speak(utterance);
    });
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setCallingLine((prevCallingLine) => {
      if (prevCallingLine === lineNumber) {
        return null;
      }
      return prevCallingLine;
    });
    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async () => {
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runLongTypesJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(2);
    setVariables((vars) => ({ ...vars, minLongValue: { variable_name: 'minLongValue', value: `${minSignedLongValue}` } }));
    setHighlightedVariables(['minLongValue']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(3);
    setVariables((vars) => ({ ...vars, maxLongValue: { variable_name: 'maxLongValue', value: `${maxSignedLongValue}` } }));
    setHighlightedVariables(['maxLongValue']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(5);
    setHighlightedVariables(['minLongValue']);
    await logMessage(`Minimum signed long value: ${minSignedLongValue}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(7);
    setHighlightedVariables(['maxLongValue']);
    await logMessage(`Maximum signed long value: ${maxSignedLongValue}`);
    await customSleep();
    setHighlightedVariables([]);

    await focusEndLine(9);
    setIsRunning(false);
  };

  const runLongTypesC = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(4);
    setVariables((vars) => ({ ...vars, minSignedLongValue: { variable_name: 'minSignedLongValue', value: `${minSignedLongValue}` } }));
    setHighlightedVariables(['minSignedLongValue']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(5);
    setVariables((vars) => ({ ...vars, maxSignedLongValue: { variable_name: 'maxSignedLongValue', value: `${maxSignedLongValue}` } }));
    setHighlightedVariables(['maxSignedLongValue']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(6);
    setVariables((vars) => ({ ...vars, maxUnsignedLongValue: { variable_name: 'maxUnsignedLongValue', value: `${maxUnsignedLongValue}` } }));
    setHighlightedVariables(['maxUnsignedLongValue']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(8);
    setHighlightedVariables(['minSignedLongValue']);
    await logMessage(`Minimum signed long value: ${minSignedLongValue}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(10);
    setHighlightedVariables(['maxSignedLongValue']);
    await logMessage(`Maximum signed long value: ${maxSignedLongValue}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(12);
    setHighlightedVariables(['maxUnsignedLongValue']);
    await logMessage(`Maximum unsigned long value:: ${maxUnsignedLongValue}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(14);
    await customSleep();

    await focusEndLine(15);
    setIsRunning(false);
  };

  const runLongTypesCpp = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(5);
    setVariables((vars) => ({ ...vars, minSignedLongValue: { variable_name: 'minSignedLongValue', value: `${minSignedLongValue}` } }));
    setHighlightedVariables(['minSignedLongValue']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(6);
    setVariables((vars) => ({ ...vars, maxSignedLongValue: { variable_name: 'maxSignedLongValue', value: `${maxSignedLongValue}` } }));
    setHighlightedVariables(['maxSignedLongValue']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(7);
    setVariables((vars) => ({ ...vars, maxUnsignedLongValue: { variable_name: 'maxUnsignedLongValue', value: `${maxUnsignedLongValue}` } }));
    setHighlightedVariables(['maxUnsignedLongValue']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(9);
    setHighlightedVariables(['minSignedLongValue']);
    await logMessage(`Minimum signed long value: ${minSignedLongValue}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(11);
    setHighlightedVariables(['maxSignedLongValue']);
    await logMessage(`Maximum signed long value: ${maxSignedLongValue}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(13);
    setHighlightedVariables(['maxUnsignedLongValue']);
    await logMessage(`Maximum unsigned long value:: ${maxUnsignedLongValue}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(15);
    await customSleep();

    await focusEndLine(16);
    setIsRunning(false);
  };

  const runLongTypes = async () => {
    if (language === 'Java') {
      await runLongTypesJava();
    } else if (language === 'C') {
      await runLongTypesC();
    } else if (language === 'C++') {
      await runLongTypesCpp();
    }
  };

  return {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    highlightedArrayVariables,
    isMuted,
    codeSamples,
    setHighlightedLine,
    setFocusedEndLine,
    setCallingLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setArrayVariables,
    setHighlightedVariables,
    setHighlightedArrayVariables,
    setIsMuted,
    runLongTypes
  };
};
