import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <p id="algorithmDescription">
        This algorithm performs operations such as adding edges to a graph, either directed or undirected, and building the structure of the graph dynamically.
      </p>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <p id="realLifeUse">
        This algorithm is commonly used in scenarios like social network analysis, web page linking analysis, and transportation networks.
      </p>
    </div>
  );
};

export default AlgorithmDescription;
