export const binarySearchPython = async (sortedList, targetElement, highlightLine, setVariables, setMid, setHighlightedVariables, setHighlightedIndices, customSleep) => {
    let low = 0;
    let high = sortedList.length - 1;
    await highlightLine(1);
    setVariables((vars) => ({ ...vars, low: { variable_name: 'low', value: low }, high: { variable_name: 'high', value: high } }));
    setHighlightedVariables(['high', 'low']);
    await customSleep();
    setHighlightedVariables([]);
  
    while (low <= high) {
      await highlightLine(2);
      setHighlightedVariables(['high', 'low']);
      await customSleep();
      setHighlightedVariables([]);

      await highlightLine(3);
      let mid = Math.floor((low + high) / 2);
      setMid(mid);
      setVariables((vars) => ({ ...vars, mid: { variable_name: 'mid', value: mid } }));
      setHighlightedVariables(['mid']);
      await customSleep();
      setHighlightedVariables([]);
      
  
      await highlightLine(4);
      let midElement = sortedList[mid];
      setVariables((vars) => ({ ...vars, midElement: { variable_name: 'mid_element', value: midElement } }));
      setHighlightedVariables(['mid_element']);
      setHighlightedIndices([{ arrayName: 'sorted_list', index: mid, iteratorName: 'mid' }]);
      await customSleep();
      setHighlightedVariables([]);
  
      await highlightLine(5);
      setHighlightedVariables(['mid_element', 'target_element']);
      await customSleep();
      setHighlightedVariables([]);
  
      if (midElement === targetElement) {
        await highlightLine(6);
        setHighlightedVariables(['mid']);
        await customSleep();
        setHighlightedVariables([]);
        return mid;
      } else if (midElement < targetElement) {
        await highlightLine(7);
        setHighlightedVariables(['mid_element', 'target_element']);
        await customSleep();
        setHighlightedVariables([]);
  
        await highlightLine(8);
        low = mid + 1;
        setVariables((vars) => ({ ...vars, low: { variable_name: 'low', value: low } }));
        setHighlightedVariables(['low']);
        await customSleep();
        setHighlightedVariables([]);
      } else {
        await highlightLine(7);
        setHighlightedVariables(['target_element']);
        await customSleep();
        setHighlightedVariables([]);
  
        await highlightLine(9);
        await customSleep();
  
        await highlightLine(10);
        high = mid - 1;
        setVariables((vars) => ({ ...vars, high: { variable_name: 'high', value: high } }));
        setHighlightedVariables(['high']);
        await customSleep();
        setHighlightedVariables([]);
      }
    }
    await highlightLine(11);
    await customSleep();
    setMid(null);
    return -1;
  };
  
  export const binarySearchJava = async (sortedList, targetElement, highlightLine, setVariables, setMid, setHighlightedVariables, setHighlightedIndices, customSleep) => {
    let low = 0;
    let high = sortedList.length - 1;
    await highlightLine(2);
    setVariables((vars) => ({ ...vars, low: { variable_name: 'low', value: low }, high: { variable_name: 'high', value: high } }));
    setHighlightedVariables(['low', 'high']);
    await customSleep();
    setHighlightedVariables([]);
  
    while (low <= high) {
      await highlightLine(3);
      setHighlightedVariables(['low', 'high']);
      await customSleep();
      setHighlightedVariables([]);

      await highlightLine(4);
      let mid = Math.floor((low + high) / 2);
      setMid(mid);
      setVariables((vars) => ({ ...vars, mid: { variable_name: 'mid', value: mid } }));
      setHighlightedVariables(['mid']);
      await customSleep();
      setHighlightedVariables([]);
  
      await highlightLine(5);
      setHighlightedVariables(['targetElement']);
      setHighlightedIndices([{ arrayName: 'sortedArray', index: mid, iteratorName: 'mid' }]);
      await customSleep();
      setHighlightedVariables([]);
  
      if (sortedList[mid] === targetElement) {
        await highlightLine(6);
        setHighlightedVariables(['mid']);
        await customSleep();
        setHighlightedVariables([]);
        return mid;
      } else if (sortedList[mid] < targetElement) {
        await highlightLine(8);
        setHighlightedVariables(['targetElement']);
        setHighlightedIndices([{ arrayName: 'sortedArray', index: mid, iteratorName: 'mid' }]);
        await customSleep();
        setHighlightedVariables([]);
  
        await highlightLine(9);
        low = mid + 1;
        setVariables((vars) => ({ ...vars, low: { variable_name: 'low', value: low } }));
        setHighlightedVariables(['low']);
        await customSleep();
        setHighlightedVariables([]);
      } else {
        await highlightLine(8);
        setHighlightedVariables(['targetElement']);
        setHighlightedIndices([{ arrayName: 'sortedArray', index: mid, iteratorName: 'mid' }]);
        await customSleep();
        setHighlightedVariables([]);
  
        await highlightLine(11);
        await customSleep();
  
        await highlightLine(12);
        high = mid - 1;
        setVariables((vars) => ({ ...vars, high: { variable_name: 'high', value: high } }));
        setHighlightedVariables(['high']);
        await customSleep();
        setHighlightedVariables([]);
      }
    }
    await highlightLine(15);
    await customSleep();
    setMid(null);
    return -1;
  };
  
  export const binarySearchC = async (sortedList, size, targetElement, highlightLine, setVariables, setMid, setHighlightedVariables, setHighlightedIndices, customSleep) => {
    let low = 0;
    let high = size - 1;
    await highlightLine(3);
    setVariables((vars) => ({ ...vars, low: { variable_name: 'low', value: low }, high: { variable_name: 'high', value: high } }));
    setHighlightedVariables(['low', 'high']);
    await customSleep();
    setHighlightedVariables([]);
  
    while (low <= high) {
      await highlightLine(4);
      setHighlightedVariables(['low', 'high']);
      await customSleep();

      await highlightLine(5);
      let mid = Math.floor((low + high) / 2);
      setMid(mid);
      setVariables((vars) => ({ ...vars, mid: { variable_name: 'mid', value: mid } }));
      setHighlightedVariables(['mid']);
      await customSleep();
      setHighlightedVariables([]);
  
      await highlightLine(6);
      setHighlightedVariables(['targetElement']);
      setHighlightedIndices([{ arrayName: 'sortedArray', index: mid, iteratorName: 'mid' }]);
      await customSleep();
      setHighlightedVariables([]);
  
      if (sortedList[mid] === targetElement) {
        await highlightLine(7);
        setHighlightedVariables(['mid']);
        await customSleep();
        setHighlightedVariables([]);
        return mid;
      } else if (sortedList[mid] < targetElement) {
        await highlightLine(9);
        setHighlightedVariables(['targetElement']);
        setHighlightedIndices([{ arrayName: 'sortedArray', index: mid, iteratorName: 'mid' }]);
        await customSleep();
        setHighlightedVariables([]);
  
        await highlightLine(10);
        low = mid + 1;
        setVariables((vars) => ({ ...vars, low: { variable_name: 'low', value: low } }));
        setHighlightedVariables(['low']);
        await customSleep();
        setHighlightedVariables([]);
      } else {
        await highlightLine(9);
        setHighlightedVariables(['targetElement']);
        setHighlightedIndices([{ arrayName: 'sortedArray', index: mid, iteratorName: 'mid' }]);
        await customSleep();
        setHighlightedVariables([]);
  
        await highlightLine(12);
        await customSleep();
  
        await highlightLine(13);
        high = mid - 1;
        setVariables((vars) => ({ ...vars, high: { variable_name: 'high', value: high } }));
        setHighlightedVariables(['high']);
        await customSleep();
        setHighlightedVariables([]);
      }
    }
    await highlightLine(16);
    await customSleep();
    setMid(null);
    return -1;
  };
  
  export const binarySearchCpp = async (sortedList, size, targetElement, highlightLine, setVariables, setMid, setHighlightedVariables, setHighlightedIndices, customSleep) => {
    let low = 0;
    let high = size - 1;
    await highlightLine(4);
    setVariables((vars) => ({ ...vars, low: { variable_name: 'low', value: low }, high: { variable_name: 'high', value: high } }));
    setHighlightedVariables(['low', 'high']);
    await customSleep();
    setHighlightedVariables([]);
  
    while (low <= high) {
      await highlightLine(5);
      setHighlightedVariables(['low', 'high']);
      await customSleep();

      await highlightLine(6);
      let mid = Math.floor((low + high) / 2);
      setMid(mid);
      setVariables((vars) => ({ ...vars, mid: { variable_name: 'mid', value: mid } }));
      setHighlightedVariables(['mid']);
      await customSleep();
      setHighlightedVariables([]);
  
      await highlightLine(7);
      setHighlightedVariables(['targetElement']);
      setHighlightedIndices([{ arrayName: 'sortedArray', index: mid, iteratorName: 'mid' }]);
      await customSleep();
      setHighlightedVariables([]);
  
      if (sortedList[mid] === targetElement) {
        await highlightLine(8);
        setHighlightedVariables(['mid']);
        await customSleep();
        setHighlightedVariables([]);
        return mid;
      } else if (sortedList[mid] < targetElement) {
        await highlightLine(10);
        setHighlightedVariables(['targetElement']);
        setHighlightedIndices([{ arrayName: 'sortedArray', index: mid, iteratorName: 'mid' }]);
        await customSleep();
        setHighlightedVariables([]);
  
        await highlightLine(11);
        low = mid + 1;
        setVariables((vars) => ({ ...vars, low: { variable_name: 'low', value: low } }));
        setHighlightedVariables(['low']);
        await customSleep();
        setHighlightedVariables([]);
      } else {
        await highlightLine(10);
        setHighlightedVariables(['targetElement']);
        setHighlightedIndices([{ arrayName: 'sortedArray', index: mid, iteratorName: 'mid' }]);
        await customSleep();
        setHighlightedVariables([]);
  
        await highlightLine(13);
        await customSleep();
  
        await highlightLine(14);
        high = mid - 1;
        setVariables((vars) => ({ ...vars, high: { variable_name: 'high', value: high } }));
        setHighlightedVariables(['mid']);
        await customSleep();
        setHighlightedVariables([]);
      }
    }
    await highlightLine(17);
    await customSleep();
    setMid(null);
    return -1;
  };
  