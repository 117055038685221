const explanations = {
    Java: {
        3: "Declaring and initializing long variable minLongValue",
        4: "Declaring and initializing long variable maxLongValue",
        6: "Printing the minimum long value",
        8: "Printing the maximum long value",
    },
    C: {
      5: "Setting minSignedLongValue to the minimum value for a signed long",
      6: "Setting maxSignedLongValue to the maximum value for a signed long",
      7: "Setting maxUnsignedLongValue to the maximum value for an unsigned long",
      9: "Printing the minimum signed long value",
      11: "Printing the maximum signed long value",
      13: "Printing the maximum unsigned long value",
      15: "Returning 0 to indicate successful execution"
    },
    "C++": {
      6: "Setting minSignedLongValue to the minimum value for a signed long",
      7: "Setting maxSignedLongValue to the maximum value for a signed long",
      8: "Setting maxUnsignedLongValue to the maximum value for an unsigned long",
      10: "Printing the minimum signed long value",
      12: "Printing the maximum signed long value",
      14: "Printing the maximum unsigned long value",
      16: "Returning 0 to indicate successful execution"
    }
  };
  
  export default explanations;
  