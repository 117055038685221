import React, { useState, useEffect } from 'react';

const VisualizationCanvas = ({ 
  arrayVariables,
  variables,
  mapVariables,
  setsVariables,
  stackVariables,
  stringVariables,
  enumVariables,
  highlightedVariables,
  highlightedArrayVariables=[],
  highlightedMapVariables=[],
  highlightedSetVariables=[],
  highlightedStackVariables=[],
  highlightedStringVariables=[],
  highlightedEnumVariables=[],
  highlightedIndices,
  highlightedMapIndex,
  highlightedSetIndex,
  highlightedStackIndex,
  highlightedStringIndices,
  highlightedEnumIndex
}) => {

  const [variableAddresses, setVariableAddresses] = useState({});
  const [arrayAddresses, setArrayAddresses] = useState({});
  const [stringAddresses, setStringAddresses] = useState({});
  const [mapAddresses, setMapAddresses] = useState({});
  const [setsVariableAddresses, setSetsVariableAddresses] = useState({});
  const [enumAddresses, setEnumAddresses] = useState({});

  useEffect(() => {
    const adjustFontSize = () => {
      document.querySelectorAll('.array-value').forEach(element => {
        let fontSize = parseInt(window.getComputedStyle(element).fontSize);
        while (element.scrollWidth > element.clientWidth || element.scrollHeight > element.clientHeight) {
          if (fontSize <= 8) break;
          fontSize--;
          element.style.fontSize = `${fontSize}px`;
        }
      });
    };
    adjustFontSize();
    window.addEventListener('resize', adjustFontSize);
    return () => window.removeEventListener('resize', adjustFontSize);
  }, []);

  useEffect(() => {
    const generateMemoryAddress = () => {
      return '0x' + Math.floor(Math.random() * 0xFFF).toString(16);
    };

    // Initialize variable addresses if not already initialized
    setVariableAddresses(prevAddresses => {
      const newAddresses = { ...prevAddresses };
      Object.keys(variables).forEach(key => {
        if (!newAddresses[key]) {
          newAddresses[key] = generateMemoryAddress();
        }
      });
      return newAddresses;
    });

    // Initialize array addresses if not already initialized
    setArrayAddresses(prevAddresses => {
      const newAddresses = { ...prevAddresses } || {};
      if (arrayVariables && typeof arrayVariables === 'object') {
        Object.keys(arrayVariables).forEach(key => {
          if (!newAddresses[key]) {
            newAddresses[key] = generateMemoryAddress();
          }
        });
      }
    
      return newAddresses;
    });
    

    // Initialize map addresses if not already initialized
    setMapAddresses(prevAddresses => {
      if (mapVariables && typeof mapVariables === 'object') {
        const newAddresses = { ...prevAddresses };
        Object.keys(mapVariables).forEach(key => {
          if (!newAddresses[key]) {
            newAddresses[key] = generateMemoryAddress();
          }
        });
        return newAddresses;
      } else {
        return prevAddresses;
      }
    }); 

    
    // Initialize set variable addresses if not already initialized
    setSetsVariableAddresses(prevAddresses => {
      if (setsVariables && typeof setsVariables === 'object') {
        const newAddresses = { ...prevAddresses };
        Object.keys(setsVariables).forEach(key => {
          if (!newAddresses[key]) {
            newAddresses[key] = generateMemoryAddress();
          }
        });
        return newAddresses;
      } else {
        return prevAddresses;
      }
    }); 


    // Initialize enum addresses if not already initialized
    setEnumAddresses(prevAddresses => {
      if (enumVariables && typeof enumVariables === 'object') {
        const newAddresses = { ...prevAddresses };
        Object.keys(enumVariables).forEach(key => {
          if (!newAddresses[key]) {
            newAddresses[key] = generateMemoryAddress();
          }
        });
        return newAddresses;
      } else {
        return prevAddresses;
      }
    });

    setStringAddresses(prevAddresses => {
      if (stringVariables && typeof stringVariables === 'object') {
        const newAddresses = { ...prevAddresses };
        const valueToAddressMap = {};
    
        // First, map existing addresses to values
        Object.keys(stringVariables).forEach(key => {
          const value = stringVariables[key].value;
          if (!valueToAddressMap[value] && newAddresses[key]) {
            valueToAddressMap[value] = newAddresses[key];
          }
        });
    
        // Then assign addresses, reusing if the value is the same
        Object.keys(stringVariables).forEach(key => {
          const value = stringVariables[key].value;
          if (!newAddresses[key]) {
            if (valueToAddressMap[value]) {
              // Reuse the address if it already exists for the same value
              newAddresses[key] = valueToAddressMap[value];
            } else {
              // Generate a new address and map it to the value
              const address = generateMemoryAddress();
              newAddresses[key] = address;
              valueToAddressMap[value] = address;
            }
          }
        });
    
        return newAddresses;
      } else {
        return prevAddresses;
      }
    });

  }, [variables, arrayVariables, stringVariables, mapVariables, setsVariables, enumVariables]);

  

  const isArrayIndexHighlighted = (arrayName, index) => {
    return highlightedIndices.some(
      (highlight) => highlight.arrayName === arrayName && highlight.index === index
    );
  };

  const isStringIndexHighlighted = (stringName, index) => {
    return highlightedStringIndices.some(
      (highlight) => highlight.stringName === stringName && highlight.index === index
    );
  };

  const isMapIndexHighlighted = (mapName, key) => {
    return highlightedMapIndex.some(
      (highlight) => highlight.mapName === mapName && highlight.key === key
    );
  };

  const isSetIndexHighlighted = (setName, key) => {
    return highlightedSetIndex.some(
      (highlight) => highlight.setName === setName && highlight.key === key
    );
  };

  const isStackIndexHighlighted = (stackName, index) => {
    return highlightedStackIndex.some(
      (highlight) => highlight.stackName === stackName && highlight.index === index
    );
  };

  const isEnumIndexHighlighted = (enumName, key) => {
    return highlightedEnumIndex.some(
      (highlight) => highlight.enumName === enumName && highlight.key === key
    );
  };

  const getIteratorName = (arrayName, index) => {
    const highlight = highlightedIndices.find(
      (highlight) => highlight.arrayName === arrayName && highlight.index === index
    );

    return highlight && highlight.iteratorName && highlight.iteratorName.length > 0
      ? highlight.iteratorName + " = "
      : "";
  };

  const getStringIteratorName = (stringName, index) => {
    const highlight = highlightedStringIndices.find(
      (highlight) => highlight.stringName === stringName && highlight.index === index
    );

    return highlight && highlight.iteratorName && highlight.iteratorName.length > 0
      ? highlight.iteratorName + " = "
      : "";
  };

  const getStackIteratorName = (stackName, index) => {
    const highlight = highlightedStackIndex.find(
      (highlight) => highlight.stackName === stackName && highlight.index === index
    );

    return highlight && highlight.iteratorName && highlight.iteratorName.length > 0
      ? highlight.iteratorName + " = "
      : "";
  };

  const isVisualizationEmpty = () => {
    return !(
      (variables && Object.keys(variables).length) ||
      (arrayVariables && Object.keys(arrayVariables).length) ||
      (stringVariables && Object.keys(stringVariables).length) ||
      (mapVariables && Object.keys(mapVariables).length) ||
      (setsVariables && Object.keys(setsVariables).length) ||
      (stackVariables && Object.keys(stackVariables).length) ||
      (enumVariables && Object.keys(enumVariables).length)
    );
  };
  
  return (
    <div className="visualization-canvas" style={{display: !isVisualizationEmpty() ? 'block' : ''}}>
      {isVisualizationEmpty() ? (
        <p 
          style={{textAlign: 'center'}}
        >Code visualizations will appear here.</p>
      ) : (
        <><div className="variables-container">
          {variables && Object.entries(variables).map(([key, { variable_name, value }], index) => (
            <div key={index} className={`variable-box-wrapper`}>
              <div key={index} className={`variable-box ${highlightedVariables.includes(variable_name) ? 'highlighted-variable' : ''}`}>
                <div className="variable-value">{value}</div>
                <div className={`${highlightedVariables.includes(variable_name) ? 'variable-name-highlighted' : 'variable-name'}`}>{variable_name}</div>
              </div>
              <div className={`variable-address ${highlightedVariables.includes(variable_name) ? 'highlighted-variable' : ''}`}>{variableAddresses[key]}</div>
            </div>
          ))}
        </div>

        <>
          {arrayVariables && Object.entries(arrayVariables).map(([arrayName, arrayData], arrayIndex) => (
            <div key={arrayIndex} className="array-container">
              <div className={`${highlightedArrayVariables.includes(arrayName) ? 'highlighted-array array-variable-box-highlighted' : 'array-variable-box'}`}>
                <div className="array-variable-name">{arrayData.variable_name}
                  <br/>
                  <span style={{fontSize: '11px'}}> {arrayAddresses[arrayName]} </span>
                </div>
              </div>
              {arrayData.value && arrayData.value.map((value, index) => (
                <div key={index}
                style={arrayData.width ? { width: `${arrayData.width}` } : {}}
                className={`${isArrayIndexHighlighted(arrayName, index) ? 'highlighted' : ''} 
                ${highlightedArrayVariables.includes(arrayName) ? 'highlighted-array array-highlighted-box' : 'array-box'}`}>
                  <div className="array-index-value">
                  <div className={`${isArrayIndexHighlighted(arrayName, index) ? 'highlighted-array-index' : 'array-index'} 
                    ${highlightedArrayVariables.includes(arrayName) ? 'highlighted-array-index' : ''}`}>
                    {getIteratorName(arrayName, index)}{index} 
                    </div>
                    <div className="array-value">{value}</div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </>

        <div className='string-container-wrapper'>
          {stringVariables && Object.entries(stringVariables).map(([stringName, stringData], stringIndex) => (
            <div key={stringIndex} className="string-container">
              <div className={`${highlightedStringVariables.includes(stringName) ? 'highlighted-string string-variable-box-highlighted' : 'string-variable-box'}`}>
                <div className="string-variable-name">{stringData.variable_name}
                  <br/>
                  <span style={{fontSize: '11px'}}> {stringAddresses[stringName]} </span>
                </div>
              </div>
              {stringData.value.split('').map((char, index) => (
                <div key={index}
                style={stringData.width ? { width: `${stringData.width}` } : {}}
                className={`${isStringIndexHighlighted(stringName, index) ? 'highlighted-str' : ''} 
                ${highlightedStringVariables.includes(stringName) ? 'highlighted-string string-highlighted-box' : 'string-box'}`}>
                  <div className="string-index-value">
                    <div className={`${isStringIndexHighlighted(stringName, index) ? 'highlighted-string-index' : 'string-index'} 
                      ${highlightedStringVariables.includes(stringName) ? 'highlighted-string-index' : ''}`}>
                      {getStringIteratorName(stringName, index)}{index} 
                    </div>
                    <div className="string-value">{char}</div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>


        <div className='dictionary-wrapper'>
          {mapVariables && Object.entries(mapVariables).map(([mapName, mapData], mapIndex) => (
            <div key={mapIndex} className={`${highlightedMapVariables.includes(mapName) ? 'highlighted-dictionary-container' : 'dictionary-container'}`}>
              {Object.entries(mapData.value).map(([key, val], index) => (
                  <div key={`map-${index}`} style={{display: 'contents'}}>
                    <div 
                      className={
                        `${isMapIndexHighlighted(mapName, key) ? 'highlighted-dictionary-row' : 'dictionary-row'} 
                        ${highlightedMapVariables.includes(mapName) ? 'highlighted-dictionary-row' : 'dictionary-row'}`
                      }
                    >
                      Hash({key}) = {index}
                    </div>
                    <div 
                      className={
                        `${isMapIndexHighlighted(mapName, key) ? 'highlighted-dictionary-cell' : 'dictionary-cell'}
                        ${highlightedMapVariables.includes(mapName) ? 'highlighted-dictionary-cell' : 'dictionary-cell'}`
                      }
                    >{
                      key}: {val}
                    </div>
                  </div>
              ))}
              <div className={`${highlightedMapVariables.includes(mapName) ? 'highlighted-dictionary-cell merged' : 'dictionary-cell merged'}`}>
                {mapName}
                <br/>
                <span style={{fontSize: '11px'}}> {mapAddresses[mapName]} </span>
              </div>
            </div>
          ))}
        </div>

        <div className='dictionary-wrapper'>
          {setsVariables && Object.entries(setsVariables).map(([setName, setData], setIndex) => (
            <div 
              key={setIndex} 
              className={`${highlightedSetVariables.includes(setName) ? 'highlighted-dictionary-container' : 'dictionary-container'}`}
            >
              {[...setData.value].map((value, valueIndex) => (
                <div key={`map-${valueIndex}`} style={{ display: 'contents' }}>
                  <div 
                    className={
                      `${isSetIndexHighlighted(setName, value) ? 'highlighted-dictionary-row' : 'dictionary-row'} 
                      ${highlightedSetVariables.includes(setName) ? 'highlighted-dictionary-row' : 'dictionary-row'}`
                    }
                  >
                    Hash({value}) = {valueIndex}
                  </div>
                  <div 
                    className={
                      `${isSetIndexHighlighted(setName, value) ? 'highlighted-dictionary-cell' : 'dictionary-cell'} 
                      ${highlightedSetVariables.includes(setName) ? 'highlighted-dictionary-cell' : 'dictionary-cell'}`
                    }
                  >
                    {value}
                  </div>
                </div>
              ))}
              <div className={`${highlightedSetVariables.includes(setName) ? 'highlighted-dictionary-cell merged' : 'dictionary-cell merged'}`}>
                {setName}
                <br/>
                <span style={{fontSize: '11px'}}> {setsVariableAddresses[setName]} </span>
              </div>
            </div>
          ))}
        </div>


        <div className='stack-container-wrapper'>
          {stackVariables && Object.entries(stackVariables).map(([stackName, stackData], stackIndex) => (
            <div 
              key={stackIndex} 
              className={`${highlightedStackVariables.includes(stackName) ? 'highlighted-stack-container' : 'stack-container'}`}
            >
              <div className={`${highlightedStackVariables.includes(stackName) ? 'highlighted-stack-top-row' : 'stack-top-row'}`}
              >index</div>
              <div style={{}} 
              className={`${highlightedStackVariables.includes(stackName) ? 'highlighted-stack-top-cell' : 'stack-top-cell'}`}
              >value</div>
              {[...stackData.value].reverse().map((value, valueIndex) => (
                <div key={`map-${valueIndex}`} style={{ display: 'contents' }}>
                  <div 
                    style={{whiteSpace: 'nowrap'}}
                    className={
                      `${isStackIndexHighlighted(stackName, stackData.value.length - 1 - valueIndex) ? 'highlighted-stack-row' : 'stack-row'} 
                      ${highlightedStackVariables.includes(stackName) ? 'highlighted-stack-row' : 'stack-row'}`
                    }
                  >
                  {getStackIteratorName(stackName, stackData.value.length - 1 - valueIndex)}{stackData.value.length - 1 - valueIndex}
                  </div>
                  <div 
                    style={{minWidth: '40px', textAlign:'center'}}
                    className={
                      `${isStackIndexHighlighted(stackName, stackData.value.length - 1 - valueIndex) ? 'highlighted-stack-cell' : 'stack-cell'} 
                      ${highlightedStackVariables.includes(stackName) ? 'highlighted-stack-cell' : 'stack-cell'}`
                    }
                  >
                    {value}
                  </div>
                </div>
              ))}
              <div className={`${highlightedStackVariables.includes(stackName) ? 'highlighted-dictionary-cell merged' : 'dictionary-cell merged'}`}>
                {stackName}
              </div>
            </div>
          ))}
        </div>

        <div className='stack-container-wrapper'>
          {enumVariables && Object.entries(enumVariables).map(([enumName, enumData], enumIndex) => (
            <div 
              key={enumIndex}
              className={`${highlightedEnumVariables.includes(enumName) ? 'highlighted-dictionary-container' : 'dictionary-container'}`}
              style={{margin: '20px'}}
            >
              {Object.entries(enumData.value).map(([key, val], index) => (
                  <div key={`map-${index}`} style={{display: 'contents'}}>
                    <div 
                      className={
                        `${isEnumIndexHighlighted(enumName, key) ? 'highlighted-dictionary-row' : 'dictionary-row'} 
                        ${highlightedEnumVariables.includes(enumName) ? 'highlighted-dictionary-row' : 'dictionary-row'}`
                      }
                      style={{fontSize: '10px', paddingLeft: '5px', paddingTop: '10px'}}
                    >
                      {key}
                    </div>
                    <div 
                      className={
                        `${isEnumIndexHighlighted(enumName, key) ? 'highlighted-dictionary-cell' : 'dictionary-cell'}
                        ${highlightedEnumVariables.includes(enumName) ? 'highlighted-dictionary-cell' : 'dictionary-cell'}`
                      }
                      style={{fontSize: '10px', padding: '3px'}}
                    >
                      {`ordinal = ${index}`} <br/>
                      {`name = ${val}`}
                    </div>
                  </div>
              ))}
              <div className={`${highlightedEnumVariables.includes(enumName) ? 'highlighted-dictionary-cell merged' : 'dictionary-cell merged'}`}>
                {enumName}
                <br/>
                <span style={{fontSize: '11px'}}> {enumAddresses[enumName]} </span>
              </div>
            </div>
          ))}
        </div></>
      )}
    </div>
  );
};

export default VisualizationCanvas;
