import React from 'react';

const Controls = ({ isRunning, isPaused, delay, setDelay, runVisualization, setIsPaused, isMuted, setIsMuted }) => {
    
    const handleMuteToggle = () => {
        setIsMuted((prevIsMuted) => {
        const newIsMuted = !prevIsMuted;
        if (!newIsMuted) {
            setDelay(2000); // Set delay to 2 seconds when unmuted
        }
        return newIsMuted;
    });
  };

  return (
    <div className="visualization-controls d-flex justify-content-between align-items-center mb-3 gap-2">
        <button 
            className="btn btn-success btn-sm"
            onClick={runVisualization}
            disabled={isRunning}
        >
            <i className="fa fa-play"></i> Run
        </button>
        <button onClick={() => setIsPaused(!isPaused)} disabled={!isRunning} className="btn btn-warning btn-sm">
            <i className={`fa ${isPaused ? 'fa-play-circle' : 'fa-pause-circle'}`}></i> {isPaused ? 'Resume' : 'Pause'}
        </button>
        <label htmlFor="delayRange">
         Delay: <span id="delayValue">{delay / 1000}</span>s
        </label>
        <input
            type="range"
            id="delayRange"
            min="0.5"
            max="10"
            step="0.1"
            value={delay / 1000}
            onChange={(e) => setDelay(e.target.value * 1000)}
            disabled={!isMuted}
            title={!isMuted ? 'Mute to adjust delay' : 'Adjust delay'}
        />
        <div onClick={handleMuteToggle} className={`mute-button ${isMuted ? 'muted' : 'unmuted'}`}>
            <div className="inner-circle">
                <i className={`fa ${isMuted ? 'fa-volume-off' : 'fa-volume-up'}`}></i>
                <div className="wave"></div>
            </div>
        </div>
    </div>
  );
};

export default Controls;
