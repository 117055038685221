import React from 'react';

const UpdateVariablesModal = ({ isVisible, onClose, initialStudent, setInitialStudent, onSave }) => {
  if (!isVisible) return null;

  return (
    <div className="modal show d-block" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content modal-glass-avatar">
          <div className="modal-header">
            <h5 className="modal-title-update-variable">Update Variables</h5>
          </div>
          <div className="modal-body">
            <div className='row'>
              <div className='col-md-6'>
                <label>
                  Student name:
                  <input
                    type="text"
                    className="form-control custom-input"
                    value={initialStudent.name}
                    onChange={(e) => 
                      setInitialStudent({
                        ...initialStudent,
                        name: e.target.value,
                      })
                    }
                  />
                </label>
              </div>
              <div className='col-md-6'>
                <label>
                  Student age:
                  <input
                    type="number"
                    className="form-control custom-input"
                    value={initialStudent.age}
                    onChange={(e) => 
                      setInitialStudent({
                        ...initialStudent,
                        age: e.target.value,
                      })
                    }
                  />
                </label>
              </div>
            </div>
            <div className='row' style={{paddingTop: '20px'}}>
              <div className='col-md-6'>
                <label>
                  Student graduated:
                  <select
                    className="form-control custom-input custom-dropdown"
                    value={initialStudent.graduated}
                    onChange={(e) => 
                      setInitialStudent({
                        ...initialStudent,
                        graduated: e.target.value,
                      })
                    }
                  >
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                </label>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-dark" onClick={onClose}>
              Cancel
            </button>
            <button type="button" className="btn btn-success" onClick={onSave}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateVariablesModal;
