import { useState, useEffect, useRef } from 'react';
import explanations from './explanations';
import codeSamplesTemplate from './codeSamples';

export const useFloatTypes = (language, piValue, averageBodyTemperature, gravitationalAcceleration) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [callingLine, setCallingLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const delayRef = useRef(delay);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [arrayVariables, setArrayVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedArrayVariables, setHighlightedArrayVariables] = useState([]);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const [isMuted, setIsMuted] = useState(true);
  const isMutedRef = useRef(isMuted);
  const synth = window.speechSynthesis;


  const updateVariables = (newPiValue, newAverageBodyTemperature, newGravitationalAcceleration) => {
    setVariables({});
     // Dynamically update the code samples
     let updatedCodeSamples = "";
     if (language === "Java") {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(/piValue\s*=\s*[\d.]+f;/, `piValue = ${newPiValue}f;`)
          .replace(/averageBodyTemperature\s*=\s*[\d.]+f;/, `averageBodyTemperature = ${newAverageBodyTemperature}f;`)
          .replace(/gravitationalAcceleration\s*=\s*[\d.]+f;/, `gravitationalAcceleration = ${newGravitationalAcceleration}f;`)
      );
     } else {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(/piValue\s*=\s*[\d.e-]+;/, `piValue = ${newPiValue};`)
          .replace(/averageBodyTemperature\s*=\s*[\d.e-]+;/, `averageBodyTemperature = ${newAverageBodyTemperature};`)
          .replace(/gravitationalAcceleration\s*=\s*[\d.e-]+;/, `gravitationalAcceleration = ${newGravitationalAcceleration};`)
      );
     }
     
    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setCallingLine(null);
    setVariables({});
    setLogs([]);
    setHighlightedVariables([]);
    setArrayVariables({});
    setHighlightedArrayVariables([]);
  };

  const speak = (message) => {
    return new Promise((resolve) => {
      if (isMutedRef.current) {
        resolve();
        return;
      }
      const utterance = new SpeechSynthesisUtterance(message);
      utterance.onend = resolve;
      synth.speak(utterance);
    });
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setCallingLine((prevCallingLine) => {
      if (prevCallingLine === lineNumber) {
        return null;
      }
      return prevCallingLine;
    });
    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async () => {
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runFloatTypesJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(2);
    setVariables((vars) => ({ ...vars, piValue: { variable_name: 'piValue', value: piValue } }));
    setHighlightedVariables(['piValue']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(3);
    setVariables((vars) => ({ ...vars, averageBodyTemperature: { variable_name: 'averageBodyTemperature', value: averageBodyTemperature } }));
    setHighlightedVariables(['averageBodyTemperature']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(4);
    setVariables((vars) => ({ ...vars, gravitationalAcceleration: { variable_name: 'gravitationalAcceleration', value: gravitationalAcceleration } }));
    setHighlightedVariables(['gravitationalAcceleration']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(6);
    setHighlightedVariables(['piValue']);
    await logMessage(`Value of Pi: ${piValue}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(8);
    setHighlightedVariables(['averageBodyTemperature']);
    await logMessage(`Average human body temperature in Celsius: ${averageBodyTemperature}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(10);
    setHighlightedVariables(['gravitationalAcceleration']);
    await logMessage(`Gravitational acceleration in m/s²: ${gravitationalAcceleration}`);
    await customSleep();
    setHighlightedVariables([]);

    await focusEndLine(12);
    setIsRunning(false);
  };

  const runFloatTypesC = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(2);
    setVariables((vars) => ({ ...vars, piValue: { variable_name: 'piValue', value: piValue } }));
    setHighlightedVariables(['piValue']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(3);
    setVariables((vars) => ({ ...vars, averageBodyTemperature: { variable_name: 'averageBodyTemperature', value: averageBodyTemperature } }));
    setHighlightedVariables(['averageBodyTemperature']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(4);
    setVariables((vars) => ({ ...vars, gravitationalAcceleration: { variable_name: 'gravitationalAcceleration', value: gravitationalAcceleration } }));
    setHighlightedVariables(['gravitationalAcceleration']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(6);
    setHighlightedVariables(['piValue']);
    await logMessage(`Value of Pi: ${piValue}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(8);
    setHighlightedVariables(['averageBodyTemperature']);
    await logMessage(`Average human body temperature in Celsius: ${averageBodyTemperature}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(10);
    setHighlightedVariables(['gravitationalAcceleration']);
    await logMessage(`Gravitational acceleration in m/s²: ${gravitationalAcceleration}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(12);
    await customSleep();

    await focusEndLine(13);
    setIsRunning(false);
  };

  const runFloatTypesCpp = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(3);
    setVariables((vars) => ({ ...vars, piValue: { variable_name: 'piValue', value: piValue } }));
    setHighlightedVariables(['piValue']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(4);
    setVariables((vars) => ({ ...vars, averageBodyTemperature: { variable_name: 'averageBodyTemperature', value: averageBodyTemperature } }));
    setHighlightedVariables(['averageBodyTemperature']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(5);
    setVariables((vars) => ({ ...vars, gravitationalAcceleration: { variable_name: 'gravitationalAcceleration', value: gravitationalAcceleration } }));
    setHighlightedVariables(['gravitationalAcceleration']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(7);
    setHighlightedVariables(['piValue']);
    await logMessage(`Value of Pi: ${piValue}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(9);
    setHighlightedVariables(['averageBodyTemperature']);
    await logMessage(`Average human body temperature in Celsius: ${averageBodyTemperature}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(11);
    setHighlightedVariables(['gravitationalAcceleration']);
    await logMessage(`Gravitational acceleration in m/s²: ${gravitationalAcceleration}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(13);
    await customSleep();

    await focusEndLine(14);
    setIsRunning(false);
  };

  const runFloatTypes = async () => {
    if (language === 'Java') {
      await runFloatTypesJava();
    } else if (language === 'C') {
      await runFloatTypesC();
    } else if (language === 'C++') {
      await runFloatTypesCpp();
    }
  };

  return {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    highlightedArrayVariables,
    isMuted,
    codeSamples,
    updateVariables,
    setHighlightedLine,
    setFocusedEndLine,
    setCallingLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setArrayVariables,
    setHighlightedVariables,
    setHighlightedArrayVariables,
    setIsMuted,
    runFloatTypes
  };
};
