const explanations = {
    C: {
      1: "Including the standard input/output header for C",
      3: "Defining a union named 'MagicalBox'",
      4: "Declaring an integer member 'number' in the union",
      5: "Declaring a character member 'letter' in the union",
      6: "Declaring a const char* member 'candy' in the union",
      9: "Starting the main function",
      10: "Creating a variable of union type 'MagicalBox' named 'box'",
      13: "Assigning an integer value to the 'number' member of the union",
      14: "Printing the current value of 'number' from the union",
      17: "Assigning a character value to the 'letter' member of the union",
      18: "Printing the current value of 'letter' from the union",
      21: "Assigning a string to the 'candy' member of the union",
      22: "Printing the current value of 'candy' from the union",
      25: "Printing the current value of 'number' after assigning to 'candy'",
      26: "Printing the current value of 'letter' after assigning to 'candy'",
      28: "Returning 0 to indicate successful execution"
    },
    'C++': {
      1: "Including the iostream library for input-output operations",
      3: "Defining a union named 'MagicalBox'",
      4: "Declaring an integer member 'number' in the union",
      5: "Declaring a character member 'letter' in the union",
      6: "Declaring a const char* member 'candy' in the union",
      9: "Starting the main function",
      10: "Creating a variable of union type 'MagicalBox' named 'box'",
      13: "Assigning an integer value to the 'number' member of the union",
      14: "Printing the current value of 'number' from the union",
      17: "Assigning a character to the 'letter' member of the union",
      18: "Printing the current value of 'letter' from the union",
      21: "Assigning a string to the 'candy' member of the union",
      22: "Printing the current value of 'candy' from the union",
      25: "Printing the current value of 'number' after assigning to 'candy'",
      26: "Printing the current value of 'letter' after assigning to 'candy'",
      28: "Returning 0 to indicate successful execution"
    }
  };
  
  export default explanations;
  