const explanations = {
  Python: {
    1: "Import the deque class from the collections module to create a double-ended queue.",
    3: "Define the Graph class to represent a graph structure.",
    4: "Define the constructor method to initialize the graph object.",
    5: "Initialize an empty dictionary to represent the adjacency list of the graph.",
    7: "Executing the bfs (Breadth-First Search) method starting from a specific vertex.",
    8: "Initialize a set to keep track of visited vertices.",
    9: "Initialize the queue with the starting vertex using deque for efficient pops.",
    10: "Mark the starting vertex as visited.",
    11: "Start a loop that continues as long as there are vertices in the queue.",
    12: "Pop the first vertex from the queue.",
    13: "Print the vertex as part of the BFS traversal.",
    14: "Iterate through the neighbors of the vertex.",
    15: "Checking if the neighbor has not been visited.",
    16: "Neighbor has not visited yet, mark the neighbor as visited.",
    17: "Enqueue the neighbor by appending it to the queue.",
    20: "Main block to test the Graph class functionality.",
    21: "Creating an instance of the Graph class.",
    23: "Calling bfs method with a starting vertex to perfrom Breadth-First Search.",
  },
  Java: {
    1: "Importing the necessary Java utilities for lists, sets, stacks, and queues.",
    3: "Defining the Graph class to represent the graph structure.",
    4: "A HashMap is used to store the adjacency list for each vertex.",
    6: "Constructor method to initialize the graph object.",
    7: "Initialize the graph as an empty HashMap.",
    10: "Executing the bfs (Breadth-First Search) method starting from a specific vertex.",
    11: "Initialize a set to keep track of visited vertices.",
    12: "A queue is initialized using LinkedList to store vertices in BFS order for processing in a FIFO manner.",
    13: "Adding the starting vertex to the queue.",
    14: "Mark the starting vertex as visited.",
    15: "Start a loop that continues as long as there are vertices in the queue.",
    16: "Pop the first vertex from the queue.",
    17: "Print the vertex as part of the BFS traversal.",
    18: "Iterate through the neighbors of the vertex.",
    19: "Checking if the neighbor has not been visited.",
    20: "Neighbor has not visited yet, mark the neighbor as visited.",
    21: "Enqueue the neighbor by appending it to the queue.",
    28: "Define the Main class to test the BFS functionality.",
    29: "Main method to execute the program.",
    30: "Creating an instance of the Graph class.",
    32: "Calling bfs method with a starting vertex to perfrom Breadth-First Search.",
  },
  C: {
    1: "Including the standard input-output library for printing to the console.",
    2: "Including the standard library for memory allocation and other utilities.",
    4: "Defining a macro MAX_VERTICES, representing the maximum number of vertices in the graph.",
    6: "Executing the bfs (Breadth-First Search) function that takes a graph and a starting vertex as parameters.",
    7: "Declaring an array to keep track of visited vertices, initialized to 0 (unvisited).",
    8: "Declaring an array to represent the queue, which will store vertices during BFS traversal.",
    9: "Initializing front and rear pointers for managing the queue.",
    11: "Marking the starting vertex as visited by setting visited[start] to 1.",
    12: "Enqueueing the starting vertex by adding it to the queue and incrementing the rear pointer.",
    14: "Starting a while loop that runs until the queue is empty (i.e., front is no longer less than rear).",
    15: "Dequeueing the next vertex from the queue by incrementing the front pointer.",
    16: "Print the vertex as part of the BFS traversal.",
    18: "Setting up a temp pointer to iterate over the adjacency list of the current vertex.",
    19: "Starting a while loop to traverse the adjacency list of the current vertex.",
    20: "Checking if the neighbor (adjacent vertex) has not been visited yet.",
    21: "Neighbor not visited, marking the neighbor as visited by setting visited[neighbor] to 1.",
    22: "Enqueueing the neighbor by adding it to the queue and incrementing the rear pointer.",
    24: "Moving to the next node in the adjacency list.",
    29: "Starting the main function.",
    30: "Creating a new graph using the createGraph function.",
    32: "Calling the bfs function with a starting vertex to perfrom Breadth-First Search.",
    34: "Returning 0 to indicate successful execution."
  },
  "C++": {
    1: "Including the input-output stream library for printing to the console.",
    2: "Including the unordered_map library to store the adjacency list of the graph.",
    3: "Including the vector library to store neighbors of each vertex.",
    4: "Including the queue library for implementing Breadth-First Search (BFS).",
    5: "Including the set library to track visited nodes.",
    7: "Using the standard namespace to avoid using 'std::' prefix before standard library functions.",
    9: "Declaring the Graph class to represent the graph structure.",
    10: "Defining the private access specifier for class members.",
    11: "Declaring an unordered_map to store the adjacency list, where each vertex is a key and its neighbors are stored in a vector.",
    13: "Defining the public access specifier for public class members.",
    15: "Executing the bfs (Breadth-First Search) method starting from a specific vertex.",
    16: "Initializing a set to track visited vertices.",
    17: "Initializing a queue to manage the BFS traversal.",
    18: "Adding the starting vertex to the queue.",
    19: "Marking the starting vertex as visited by inserting it into the set.",
    20: "Starting a while loop that runs until the queue is empty.",
    21: "Fetching the vertex at the front of the queue.",
    22: "Removing the front element from the queue.",
    23: "Print the vertex as part of the BFS traversal.",
    24: "Iterating over all neighbors of the current vertex.",
    25: "Checking if the neighbor has not been visited yet.",
    26: "Marking the neighbor as visited by inserting it into the set.",
    27: "Adding the neighbor to the queue for further exploration.",
    34: "Main method to test the Graph class functionality.",
    35: "Creating an instance of the Graph class.",
    37: "Calling bfs method with a starting vertex to perfrom Breadth-First Search.",
    38: "Returning 0 to indicate successful execution."
  }  
};
  
  export default explanations;
  