const codeSamples = {
    Python: [
      "if __name__ == '__main__':",
      "    greeting = \"Hello, World!\"",
      "    for i in range(len(greeting)):",
      "        print(greeting[i])"
    ],
    Java: [
      "public class BasicString {",
      "    public static void main(String[] args) {",
      "        String greeting = \"Hello, World!\";",
      "        for (int i = 0; i < greeting.length(); i++) {",
      "            System.out.println(greeting.charAt(i));",
      "        }",
      "    }",
      "}"
    ],
    C: [
      "#include <stdio.h>",
      "#include <string.h>",
      "  ",
      "int main() {",
      "    char greeting[] = \"Hello, World!\";",
      "    int size = strlen(greeting);",
      "    for (int i = 0; i < size; i++) {",
      "        printf(\"%c\\n\", greeting[i]);",
      "    }",
      "    return 0;",
      "}"
    ],
    "C++": [
      "#include <iostream>",
      "#include <cstring>",
      "using namespace std;",
      "  ",
      "int main() {",
      "    char greeting[] = \"Hello, World!\";",
      "    int size = strlen(greeting);",
      "    for (int i = 0; i < size; i++) {",
      "        cout << greeting[i] << endl;",
      "    }",
      "    return 0;",
      "}"
    ]
  };
  
  export default codeSamples;
  