const explanations = {
    Java: {
      3: "Declaring and initializing double variable earthMass",
      4: "Declaring and initializing double variable avogadroNumber",
      5: "Declaring and initializing double variable electronCharge",
      7: "Printing Earth's mass in kilograms",
      9: "Printing Avogadro's number",
      11: "Printing electron charge in coulombs",
    },
    C: {
      3: "Declaring and initializing double variable earthMass",
      4: "Declaring and initializing double variable avogadroNumber",
      5: "Declaring and initializing double variable electronCharge",
      7: "Printing Earth's mass in kilograms",
      9: "Printing Avogadro's number",
      11: "Printing electron charge in coulombs",
      13: "Returning 0 to indicate successful execution"
    },
    "C++": {
      4: "Declaring and initializing double variable earthMass",
      5: "Declaring and initializing double variable avogadroNumber",
      6: "Declaring and initializing double variable electronCharge",
      8: "Printing Earth's mass in kilograms",
      10: "Printing Avogadro's number",
      12: "Printing electron charge in coulombs",
      14: "Returning 0 to indicate successful execution"
    }
  };
  
  export default explanations;
  