const codeSamples = {
    C: [
      "#include <stdio.h>",
      " ",
      "typedef unsigned int CakeCount;",
      " ",
      "int main() {",
      "    CakeCount cakesBakedToday = 25;",
      "    CakeCount cakesBakedYesterday = 30;",
      " ",
      "    printf(\"Cakes baked today: %u\\n\", cakesBakedToday);",
      "    printf(\"Cakes baked yesterday: %u\\n\", cakesBakedYesterday);",
      " ",
      "    return 0;",
      "}"
    ],
    'C++': [
      "#include <iostream>",
      " ",
      "typedef unsigned int CakeCount;",
      " ",
      "int main() {",
      "    CakeCount cakesBakedToday = 25;",
      "    CakeCount cakesBakedYesterday = 30;",
      " ",
      "    std::cout << \"Cakes baked today: \" << cakesBakedToday << std::endl;",
      "    std::cout << \"Cakes baked yesterday: \" << cakesBakedYesterday << std::endl;",
      " ",
      "    return 0;",
      "}"
    ],
  };
  
  export default codeSamples;
  