const codeSamples = {
    Python: [
      "class BinaryTree:",
      "    def __init__(self):",
      "        self.root = None",
      "  ",
      "    def postorder_traversal(self):",
      "        current = self.root",
      "        if current is None:",
      "            return",
      "  ",
      "        stack1 = [current]",
      "        stack2 = []",
      "        while len(stack1) > 0:",
      "            current = stack1.pop()",
      "            stack2.append(current)",
      "            if current.left is not None:",
      "                stack1.append(current.left)",
      "            if current.right is not None:",
      "                stack1.append(current.right)",
      "  ",
      "        while len(stack2) > 0:",
      "            current = stack2.pop()",
      "            print(current.data, end=', ')",
      "  ",
      "  ",
      "if __name__ == '__main__':",
      "    binaryTree = BinaryTree()",
      "  ",
      "    binaryTree.postorder_traversal()",
    ],
    Java: [
      "import java.util.Stack;",
      "  ",
      "class BinaryTree {",
      "    Node root;",
      "  ",
      "    BinaryTree() {",
      "        root = null;",
      "    }",
      "  ",
      "    void postorderTraversal() {",
      "        Node current = root;",
      "        if (current == null) {",
      "            return;",
      "        }",
      "  ",
      "        Stack<Node> stack1 = new Stack<>();",
      "        Stack<Node> stack2 = new Stack<>();",
      "        stack1.push(current);",
      "        while (!stack1.isEmpty()) {",
      "            current = stack1.pop();",
      "            stack2.push(current);",
      "            if (current.left != null) {",
      "                stack1.push(current.left);",
      "            }",
      "            if (current.right != null) {",
      "                stack1.push(current.right);",
      "            }",
      "        }",
      "  ",
      "        while (!stack2.isEmpty()) {",
      "            current = stack2.pop();",
      "            System.out.print(current.data + \", \");",
      "        }",
      "    }",
      "}",
      "  ",
      "public class Main {",
      "    public static void main(String[] args) {",
      "        BinaryTree binaryTree = new BinaryTree();",
      "  ",
      "        binaryTree.postorderTraversal();",
      "    }",
      "}",
    ],
    C: [
      "#include <stdio.h>",
      "#include <stdlib.h>",
      "  ",
      "struct Node {",
      "    int data;",
      "    struct Node* left;",
      "    struct Node* right;",
      "};",
      "  ",
      "void postorderTraversal(struct Node* root) {",
      "    if (root == NULL) {",
      "        return;",
      "    }",
      "  ",
      "    struct Node* stack1[100];",
      "    struct Node* stack2[100];",
      "    int top1 = -1, top2 = -1;",
      "  ",
      "    stack1[++top1] = root;",
      "  ",
      "    while (top1 >= 0) {",
      "        struct Node* current = stack1[top1--];",
      "        stack2[++top2] = current;",
      "  ",
      "        if (current->left != NULL) {",
      "            stack1[++top1] = current->left;",
      "        }",
      "  ",
      "        if (current->right != NULL) {",
      "            stack1[++top1] = current->right;",
      "        }",
      "    }",
      "  ",
      "    while (top2 >= 0) {",
      "        struct Node* current = stack2[top2--];",
      "        printf(\"%d, \", current->data);",
      "    }",
      "}",
      "  ",
      "int main() {",
      "    struct Node* root = NULL;",
      "    postorderTraversal(root);",
      "    return 0;",
      "}"
    ],
    'C++': [
      "#include <iostream>",
      "#include <stack>",
      "using namespace std;",
      "  ",
      "class BinaryTree {",
      "public:",
      "    Node* root;",
      "  ",
      "    BinaryTree() {",
      "        root = nullptr;",
      "    }",
      "  ",
      "    void postorderTraversal() {",
      "        Node* current = root;",
      "        if (current == nullptr) {",
      "            return;",
      "        }",
      "  ",
      "        stack<Node*> stack1;",
      "        stack<Node*> stack2;",
      "        stack1.push(current);",
      "        while (!stack1.empty()) {",
      "            current = stack1.top();",
      "            stack1.pop();",
      "            stack2.push(current);",
      "            if (current->left) {",
      "                stack1.push(current->left);",
      "            }",
      "            if (current->right) {",
      "                stack1.push(current->right);",
      "            }",
      "        }",
      "  ",
      "        while (!stack2.empty()) {",
      "            current = stack2.top();",
      "            stack2.pop();",
      "            cout << current->data << \", \";",
      "        }",
      "    }",
      "};",
      "  ",
      "int main() {",
      "    BinaryTree binaryTree;",
      "  ",
      "    binaryTree.postorderTraversal();",
      "    return 0;",
      "}"
    ],
  };
  
  export default codeSamples;
  