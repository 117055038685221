const codeSamples = {
    Python: [
        "if __name__ == '__main__':",
        "    # Declaring variables",
        "    x = 10          # Integer",
        "    y = 3.14        # Float",
        "    z = 2 + 3j      # Complex number",
        "  ",
        "    # Printing the values and types",
        "    print('x (Integer):', x, '| Type:', type(x))",
        "    print('y (Float):', y, '| Type:', type(y))",
        "    print('z (Complex):', z, '| Type:', type(z))",
        ""
    ]
};

export default codeSamples;
