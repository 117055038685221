const codeSamples = {
    C: [
        "#include <stdio.h>",
        " ",
        "struct Student {",
        "    char name[50];",
        "    int age;",
        "};",
        " ",
        "int main() {",
        "    struct Student student1;",
        " ",
        "    snprintf(student1.name, sizeof(student1.name), \"Alice\");",
        "    student1.age = 20;",
        " ",
        "    printf(\"Student Name: %s\\n\", student1.name);",
        "    printf(\"Student Age: %d\\n\", student1.age);",
        " ",
        "    return 0;",
        "}"
    ],    
    'C++': [
        "#include <iostream>",
        "using namespace std;",
        " ",
        "struct Person {",
        "    string name;",
        "    int age;",
        " ",
        "    void displayInfo() {",
        "        cout << \"Name: \" << name << endl;",
        "        cout << \"Age: \" << age << endl;",
        "    }",
        " ",
        "    void setDetails(string personName, int personAge) {",
        "        name = personName;",
        "        age = personAge;",
        "    }",
        "};",
        " ",
        "int main() {",
        "    Person person1;",
        "    person1.setDetails(\"John Doe\", 25);",
        "    person1.displayInfo();",
        " ",
        "    return 0;",
        "}"
    ]
};

export default codeSamples;
