import { useState, useEffect, useRef } from 'react';
import { stackPython, stackJava, stackC, stackCpp } from './stackAlgorithms';
import explanations from './explanations';
import codeSamplesTemplate from './codeSamples';

export const useStack = (language, stackSize, data) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [callingLine, setCallingLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const delayRef = useRef(delay);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [arrayVariables, setArrayVariables] = useState({});
  const [stackVariables, setStackVariables] = useState({});
  const [tos, setTos] = useState(-1);
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedArrayVariables, setHighlightedArrayVariables] = useState([]);
  const [highlightedStackVariables, setHighlightedStackVariables] = useState([]);
  const [highlightedIndices, setHighlightedIndices] = useState([]);
  const [highlightedStackIndex, setHighlightedStackIndex] = useState([]);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const [isMuted, setIsMuted] = useState(true);
  const isMutedRef = useRef(isMuted);
  const synth = window.speechSynthesis;


  const updateVariables = (newData) => {
    setVariables({});
    resetState();
    let updatedCodeSamples = "";
    let index = 0;
     // Dynamically update the code samples
     updatedCodeSamples = codeSamplesTemplate[language].map((line) => {
      return line.replace(/push\s*\(\s*\d+\s*\)/g, () => {
        const newValue = newData[index] || 0; // Fallback to 0 if out of range
        index++;
        return `push(${newValue})`;
      });
    });
    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setCallingLine(null);
    setVariables({});
    setLogs([]);
    setTos(-1);
    setVariables({});
    setArrayVariables({});
    setStackVariables({});
    setHighlightedVariables([]);
    setHighlightedArrayVariables([]);
    setHighlightedStackVariables([]);
    setHighlightedIndices([]);
    setHighlightedStackIndex([]);
  };

  const speak = (message) => {
    return new Promise((resolve) => {
      if (isMutedRef.current) {
        resolve();
        return;
      }
      const utterance = new SpeechSynthesisUtterance(message);
      utterance.onend = resolve;
      synth.speak(utterance);
    });
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setCallingLine((prevCallingLine) => {
      if (prevCallingLine === lineNumber) {
        return null;
      }
      return prevCallingLine;
    });
    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const highlightCallingLine = async (lineNumber) => {
    setCallingLine(lineNumber);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async () => {
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  // Run Stack Visualization for Python
  const runStackPython = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    const { init, push, pop } = await stackPython(highlightLine, setVariables, setArrayVariables, setStackVariables, setHighlightedVariables, setHighlightedArrayVariables, setHighlightedStackVariables, setHighlightedIndices, setHighlightedStackIndex, highlightCallingLine, customSleep, logMessage);
    
    await highlightLine(28);
    await customSleep();

    await highlightCallingLine(28);
    await init(stackSize);
    await highlightLine(28);
    await customSleep();

    await highlightLine(30);
    await customSleep();

    await highlightCallingLine(30);
    await pop();
    await customSleep();
    await highlightLine(30);
    await customSleep();

    await highlightLine(32);
    await customSleep();

    await highlightCallingLine(32);
    await push(data[0]);
    await customSleep();
    await highlightLine(32);
    await customSleep();

    await highlightLine(33);
    await customSleep();
    
    await highlightCallingLine(33);
    await push(data[1]);
    await customSleep();
    await highlightLine(33);
    await customSleep();

    await highlightLine(34);
    await customSleep();
    
    await highlightCallingLine(34);
    await push(data[2]);
    await customSleep();
    await highlightLine(34);
    await customSleep();

    await highlightLine(35);
    await customSleep();
    
    await highlightCallingLine(35);
    await push(data[3]);
    await customSleep();
    await highlightLine(35);
    await customSleep();

    await highlightLine(37);
    await customSleep();

    await highlightCallingLine(37);
    await pop();
    await highlightLine(37);
    await customSleep();


    await focusEndLine(37);
    setIsRunning(false);
  };

  // Run Stack Visualization for Java
  const runStackJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    const { init, push, pop } = await stackJava(highlightLine, setVariables, setArrayVariables, setStackVariables, setHighlightedVariables, setHighlightedArrayVariables, setHighlightedStackVariables, setHighlightedIndices, setHighlightedStackIndex, highlightCallingLine, customSleep, logMessage);
    
    await highlightLine(39);
    await customSleep();

    await highlightCallingLine(39);
    await init(stackSize);
    await highlightLine(39);
    await customSleep();

    await highlightLine(41);
    await customSleep();

    await highlightCallingLine(41);
    await pop();
    await customSleep();
    await highlightLine(41);
    await customSleep();

    await highlightLine(43);
    await customSleep();

    await highlightCallingLine(43);
    await push(data[0]);
    await customSleep();
    await highlightLine(43);
    await customSleep();

    await highlightLine(44);
    await customSleep();
    
    await highlightCallingLine(44);
    await push(data[1]);
    await customSleep();
    await highlightLine(44);
    await customSleep();

    await highlightLine(45);
    await customSleep();
    
    await highlightCallingLine(45);
    await push(data[2]);
    await customSleep();
    await highlightLine(45);
    await customSleep();

    await highlightLine(46);
    await customSleep();
    
    await highlightCallingLine(46);
    await push(data[3]);
    await customSleep();
    await highlightLine(46);
    await customSleep();

    await highlightLine(48);
    await customSleep();

    await highlightCallingLine(48);
    await pop();
    await highlightLine(48);
    await customSleep();


    await focusEndLine(50);
    setIsRunning(false);
  };

  // Run Stack Visualization for C
  const runStackC = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    const { init, push, pop, display } = await stackC(highlightLine, setVariables, setArrayVariables, setStackVariables, setHighlightedVariables, setHighlightedArrayVariables, setHighlightedStackVariables, setHighlightedIndices, setHighlightedStackIndex, highlightCallingLine, customSleep, logMessage);
    
    await init(stackSize);

    await highlightLine(39);
    await customSleep();

    await highlightCallingLine(39);
    await push(data[0]);
    await customSleep();
    await highlightLine(39);
    await customSleep();

    await highlightLine(40);
    await customSleep();
    
    await highlightCallingLine(40);
    await push(data[1]);
    await customSleep();
    await highlightLine(40);
    await customSleep();

    await highlightLine(41);
    await customSleep();
    
    await highlightCallingLine(41);
    await push(data[2]);
    await customSleep();
    await highlightLine(41);
    await customSleep();

    await highlightLine(42);
    await customSleep();
    
    await highlightCallingLine(42);
    await push(data[3]);
    await customSleep();
    await highlightLine(42);
    await customSleep();

    await highlightLine(44);
    await customSleep();

    await highlightCallingLine(44);
    await display();
    await highlightLine(44);
    await customSleep();

    await highlightLine(46);
    await customSleep();

    await highlightCallingLine(46);
    await pop();
    await highlightLine(46);
    await customSleep();

    await highlightLine(47);
    await customSleep();

    await highlightCallingLine(47);
    await pop();
    await highlightLine(47);
    await customSleep();

    await highlightLine(49);
    await customSleep();

    await highlightCallingLine(49);
    await display();
    await highlightLine(49);
    await customSleep();

    await highlightLine(51);
    await customSleep();

    await focusEndLine(52);
    setIsRunning(false);
  };

  // Run Stack Visualization for C++
  const runStackCpp = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    const { init, push, pop, display } = await stackCpp(highlightLine, setVariables, setArrayVariables, setStackVariables, setHighlightedVariables, setHighlightedArrayVariables, setHighlightedStackVariables, setHighlightedIndices, setHighlightedStackIndex, highlightCallingLine, customSleep, logMessage);
    
    await highlightLine(43);
    await customSleep();

    await highlightCallingLine(43);
    await init(stackSize);
    await highlightLine(43);
    await customSleep();

    await highlightLine(45);
    await customSleep();

    await highlightCallingLine(45);
    await push(data[0]);
    await customSleep();
    await highlightLine(45);
    await customSleep();

    await highlightLine(46);
    await customSleep();
    
    await highlightCallingLine(46);
    await push(data[1]);
    await customSleep();
    await highlightLine(46);
    await customSleep();

    await highlightLine(47);
    await customSleep();
    
    await highlightCallingLine(47);
    await push(data[2]);
    await customSleep();
    await highlightLine(47);
    await customSleep();

    await highlightLine(48);
    await customSleep();
    
    await highlightCallingLine(48);
    await push(data[3]);
    await customSleep();
    await highlightLine(48);
    await customSleep();

    await highlightLine(50);
    await customSleep();

    await highlightCallingLine(50);
    await display();
    await highlightLine(50);
    await customSleep();

    await highlightLine(52);
    await customSleep();

    await highlightCallingLine(52);
    await pop();
    await highlightLine(52);
    await customSleep();

    await highlightLine(53);
    await customSleep();

    await highlightCallingLine(53);
    await pop();
    await highlightLine(53);
    await customSleep();

    await highlightLine(55);
    await customSleep();

    await highlightCallingLine(55);
    await display();
    await highlightLine(55);
    await customSleep();

    await highlightLine(57);
    await customSleep();

    await focusEndLine(58);
    setIsRunning(false);
  };

  const runStack = async () => {
    if (language === 'Python') {
      await runStackPython();
    } else if (language === 'Java') {
      await runStackJava();
    } else if (language === 'C') {
      await runStackC();
    } else if (language === 'C++') {
      await runStackCpp();
    }
  };

  return {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    stackVariables,
    tos,
    highlightedVariables,
    highlightedArrayVariables,
    highlightedStackVariables,
    highlightedIndices,
    highlightedStackIndex,
    isMuted,
    codeSamples,
    updateVariables,
    setHighlightedLine,
    setFocusedEndLine,
    setCallingLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setArrayVariables,
    setStackVariables,
    setTos,
    setHighlightedVariables,
    setHighlightedArrayVariables,
    setHighlightedStackVariables,
    setHighlightedIndices,
    setHighlightedStackIndex,
    setIsMuted,
    runStack
  };
};
