const explanations = {
    Java: {
      3: "Declaring and initializing float variable piValue",
      4: "Declaring and initializing float variable averageBodyTemperature",
      5: "Declaring and initializing float variable gravitationalAcceleration",
      7: "Printing the value of Pi",
      9: "Printing the average human body temperature in Celsius",
      11: "Printing the gravitational acceleration in m/s²"
    },
    C: {
      3: "Declaring and initializing float variable piValue",
      4: "Declaring and initializing float variable averageBodyTemperature",
      5: "Declaring and initializing float variable gravitationalAcceleration",
      7: "Printing the value of Pi",
      9: "Printing the average human body temperature in Celsius",
      11: "Printing the gravitational acceleration in m/s²",
      13: "Returning 0 to indicate successful execution"
    },
    "C++": {
      4: "Declaring and initializing float variable piValue",
      5: "Declaring and initializing float variable averageBodyTemperature",
      6: "Declaring and initializing float variable gravitationalAcceleration",
      8: "Printing the value of Pi",
      10: "Printing the average human body temperature in Celsius",
      12: "Printing the gravitational acceleration in m/s²",
      14: "Returning 0 to indicate successful execution"
    }
  };
  
  export default explanations;
  