import React from 'react';

const UpdateVariablesModal = ({
  modalVisible,
  variables,
  setVariables,
  onCancel,
  onSave,
}) => {
  if (!modalVisible) return null;

  return (
    <div className="modal show d-block" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content modal-glass-avatar">
          <div className="modal-header">
            <h5 className="modal-title-update-variable">Update Variables</h5>
          </div>
          <div className="modal-body">
            {Object.keys(variables).map((key) => (
              <label key={key}>
                Variable {key}:
                <input
                  type="text"
                  className="form-control custom-input"
                  value={variables[key]}
                  onChange={(e) =>
                    setVariables[`set${key.charAt(0).toUpperCase() + key.slice(1)}`](
                      e.target.value
                    )
                  }
                />
              </label>
            ))}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-dark" onClick={onCancel}>
              Cancel
            </button>
            <button type="button" className="btn btn-success" onClick={onSave}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateVariablesModal;
