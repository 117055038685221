const codeSamples = {
    Python: [
      "def bubble_sort(list):",
      "    for i in range(len(list)):",
      "        for j in range(0, len(list) - i - 1):",
      "            if list[j] > list[j + 1]:",
      "                list[j], list[j + 1] = list[j + 1], list[j]",
      "    return list",
      "  ",
      "if __name__ == '__main__':",
      "    list = [64, 25, 12, 22, 11]",
      "    sorted_list = bubble_sort(list)",
      "    print(f'Sorted list: {sorted_list}')"
    ],
    Java: [
      "public class BubbleSort {",
      "    int[] bubbleSort(int list[]) {",
      "        int n = list.length;",
      "        for (int i = 0; i < n-1; i++) {",
      "            for (int j = 0; j < n-i-1; j++) {",
      "                if (list[j] > list[j+1]) {",
      "                    int temp = list[j];",
      "                    list[j] = list[j+1];",
      "                    list[j+1] = temp;",
      "                }",
      "             }",
      "         }",
      "        return list",
      "    }",
      "  ",
      "    public static void main(String args[]) {",
      "        BubbleSort ob = new BubbleSort();",
      "        int list[] = {64, 25, 12, 22, 11};",
      "        int sortedList[] = ob.bubbleSort(list);",
      "        System.out.println('Sorted list:');",
      "        for (int itr = 0; itr < sortedList.length; itr++)",
      "            System.out.print(sortedList[itr] + ' ');",
      "    }",
      "}"
    ],
    C: [
      "#include <stdio.h>",
      "int[] bubbleSort(int list[], int n) {",
      "    int i, j, temp;",
      "    for (i = 0; i < n-1; i++) {",
      "        for (j = 0; j < n-i-1; j++) {",
      "            if (list[j] > list[j+1]) {",
      "                temp = list[j];",
      "                list[j] = list[j+1];",
      "                list[j+1] = temp;",
      "            }",
      "        }",
      "    }",
      "    return list;",
      "}",
      "  ",
      "int main() {",
      "    int list[] = {64, 25, 12, 22, 11};",
      "    int n = sizeof(list)/sizeof(list[0]);",
      "    int sortedList[] = bubbleSort(list, n);",
      "    printf('Sorted list: \\n');",
      "    for (int itr=0; itr < n; itr++)",
      "        printf('%d ', sortedList[itr]);",
      "    return 0;",
      "}"
    ],
    "C++": [
      "#include <iostream>",
      "using namespace std;",
      "int[] bubbleSort(int list[], int n) {",
      "    int i, j, temp;",
      "    for (i = 0; i < n-1; i++) {",
      "        for (j = 0; j < n-i-1; j++) {",
      "            if (list[j] > list[j+1]) {",
      "                temp = list[j];",
      "                list[j] = list[j+1];",
      "                list[j+1] = temp;",
      "            }",
      "        }",
      "    }",
      "    return list;",
      "}",
      "  ",
      "int main() {",
      "    int list[] = {64, 25, 12, 22, 11};",
      "    int n = sizeof(list)/sizeof(list[0]);",
      "    int sortedList[] = bubbleSort(list, n);",
      "    cout << 'Sorted list: ';",
      "    for (int itr = 0; itr < n; itr++)",
      "        cout << sortedList[itr] << ' ';",
      "    return 0;",
      "}"
    ]
  };
  
  export default codeSamples;
  