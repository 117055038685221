const explanations = {
    Python: {
      1: "Defining the selection_sort function",
      2: "Starting the outer loop to iterate over the list",
      3: "Setting the current index as the minimum index",
      4: "Starting the inner loop to find the minimum element",
      5: "Comparing current element with the current minimum",
      6: "Updating the minimum index if a smaller element is found",
      7: "Swapping the found minimum element with the first element",
      8: "Returning the sorted list",
      10: "Main execution block",
      11: "Initializing the list",
      12: "Calling the selection_sort function",
      13: "Printing the result"
    },
    Java: {
      1: "Defining the SelectionSort class",
      2: "Defining the selectionSort method",
      3: "Calculating the size of the array",
      4: "Starting the outer loop to iterate over the array",
      5: "Setting the current index as the minimum index",
      6: "Starting the inner loop to find the minimum element",
      7: "Comparing current element with the current minimum",
      8: "Updating the minimum index if a smaller element is found",
      9: "Storing minimum element with a temp variable",
      10: "Swapping the found minimum element with the first element",
      11: "Swapping the the first element with the temp varaible",
      13: "Returning the sorted array",
      15: "Main method of the SelectionSort class",
      16: "Initializing the SelectionSort object",
      17: "Initializing the array",
      18: "Calling the SelectionSort function",
      19: "Printing the result"
    },
    C: {
      2: "Defining the selectionSort function",
      3: "Declaring variable i, j, minIndex and temp",
      4: "Starting the outer loop to iterate over the array",
      5: "Setting the current index as the minimum index",
      6: "Starting the inner loop to find the minimum element",
      7: "Comparing current element with the current minimum",
      8: "Updating the minimum index if a smaller element is found",
      9: "Storing minimum element with a temp variable",
      10: "Swapping the found minimum element with the first element",
      11: "Swapping the the first element with the temp varaible",
      13: "Returning the sorted array",
      16: "Main function of the program",
      17: "Initializing the array",
      18: "Calculating the size of the array",
      19: "Calling the SelectionSort function",
      20: "Printing the result",
      21: "Starting a loop to print the sorted array",
      22: "Printing item from the array",
      24: "Returning 0 to indicate successful execution"
    },
    'C++': {
      3: "Defining the selectionSort method",
      4: "Declaring variable i, j, minIndex and temp",
      5: "Starting the outer loop to iterate over the array",
      6: "Setting the current index as the minimum index",
      7: "Starting the inner loop to find the minimum element",
      8: "Comparing current element with the current minimum",
      9: "Updating the minimum index if a smaller element is found",
      10: "Storing minimum element with a temp variable",
      11: "Swapping the found minimum element with the first element",
      12: "Swapping the the first element with the temp varaible",
      14: "Returning the sorted array",
      16: "Main function of the program",
      17: "Initializing the array",
      18: "Calculating the size of the array",
      19: "Calling the SelectionSort function",
      20: "Printing the result",
      21: "Returning 0 to indicate successful execution"
    }
};
  
export default explanations;
  