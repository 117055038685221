import React, { useState, useEffect } from 'react';
import { ArcherContainer, ArcherElement } from 'react-archer';
import '../VisualizationCanvas.css';

const VisualizationCanvas = ({ 
  variables,
  stringVariables,
  structureVariables,
  highlightedVariables,
  highlightedStringVariables=[],
  highlightedStructureVariables=[],
  highlightedStringIndices,
  highlightedStructureMethods
}) => {
  const [variableAddresses, setVariableAddresses] = useState({});
  const [stringAddresses, setStringAddresses] = useState({});
  const [structureVariableAddresses, setStructureVariableAddresses] = useState({});

  useEffect(() => {
    const adjustFontSize = () => {
      document.querySelectorAll('.array-value').forEach(element => {
        let fontSize = parseInt(window.getComputedStyle(element).fontSize);
        while (element.scrollWidth > element.clientWidth || element.scrollHeight > element.clientHeight) {
          if (fontSize <= 8) break;
          fontSize--;
          element.style.fontSize = `${fontSize}px`;
        }
      });
    };
    adjustFontSize();
    window.addEventListener('resize', adjustFontSize);
    return () => window.removeEventListener('resize', adjustFontSize);
  }, []);

  useEffect(() => {
    const generateMemoryAddress = () => {
      return '0x' + Math.floor(Math.random() * 0xFFF).toString(16);
    };

    // Initialize variable addresses if not already initialized
    setVariableAddresses(prevAddresses => {
      const newAddresses = { ...prevAddresses };
      Object.keys(variables).forEach(key => {
        if (!newAddresses[key]) {
          newAddresses[key] = generateMemoryAddress();
        }
      });
      return newAddresses;
    });

    // Initialize structureVariable addresses if not already initialized
    setStructureVariableAddresses(prevAddresses => {
      if (structureVariables && typeof structureVariables === 'object') {
        const newAddresses = { ...prevAddresses };
        Object.keys(structureVariables).forEach(key => {
          if (!newAddresses[key]) {
            newAddresses[key] = generateMemoryAddress();
          }
        });
        return newAddresses;
      } else {
        return prevAddresses;
      }
    });

    setStringAddresses(prevAddresses => {
      if (stringVariables && typeof stringVariables === 'object') {
        const newAddresses = { ...prevAddresses };
        const valueToAddressMap = {};
    
        // First, map existing addresses to values
        Object.keys(stringVariables).forEach(key => {
          const value = stringVariables[key].value;
          if (!valueToAddressMap[value] && newAddresses[key]) {
            valueToAddressMap[value] = newAddresses[key];
          }
        });
    
        // Then assign addresses, reusing if the value is the same
        Object.keys(stringVariables).forEach(key => {
          const value = stringVariables[key].value;
          if (!newAddresses[key]) {
            if (valueToAddressMap[value]) {
              // Reuse the address if it already exists for the same value
              newAddresses[key] = valueToAddressMap[value];
            } else {
              // Generate a new address and map it to the value
              const address = generateMemoryAddress();
              newAddresses[key] = address;
              valueToAddressMap[value] = address;
            }
          }
        });
    
        return newAddresses;
      } else {
        return prevAddresses;
      }
    });

  }, [variables, stringVariables, structureVariables]);


  const isStringIndexHighlighted = (stringName, index) => {
    return highlightedStringIndices.some(
      (highlight) => highlight.stringName === stringName && highlight.index === index
    );
  };

  const getStringIteratorName = (stringName, index) => {
    const highlight = highlightedStringIndices.find(
      (highlight) => highlight.stringName === stringName && highlight.index === index
    );

    return highlight && highlight.iteratorName && highlight.iteratorName.length > 0
      ? highlight.iteratorName + " = "
      : "";
  };

  const isVisualizationEmpty = () => {
    return !(
      (variables && Object.keys(variables).length) ||
      (stringVariables && Object.keys(stringVariables).length) ||
      (structureVariables && Object.keys(structureVariables).length)
    );
  };

  return (
    <ArcherContainer strokeColor="red">
      <div className="visualization-canvas" style={{flexDirection: 'column'}}>
        {isVisualizationEmpty() ? (
          <p 
            style={{textAlign: 'center'}}
          >Code visualizations will appear here.</p>
          ) : (
            <>
              <>
                {structureVariables && Object.entries(structureVariables).map(([structureVariableName, structureVariableData], structureVariableIndex) => {
                  const getStyleByDataType = (dataType) => {
                    switch (dataType) {
                      case 'string':
                        return { strokeColor: '#00e676', strokeWidth: 2, noCurves: true };
                      default:
                        return { strokeColor: '#00e676', strokeWidth: 2 };
                    }
                  };

                  const relations = structureVariableData.value.attributes.map((attribute) => ({
                    targetId: attribute.name,
                    style: getStyleByDataType(attribute.dataType),
                    targetAnchor: 'right',
                    sourceAnchor: 'right'
                  }));
                  
                  return (
                      <ArcherElement
                        id={structureVariableName}
                        key={structureVariableName}
                        relations={relations}
                      >
                        <div key={structureVariableIndex}
                          className={`${highlightedStructureVariables.includes(structureVariableName) ? 'highlighted-class-diagram' : 'class-diagram'}`}
                          style={{minWidth: '320px'}}
                        >
                          <div
                            className={`${highlightedStructureVariables.includes(structureVariableName) ? 'highlighted-class-name' : 'class-name'}`}
                          >{structureVariableData.structureName} : Struct</div>
                          <div
                            className={`${highlightedStructureVariables.includes(structureVariableName) ? 'highlighted-attributes' : 'attributes'}`}
                          >
                            {structureVariableData.value.attributes.map((attribute, attributeIndex) => (
                                <div key={attributeIndex}>
                                  - {attribute.name} : {attribute.dataType}
                                </div>
                            ))}
                          </div>
                          <div
                            className={`${highlightedStructureVariables.includes(structureVariableName) ? 'highlighted-methods' : 'methods'}`}
                          >
                            {structureVariableData.value.methods.map((method, methodIndex) => (
                                <div key={methodIndex}
                                  style={{paddingBottom: '5px'}}
                                  className={`${highlightedStructureMethods.includes(method.name) ? 'highlighted-class-method' : ''}`}
                                >
                                + {method.name}({method.parameters.map((param, paramIndex) => (
                                  <span key={paramIndex}>
                                    {param.name} : {param.dataType}{paramIndex < method.parameters.length - 1 ? ', ' : ''}
                                  </span>
                                ))}) {method.returnType ? ` : ${method.returnType}` : ''}
                                </div>
                            ))}
                          </div>
                          <div
                            className={`${highlightedStructureVariables.includes(structureVariableName) ? 'highlighted-object-name' : 'object-name'}`}
                          >
                            {structureVariableName} <br/>
                            <span style={{fontSize: '11px'}}> {structureVariableAddresses[structureVariableName]} </span>
                          </div>
                        </div>
                      </ArcherElement>
                    );
                  })}
              </>

              <div className="variables-container">
                {variables && Object.entries(variables).map(([key, { variable_name, value }], index) => (
                  <ArcherElement
                    id={variable_name}
                    key={variable_name}
                  >
                    <div key={index} className={`variable-box-wrapper`}>
                      <div key={index} className={`variable-box ${highlightedVariables.includes(variable_name) ? 'highlighted-variable' : ''}`}>
                      <div className="variable-value">{value}</div>
                      <div className={`${highlightedVariables.includes(variable_name) ? 'variable-name-highlighted' : 'variable-name'}`}>{variable_name}</div>
                      </div>
                      <div className={`variable-address ${highlightedVariables.includes(variable_name) ? 'highlighted-variable' : ''}`}>{variableAddresses[key]}</div>
                    </div>
                  </ArcherElement>
                ))}
              </div>

              <>
                {stringVariables && Object.entries(stringVariables).map(([stringName, stringData], stringIndex) => (
                  <ArcherElement
                    id={stringName}
                    key={stringName}
                  >
                    <div key={stringIndex} className="string-container">
                      <div className={`${highlightedStringVariables.includes(stringName) ? 'highlighted-string string-variable-box-highlighted' : 'string-variable-box'}`}>
                        <div className="string-variable-name">{stringData.variable_name}
                          <br/>
                          <span style={{fontSize: '11px'}}> {stringAddresses[stringName]} </span>
                        </div>
                      </div>
                      {stringData.value.split('').map((char, index) => (
                        <div key={index}
                          style={stringData.width ? { width: `${stringData.width}` } : {}}
                          className={`${isStringIndexHighlighted(stringName, index) ? 'highlighted-str' : ''} 
                          ${highlightedStringVariables.includes(stringName) ? 'highlighted-string string-highlighted-box' : 'string-box'}`}
                        >
                          <div className="string-index-value">
                            <div className={`${isStringIndexHighlighted(stringName, index) ? 'highlighted-string-index' : 'string-index'} 
                              ${highlightedStringVariables.includes(stringName) ? 'highlighted-string-index' : ''}`}>
                              {getStringIteratorName(stringName, index)}{index} 
                            </div>
                            <div className="string-value">{char}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </ArcherElement>
                ))}
              </>
            </>
          )
        }

      </div>
    </ArcherContainer>
  );
};

export default VisualizationCanvas;
