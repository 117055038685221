import React from 'react';

const UpdateVariablesModal = ({
  isVisible,
  onClose,
  piValue,
  setPiValue,
  averageBodyTemperature,
  setAverageBodyTemperature,
  gravitationalAcceleration,
  setGravitationalAcceleration,
  onSave,
}) => {
  if (!isVisible) return null;

  return (
    <div className="modal show d-block" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content modal-glass-avatar">
          <div className="modal-header">
            <h5 className="modal-title-update-variable">Update Variables</h5>
          </div>
          <div className="modal-body">
            <div className='row'>
                <div className='col-md-6'>
                    <label>
                        PI Value:
                        <input
                            type="number"
                            className="form-control custom-input"
                            value={piValue}
                            onChange={(e) => setPiValue(e.target.value)}
                        />
                    </label>
                </div>
                <div className='col-md-6'>
                    <label>
                        Avg. Body Temperature:
                        <input
                            type="number"
                            className="form-control custom-input"
                            value={averageBodyTemperature}
                            onChange={(e) => setAverageBodyTemperature(e.target.value)}
                        />
                    </label>
                </div>
            </div>
            <div className='row' style={{paddingTop: '20px'}}>
                <div className='col-md-6'>
                    <label>
                        Gravitational Acceleration:
                        <input
                            type="number"
                            className="form-control custom-input"
                            value={gravitationalAcceleration}
                            onChange={(e) => setGravitationalAcceleration(e.target.value)}
                        />
                    </label>
                </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-dark" onClick={onClose}>
              Cancel
            </button>
            <button type="button" className="btn btn-success" onClick={onSave}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateVariablesModal;
