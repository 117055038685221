const codeSamples = {
    Python: [
      "class Queue:",
      "    def __init__(self, _size):",
      "        self.size = _size",
      "        self.queue = [None] * _size",
      "        self.front = self.rear = -1",
      "  ",
      "    def enqueue(self, data):",
      "        if self.rear == self.size - 1:",
      "            print('Queue is Full')",
      "        else:",
      "            if self.front == -1:",
      "                self.front = 0",
      "            self.rear += 1",
      "            self.queue[self.rear] = data",
      "  ",
      "    def dequeue(self):",
      "        if self.front == -1 or self.front > self.rear:",
      "            print('Queue is Empty')",
      "        else:",
      "            self.queue[self.front] = None",
      "            self.front += 1",
      "  ",
      "    def display(self):",
      "        if self.front == -1:",
      "            print('Queue is Empty')",
      "        else:",
      "            print('Queue elements are:')",
      "            for i in range(self.front, self.rear + 1):",
      "                print(self.queue[i])",
      "            print('-----')",
      "  ",
      "if __name__ == '__main__':",
      "    queue = Queue(3)",
      "  ",
      "    queue.dequeue()",
      "  ",
      "    queue.enqueue(10)",
      "    queue.enqueue(20)",
      "    queue.enqueue(30)",
      "    queue.enqueue(40)",
      "  ",
      "    queue.display()",
      "  ",
      "    queue.dequeue()",
      "  ",
      "    queue.display()",
    ],
    Java: [
      "public class Queue {",
      "    private int size;",
      "    private int[] queue;",
      "    private int front, rear;",
      "  ",
      "    public Queue(int _size) {",
      "        size = _size;",
      "        queue = new int[size];",
      "        front = rear = -1;",
      "    }",
      "  ",
      "    public void enqueue(int data) {",
      "        if (rear == size - 1) {",
      "            System.out.println('Queue is Full');",
      "        } else {",
      "            if (front == -1) {",
      "                front = 0;",
      "            }",
      "            rear++;",
      "            queue[rear] = data;",
      "        }",
      "    }",
      "  ",
      "    public void dequeue() {",
      "        if (front == -1 || front > rear) {",
      "            System.out.println('Queue is Empty');",
      "        } else {",
      "            queue[front] = 0;",
      "            front++;",
      "        }",
      "    }",
      "  ",
      "    public void display() {",
      "        if (front == -1) {",
      "            System.out.println('Queue is Empty');",
      "        } else {",
      "            System.out.println('Queue elements are:');",
      "            for (int i = front; i <= rear; i++) {",
      "                System.out.println(queue[i]);",
      "            }",
      "            System.out.println('-----');",
      "        }",
      "    }",
      "  ",
      "    public static void main(String[] args) {",
      "        Queue queue = new Queue(3);",
      "        queue.dequeue();",
      "  ",
      "        queue.enqueue(10);",
      "        queue.enqueue(20);",
      "        queue.enqueue(30);",
      "        queue.enqueue(40);",
      "  ",
      "        queue.display()",
      "  ",
      "        queue.dequeue();",
      "  ",
      "        queue.display()",
      "    }",
      "}"
    ],
    C: [
      "#include <stdio.h>",
      "  ",
      "#define MAX 3  // Define maximum size of the stack",
      "  ",
      "int queue[MAX];",
      "int front = -1, rear = -1, size = MAX;",
      "  ",
      "void enqueue(int data) {",
      "    if (rear == size - 1) {",
      "        printf('Queue is Full\\n');",
      "    } else {",
      "        if (front == -1) {",
      "            front = 0;",
      "        }",
      "        rear++;",
      "        queue[rear] = data;",
      "    }",
      "}",
      "  ",
      "void dequeue() {",
      "    if (front == -1 || front > rear) {",
      "        printf('Queue is Empty\\n');",
      "    } else {",
      "        queue[front] = 0;",
      "        front++;",
      "    }",
      "}",
      "  ",
      "void display() {",
      "    if (front == -1) {",
      "        printf('Queue is Empty\\n');",
      "    } else {",
      "        printf('Queue elements are:\\n');",
      "        for (int i = front; i <= rear; i++) {",
      "            printf('%d\\n', queue[i]);",
      "        }",
      "        printf('-----\\n');",
      "    }",
      "}",
      "  ",
      "int main() {",
      "    dequeue();",
      "  ",
      "    enqueue(10);",
      "    enqueue(20);",
      "    enqueue(30);",
      "    enqueue(40);",
      "  ",
      "    display();",
      "  ",
      "    dequeue();",
      "  ",
      "    display();",
      "  ",
      "    return 0;",
      "}"
    ],
    "C++": [
      "#include <iostream>",
      "using namespace std;",
      "  ",
      "#define MAX 3  // Define maximum size of the stack",
      "  ",
      "int queue[MAX];",
      "int front = -1, rear = -1, size = MAX;",
      "  ",
      "void enqueue(int data) {",
      "    if (rear == size - 1) {",
      "        cout << 'Queue is Full' << endl;",
      "    } else {",
      "        if (front == -1) {",
      "            front = 0;",
      "        }",
      "        rear++;",
      "        queue[rear] = data;",
      "    }",
      "}",
      "  ",
      "void dequeue() {",
      "    if (front == -1 || front > rear) {",
      "        cout << 'Queue is Empty' << endl;",
      "    } else {",
      "        queue[front] = 0;",
      "        front++;",
      "    }",
      "}",
      "  ",
      "void display() {",
      "    if (front == -1) {",
      "        cout << 'Queue is Empty' << endl;",
      "    } else {",
      "        cout << 'Queue elements are:' << endl;",
      "        for (int i = front; i <= rear; i++) {",
      "            cout << queue[i] << endl;",
      "        }",
      "        cout << '-----' << endl;",
      "    }",
      "}",
      "  ",
      "int main() {",
      "    dequeue();",
      "  ",
      "    enqueue(10);",
      "    enqueue(20);",
      "    enqueue(30);",
      "    enqueue(40);",
      "  ",
      "    display();",
      "  ",
      "    dequeue();",
      "  ",
      "    display();",
      "  ",
      "    return 0;",
      "}"
    ]
  };
  
  export default codeSamples;
  