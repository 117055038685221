const codeSamples = {
    Python: [
      'if __name__ == "__main__":',
      '    s1 = "Hello"',
      '    s1 = str + " World"',
      ''
    ],
    Java: [
      'public class StringImmutable {',
      '    public static void main(String[] args) {',
      '        String s1 = "hello";',
      '        s1 = s1.concat(" World");',
      '    }',
      '}',
    ],
  };
  
  export default codeSamples;
  