const codeSamples = {
    Python: [
      "if __name__ == '__main__':",
      "    arr = [5, 7, 1, 9, 2]",
      "    n = len(arr)",
      "    total = 0",
      "    for i in range(0, n):",
      "        total += arr[i]",
      "    print('total = ', total)"
    ],
    Java: [
      "public class SumOfAllElements {",
      "    public static void main(String[] args) {",
      "        int arr[] = {5, 7, 1, 9, 2};",
      "        int n = arr.length;",
      "        int total = 0;",
      "        for (int i = 0; i < n; i++) {",
      "            total += arr[i];",
      "        }",
      "        System.out.println('total = ' + total);",
      "    }",
      "}"
    ],
    C: [
      "#include <stdio.h>",
      " ",
      "int main() {",
      "    int arr[] = {5, 7, 1, 9, 2};",
      "    int n = sizeof(arr) / sizeof(arr[0]);",
      "    int total = 0;",
      "    for (int i = 0; i < n; i++) {",
      "        total += arr[i];",
      "    }",
      "    printf('total = %d\\n', total);",
      "    return 0;",
      "}"
    ],
    "C++": [
      "#include <iostream>",
      "using namespace std;",
      " ",
      "int main() {",
      "    int arr[] = {5, 7, 1, 9, 2};",
      "    int n = sizeof(arr) / sizeof(arr[0]);",
      "    int total = 0;",
      "    for (int i = 0; i < n; i++) {",
      "        total += arr[i];",
      "    }",
      "    cout << 'total = ' << total << endl;",
      "    return 0;",
      "}"
    ]
  };
  
  export default codeSamples;
  