const codeSamples = {
    Python: [
      "class Stack:",
      "    def __init__(self, _size):",
      "        self.size = _size - 1",
      "        self.stack = [\"\" for i in range(_size)]",
      "        self.tos = -1",
      " ",
      "    def push(self, data):",
      "        if self.tos < self.size:",
      "            self.tos += 1",
      "            self.stack[self.tos] = data",
      "            self.display()",
      "        else:",
      "            print('Stack is Full')",
      " ",
      "    def pop(self):",
      "        if self.tos < 0:",
      "            print('Stack is Empty')",
      "        else:",
      "            self.stack[self.tos] = None",
      "            self.tos -= 1",
      "            self.display()",
      " ",
      "    def display(self):",
      "        for i in range(self.tos, -1, -1):",
      "            print(self.stack[i])",
      "        print('-----')",
      " ",
      "if __name__ == '__main__':",
      "    stack = Stack(3)",
      " ",
      "    stack.pop()",
      " ",
      "    stack.push(10)",
      "    stack.push(20)",
      "    stack.push(30)",
      "    stack.push(40)",
      " ",
      "    stack.pop()"
    ],
    Java: [
      "public class Stack {",
      "    private int size;",
      "    private int[] stack;",
      "    private int tos;",
      "  ",
      "    public Stack(int _size) {",
      "        size = _size - 1;",
      "        stack = new int[_size];",
      "        tos = -1;",
      "    }",
      "  ",
      "    public void push(int data) {",
      "        if (tos < size) {",
      "            tos++;",
      "            stack[tos] = data;",
      "            display();",
      "        } else {",
      "            System.out.println('Stack is Full');",
      "        }",
      "    }",
      "  ",
      "    public void pop() {",
      "        if (tos < 0) {",
      "            System.out.println('Stack is Empty');",
      "        } else {",
      "            stack[tos] = 0;",
      "            tos--;",
      "            display();",
      "        }",
      "    }",
      "  ",
      "    public void display() {",
      "        for (int i = tos; i >= 0; i--) {",
      "            System.out.println(stack[i]);",
      "        }",
      "        System.out.println('-----');",
      "    }",
      "  ",
      "    public static void main(String[] args) {",
      "        Stack stack = new Stack(3);",
      "  ",
      "        stack.pop();",
      "  ",
      "        stack.push(10);",
      "        stack.push(20);",
      "        stack.push(30);",
      "        stack.push(40);",
      "  ",
      "        stack.pop();",
      "    }",
      "}"
    ],
    C: [
      "#include <stdio.h>",
      "#include <stdlib.h>",
      "  ",
      "#define MAX 3  // Define maximum size of the stack",
      "  ",
      "int stack[MAX];",
      "int tos = -1;  // Initialize stack as empty",
      "  ",
      "// Function to push an element onto the stack",
      "void push(int value) {",
      "    if (tos == MAX - 1) {  // Check if stack is full",
      "        printf(\"Stack Overflow\\n\");",
      "    } else {",
      "        stack[++tos] = value;",
      "    }",
      "}",
      "  ",
      "// Function to pop an element from the stack",
      "void pop() {",
      "    if (tos == -1) {  // Check if stack is empty",
      "        printf(\"Stack Underflow\\n\");",
      "    } else {",
      "        stack[tos--] = 0;",
      "    }",
      "}",
      "  ",
      "// Function to display all elements of the stack",
      "void display() {",
      "    if (tos == -1) {  // Check if stack is empty",
      "        printf(\"Stack is empty\\n\");",
      "    } else {",
      "        printf(\"Stack elements are:\\n\");",
      "        for (int i = tos; i >= 0; i--) {",
      "            printf(\"%d\\n\", stack[i]);",
      "        }",
      "    }",
      "}",
      "  ",
      "int main() {",
      "    push(10);",
      "    push(20);",
      "    push(30);",
      "    push(40);",
      "  ",
      "    display();",
      "  ",
      "    pop();",
      "    pop();",
      "  ",
      "    display();",
      "  ",
      "    return 0;",
      "}"
    ],
    "C++": [
      "#include <iostream>",
      "using namespace std;",
      "  ",
      "#define MAX 3  // Define maximum size of the stack",
      "  ",
      "class Stack {",
      "private:",
      "    int stack[MAX];",
      "    int tos;",
      "public:",
      "    Stack() {  // Constructor to initialize stack",
      "        tos = -1;",
      "    }",
      "  ",
      "    void push(int value) {",
      "        if (tos == MAX - 1) {  // Check if stack is full",
      "            cout << \"Stack Overflow\" << endl;",
      "        } else {",
      "            stack[++tos] = value;",
      "        }",
      "    }",
      "  ",
      "    void pop() {",
      "        if (tos == -1) {  // Check if stack is empty",
      "            cout << \"Stack Underflow\" << endl;",
      "        } else {",
      "            stack[tos--] = 0;",
      "        }",
      "    }",
      "  ",
      "    void display() {",
      "        if (tos == -1) {  // Check if stack is empty",
      "            cout << \"Stack is empty\" << endl;",
      "        } else {",
      "            cout << \"Stack elements are:\" << endl;",
      "            for (int i = tos; i >= 0; i--) {",
      "                cout << stack[i] << endl;",
      "            }",
      "        }",
      "    }",
      "};",
      "  ",
      "int main() {",
      "    Stack s;",
      "  ",
      "    s.push(10);",
      "    s.push(20);",
      "    s.push(30);",
      "    s.push(40);",
      "  ",
      "    s.display();",
      "  ",
      "    s.pop()",
      "    s.pop()",
      "  ",
      "    s.display();",
      "  ",
      "    return 0;",
      "}"
    ]
  };
  
  export default codeSamples;
  