const explanations = {
    Java: {
      3: "Declaring and initializing character variable letter",
      4: "Declaring and initializing character variable digit",
      5: "Declaring and initializing character variable specialChar",
      7: "Printing the value of character letter",
      8: "Printing the value of character digit",
      9: "Printing the value of character specialChar",
    },
    C: {
      3: "Declaring and initializing character variable letter",
      4: "Declaring and initializing character variable digit",
      5: "Declaring and initializing character variable specialChar",
      7: "Printing the value of character letter",
      8: "Printing the value of character digit",
      9: "Printing the value of character specialChar",
      10: "Returning 0 to indicate successful execution"
    },
    "C++": {
      4: "Declaring and initializing character variable letter",
      5: "Declaring and initializing character variable digit",
      6: "Declaring and initializing character variable specialChar",
      8: "Printing the value of character letter",
      9: "Printing the value of character digit",
      10: "Printing the value of character specialChar",
      11: "Returning 0 to indicate successful execution"
    }
  };
  
  export default explanations;
  