const explanations = {
    Python: {
      1: 'Define the are_strings_equal function',
      2: 'Check if the lengths of the strings are not equal',
      3: 'Returning False because the lengths are not equal',
      5: 'Iterate over each character in the strings',
      6: 'Check if characters at the same position are different',
      7: 'Returning False because a difference is found',
      9: 'Returning True because no differences are found',
      11: 'Main execution block',
      12: 'Declaring and Initialize string1',
      13: 'Declaring and Initialize string2',
      15: 'Check if string1 and string2 are equal using are_strings_equal function',
      16: 'Print that the strings are equal',
      17: 'Strings are not equal, so executing else block',
      18: 'Print that the strings are not equal',
    },
    Java: {
      2: 'Define the areStringsEqual method',
      3: 'Check if the lengths of the strings are not equal',
      4: 'Returning False because the lengths are not equal',
      7: 'Iterate over each character in the strings',
      8: 'Check if characters at the same position are different',
      9: 'Returning False because a difference is found',
      13: 'Returning True because no differences are found',
      16: 'Main method',
      17: 'Declaring and Initialize string1',
      18: 'Declaring and Initialize string2',
      20: 'Check if string1 and string2 are equal using areStringsEqual method',
      21: 'Print that the strings are equal',
      22: 'Strings are not equal, so executing else block',
      23: 'Print that the strings are not equal',
    },
    C: {
      4: 'Define the areStringsEqual function',
      5: 'Check if the lengths of the strings are not equal',
      6: 'Returning 0 because the lengths are not equal',
      9: 'Iterate over each character in the strings',
      10: 'Check if characters at the same position are different',
      11: 'Returning 0 because a difference is found',
      15: 'Returning 1 because no differences are found',
      18: 'Main function',
      19: 'Declaring and Initialize string1',
      20: 'Declaring and Initialize string2',
      22: 'Check if string1 and string2 are equal using areStringsEqual function',
      23: 'Print that the strings are equal',
      24: 'Strings are not equal, so executing else block',
      25: 'Print that the strings are not equal',
      27: "Return 0 to indicate successful execution"
    },
    'C++': {
      5: 'Define the areStringsEqual function',
      6: 'Check if the lengths of the strings are not equal',
      7: 'Returning False because the lengths are not equal',
      10: 'Iterate over each character in the strings',
      11: 'Check if characters at the same position are different',
      12: 'Returning False because a difference is found',
      16: 'Returning True because no differences are found',
      19: 'Main function',
      20: 'Declaring and Initialize string1',
      21: 'Declaring and Initialize string2',
      23: 'Check if string1 and string2 are equal using areStringsEqual function',
      24: 'Print that the strings are equal',
      25: 'Strings are not equal, so executing else block',
      26: 'Print that the strings are not equal',
      28: "Return 0 to indicate successful execution"
    },
  };
  
  export default explanations;
  