const codeSamples = {
    Python: [
      "if __name__ == '__main__':",
      "    str1 = \"Code\"",
      "    str2 = \"Sleep\"",
      "    result_plus = \"\"",
      "  ",
      "    for char in str1:",
      "        result_plus += char",
      "  ",
      "    for char in str2:",
      "        result_plus += char",
      "  ",
      "    print(result_plus)"
    ],
    Java: [
      "public class StringConcatenation {",
      "    public static void main(String[] args) {",
      "        String str1 = \"Code\";",
      "        String str2 = \"Sleep\";",
      "        StringBuilder resultPlus = new StringBuilder();",
      "  ",
      "        for (char c : str1.toCharArray()) {",
      "            resultPlus.append(c);",
      "        }",
      "  ",
      "        for (char c : str2.toCharArray()) {",
      "            resultPlus.append(c);",
      "        }",
      "  ",
      "        System.out.println(resultPlus.toString());",
      "    }",
      "}"
    ],
    C: [
      "#include <stdio.h>",
      "  ",
      "int main() {",
      "    char str1[] = \"Code\";",
      "    char str2[] = \"Sleep\";",
      "    char resultPlus[20] = \"\";",
      "  ",
      "    int currentIndex = 0;",
      "  ",
      "    for (int i = 0; i < strlen(str1); i++) {",
      "        resultPlus[currentIndex] = str1[i];",
      "        currentIndex++;",
      "    }",
      "  ",
      "    for (int i = 0; i < strlen(str2); i++) {",
      "        resultPlus[currentIndex] = str1[i];",
      "        currentIndex++;",
      "    }",
      "  ",
      "    printf(\"%s\\n\", resultPlus);",
      "    return 0;",
      "}"
    ],
    "C++": [
      "#include <iostream>",
      "#include <string>",
      "using namespace std;",
      "  ",
      "int main() {",
      "    string str1 = \"Code\";",
      "    string str2 = \"Sleep\";",
      "    string resultPlus = \"\";",
      "  ",
      "    for (char c : str1) {",
      "        resultPlus += c;",
      "    }",
      "  ",
      "    for (char c : str2) {",
      "        resultPlus += c;",
      "    }",
      "  ",
      "    cout << resultPlus << endl;",
      "    return 0;",
      "}"
    ]
  };
  
  export default codeSamples;
  