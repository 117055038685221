const explanations = {
    Python: {
        2: "Declares and initializes the string variable s1 with the value 'Hello'.",
        3: "Creates a new string by concatenating the existing string referenced by s1 with ' World'. The original string 'Hello' remains unchanged due to immutability, and s1 now references the new string 'Hello World'."
    },
    Java: {
        3: "Declares and initializes the string variable s1 with the value 'Hello'.",
        4: "Creates a new string by concatenating the existing string referenced by s1 with ' World'. The original string 'Hello' remains unchanged due to immutability, and s1 now references the new string 'Hello World'."
    },
  };
  
  export default explanations;
  