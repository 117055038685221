export const linearSearchPython = async (list, targetElement, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, customSleep) => {
  for (let i = 0; i < list.length; i++) {
    setHighlightedIndices([{ arrayName: 'list', index: i, iteratorName: 'i' }]);

    await highlightLine(1);
    setHighlightedVariables(['i', 'element']);
    let element = list[i];
    setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i }, element: { variable_name: 'element', value: element } }));
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(2);
    setHighlightedVariables(['element', 'target_element']);
    await customSleep();
    setHighlightedVariables([]);
    if (element === targetElement) {
      await highlightLine(3);
      setHighlightedVariables(['i']);
      await customSleep();
      setHighlightedVariables([]);
      return i; // Element found, return its index
    }
  }

  await highlightLine(4);
  await customSleep();
  setHighlightedIndices([]);
  return -1; // Element not found
};

export const linearSearchJava = async (list, targetElement, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, customSleep) => {
  for (let i = 0; i < list.length; i++) {
    setHighlightedIndices([{ arrayName: 'list', index: i, iteratorName: 'i' }]);

    await highlightLine(2);
    setHighlightedVariables(['i']);
    setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i }}));
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(3);
    setHighlightedVariables(['targetElement']);
    await customSleep();
    setHighlightedVariables([]);
    if (list[i] === targetElement) {
      await highlightLine(4);
      setHighlightedVariables(['i']);
      await customSleep();
      setHighlightedVariables([]);
      return i; // Element found, return its index
    }
  }

  await highlightLine(7);
  await customSleep();
  setHighlightedIndices([]);
  return -1; // Element not found
};

export const linearSearchC = async (list, size, targetElement, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, customSleep) => {
  for (let i = 0; i < size; i++) {
    setHighlightedIndices([{ arrayName: 'list', index: i, iteratorName: 'i' }]);

    await highlightLine(3);
    setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i }}));
    setHighlightedVariables(['i']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(4);
    setHighlightedVariables(['targetElement']);
    await customSleep();
    setHighlightedVariables([]);
    if (list[i] === targetElement) {
      await highlightLine(5);
      setHighlightedVariables(['i']);
      await customSleep();
      setHighlightedVariables([]);
      return i; // Element found, return its index
    }
  }

  await highlightLine(8);
  await customSleep();
  setHighlightedIndices([]);
  return -1; // Element not found
};

export const linearSearchCpp = async (list, size, targetElement, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, customSleep) => {
  for (let i = 0; i < size; i++) {
    setHighlightedIndices([{ arrayName: 'list', index: i, iteratorName: 'i' }]);

    await highlightLine(4);
    setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i }}));
    setHighlightedVariables(['i']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(5);
    setHighlightedVariables(['targetElement']);
    await customSleep();
    setHighlightedVariables([]);
    if (list[i] === targetElement) {
      await highlightLine(6);
      setHighlightedVariables(['i']);
      await customSleep();
      setHighlightedVariables([]);
      return i; // Element found, return its index
    }
  }

  await highlightLine(9);
  await customSleep();
  setHighlightedIndices([]);
  return -1; // Element not found
};
