const codeSamples = {
    Java: [
      "public class ShortTypes {",
      "    public static void main(String[] args) {",
      "        short daysInWeek = 7;",
      "        short maxShortValue = 32767;",
      "        short minShortValue = -32768;",
      "  ",
      "        System.out.println('Days in a week: ' + daysInWeek);",
      "  ",
      "        System.out.println('Maximum short value: ' + maxShortValue);",
      "  ",
      "        System.out.println('Minimum short value: ' + minShortValue);",
      "    }",
      "}"
    ],
    C: [
      "#include <stdio.h>",
      "int main() {",
      "    short daysInWeek = 7;",
      "    short maxShortValue = 32767;",
      "    short minShortValue = -32768;",
      "  ",
      "    printf('Days in a week: %d\\n', daysInWeek);",
      "  ",
      "    printf('Maximum short value: %d\\n', maxShortValue);",
      "  ",
      "    printf('Minimum short value: %d\\n', minShortValue);",
      "  ",
      "    return 0;",
      "}"
    ],
    "C++": [
      "#include <iostream>",
      "using namespace std;",
      "int main() {",
      "    short daysInWeek = 7;",
      "    short maxShortValue = 32767;",
      "    short minShortValue = -32768;",
      "  ",
      "    cout << 'Days in a week: ' << daysInWeek << endl;",
      "  ",
      "    cout << 'Maximum short value: ' << maxShortValue << endl;",
      "  ",
      "    cout << 'Minimum short value: ' << minShortValue << endl;",
      "  ",
      "    return 0;",
      "}"
    ]
  };
  
  export default codeSamples;
  