const codeSamples = {
    Python: [
      "if __name__ == '__main__':",
      "    is_hungry = True",
      "    has_food = False",
      "  ",
      "    if is_hungry and has_food:",
      "        print('Time to eat!')",
      "    else:",
      "        print('No food or not hungry.')",
      "  ",
      "    print('Is hungry:', is_hungry)",
      "    print('Has food:', has_food)"
    ],
    Java: [
      "public class BooleanExample {",
      "    public static void main(String[] args) {",
      "        boolean isHungry = true;",
      "        boolean hasFood = false;",
      "  ",
      "        if (isHungry && hasFood) {",
      "            System.out.println('Time to eat!');",
      "        } else {",
      "            System.out.println('No food or not hungry.');",
      "        }",
      "  ",
      "        System.out.println('Is hungry: ' + isHungry);",
      "        System.out.println('Has food: ' + hasFood);",
      "    }",
      "}"
    ],
    "C++": [
      "#include <iostream>",
      "using namespace std;",
      "int main() {",
      "    bool isHungry = true;",
      "    bool hasFood = false;",
      "  ",
      "    if (isHungry && hasFood) {",
      "        cout << 'Time to eat!' << endl;",
      "    } else {",
      "        cout << 'No food or not hungry.' << endl;",
      "    }",
      "  ",
      "    cout << 'Is hungry: ' << boolalpha << isHungry << endl;",
      "    cout << 'Has food: ' << boolalpha << hasFood << endl;",
      "    return 0;",
      "}"
    ]
  };
  
  export default codeSamples;
  