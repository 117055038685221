const codeSamples = {
    Java: [
      "public class LongTypes {",
      "    public static void main(String[] args) {",
      "        long minLongValue = Long.MIN_VALUE;",
      "        long maxLongValue = Long.MAX_VALUE;",
      "  ",
      "        System.out.println('Minimum signed long value: ' + minLongValue);",
      "  ",
      "        System.out.println('Maximum signed long value: ' + maxLongValue);",
      "    }",
      "}"
    ],
    C: [
      "#include <stdio.h>",
      "#include <limits.h>",
      "  ",
      "int main() {",
      "    long long minSignedLongValue = LONG_MIN;",
      "    long long maxSignedLongValue = LONG_MAX;",
      "    unsigned long maxUnsignedLongValue = ULONG_MAX;",
      "  ",
      "    printf('Minimum signed long value: %lld\\n', minSignedLongValue);",
      "  ",
      "    printf('Maximum signed long value: %lld\\n', maxSignedLongValue);",
      "  ",
      "    printf('Maximum unsigned long value: %llu\\n', maxUnsignedLongValue);",
      "  ",
      "    return 0;",
      "}"
    ],
    "C++": [
      "#include <iostream>",
      "#include <limits.h>",
      "using namespace std;",
      "  ",
      "int main() {",
      "    long long minSignedLongValue = LONG_MIN;",
      "    long long maxSignedLongValue = LONG_MAX;",
      "    unsigned long maxUnsignedLongValue = ULONG_MAX;",
      "  ",
      "    cout << 'Minimum signed long value: ' << minSignedLongValue << endl;",
      "  ",
      "    cout << 'Maximum signed long value: ' << maxSignedLongValue << endl;",
      "  ",
      "    cout << 'Maximum unsigned long value: ' << maxUnsignedLongValue << endl;",
      "  ",
      "    return 0;",
      "}"
    ]
  };
  
  export default codeSamples;
  