const codeSamples = {
    Python: [
      "def linear_search(list, target_element):",
      "    for i, element in enumerate(list):",
      "        if element == target_element:",
      "            return i  # Element found, return its index",
      "    return -1  # Element not found",
      "  ",
      "if __name__ == '__main__':",
      "    list = [2, 3, 4, 10, 40]",
      "    target_element = 10",
      "    result = linear_search(list, target_element)",
      "    if result != -1:",
      "        print(f'Element {target_element} found at index {result}.')",
      "    else:",
      "        print(f'Element {target_element} not found in the list.')"
    ],
    Java: [
      "public class LinearSearch {",
      "    int linearSearch(int list[], int targetElement) {",
      "        for (int i = 0; i < list.length; i++) {",
      "            if (list[i] == targetElement) {",
      "                return i;  // Element found, return its index",
      "            }",
      "        }",
      "        return -1;  // Element not found",
      "    }",
      "  ",
      "    public static void main(String args[]) {",
      "        LinearSearch ls = new LinearSearch();",
      "        int list[] = {2, 3, 4, 10, 40};",
      "        int targetElement = 10;",
      "        int result = ls.linearSearch(list, targetElement);",
      "        if (result != -1) {",
      "            System.out.println('Element found at index ' + result);",
      "        }",
      "        else {",
      "            System.out.println('Element not found');",
      "        }",
      "    }",
      "}"
    ],
    C: [
      "#include <stdio.h>",
      "  ",
      "int linearSearch(int list[], int size, int targetElement) {",
      "    for (int i = 0; i < size; i++) {",
      "        if (list[i] == targetElement) {",
      "            return i;  // Element found, return its index",
      "        }",
      "    }",
      "    return -1;  // Element not found",
      "}",
      "  ",
      "int main() {",
      "    int list[] = {2, 3, 4, 10, 40};",
      "    int size = sizeof(list) / sizeof(list[0]);",
      "    int targetElement = 10;",
      "    int result = linearSearch(list, size, targetElement);",
      "    if (result != -1) {",
      "        printf('Element found at index %d', result);",
      "    }",
      "    else {",
      "        printf('Element not found');",
      "    }",
      "    return 0;",
      "}"
    ],
    "C++": [
      "#include <iostream>",
      "using namespace std;",
      "  ",
      "int linearSearch(int list[], int size, int targetElement) {",
      "    for (int i = 0; i < size; i++) {",
      "        if (list[i] == targetElement) {",
      "            return i;  // Element found, return its index",
      "        }",
      "    }",
      "    return -1;  // Element not found",
      "}",
      "  ",
      "int main() {",
      "    int list[] = {2, 3, 4, 10, 40};",
      "    int size = sizeof(list) / sizeof(list[0]);",
      "    int targetElement = 10;",
      "    int result = linearSearch(list, size, targetElement);",
      "    if (result != -1) {",
      "        cout << 'Element found at index ' << result;",
      "    }",
      "    else {",
      "        cout << 'Element not found';",
      "    }",
      "    return 0;",
      "}"
    ]
};
  
export default codeSamples;
  