const codeSamples = {
    Python: [
        "if __name__ == '__main__':",
        "    # Initial values",
        "    a = 5",
        "    b = 10",
        " ",
        "    # Print initial values",
        "    print(f'Before swapping: a = {a}, b = {b}')",
        " ",
        "    # Swapping values using a temporary variable",
        "    temp = a",
        "    a = b",
        "    b = temp",
        " ",
        "    # Print values after swapping",
        "    print(f'After swapping: a = {a}, b = {b}')"
    ],
    Java: [
        "public class Main {",
        "    public static void main(String[] args) {",
        "        // Initial values",
        "        int a = 5;",
        "        int b = 10;",
        " ",
        "        // Print initial values",
        "        System.out.println(\"Before swapping: a = \" + a + \", b = \" + b);",
        " ",
        "        // Swapping values using a temporary variable",
        "        int temp = a;",
        "        a = b;",
        "        b = temp;",
        " ",
        "        // Print values after swapping",
        "        System.out.println(\"After swapping: a = \" + a + \", b = \" + b);",
        "    }",
        "}"
    ],
    C: [
        "#include <stdio.h>",
        " ",
        "int main() {",
        "    // Initial values",
        "    int a = 5;",
        "    int b = 10;",
        " ",
        "    // Print initial values",
        "    printf(\"Before swapping: a = %d, b = %d\\n\", a, b);",
        " ",
        "    // Swapping values using a temporary variable",
        "    int temp = a;",
        "    a = b;",
        "    b = temp;",
        " ",
        "    // Print values after swapping",
        "    printf(\"After swapping: a = %d, b = %d\\n\", a, b);",
        "    return 0;",
        "}"
    ],
    'C++': [
        "#include <iostream>",
        "using namespace std;",
        " ",
        "int main() {",
        "    // Initial values",
        "    int a = 5;",
        "    int b = 10;",
        " ",
        "    // Print initial values",
        "    cout << \"Before swapping: a = \" << a << \", b = \" << b << endl;",
        " ",
        "    // Swapping values using a temporary variable",
        "    int temp = a;",
        "    a = b;",
        "    b = temp;",
        " ",
        "    // Print values after swapping",
        "    cout << \"After swapping: a = \" << a << \", b = \" << b << endl;",
        "    return 0;",
        "}"
    ]
};

export default codeSamples;
