import React from 'react';

const UpdateVariablesModal = ({
  isVisible,
  onClose,
  minSignedLongValue,
  setMinSignedLongValue,
  maxSignedLongValue,
  setMaxSignedLongValue,
  maxUnsignedLongValue,
  setMaxUnsignedLongValue,
  language,
}) => {
  if (!isVisible) return null;

  return (
    <div className="modal show d-block" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content modal-glass-avatar">
          <div className="modal-header">
            <h5 className="modal-title-update-variable">Update Variables</h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-6">
                <label>
                  {language === "Java"
                    ? "Min Long Value:"
                    : "Min Signed Long Value:"}
                  <input
                    type="text"
                    className="form-control custom-input"
                    value={minSignedLongValue.toString()}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (!isNaN(value)) {
                        setMinSignedLongValue(value);
                      }
                    }}
                  />
                </label>
              </div>
              <div className="col-md-6">
                <label>
                  {language === "Java"
                    ? "Max Long Value:"
                    : "Max Signed Long Value:"}
                  <input
                    type="text"
                    className="form-control custom-input"
                    value={maxSignedLongValue.toString()}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (!isNaN(value)) {
                        setMaxSignedLongValue(value);
                      }
                    }}
                  />
                </label>
              </div>
              {language !== "Java" && (
                <div className="col-md-6">
                  <label>
                    Max Unsigned Long Value:
                    <input
                      type="text"
                      className="form-control custom-input"
                      value={maxUnsignedLongValue.toString()}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (!isNaN(value)) {
                          setMaxUnsignedLongValue(value);
                        }
                      }}
                    />
                  </label>
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-dark"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={onClose}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateVariablesModal;
