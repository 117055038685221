import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const PricingSection = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Set an SEO-friendly page title
    document.title = `Pricing | Coding Canvas`;

    // Set meta description for SEO
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", `Explore interactive tutorials, code samples, and exercises to learn programming visually on Coding Canvas.`);
    }
  }, []);

  const handleGetStartedClick = () => {
    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    if (isLoggedIn) {
      navigate('/');
    } else {
      // Dispatch an event or directly show the login modal
      const registerModalEvent = new Event('showRegisterModal');
      window.dispatchEvent(registerModalEvent);
    }
  };

  const handleSubscibe = () => {
    alert('Coming Soon...');
  };

  return (
    <main className="container">
      {/* Pricing Section */}
      <section className="text-center">
        <h1 className="mb-3">Pricing Plans</h1>
        <p>Choose a plan that suits your learning goals. Start with our Free plan or subscribe for additional features and extended access.</p>
      </section>

      <div className="row text-center">
        {/* Free Plan */}
        <div className="col-md-4 mb-4">
          <div className="card h-100">
            <div className="card-header bg-dark text-white opacity-95">Free</div>
            <div className="card-body">
              <h2 className="card-title">₹0 <small className="text-muted">/ month</small></h2>
              <ul className="list-unstyled">
                <li>Access to Basic Topics</li>
                <li>Limited Visualizations</li>
                <li>Community Support</li>
              </ul>
              <button className="btn btn-dark mt-3" onClick={handleGetStartedClick}>Get Started</button>
            </div>
          </div>
        </div>

        {/* 6 Months Plan */}
        <div className="col-md-4 mb-4">
          <div className="card h-100">
            <div className="card-header bg-dark text-white opacity-95">6 Months</div>
            <div className="card-body">
              <h2 className="card-title">₹349 <small className="text-muted">/ 6 months</small></h2>
              <ul className="list-unstyled">
                <li>Access to All Topics</li>
                <li>Unlimited Visualizations</li>
                <li>Email Support</li>
              </ul>
              <button className="btn btn-dark mt-3" onClick={handleSubscibe}>Subscribe Now</button>
            </div>
          </div>
        </div>

        {/* 1 Year Plan */}
        <div className="col-md-4 mb-4">
          <div className="card h-100">
            <div className="card-header bg-dark text-white opacity-95">1 Year</div>
            <div className="card-body">
              <h2 className="card-title">₹599 <small className="text-muted">/ year</small></h2>
              <ul className="list-unstyled">
                <li>Access to All Topics</li>
                <li>Unlimited Visualizations</li>
                <li>Email Support</li>
              </ul>
              <button className="btn btn-dark mt-3" onClick={handleSubscibe}>Subscribe Now</button>
            </div>
          </div>
        </div>
      </div>

      {/* Testimonials Section */}
      <section className="my-5 text-center">
        <h2 className="mb-4">What Our Users Are Saying</h2>
        <div className="row">
            <div className="col-md-4 mb-4">
            <div className="card h-100 shadow testimonial">
                <div className="card-body d-flex flex-column">
                <p>"CodingCanvas has transformed my learning journey. The visualizations make even the hardest concepts easy to understand!"</p>
                <footer className="blockquote-footer mt-auto">- Alex, Computer Science Student</footer>
                </div>
            </div>
            </div>
            <div className="col-md-4 mb-4">
            <div className="card h-100 shadow testimonial">
                <div className="card-body d-flex flex-column">
                <p>"Thanks to CodingCanvas, I landed my dream job as a developer! The interactive approach is a game-changer."</p>
                <footer className="blockquote-footer mt-auto">- Priya, Software Developer</footer>
                </div>
            </div>
            </div>
            <div className="col-md-4 mb-4">
            <div className="card h-100 shadow testimonial">
                <div className="card-body d-flex flex-column">
                <p>"The affordable pricing and quality content make CodingCanvas a must-have for anyone learning programming."</p>
                <footer className="blockquote-footer mt-auto">- Rohit, Coding Enthusiast</footer>
                </div>
            </div>
            </div>
        </div>
      </section>


        {/* Why Choose Us Section */}
        <section className="my-5 text-center">
        <h2 className="mb-4">Why Choose CodingCanvas?</h2>
        <p>At CodingCanvas, we prioritize interactive learning, visual understanding, and real-world coding applications. With access to a wide array of topics, personalized support, and community engagement, our platform is designed to make programming accessible and enjoyable for everyone.</p>
        <ul className="list-unstyled">
            <li>✔ Proven teaching methods used by thousands of students</li>
            <li>✔ Detailed visualizations for deeper understanding</li>
            <li>✔ Community-driven support and engagement</li>
            <li>✔ Affordable pricing with maximum value</li>
        </ul>
        </section>
    </main>
  );
};

export default PricingSection;
