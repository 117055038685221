const codeSamples = {
    Python: [
      "class Node:",
      "    def __init__(self, data):",
      "        self.data = data",
      "        self.next = None",
      "  ",
      "class LinkedList:",
      "    def __init__(self):",
      "        self.head = None",
      "  ",
      "    def update(self, old_data, new_data):",
      "        if self.head is None:",
      "            print('The list is empty.')",
      "            return",
      "  ",
      "        temp = self.head",
      "        while temp is not None:",
      "            if temp.data == old_data:",
      "                temp.data = new_data",
      "                print(f'{old_data} updated to {new_data}')",
      "                return",
      "            temp = temp.next",
      "        print(f'{old_data} not found in the list.')",
      "  ",
      "if __name__ == '__main__':",
      "    list = LinkedList()",
      "  ",
      "    list.update(20, 25)"
    ],
    Java: [
      "class Node {",
      "    int data;",
      "    Node next;",
      "  ",
      "    Node(int data) {",
      "        this.data = data;",
      "        this.next = null;",
      "    }",
      "}",
      "  ",
      "class LinkedList {",
      "    Node head;",
      "  ",
      "    public void update(int oldData, int newData) {",
      "        if (head == null) {",
      "            System.out.println(\"The list is empty.\");",
      "            return;",
      "        }",
      "  ",
      "        Node temp = head;",
      "        while (temp != null) {",
      "            if (temp.data == oldData) {",
      "                temp.data = newData;",
      "                System.out.println(oldData + \" updated to \" + newData);",
      "                return;",
      "            }",
      "            temp = temp.next;",
      "        }",
      "        System.out.println(oldData + \" not found in the list.\");",
      "    }",
      "  ",
      "    public static void main(String[] args) {",
      "        LinkedList list = new LinkedList();",
      "  ",
      "        list.update(20, 25);",
      "    }",
      "}"
    ],
    C: [
      "#include <stdio.h>",
      "#include <stdlib.h>",
      "  ",
      "struct Node {",
      "    int data;",
      "    struct Node* next;",
      "};",
      "  ",
      "struct Node* head = NULL;",
      "  ",
      "void update(int oldData, int newData) {",
      "    if (head == NULL) {",
      "        printf(\"The list is empty.\\n\");",
      "        return;",
      "    }",
      "  ",
      "    struct Node* temp = head;",
      "    while (temp != NULL) {",
      "        if (temp->data == oldData) {",
      "            temp->data = newData;",
      "            printf(\"%d updated to %d\\n\", oldData, newData);",
      "            return;",
      "        }",
      "        temp = temp->next;",
      "    }",
      "    printf(\"%d not found in the list.\\n\", oldData);",
      "}",
      "  ",
      "int main() {",
      "    update(20, 25);",
      "  ",
      "    return 0;",
      "}"
    ],
    "C++": [
      "#include <iostream>",
      "using namespace std;",
      "  ",
      "class Node {",
      "public:",
      "    int data;",
      "    Node* next;",
      "  ",
      "    Node(int data) {",
      "        this->data = data;",
      "        this->next = nullptr;",
      "    }",
      "};",
      "  ",
      "class LinkedList {",
      "public:",
      "    Node* head;",
      "  ",
      "    LinkedList() {",
      "        head = nullptr;",
      "    }",
      "  ",
      "    void update(int oldData, int newData) {",
      "        if (head == nullptr) {",
      "            std::cout << \"The list is empty.\" << std::endl;",
      "            return;",
      "        }",
      "  ",
      "        Node* temp = head;",
      "        while (temp != nullptr) {",
      "            if (temp->data == oldData) {",
      "                temp->data = newData;",
      "                std::cout << oldData << \" updated to \" << newData << std::endl;",
      "                return;",
      "            }",
      "            temp = temp->next;",
      "        }",
      "        std::cout << oldData << \" not found in the list.\" << std::endl;",
      "    }",
      "};",
      "  ",
      "int main() {",
      "    LinkedList list;",
      "  ",
      "    list.update(20, 25);",
      "  ",
      "    return 0;",
      "}"
    ]
  };
  
  export default codeSamples;
  