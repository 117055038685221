const explanations = {
    Python: {
      1: "Define the Node class to represent each element of the binary search tree.",
      2: "Executing the constructor method __init__ to initialize a node that represents each element of the binary tree.",
      3: "Assigning the data to the node's data attribute.",
      4: "Setting the left pointer attribute to None.",
      5: "Setting the right pointer attribute to None.",
      7: "Define the BinarySearchTree class to manage the tree structure.",
      8: "Executing the constructor method __init__ of the class BinarySearchTree.",
      9: "Initializing the binary search tree with an empty root (i.e., the tree is initially empty).",
      11: "Executing the add method to add a node to the binary search tree.",
      12: "Create a new node with the provided data.",
      13: "Check if the tree is empty (i.e., root is None).",
      14: "The root is empty, the new node becomes the root of the tree.",
      15: "Root is not empty, executing else block.",
      16: "Starts traversal from the root of the tree.",
      17: "Begins an infinite loop for finding the correct position to insert the new node.",
      18: "Save the current node as the parent node.",
      19: "Checking if the new data is smaller than the current node's data.",
      20: "Data is smaller, moving to the left child.",
      21: "Checking if the left child is empty, i.e, None.",
      22: "Left child is empty, so Inserting the new node as the left child of the parent node.",
      23: "Exiting from the method.",
      24: "Data is not smaller, executing the else block.",
      25: "Moving to the right child.",
      26: "Checking if the right child is empty, i.e, None.",
      27: "Right child is empty, so Inserting the new node as the right child of the parent node.",
      28: "Exiting from the method.",
      30: "Main block to test the Binary Search Tree class functionality.",
      31: "Creating an instance of the BinarySearchTree class.",
      32: "Adding the first node to the BinarySearchTree.",
      33: "Adding a new node to the BinarySearchTree.",
      34: "Adding a new node to the BinarySearchTree.",
      35: "Adding a new node to the BinarySearchTree.",
      36: "Adding a new node to the BinarySearchTree.",
      37: "Adding a new node to the BinarySearchTree.",
      38: "Adding a new node to the BinarySearchTree.",
    },
  
    Java: {
      1: "Define the Node class to represent each element of the binary search tree.",
      2: "Declaring integer variable data.",
      3: "Declaring Nod etype pointer variable left and right.",
      5: "Executing the constructor method to initialize a node that represents each element of the binary search tree.",
      6: "Assigning the data to the node's data attribute.",
      7: "Setting the left and right pointer attribute to null.",
      11: "Define the BinarySearchTree class to manage the tree structure.",
      12: "Declaring the Node type variable root.",
      14: "Executing the constructor method of the class BinarySearchTree.",
      15: "Initializing the binary search tree with an empty root (i.e., the tree is initially empty).",
      18: "Executing the add method to add a node to the binary search tree.",
      19: "Create a new node with the provided data.",
      20: "Check if the tree is empty (i.e., root is null).",
      21: "The root is empty, the new node becomes the root of the tree.",
      22: "Root is not empty, executing else block.",
      23: "Starts traversal from the root of the tree.",
      24: "Begins an infinite loop for finding the correct position to insert the new node.",
      25: "Save the current node as the parent node.",
      26: "Checking if the new data is smaller than the current node's data.",
      27: "Data is smaller, moving to the left child.",
      28: "Checking if the left child is empty, i.e, null.",
      29: "Left child is empty, so Inserting the new node as the left child of the parent node.",
      30: "Exiting from the method.",
      32: "Data is not smaller, executing the else block.",
      33: "Moving to the right child.",
      34: "Checking if the right child is empty, i.e, null.",
      35: "Right child is empty, so Inserting the new node as the right child of the parent node.",
      36: "Exiting from the method.",
      44: "Main class.",
      45: "Main function to test the Binary Search Tree class functionality.",
      46: "Creating an instance of the BinarySearchTree class.",
      47: "Adding the first node to the BinarySearchTree.",
      48: "Adding a new node to the BinarySearchTree.",
      49: "Adding a new node to the BinarySearchTree.",
      50: "Adding a new node to the BinarySearchTree.",
      51: "Adding a new node to the BinarySearchTree.",
      52: "Adding a new node to the BinarySearchTree.",
      53: "Adding a new node to the BinarySearchTree.",
    },
    "C++": {
      1: "Include the standard input-output stream library.",
      2: "Using the standard namespace.",
      4: "Define the Node class to represent each element of the binary search tree.",
      5: "Public access specifier for class members.",
      6: "Declare an integer variable to store data.",
      7: "Declare pointer variables for left and right children of the node.",
      8: "Executing the constructor to initialize the node with data.",
      9: "Assign the data to the node's data attribute.",
      10: "Set left and right pointers to null.",
      14: "Define the BinarySearchTree class to manage the tree structure.",
      15: "Public access specifier for class members.",
      16: "Declare a pointer to the root node.",
      17: "Executing the constructor to initialize the binary search tree.",
      18: "Set the root to null pointer initially.",
      21: "Executing the add method to add a new node to the tree.",
      22: "Create a new node with the provided data.",
      23: "Checking if the tree is empty (i.e., root is null pointer).",
      24: "The root is empty, the new node becomes the root of the tree.",
      25: "Root is not empty, executing else block.",
      26: "Starting traversal from the root of the tree.",
      27: "Begins an infinite loop for finding the correct position to insert the new node.",
      28: "Save the current node as the parent.",
      29: "Checking if the new data is smaller than the current node's data.",
      30: "Data is smaller, moving to the left child.",
      31: "Checking if the left child is empty, i.e, null pointer.",
      32: "Left child is empty, so Inserting the new node as the left child of the parent node.",
      33: "Exiting from the method.",
      35: "Data is not smaller, executing the else block.",
      36: "Moving to the right child.",
      37: "Checking if the right child is empty, i.e, null pointer.",
      38: "Right child is empty, so Inserting the new node as the right child of the parent node.",
      39: "Exiting from the method.",
      47: "Main function to test the Binary Search Tree class.",
      48: "Creating an instance of BinarySearchTree class.",
      49: "Adding the first node to the BinarySearchTree.",
      50: "Adding a new node to the BinarySearchTree.",
      51: "Adding a new node to the BinarySearchTree.",
      52: "Adding a new node to the BinarySearchTree.",
      53: "Adding a new node to the BinarySearchTree.",
      54: "Adding a new node to the BinarySearchTree.",
      55: "Adding a new node to the BinarySearchTree.",
      56: "Returning 0 to indicate successful execution."
    },
    "C": {
      1: "Include the standard input-output header file.",
      2: "Include the standard library header file for memory allocation.",
      4: "Define the structure Node to represent each element of the binary search tree.",
      5: "Declare an integer variable to store data.",
      6: "Declare a pointer to the left child node.",
      7: "Declare a pointer to the right child node.",
      10: "Executing createNode function.",
      11: "Allocate memory for the new node.",
      12: "Assign the data to the node's data attribute.",
      13: "Set the left child pointer to NULL.",
      14: "Set the right child pointer to NULL.",
      15: "Return the newly created node.",
      18: "Executing addNode function to add a new node to the binary search tree.",
      19: "Calling createNode function to create a new node with the provided data.",
      20: "Checking if the tree is empty (i.e., root is NULL).",
      21: "The root is empty, the new node becomes the root of the tree.",
      22: "Root is not empty, executing else block.",
      23: "Starting traversal from the root of the tree.",
      24: "Begins an infinite loop for finding the correct position to insert the new node.",
      25: "Save the current node as the parent.",
      26: "Checking if the new data is smaller than the current node's data.",
      27: "Data is smaller, moving to the left child.",
      28: "Checking if the left child is empty, i.e, NULL.",
      29: "Left child is empty, so Inserting the new node as the left child of the parent node.",
      30: "Returning from the function.",
      32: "Data is not smaller, executing the else block.",
      33: "Moving to the right child.",
      34: "Checking if the right child is empty, i.e, NULL.",
      35: "Right child is empty, so Inserting the new node as the right child of the parent node.",
      36: "Returning from the function.",
      43: "Main function to test the BinarySearchTree class.",
      44: "Declare the root of the binary search tree as NULL.",
      45: "Adding the first node to the BinarySearchTree.",
      46: "Adding a new node to the BinarySearchTree.",
      47: "Adding a new node to the BinarySearchTree.",
      48: "Adding a new node to the BinarySearchTree.",
      49: "Adding a new node to the BinarySearchTree.",
      50: "Adding a new node to the BinarySearchTree.",
      51: "Adding a new node to the BinarySearchTree.",
      52: "Returning 0 to indicate successful execution."
    }
  };
  
  export default explanations;
  