const codeSamples = {
    Python: [
      'def are_strings_equal(string1, string2):',
      '    if len(string1) != len(string2):',
      '        return False',
      ' ',
      '    for i in range(len(string1)):',
      '        if string1[i] != string2[i]:',
      '            return False',
      ' ',
      '    return True',
      ' ',
      'if __name__ == "__main__":',
      '    string1 = "hello"',
      '    string2 = "hello"',
      ' ',
      '    if are_strings_equal(string1, string2):',
      '        print("The strings are equal.")',
      '    else:',
      '        print("The strings are not equal.")',
    ],
    Java: [
      'public class StringEqualCheck {',
      '    public static boolean areStringsEqual(String string1, String string2) {',
      '        if (string1.length() != string2.length()) {',
      '            return false;',
      '        }',
      ' ',
      '        for (int i = 0; i < string1.length(); i++) {',
      '            if (string1.charAt(i) != string2.charAt(i)) {',
      '                return false;',
      '            }',
      '        }',
      ' ',
      '        return true;',
      '    }',
      ' ',
      '    public static void main(String[] args) {',
      '        String string1 = "hello";',
      '        String string2 = "hello";',
      ' ',
      '        if (areStringsEqual(string1, string2)) {',
      '            System.out.println("The strings are equal.");',
      '        } else {',
      '            System.out.println("The strings are not equal.");',
      '        }',
      '    }',
      '}',
    ],
    C: [
      '#include <stdio.h>',
      '#include <string.h>',
      ' ',
      'int areStringsEqual(char string1[], char string2[]) {',
      '    if (strlen(string1) != strlen(string2)) {',
      '        return 0;',
      '    }',
      ' ',
      '    for (int i = 0; i < strlen(string1); i++) {',
      '        if (string1[i] != string2[i]) {',
      '            return 0;',
      '        }',
      '    }',
      ' ',
      '    return 1;',
      '}',
      ' ',
      'int main() {',
      '    char string1[] = "hello";',
      '    char string2[] = "hello";',
      ' ',
      '    if (areStringsEqual(string1, string2)) {',
      '        printf("The strings are equal.\\n");',
      '    } else {',
      '        printf("The strings are not equal.\\n");',
      '    }',
      '    return 0;',
      '}',
    ],
    'C++': [
      '#include <iostream>',
      '#include <cstring>',
      'using namespace std;',
      ' ',
      'bool areStringsEqual(char string1[], char string2[]) {',
      '    if (strlen(string1) != strlen(string2)) {',
      '        return false;',
      '    }',
      ' ',
      '    for (int i = 0; i < strlen(string1); i++) {',
      '        if (string1[i] != string2[i]) {',
      '            return false;',
      '        }',
      '    }',
      ' ',
      '    return true;',
      '}',
      ' ',
      'int main() {',
      '    char string1[] = "hello";',
      '    char string2[] = "hello";',
      ' ',
      '    if (areStringsEqual(string1, string2)) {',
      '        cout << "The strings are equal." << endl;',
      '    } else {',
      '        cout << "The strings are not equal." << endl;',
      '    }',
      '    return 0;',
      '}',
    ],
  };
  
  export default codeSamples;
  