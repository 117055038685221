import React from 'react';

const UpdateVariablesModal = ({
  isVisible,
  moonLandingYear,
  olympicGoldMedals,
  highestMountainHeightMeters,
  lowestTemperatureRecordedCelsius,
  setMoonLandingYear,
  setOlympicGoldMedals,
  setHighestMountainHeightMeters,
  setLowestTemperatureRecordedCelsius,
  onSave,
  onClose
}) => {
  if (!isVisible) return null;

  return (
    <div className="modal show d-block" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content modal-glass-avatar">
          <div className="modal-header">
            <h5 className="modal-title-update-variable">Update Variables</h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-6">
                <label>
                  Moon Landing Year:
                  <input
                    type="number"
                    className="form-control custom-input"
                    value={moonLandingYear}
                    onChange={(e) => setMoonLandingYear(parseInt(e.target.value))}
                  />
                </label>
              </div>
              <div className="col-md-6">
                <label>
                  Olympic Gold Medals:
                  <input
                    type="number"
                    className="form-control custom-input"
                    value={olympicGoldMedals}
                    onChange={(e) => setOlympicGoldMedals(parseInt(e.target.value))}
                  />
                </label>
              </div>
            </div>
            <div className='row' style={{paddingTop: '25px'}}>
              <div className="col-md-6">
                <label>
                  Highest Mountain Height Meters:
                  <input
                    type="number"
                    className="form-control custom-input"
                    value={highestMountainHeightMeters}
                    onChange={(e) => setHighestMountainHeightMeters(parseInt(e.target.value))}
                  />
                </label>
              </div>
              <div className="col-md-6">
                <label>
                  Lowest Temperature Recorded Celsius:
                  <input
                    type="number"
                    className="form-control custom-input"
                    value={lowestTemperatureRecordedCelsius}
                    onChange={(e) => setLowestTemperatureRecordedCelsius(parseInt(e.target.value))}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-dark" onClick={onClose}>
              Cancel
            </button>
            <button type="button" className="btn btn-success" onClick={onSave}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateVariablesModal;
