import React, { useState, useEffect, useMemo } from 'react';
import { ArcherContainer, ArcherElement } from 'react-archer';
import '../VisualizationCanvas.css';

const VisualizationCanvas = ({ 
  arrayVariables,
  variables,
  highlightedVariables,
  highlightedArrayVariables,
  highlightedIndices
}) => {
  const [variableAddresses, setVariableAddresses] = useState({});
  const [arrayAddresses, setArrayAddresses] = useState({});

  useEffect(() => {
    const adjustFontSize = () => {
      document.querySelectorAll('.array-value').forEach(element => {
        let fontSize = parseInt(window.getComputedStyle(element).fontSize);
        while (element.scrollWidth > element.clientWidth || element.scrollHeight > element.clientHeight) {
          if (fontSize <= 8) break;
          fontSize--;
          element.style.fontSize = `${fontSize}px`;
        }
      });
    };
    adjustFontSize();
    window.addEventListener('resize', adjustFontSize);
    return () => window.removeEventListener('resize', adjustFontSize);
  }, []);

  useEffect(() => {
    const generateMemoryAddress = () => {
      return '0x' + Math.floor(Math.random() * 0xFFF).toString(16);
    };

    setVariableAddresses(prevAddresses => {
      const newAddresses = { ...prevAddresses };
      Object.keys(variables).forEach(key => {
        if (!newAddresses[key]) {
          newAddresses[key] = generateMemoryAddress();
        }
      });
      return newAddresses;
    });

    setArrayAddresses(prevAddresses => {
      const newAddresses = { ...prevAddresses };
      Object.keys(arrayVariables).forEach(key => {
        if (!newAddresses[key]) {
          newAddresses[key] = generateMemoryAddress();
        }
      });
      return newAddresses;
    });
  }, [variables, arrayVariables]);

  const isArrayIndexHighlighted = (arrayName, index) => {
    return highlightedIndices.some(
      (highlight) => highlight.arrayName === arrayName && highlight.index === index
    );
  };

  const getIteratorName = (arrayName, index) => {
    const highlight = highlightedIndices.find(
      (highlight) => highlight.arrayName === arrayName && highlight.index === index
    );

    return highlight && highlight.iteratorName && highlight.iteratorName.length > 0
      ? highlight.iteratorName + " = "
      : "";
  };

  const renderArray = (arrayName, arrayData) => {
    return (
      <ArcherElement
        id={arrayName}
        key={arrayName}
        relations={arrayData.parent ? [{
          targetId: arrayData.parent,
          targetAnchor: 'bottom',
          sourceAnchor: 'top',
          style: { strokeColor: '#343a40', strokeWidth: 2, endMarker: false, startMarker: true }
        }] : []}
      >
        <div className="array-container" style={{paddingBottom: '0px'}}>
          <div className={`${highlightedArrayVariables.includes(arrayName) ? 'highlighted-array array-variable-box-highlighted' : 'array-variable-box'}`}>
            <div className={`array-variable-name`}>
              {arrayData.variable_name}
              <br />
              <span style={{ fontSize: '11px' }}>{arrayAddresses[arrayName]}</span>
            </div>
          </div>
          {arrayData.value.map((value, index) => (
            <div key={index} className={`${isArrayIndexHighlighted(arrayName, index) ? 'highlighted' : ''} 
              ${highlightedArrayVariables.includes(arrayName) ? 'highlighted-array array-highlighted-box' : 'array-box'}`}>
              <div className="array-index-value">
                <div className={`${isArrayIndexHighlighted(arrayName, index) ? 'highlighted-array-index' : 'array-index'} 
                  ${highlightedArrayVariables.includes(arrayName) ? 'highlighted-array-index' : ''}`}>
                  {getIteratorName(arrayName, index)}{index}
                </div>
                <div className="array-value">{value}</div>
              </div>
            </div>
          ))}
        </div>
      </ArcherElement>
    );
  };

  const categorizedArrays = useMemo(() => {
    const main = [];

    const leftArray = {};
    const rightArray = {};

    Object.entries(arrayVariables).forEach(([arrayName, arrayData]) => {
      if (!leftArray[arrayData.parent] && arrayName.startsWith('L')) {
        leftArray[arrayData.parent] = [];
      }

      if (!rightArray[arrayData.parent] && arrayName.startsWith('R')) {
        rightArray[arrayData.parent] = [];
      }

      if (arrayName.startsWith('L')) {
        leftArray[arrayData.parent].push(renderArray(arrayName, arrayData));
      } else if (arrayName.startsWith('R')) {
        rightArray[arrayData.parent].push(renderArray(arrayName, arrayData));
      } else {
        main.push(renderArray(arrayName, arrayData));
      }
    });

    return { main, leftArray, rightArray };
  }, [arrayVariables, arrayAddresses, highlightedIndices]);

  const isVisualizationEmpty = () => {
    return !(
      (variables && Object.keys(variables).length) ||
      (arrayVariables && Object.keys(arrayVariables).length)
    );
  };

  return (
    <div className="visualization-canvas" style={{display: !isVisualizationEmpty() ? 'block' : ''}}>
      {isVisualizationEmpty() ? (
        <p 
          style={{textAlign: 'center'}}
        >Code visualizations will appear here.</p>
        ) : (
          <ArcherContainer strokeColor="red">
            <div className="variables-container">
              {variables && Object.entries(variables).map(([key, { variable_name, value }], index) => (
                <div key={index} className="variable-box-wrapper">
                  <div className={`variable-box ${highlightedVariables.includes(variable_name) ? 'highlighted-variable' : ''}`}>
                    <div className="variable-value">{value}</div>
                    <div className={`${highlightedVariables.includes(variable_name) ? 'variable-name-highlighted' : 'variable-name'}`}>{variable_name}</div>
                  </div>
                  <div className={`variable-address ${highlightedVariables.includes(variable_name) ? 'highlighted-variable' : ''}`}>
                    {variableAddresses[key]}
                  </div>
                </div>
              ))}
            </div>

            <div className="main-array-container">
              {categorizedArrays.main}
            </div>
            <div style={{display: 'flex'}}>
              <div className="left-container-merge-sort">
                {Object.entries(categorizedArrays.leftArray).map(([parent, children]) => (
                  <div key={parent} className="parent-child-container">
                    <div className="parent-name">&nbsp;</div>
                    <div style={{display: 'flex'}}>
                      {children.map((child, index) => (
                        <div key={index} className="child-container">{child}</div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>

              <div className="right-container-merge-sort">
                {Object.entries(categorizedArrays.rightArray).map(([parent, children]) => (
                  <div key={parent} className="parent-child-container">
                    <div className="parent-name">&nbsp;</div>
                    <div style={{display: 'flex'}}>
                      {children.map((child, index) => (
                        <div key={index} className="child-container">{child}</div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </ArcherContainer>
        )
      }
    </div>
  );
};

export default VisualizationCanvas;
