const explanations = {
    Python: {
      2: "Declaring and initializing variable 'count'",
      3: "Declaring and initializing variable 'max_count'",
      5: "Starting the while loop and iterating until count is less than max_count",
      6: "Printing the current count",
      7: "Incrementing the count"
    },
    Java: {
      3: "Declaring and initializing variable 'count'",
      4: "Declaring and initializing variable 'maxCount'",
      6: "Starting the while loop and iterating until count is less than maxCount",
      7: "Printing the current count",
      8: "Incrementing the count"
    },
    C: {
      3: "Declaring and initializing variable 'count'",
      4: "Declaring and initializing variable 'maxCount'",
      6: "Starting the while loop and iterating until count is less than maxCount",
      7: "Printing the current count",
      8: "Incrementing the count",
      10: "Returning 0 to indicate successful execution"
    },
    "C++": {
      4: "Declaring and initializing variable 'count'",
      5: "Declaring and initializing variable 'maxCount'",
      7: "Starting the while loop and iterating until count is less than maxCount",
      8: "Printing the current count",
      9: "Incrementing the count",
      11: "Returning 0 to indicate successful execution"
    }
  };
  
  export default explanations;
  