const explanations = {
    Python: {
        3: "Declaring and initializing an integer variable 'x'",
        4: "Declaring and initializing a float variable 'y'",
        5: "Declaring and initializing a complex variable 'z'",        
        8: "Printing the value and type of the integer variable 'x'",
        9: "Printing the value and type of the float variable 'y'",
        10: "Printing the value and type of the complex variable 'z'"
    }
};

export default explanations;
