const explanations = {
  Python: {
    1: "Define the BinaryTree class to represent the binary tree structure.",
    2: "Initialize the BinaryTree class with a constructor method __init__.",
    3: "Create an attribute 'root' to represent the root of the tree, initially set to None.",
    5: "Executing the method inorder_traversal to perform an in-order traversal of the binary tree.",
    6: "Set the current node to the root of the binary tree.",
    7: "Checking if the current node (root) is None (i.e., the tree is empty).",
    8: "Tree is empty, returning from the method without doing anything.",
    10: "Creating an empty stack to keep track of nodes during the traversal.",
    11: "Starting a loop that continues as long as the current node is not None or the stack is not empty.",
    12: "Traverse the left subtree by iterating until the current node is None.",
    13: "Push the current node onto the stack to backtrack later.",
    14: "Move to the left child of the current node.",
    15: "Pop a node from the stack and set it as the current node.",
    16: "Print the data of the current node.",
    17: "Move to the right child of the current node to continue traversal.",
    20: "Main block to test the BinaryTree class functionality.",
    21: "Create an instance of the BinaryTree class.",
    23: "Calling the inorder_traversal method to traverse the tree and print its elements in order."
  },
  Java: {
    1: "Import the Stack class from the java.util package to use for storing nodes during traversal.",
    3: "Define the BinaryTree class to represent the binary tree structure.",
    4: "Declare a 'root' attribute to represent the root of the tree, initially set to null.",
    6: "Define the constructor BinaryTree() to initialize the tree.",
    7: "Set the 'root' of the tree to null, indicating an empty tree.",
    10: "Executing the inorderTraversal method to perform an in-order traversal of the binary tree.",
    11: "Set the current node to the root of the binary tree.",
    12: "Checking if the current node (root) is null (i.e., the tree is empty).",
    13: "Tree is empty, returning from the method without further processing.",
    16: "Creating an empty stack to keep track of nodes during the traversal.",
    17: "Starting a loop that continues as long as the current node is not null or the stack is not empty.",
    18: "Traverse the left subtree by iterating until the current node is null.",
    19: "Push the current node onto the stack to backtrack later.",
    20: "Move to the left child of the current node.",
    22: "Pop a node from the stack and set it as the current node.",
    23: "Print the data of the current node.",
    24: "Move to the right child of the current node to continue traversal.",
    29: "Define the Main class to test the BinaryTree class functionality.",
    30: "Main method to execute the program.",
    31: "Create an instance of the BinaryTree class.",
    33: "Calling the inorderTraversal method to traverse the tree and print its elements in order."
  },
  C: {
    1: "Include the stdio.h header for input and output functions like printf.",
    2: "Include the stdlib.h header for memory allocation functions.",
    4: "Define the Node struct to represent each element of the binary tree.",
    5: "Declare the data attribute to store the value of the node.",
    6: "Declare a pointer to represent the left child of the node.",
    7: "Declare a pointer to represent the right child of the node.",
    10: "Executing the inorderTraversal function to perform an in-order traversal of the binary tree.",
    11: "Declare an array to represent the stack for holding nodes during traversal.",
    12: "Initialize the top of the stack to -1 (indicating an empty stack).",
    13: "Set the current pointer to the root node for traversal.",
    15: "Start the outer loop that continues as long as current is not NULL or the stack is not empty.",
    16: "Start the inner loop to traverse the left subtree by pushing nodes onto the stack.",
    17: "Push the current node onto the stack and move to its left child.",
    18: "Set the current pointer to the left child of the current node.",
    20: "Pop the top node from the stack and set it as the current node.",
    21: "Print the data of the current node as part of the in-order traversal.",
    22: "Set the current pointer to the right child of the current node to traverse the right subtree.",
    26: "Main block to test the inorderTraversal function.",
    27: "Initialize the root of the tree as NULL (indicating an empty tree).",
    28: "Calling the inorderTraversal function to perform the traversal starting from the root.",
    29: "Return 0 to indicate successful program termination."
  },
  'C++': {
    1: "Include the standard input-output stream library.",
    2: "Include the stack library to use the stack data structure for storing nodes during traversal.",
    3: "Using the standard namespace.",
    5: "Define the BinaryTree class to represent the structure of the binary tree.",
    6: "Use public access specifier to make the following members accessible from outside the class.",
    7: "Declare a pointer to the root node.",
    9: "Executing the constructor to initialize the binary tree.",
    10: "Set the root to null pointer initially.",
    13: "Executing the inorderTraversal method to perform an in-order traversal of the binary tree.",
    14: "Set the current node to the root of the binary tree.",
    15: "Checking if the current node (root) is null pointer (i.e., the tree is empty).",
    16: "Tree is empty, returning from the method without further processing.",
    19: "Creating an empty stack to keep track of nodes during traversal.",
    20: "Starting a loop that continues as long as the current node is not null or the stack is not empty.",
    21: "Traverse the left subtree by iterating until the current node is null.",
    22: "Push the current node onto the stack to backtrack later.",
    23: "Move to the left child of the current node.",
    25: "Pop a node from the stack and set it as the current node.",
    26: "Removing the top node from the stack to process it.",
    27: "Print the data of the current node.",
    28: "Move to the right child of the current node to continue traversal.",
    33: "Main method to test the BinaryTree class functionality.",
    34: "Create an instance of the BinaryTree class.",
    36: "Calling the inorderTraversal method to traverse the tree and print its elements in order.",
    38: "Returning 0 to indicate successful execution of the program.",
  },
};
  
  export default explanations;
  