import React, { useState } from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvas';
import AlgorithmDescription from '../AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';
import UpdateVariablesModal from './UpdateVariablesModal';

import { useStringConcatenation } from './useStringConcatenation';

const StringConcatenation = ({ language, subTopicId }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [str1, setStr1] = useState("Code");
    const [str2, setStr2] = useState("Sleep");

    const {
        highlightedLine,
        focusedEndLine,
        isRunning,
        isPaused,
        delay,
        logs,
        variables,
        stringVariables,
        highlightedVariables,
        highlightedStringVariables,
        highlightedStringIndices,
        isMuted,
        codeSamples,
        setDelay,
        setIsPaused,
        setIsMuted,
        updateVariables,
        runStringConcatenation,
    } = useStringConcatenation(language, str1, str2);


    const handleUpdateVariables = () => {
        updateVariables(str1, str2);
        setModalVisible(false);
    };

    return (
        <div className="container-fluid main-container">
            <div className="content-area d-flex">
                <div className="col-lg-6">
                <CodeDisplay
                    codeLines={codeSamples || []}
                    highlightedLine={highlightedLine}
                    focusedEndLine={focusedEndLine}
                    topic={"String Concatenation"}
                    subTopicId={subTopicId}
                    language={language}
                    onOpenModal={() => setModalVisible(true)}
                />
                </div>
                <div className="col-lg-6">
                <div className="visualize-card shadow-lg p-3">
                    <h3 className="section-title">Canvas</h3>
                    <Controls
                        isRunning={isRunning}
                        isPaused={isPaused}
                        delay={delay}
                        setDelay={setDelay}
                        runVisualization={runStringConcatenation}
                        setIsPaused={setIsPaused}
                        isMuted={isMuted}
                        setIsMuted={setIsMuted}
                    />
                    <VisualizationCanvas
                        variables={variables}
                        stringVariables={stringVariables}
                        highlightedVariables={highlightedVariables}
                        highlightedStringVariables={highlightedStringVariables}
                        highlightedStringIndices={highlightedStringIndices}
                    />
                    <LoggerDrawer logs={logs} />
                </div>
                </div>
            </div>
            <div className="col-lg-12 mt-4">
                <AlgorithmDescription />
            </div>
            <UpdateVariablesModal
                isVisible={modalVisible}
                str1={str1}
                str2={str2}
                setStr1={setStr1}
                setStr2={setStr2}
                onSave={handleUpdateVariables}
                onClose={() => setModalVisible(false)}
            />
        </div>
    );
};

export default StringConcatenation;
