export const bubbleSortPython = async (list, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, customSleep) => {
    for (let i = 0; i < list.length; i++) {
      await highlightLine(1);
      setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
      setHighlightedVariables(['i']);
      await customSleep();
      setHighlightedVariables([]);
      
      for (let j = 0; j < list.length - i - 1; j++) {
        await highlightLine(2);
        setVariables((vars) => ({ ...vars, j: { variable_name: 'j', value: j } }));
        setHighlightedVariables(['j']);
        await customSleep();
        setHighlightedVariables([]);
        
        await highlightLine(3);
        setHighlightedIndices([{ arrayName: 'list', index: j, iteratorName: 'j' }, { arrayName: 'list', index: j + 1, iteratorName: 'j + 1' }]);
        await customSleep();
        setHighlightedVariables([]);
        if (list[j] > list[j + 1]) {
          await highlightLine(4);
          [list[j], list[j + 1]] = [list[j + 1], list[j]];
          setHighlightedIndices([{ arrayName: 'list', index: j, iteratorName: 'j' }, { arrayName: 'list', index: j + 1, iteratorName: 'j + 1' }]);
          await customSleep();
          setHighlightedIndices([]);
        }
      }
    }
  
    await highlightLine(5);
    await customSleep();
    setHighlightedIndices([]);
    return list;
  };
  
  export const bubbleSortJava = async (list, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, customSleep) => {
    await highlightLine(2);
    let n = list.length;
    setVariables((vars) => ({ ...vars, n: { variable_name: 'n', value: n } }));
    setHighlightedVariables(['n']);
    await customSleep();
    setHighlightedVariables([]);
    
    for (let i = 0; i < n - 1; i++) {
      await highlightLine(3);
      setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
      setHighlightedVariables(['i']);
      await customSleep();
      setHighlightedVariables([]);
      
      for (let j = 0; j < n - i - 1; j++) {
        await highlightLine(4);
        setVariables((vars) => ({ ...vars, j: { variable_name: 'j', value: j } }));
        setHighlightedVariables(['j']);
        await customSleep();
        setHighlightedVariables([]);
        
        await highlightLine(5);
        setHighlightedIndices([{ arrayName: 'list', index: j, iteratorName: 'j' }, { arrayName: 'list', index: j + 1, iteratorName: 'j + 1' }]);
        await customSleep();
        setHighlightedIndices([]);
        if (list[j] > list[j + 1]) {
          await highlightLine(6);
          let temp = list[j];
          setVariables((vars) => ({ ...vars, temp: { variable_name: 'temp', value: temp } }));
          setHighlightedVariables(['temp']);
          setHighlightedIndices([{ arrayName: 'list', index: j, iteratorName: 'j' }]);
          await customSleep();
          setHighlightedVariables([]);
          setHighlightedIndices([]);

          await highlightLine(7);
          list[j] = list[j + 1];
          setHighlightedIndices([{ arrayName: 'list', index: j, iteratorName: 'j' }, { arrayName: 'list', index: j + 1, iteratorName: 'j + 1' }]);
          await customSleep();
          setHighlightedIndices([]);

          await highlightLine(8);
          list[j + 1] = temp;
          setHighlightedIndices([{ arrayName: 'list', index: j + 1, iteratorName: 'j + 1' }]);
          setHighlightedVariables(['temp']);
          await customSleep();
          setHighlightedIndices([]);
          setHighlightedVariables([]);
        }
      }
    }
  
    await highlightLine(12);
    await customSleep();
    setHighlightedIndices([]);
    return list;
  };
  
  export const bubbleSortC = async (list, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, customSleep) => {
    let i, j, temp;
    await highlightLine(2);
    setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i }, j: { variable_name: 'j', value: j }, temp: { variable_name: 'temp', vmpalue: temp } }));
    await customSleep();
    
    let n = list.length;
    for (let i = 0; i < n - 1; i++) {
      await highlightLine(3);
      setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
      setHighlightedVariables(['i']);
      await customSleep();
      setHighlightedVariables([]);
      
      for (let j = 0; j < n - i - 1; j++) {
        await highlightLine(4);
        setVariables((vars) => ({ ...vars, j: { variable_name: 'j', value: j } }));
        setHighlightedVariables(['j']);
        await customSleep();
        setHighlightedVariables([]);
        
        await highlightLine(5);
        setHighlightedIndices([{ arrayName: 'list', index: j, iteratorName: 'j' }, { arrayName: 'list', index: j + 1, iteratorName: 'j + 1' }]);        
        await customSleep();
        setHighlightedIndices([]);
        if (list[j] > list[j + 1]) {
          await highlightLine(6);
          let temp = list[j];
          setVariables((vars) => ({ ...vars, temp: { variable_name: 'temp', value: temp } }));
          setHighlightedVariables(['temp']);
          setHighlightedIndices([{ arrayName: 'list', index: j, iteratorName: 'j' }]);
          await customSleep();
          setHighlightedVariables([]);
          setHighlightedIndices([]);

          await highlightLine(7);
          list[j] = list[j + 1];
          setHighlightedIndices([{ arrayName: 'list', index: j, iteratorName: 'j' }, { arrayName: 'list', index: j + 1, iteratorName: 'j + 1' }]);
          await customSleep();
          setHighlightedIndices([]);

          await highlightLine(8);
          list[j + 1] = temp;
          setHighlightedIndices([{ arrayName: 'list', index: j + 1, iteratorName: 'j + 1' }]);
          setHighlightedVariables(['temp']);
          await customSleep();
          setHighlightedIndices([]);
          setHighlightedVariables([]);
        }
      }
    }
  
    await highlightLine(12);
    await customSleep();
    setHighlightedIndices([]);
    return list;
  };
  
  export const bubbleSortCpp = async (list, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, customSleep) => {
    let i, j, temp;
    await highlightLine(3);
    setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i }, j: { variable_name: 'j', value: j }, temp: { variable_name: 'temp', vmpalue: temp } }));
    await customSleep();

    let n = list.length;
    for (let i = 0; i < n - 1; i++) {
      await highlightLine(4);
      setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
      setHighlightedVariables(['i']);
      await customSleep();
      setHighlightedVariables([]);
      
      for (let j = 0; j < n - i - 1; j++) {
        await highlightLine(5);
        setVariables((vars) => ({ ...vars, j: { variable_name: 'j', value: j } }));
        setHighlightedVariables(['j']);
        await customSleep();
        setHighlightedVariables([]);
        
        await highlightLine(6);
        setHighlightedIndices([{ arrayName: 'list', index: j, iteratorName: 'j' }, { arrayName: 'list', index: j + 1, iteratorName: 'j + 1' }]);        
        await customSleep();
        setHighlightedIndices([]);
        if (list[j] > list[j + 1]) {
          await highlightLine(7);
          let temp = list[j];
          setVariables((vars) => ({ ...vars, temp: { variable_name: 'temp', value: temp } }));
          setHighlightedVariables(['temp']);
          setHighlightedIndices([{ arrayName: 'list', index: j, iteratorName: 'j' }]);
          await customSleep();
          setHighlightedVariables([]);
          setHighlightedIndices([]);

          await highlightLine(8);
          list[j] = list[j + 1];
          setHighlightedIndices([{ arrayName: 'list', index: j, iteratorName: 'j' }, { arrayName: 'list', index: j + 1, iteratorName: 'j + 1' }]);
          await customSleep();
          setHighlightedIndices([]);

          await highlightLine(9);
          list[j + 1] = temp;
          setHighlightedIndices([{ arrayName: 'list', index: j + 1, iteratorName: 'j + 1' }]);
          setHighlightedVariables(['temp']);
          await customSleep();
          setHighlightedIndices([]);
          setHighlightedVariables([]);
        }
      }
    }
  
    await highlightLine(13);
    await customSleep();
    setHighlightedIndices([]);
    return list;
  };
  