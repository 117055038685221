// useBinarySearch.js
import { useState, useEffect, useRef } from 'react';
import { binarySearchPython, binarySearchJava, binarySearchC, binarySearchCpp } from './binarySearchAlgorithms';
import explanations from './explanations';
import codeSamplesTemplate from './codeSamples';

export const useBinarySearch = (language, sortedList, targetElement) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [callingLine, setCallingLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const delayRef = useRef(delay);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [arrayVariables, setArrayVariables] = useState({});
  const [mid, setMid] = useState(null);
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedArrayVariables, setHighlightedArrayVariables] = useState([]);
  const [highlightedIndices, setHighlightedIndices] = useState([]);
  const [isMuted, setIsMuted] = useState(true);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const isMutedRef = useRef(isMuted);
  const synth = window.speechSynthesis;


  const updateVariables = (newSortedList, newTargetElement) => {
    setVariables({});
    setArrayVariables({});

     // Dynamically update the code samples
     let updatedCodeSamples = "";
     if (language == 'Python') {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(/sorted_list = \[.*?\]/, `sorted_list = [${newSortedList.join(", ")}]`)
          .replace(/target_element = \d+/, `target_element = ${newTargetElement}`)
      );
     } else {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(
            /int sortedArray\[\] = \{.*?\};/,
            `int sortedArray[] = {${newSortedList.join(", ")}};`
          )
          .replace(/targetElement = \d+/, `targetElement = ${newTargetElement}`)
      );
     }
    
    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setCallingLine(null);
    setVariables({});
    setLogs([]);
    setMid(null);
    setHighlightedVariables([]);
    setHighlightedArrayVariables([]);
    setHighlightedIndices([]);
  };

  const speak = (message) => {
    return new Promise((resolve) => {
      if (isMutedRef.current) {
        resolve();
        return;
      }
      const utterance = new SpeechSynthesisUtterance(message);
      utterance.onend = resolve;
      synth.speak(utterance);
    });
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setCallingLine((prevCallingLine) => {
      if (prevCallingLine === lineNumber) {
        return null;
      }
      return prevCallingLine;
    });
    setHighlightedLine(lineNumber);    
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const highlightCallingLine = async (lineNumber) => {
    setCallingLine(lineNumber);
    await customSleep();
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async () => {
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runBinarySearchPython = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(14);
    setArrayVariables({ sorted_list: { variable_name: 'sorted_list', value: sortedList } });
    setHighlightedArrayVariables(['sorted_list']);
    await customSleep();
    setHighlightedArrayVariables([]);

    await highlightLine(15);
    setVariables((vars) => ({ ...vars, targetElement: { variable_name: 'target_element', value: targetElement } }));
    setHighlightedVariables(['target_element']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(16);
    await customSleep();

    await highlightCallingLine(16);
    let result = await binarySearchPython(sortedList, targetElement, highlightLine, setVariables, setMid, setHighlightedVariables, setHighlightedIndices, customSleep);
    setVariables((vars) => ({ ...vars, result: { variable_name: 'result', value: result } }));
    await highlightLine(16);
    setHighlightedVariables(['result']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(17);
    await customSleep();
    if (result !== -1) {
      await highlightLine(18);
      await logMessage(`Element ${targetElement} found at index ${result}.`);
      await customSleep();
    } else {
      await highlightLine(19);
      await customSleep();

      await highlightLine(20);
      await logMessage(`Element ${targetElement} not found in the list.`);
      await customSleep();
    }

    await focusEndLine(21);
    setIsRunning(false);
  };

  const runBinarySearchJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(18);
    await customSleep();

    await highlightLine(19);
    setArrayVariables({ sortedArray: { variable_name: 'sortedArray', value: sortedList } });
    setHighlightedArrayVariables(['sortedArray']);
    await customSleep();
    setHighlightedArrayVariables([]);

    await highlightLine(20);
    setVariables((vars) => ({ ...vars, targetElement: { variable_name: 'targetElement', value: targetElement } }));
    setHighlightedVariables(['targetElement']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(21);
    await customSleep();

    await highlightCallingLine(21);
    let result = await binarySearchJava(sortedList, targetElement, highlightLine, setVariables, setMid, setHighlightedVariables, setHighlightedIndices, customSleep);
    setVariables((vars) => ({ ...vars, result: { variable_name: 'result', value: result } }));
    await highlightLine(21);
    setHighlightedVariables(['result']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(22);
    await customSleep();
    if (result !== -1) {
      await highlightLine(23);
      await logMessage(`Element ${targetElement} found at index ${result}.`);
      await customSleep();
    } else {
      await highlightLine(25);
      await customSleep();

      await highlightLine(26);
      await logMessage(`Element not found`);
      await customSleep();
    }

    await focusEndLine(30);
    setIsRunning(false);
  };

  const runBinarySearchC = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(20);
    setArrayVariables({ sortedArray: { variable_name: 'sortedArray', value: sortedList } });
    setHighlightedArrayVariables(['sortedArray']);
    await customSleep();
    setHighlightedArrayVariables([]);

    await highlightLine(21);
    let size = sortedList.length;
    setVariables((vars) => ({ ...vars, size: { variable_name: 'size', value: size } }));
    setHighlightedVariables(['size']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(22);
    setVariables((vars) => ({ ...vars, targetElement: { variable_name: 'targetElement', value: targetElement } }));
    setHighlightedVariables(['targetElement']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(23);
    await customSleep();

    await highlightCallingLine(23);
    let result = await binarySearchC(sortedList, size, targetElement, highlightLine, setVariables, setMid, setHighlightedVariables, setHighlightedIndices, customSleep);
    setVariables((vars) => ({ ...vars, result: { variable_name: 'result', value: result } }));
    await highlightLine(23);
    setHighlightedVariables(['result']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(24);
    await customSleep();
    if (result !== -1) {
      await highlightLine(25);
      await logMessage(`Element found at index ${result}.`);
      await customSleep();
    } else {
      await highlightLine(27);
      await customSleep();

      await highlightLine(28);
      await logMessage(`Element not found`);
      await customSleep();
    }

    await highlightLine(30);
    await customSleep();

    await focusEndLine(32);
    setIsRunning(false);
  };

  const runBinarySearchCpp = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(21);
    setArrayVariables({ sortedArray: { variable_name: 'sortedArray', value: sortedList } });
    setHighlightedArrayVariables(['sortedArray']);
    await customSleep();
    setHighlightedArrayVariables([]);

    await highlightLine(22);
    let size = sortedList.length;
    setVariables((vars) => ({ ...vars, size: { variable_name: 'size', value: size } }));
    setHighlightedVariables(['size']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(23);
    setVariables((vars) => ({ ...vars, targetElement: { variable_name: 'targetElement', value: targetElement } }));
    setHighlightedVariables(['targetElement']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(24);
    await customSleep();

    await highlightCallingLine(24);
    let result = await binarySearchCpp(sortedList, size, targetElement, highlightLine, setVariables, setMid, setHighlightedVariables, setHighlightedIndices, customSleep);
    setVariables((vars) => ({ ...vars, result: { variable_name: 'result', value: result } }));
    await highlightLine(24);
    setHighlightedVariables(['result']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(25);
    await customSleep();
    if (result !== -1) {
      await highlightLine(26);
      await logMessage(`Element found at index ${result}.`);
      await customSleep();
    } else {
      await highlightLine(28);
      await customSleep();

      await highlightLine(29);
      await logMessage(`Element not found`);
      await customSleep();
    }

    await highlightLine(31);
    await customSleep();

    await focusEndLine(33);
    setIsRunning(false);
  };

  const runBinarySearch = async () => {
    if (language === 'Python') {
      await runBinarySearchPython();
    } else if (language === 'Java') {
      await runBinarySearchJava();
    } else if (language === 'C') {
      await runBinarySearchC();
    } else if (language === 'C++') {
      await runBinarySearchCpp();
    }
  };

  return {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    mid,
    highlightedVariables,
    highlightedArrayVariables,
    highlightedIndices,
    isMuted,
    codeSamples,
    updateVariables,
    setHighlightedLine,
    setFocusedEndLine,
    setCallingLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setArrayVariables,
    setMid,
    setHighlightedVariables,
    setHighlightedArrayVariables,
    setHighlightedIndices,
    setIsMuted,
    runBinarySearch
  };
};
