const explanations = {
    Java: {
        1: "Defining the Animal interface",
        2: "Declaring an abstract method sound() in the Animal interface",
        5: "Defining the Dog class that implements the Animal interface",
        6: "Declaring a private field favoriteToy of type String",
        8: "The constructor method for the Dog class",
        9: "Initializing the class field favoriteToy",
        12: "Providing the implementation for the sound() method in the Dog class",
        13: "Printing the message",
        17: "Defining the Main class",
        18: "Defining the main method",
        19: "Creating an instance of Dog with the name 'myDog' using the constructor.",
        20: "Calling the sound() method on the Dog instance"
    }
};

export default explanations;
