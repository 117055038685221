const explanations = {
    Python: {
         3: "Declaring and initializing a string variable 'name'",
         4: "Printing the string 'name'",
         7: "Declaring and initializing a list variable 'fruits' with initial values",
         8: "Appending 'date' to the list 'fruits'",
         9: "Printing the updated list 'fruits'",
        10: "Printing the first item in the list 'fruits'",
        13: "Declaring and initializing a tuple variable 'coordinates'",
        14: "Printing the tuple 'coordinates'",
        15: "Printing the second item in the tuple 'coordinates'",
        18: "Declaring and initializing a range variable 'numbers'",
        19: "Converting the range to a list and printing it"
    }
};

export default explanations;
