const codeSamples = {
    Python: [
      'if __name__ == "__main__":',
      '    s1 = "hello"',
      '    s2 = "world"',
      '    s3 = "hello"',
    ],
    Java: [
      'public class StringInterning {',
      '    public static void main(String[] args) {',
      '        String s1 = "hello";',
      '        String s2 = "world";',
      '        String s3 = "hello";',
      '    }',
      '}',
    ],
  };
  
  export default codeSamples;
  