import React, { useEffect } from 'react';
import { BrowserRouter as Router,
  Route, Routes, useNavigate, useParams, useLocation
} from 'react-router-dom';

import Navbar from './components/Navbar';
import MainContent from './components/MainContent';
import Footer from './components/Footer';
import LearningTopics from './components/LearningTopics';
import PricingSection from './components/PricingSection';
import ContactUs from './components/ContactUs';
import EmailVerification from './components/EmailVerification';
import UserProfile from './components/UserProfile';

import BasicCode from './components/BasicCode/BasicCode';
import ArithmeticOperations from './components/ArithmeticOperations/ArithmeticOperations';
import IfElseOperations from './components/IfElseOperations/IfElseOperations';
import ValueInterchange from './components/ValueInterchange/ValueInterchange';
import SwitchCaseOperations from './components/SwitchCaseOperations/SwitchCaseOperations';

import NumericTypes from './components/NumericTypes/NumericTypes';
import SequenceTypes from './components/SequenceTypes/SequenceTypes';
import DictionaryTypes from './components/DictionaryTypes/DictionaryTypes';
import SetTypes from './components/SetTypes/SetTypes';
import BooleanTypes from './components/BooleanTypes/BooleanTypes';
import IntegerTypes from './components/IntegerTypes/IntegerTypes';
import FloatTypes from './components/FloatTypes/FloatTypes';
import DoubleTypes from './components/DoubleTypes/DoubleTypes';
import ShortTypes from './components/ShortTypes/ShortTypes';
import LongTypes from './components/LongTypes/LongTypes';
import CharTypes from './components/CharTypes/CharTypes';
import EnumTypes from './components/EnumTypes/EnumTypes';
import ClassType from './components/ClassType/ClassType';
import InterfaceType from './components/InterfaceType/InterfaceType';
import StructureType from './components/StructureType/StructureType';
import UnionType from './components/UnionType/UnionType';
import TypeDef from './components/TypeDef/TypeDef';

import ForLoop from './components/ForLoop/ForLoop';
import WhileLoop from './components/WhileLoop/WhileLoop';
import DoWhileLoop from './components/DoWhileLoop/DoWhileLoop';

import BasicArray from './components/BasicArray/BasicArray';
import SumOfAllElements from './components/SumOfAllElements/SumOfAllElements';
import Stack from './components/Stack/Stack';
import Queue from './components/Queue/Queue';

import BasicString from './components/BasicString/BasicString';
import StringConcatenation from './components/StringConcatenation/StringConcatenation';
import StringPalindrome from './components/StringPalindrome/StringPalindrome';
import StringEqualCheck from './components/StringEqualCheck/StringEqualCheck';
import StringInterning from './components/StringInterning/StringInterning';
import StringImmutable from './components/StringImmutable/StringImmutable';

import BinarySearch from './components/BinarySearch/BinarySearch';
import LinearSearch from './components/LinearSearch/LinearSearch';

import SelectionSort from './components/SelectionSort/SelectionSort';
import BubbleSort from './components/BubbleSort/BubbleSort';
import InsertionSort from './components/InsertionSort/InsertionSort';
import QuickSort from './components/QuickSort/QuickSort';
import MergeSort from './components/MergeSort/MergeSort';

import LinkedList from './components/LinkedList/LinkedList';
import LinkedListTraversal from './components/LinkedListTraversal/LinkedListTraversal';
import LinkedListDeletion from './components/LinkedListDeletion/LinkedListDeletion';
import LinkedListUpdate from './components/LinkedListUpdate/LinkedListUpdate';

import BinaryTree from './components/BinaryTree/BinaryTree';
import BinarySearchTree from './components/BinarySearchTree/BinarySearchTree';
import BinaryTreeInorderTraversal from './components/BinaryTreeInorderTraversal/BinaryTreeInorderTraversal';
import BinaryTreePreorderTraversal from './components/BinaryTreePreorderTraversal/BinaryTreePreorderTraversal';
import BinaryTreePostorderTraversal from './components/BinaryTreePostorderTraversal/BinaryTreePostorderTraversal';
import BinaryTreeLevelorderTraversal from './components/BinaryTreeLevelorderTraversal/BinaryTreeLevelorderTraversal';

import Graph from './components/Graph/Graph';
import BFS from './components/Graph_BFS/BFS';
import DFS from './components/Graph_DFS/DFS';

import './style.css';
import './VisualizationCanvas.css';

// Define a function to convert topic names to URL-friendly strings
const formatToURL = (name) => name.toLowerCase().replace(/\s+/g, '-');

const toTitleCase = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const App = () => {
  const navigate = useNavigate();

  return (
    <div className="">
      <Navbar />
      
      <Routes>
        <Route path="/" element={
          <MainContent onSelectLanguage={(lang) => navigate(`/topics/${lang.toLowerCase()}`)}/>
        } />
        <Route path="/topics/:language" element={<LearningTopicsWrapper />} />
        <Route path="/topics/:language/:topic" element={<TopicDetailsWrapper />} />
        <Route path="/pricing" element={<PricingSection />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/verify-email" element={<EmailVerification />} />
        <Route path="/profile" element={<UserProfile />} />
      </Routes>
      <Footer />
    </div>
  );
};

const LearningTopicsWrapper = () => {
  const { language } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // Ensure the language is in lowercase for consistency
    const lowerCaseLanguage = decodeURIComponent(language).toLowerCase();

    // Set an SEO-friendly page title
    document.title = `Learn ${toTitleCase(lowerCaseLanguage)} Programming | Coding Canvas`;
    document
      .querySelector('meta[name="description"]')
      ?.setAttribute("content", `Explore interactive tutorials, code samples, and exercises to learn ${toTitleCase(lowerCaseLanguage)} on Coding Canvas.`);
  }, [language]);

  const formattedlanguage = decodeURIComponent(language).charAt(0).toUpperCase() + decodeURIComponent(language).slice(1);

  return (
    <LearningTopics 
      selectedLanguage={decodeURIComponent(formattedlanguage)} 
      onSelectTopic={(topic, id) => navigate(`/topics/${language.toLowerCase()}/${formatToURL(topic)}`, { state: { subTopicId: id } })}
    />
  );
};

const TopicDetailsWrapper = () => {
  const { language, topic } = useParams();

  const location = useLocation();
  const subTopicId = location.state?.subTopicId;

  useEffect(() => {
    // Set the SEO-friendly page title based on the selected topic
    document.title = `${toTitleCase(decodeURIComponent(language))} - ${toTitleCase(decodeURIComponent(topic).replace(/-/g, ' '))}`;
  }, [language, topic]);

  // Format the topic from the URL to match the component conditions
  const formattedTopic = formatToURL(decodeURIComponent(topic));

  const formattedlanguage = decodeURIComponent(language).charAt(0).toUpperCase() + decodeURIComponent(language).slice(1);

  return (
    <div className="">
      {formattedTopic === 'basic-code' && <BasicCode language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'arithmetic-operations' && <ArithmeticOperations language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'if-else' && <IfElseOperations language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'value-interchange' && <ValueInterchange language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'switch-case' && <SwitchCaseOperations language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}

      {formattedTopic === 'numeric-types' && <NumericTypes language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'sequence-types' && <SequenceTypes language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {(formattedTopic === 'dictionary' || formattedTopic === 'map') && <DictionaryTypes language={decodeURIComponent(formattedlanguage)} topic={formattedTopic} subTopicId={subTopicId} />}
      {formattedTopic === 'set' && <SetTypes language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'boolean' && <BooleanTypes language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'integer' && <IntegerTypes language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'float' && <FloatTypes language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'double' && <DoubleTypes language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'short' && <ShortTypes language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'long' && <LongTypes language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'character' && <CharTypes language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {(formattedTopic === 'enums' || formattedTopic === 'enumerations') && <EnumTypes language={decodeURIComponent(formattedlanguage)} topic={formattedTopic} subTopicId={subTopicId} />}
      {(formattedTopic === 'classes' || formattedTopic === 'class') && <ClassType language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'interfaces' && <InterfaceType language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'structures' && <StructureType language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'unions' && <UnionType language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'typedef' && <TypeDef language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}

      {formattedTopic === 'for-loop' && <ForLoop language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'while-loop' && <WhileLoop language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'do-while-loop' && <DoWhileLoop language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}

      {formattedTopic === 'basic-array' && <BasicArray language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'sum-of-all-elements' && <SumOfAllElements language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'stack' && <Stack language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'queue' && <Queue language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}

      {formattedTopic === 'basic-string' && <BasicString language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'concatenation' && <StringConcatenation language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'palindrome' && <StringPalindrome language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'equal-check' && <StringEqualCheck language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'interning' && <StringInterning language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'immutable' && <StringImmutable language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}

      {formattedTopic === 'binary-search' && <BinarySearch language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'linear-search' && <LinearSearch language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}

      {formattedTopic === 'selection-sort' && <SelectionSort language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'bubble-sort' && <BubbleSort language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'insertion-sort' && <InsertionSort language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'quick-sort' && <QuickSort language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'merge-sort' && <MergeSort language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}

      {formattedTopic === 'singly-linked-list' && <LinkedList language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'singly-linked-list-traversal' && <LinkedListTraversal language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'singly-linked-list-deletion' && <LinkedListDeletion language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'singly-linked-list-update' && <LinkedListUpdate language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}

      {formattedTopic === 'binary-tree' && <BinaryTree language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'binary-search-tree' && <BinarySearchTree language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'inorder-traversal' && <BinaryTreeInorderTraversal language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'preorder-traversal' && <BinaryTreePreorderTraversal language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'postorder-traversal' && <BinaryTreePostorderTraversal language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'levelorder-traversal' && <BinaryTreeLevelorderTraversal language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}

      {formattedTopic === 'graph' && <Graph language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'dfs' && <DFS language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
      {formattedTopic === 'bfs' && <BFS language={decodeURIComponent(formattedlanguage)} subTopicId={subTopicId} />}
    </div>
  );
};

const AppWrapper = () => (
  <Router>
    <Routes>
      <Route path="/*" element={<App />} />
    </Routes>
  </Router>
);

export default AppWrapper;
