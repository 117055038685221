const explanations = {
  Python: {
    1: "Defining the Node class that represents a single element in the linked list.",
    2: "Executing the constructor method __init__ to initialize the node with the provided data.",
    3: "Assigning the passed data to the node's data attribute.",
    4: "Setting the next attribute to None, indicating that the node does not point to any other node yet.",
    6: "Defining the LinkedList class that manages the linked list.",
    7: "Executing the constructor method __init__ of the LinkedList class.",
    8: "Initializing the linked list with an empty head (i.e., the list is initially empty).",
    10: "Executing the delete method, which removes a node with a given data value from the linked list.",
    11: "Checking if the list is empty by verifying if head is None.",
    12: "Head is None, so it prints 'The list is empty.' to indicate there are no elements.",
    13: "Exiting from the method.",
    15: "Checking if the head node contains the data to be deleted.",
    16: "Head node contains the data, moving the head pointer to the next node, effectively deleting the current head.",
    17: "Printing a message indicating the node with the given data was deleted.",
    18: "Exiting from the method.",
    20: "Declare a temporary variable temp to traverse the list starting from the head.",
    21: "Loops through the list as long as the next node exists and its data doesn't match the target data.",
    22: "Moves the pointer to the next node in the linked list for further traversal.",
    23: "Checking if the node to delete is found by examining temp.next.",
    24: "temp.next is None, that means the data wasn't found in the list. Printing a not found message.",
    25: "temp.next is not None, that means the node to be deleted is found. So, executing else block.",
    26: "Adjusting temp.next to skip over the node containing the target data, effectively removing it from the list by pointing to the following node.",
    27: "Printing a message indicating the node was deleted from the list.",
    29: "Main block of the LinkedList class.",
    30: "Creating an instance of LinkedList named list.",
    32: "Calling the delete method to remove a node that contains the specified data."
  },
  Java: {
    1: "Defining the Node class that represents a single element in the linked list.",
    2: "Declaring an integer data variable to hold the value for the node.",
    3: "Declaring a next variable that will point to the next node in the linked list.",
    5: "Executing the Node constructor to initialize the node with data.",
    6: "Setting the node's data to the passed value.",
    7: "Setting the next pointer to null since it's a new node without a next node.",
    11: "Defining the LinkedList class that will manage the linked list.",
    12: "Declaring a head variable to keep track of the first node in the linked list.",
    14: "Executing the delete method, which removes a node with a given data value from the linked list.",
    15: "Checking if the list is empty by verifying if head is null.",
    16: "Head is null, so it prints 'The list is empty.' to indicate there are no elements.",
    17: "Exiting from the method.",
    20: "Checking if the head node contains the data to be deleted.",
    21: "Head node contains the data, moving the head pointer to the next node, effectively deleting the current head.",
    22: "Printing a message indicating the node with the given data was deleted.",
    23: "Exiting from the method.",
    26: "Declare a temporary variable temp to traverse the list starting from the head.",
    27: "Loops through the list as long as the next node exists and its data doesn't match the target data.",
    28: "Moves the pointer to the next node in the linked list for further traversal.",
    30: "Checking if the node to delete is found by examining temp.next.",
    31: "temp.next is null, that means the data wasn't found in the list. Printing a not found message.",
    32: "temp.next is not null, that means the node to be deleted is found. So, executing else block.",
    33: "Adjusting temp.next to skip over the node containing the target data, effectively removing it from the list by pointing to the following node.",
    34: "Printing a message indicating the node was deleted from the list.",
    38: "Main block of the LinkedList class.",
    39: "Creating an instance of LinkedList named list.",
    40: "Calling the delete method to remove a node that contains the specified data."
  },
  C: {
    1: "Including the standard input-output library.",
    2: "Including the standard library for memory allocation.",
    4: "Defining the Node structure to represent an element in the linked list.",
    5: "Declaring an integer data variable to hold the value of the node.",
    6: "Declaring a pointer to the next node in the list.",
    9: "Defining a global head pointer to track the start of the linked list.",
    11: "Executing the delete function, which removes a node with a given data value from the linked list.",
    12: "Checking if the list is empty by verifying if head is NULL.",
    13: "Head is NULL, so it prints 'The list is empty.' to indicate there are no elements.",
    14: "Exiting from the function.",
    17: "Checking if the head node contains the data to be deleted.",
    18: "Declaring a temporary pointer to store the value of the head. After updating the head node, the temporary node will be deleted.",
    19: "Moving the head pointer to the next node.",
    20: "Deleting the old head node from the memory.",
    21: "Printing a message indicating the node with the given data was deleted.",
    22: "Exiting from the function.",
    25: "Declare a temporary variable temp to traverse the list starting from the head.",
    26: "Loops through the list as long as the next node exists and its data doesn't match the target data.",
    27: "Moves the pointer to the next node in the linked list for further traversal.",
    30: "Checking if the node to delete is found by examining temp.next.",
    31: "Next of temp is NULL, that means the data wasn't found in the list. Printing a not found message.",
    32: "Next of temp is not a NULL, that means the node to be deleted is found. So, executing else block.",
    33: "Declare a pointer of type Structure Node, named toDelete, to hold the node that is to be deleted.",
    34: "Adjusting temp.next to skip over the node containing the target data, effectively removing it from the list.",
    35: "Deleting the target node from the memory.",
    36: "Printing a message indicating the node was deleted from the list.",
    40: "Main block of the LinkedList class.",
    42: "Calling the delete function to remove a node that contains the specified data.",
    44: "Returning 0 to indicate successful execution.",
  },
  "C++": {
    1: "Including the iostream library for input-output operations.",
    2: "Using the standard namespace.",
    4: "Defining the Node class to represent an element in the linked list.",
    6: "Declaring public the data variable to hold the node's value.",
    7: "Declaring a public pointer to the next node in the linked list.",
    9: "Executing the Node constructor to initialize the node with data.",
    10: "Setting the node's data to the passed value.",
    11: "Setting the next pointer to nullptr since it's a new node.",
    15: "Defining the LinkedList class to manage the linked list.",
    17: "Declaring a head pointer to track the start of the linked list.",
    19: "Executing the constructor method of the class LinkedList.",
    20: "Initializing the head pointer to nullptr (i.e., the list is initially empty).",
    23: "Executing the delete method, which removes a node with a given data value from the linked list.",
    24: "Checking if the list is empty by verifying if head is null pointer.",
    25: "Head is null pointer, so it prints 'The list is empty.' to indicate there are no elements.",
    26: "Exiting from the method.",
    29: "Checking if the head node contains the data to be deleted.",
    30: "Declaring a temporary pointer to store the value of the head. After updating the head node, the temporary node will be deleted.",
    31: "Moving the head pointer to the next node.",
    32: "Deleting the old head node from the memory.",
    33: "Printing a message indicating the node with the given data was deleted.",
    34: "Exiting from the method.",
    37: "Declare a temporary variable temp to traverse the list starting from the head.",
    38: "Loops through the list as long as the next node exists and its data doesn't match the target data.",
    39: "Moves the pointer to the next node in the linked list for further traversal.",
    41: "Checking if the node to delete is found by examining temp.next.",
    42: "Next of temp is null pointer, that means the data wasn't found in the list. Printing a not found message.",
    43: "Next of temp is not a null pointer, that means the node to be deleted is found. So, executing else block.",
    44: "Declare a pointer of type Node, named toDelete, to hold the node that is to be deleted.",
    45: "Adjusting temp.next to skip over the node containing the target data, effectively removing it from the list.",
    46: "Deleting the target node from the memory.",
    47: "Printing a message indicating the node was deleted from the list.",
    53: "Main block of the LinkedList class.",
    54: "Creating an instance of LinkedList named list.",
    56: "Calling the deleteNode method to remove a node that contains the specified data.",
    58: "Returning 0 to indicate successful execution.",
  },
};

export default explanations;
  