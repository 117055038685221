const explanations = {
    Python: {
        3: "Creating a set named 'fruits'",
        4: "Printing the elements of the 'fruits' set.",
        6: "Adding an element to the 'fruits' set.",
        7: "Printing the updated 'fruits' set",
        9: "Removing an element from the 'fruits' set.",
        10: "Printing the updated 'fruits' set",
        12: "Checking if an element is present in the 'fruits' set and printing the result.",
        14: "Creating another set named 'berry_fruits'.",
        17: "Performing union of 'fruits' with 'berry_fruits' and storing the result in 'union_set'.",
        18: "Printing the 'union_set' which contains all elements from both 'fruits' and 'berry_fruits'.",
        21: "Performing intersection of 'fruits' with 'berry_fruits' and storing the result in 'intersection_set'.",
        22: "Printing the 'intersection_set' which contains elements common to both 'fruits' and 'berry_fruits'.",
        25: "Performing difference of 'fruits' from 'berry_fruits' and storing the result in 'difference_set'.",
        26: "Printing the 'difference_set' which contains elements in 'fruits' but not in 'berry_fruits'.",
    },
    Java: {
        6: "Creating a HashSet named 'fruits' and initializing it with unique elements from the given list.",
        7: "Printing the elements of the 'fruits' set to display its contents.",
        10: "Adding an element to the 'fruits' HashSet.",
        11: "Printing the elements of the 'fruits' HashSet.",
        14: "Removing item from the 'fruits' HashSet.",
        15: "Printing the updated 'fruits' HashSet.",
        18: "Checking if 'Orange' is present in the 'fruits' set and printing the result.",
        21: "Creating another HashSet named 'berryFruits' and initializing it with unique elements from the given list.",
        24: "Create a new HashSet named 'unionSet' that contains all elements from the 'fruits' collection.",
        25: "Performing union of 'fruits' with 'berryFruits' and storing the result in 'unionSet'.",
        26: "Printing the 'unionSet' which contains all elements from both 'fruits' and 'berryFruits'.",
        29: "Create a new HashSet named 'intersectionSet' that contains all elements from the 'fruits' collection.",
        30: "Performing intersection of 'fruits' with 'berryFruits' and storing the result in 'intersectionSet'.",
        31: "Printing the 'intersectionSet' which contains elements common to both 'fruits' and 'berryFruits'.",
        34: "Create a new HashSet named 'differenceSet' that contains all elements from the 'fruits' collection.",
        35: "Performing difference of 'fruits' from 'berryFruits' and storing the result in 'differenceSet'.",
        36: "Printing the 'differenceSet' which contains elements in 'fruits' but not in 'berryFruits'."
    }
};

export default explanations;
