const explanations = {
    Python: {
      1: 'Main block execution check',
      2: 'Declaring and Initializing string variable s1 with "hello"',
      3: 'Declaring and Initializing string variable s2 with "world"',
      4: 'Declaring and Initializing string variable s3 with "hello". s3 references the existing string s1, sharing the same memory address.',
    },
    Java: {
      2: 'Main method',
      3: 'Declaring and Initializing string variable s1 with "hello"',
      4: 'Declaring and Initializing string variable s2 with "world"',
      5: 'Declaring and Initializing string variable s3 with "hello". s3 references the existing string s1, sharing the same memory address.',
    },
  };
  
  export default explanations;
  