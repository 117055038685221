const codeSamples = {
    Python: [
      "class Graph:",
      "    def __init__(self):",
      "        self.graph = {}",
      "  ",
      "    def dfs(self, start):",
      "        visited = set()",
      "        stack = [start]",
      "        while stack:",
      "            vertex = stack.pop()",
      "            if vertex not in visited:",
      "                print(vertex, end=' ')",
      "                visited.add(vertex)",
      "                for neighbor in reversed(self.graph[vertex]):",
      "                    if neighbor not in visited:",
      "                        stack.append(neighbor)",
      "  ",
      "  ",
      "if __name__ == '__main__':",
      "    graph = Graph()",
      "  ",
      "    graph.dfs(10)"
    ],
    Java: [
      "import java.util.*;",
      "  ",
      "class Graph {",
      "    private Map<Integer, List<Integer>> graph;",
      "  ",
      "    public Graph() {",
      "        graph = new HashMap<>();",
      "    }",
      "  ",
      "    public void dfs(int start) {",
      "        Set<Integer> visited = new HashSet<>();",
      "        Stack<Integer> stack = new Stack<>();",
      "        stack.push(start);",
      "        while (!stack.isEmpty()) {",
      "            int vertex = stack.pop();",
      "            if (!visited.contains(vertex)) {",
      "                System.out.print(vertex + ' ');",
      "                visited.add(vertex);",
      "                for (int neighbor : graph.get(vertex)) {",
      "                    if (!visited.contains(neighbor)) {",
      "                        stack.push(neighbor);",
      "                    }",
      "                }",
      "            }",
      "        }",
      "    }",
      "  ",
      "  ",
      "public class Main {",
      "    public static void main(String[] args) {",
      "        Graph graph = new Graph();",
      "  ",
      "        graph.dfs(1);",
      "    }",
      "}"
    ],
    C: [
      "#include <stdio.h>",
      "#include <stdlib.h>",
      "  ",
      "#define MAX_VERTICES 6  // Maximum number of vertices",
      "  ",
      "void dfs(Graph* graph, int start) {",
      "    int visited[MAX_VERTICES] = {0};",
      "    int stack[MAX_VERTICES];",
      "    int top = -1;",
      "  ",
      "    stack[++top] = start;",
      "  ",
      "    while (top >= 0) {",
      "        int vertex = stack[top--];",
      "        if (!visited[vertex]) {",
      "            printf(\"%d \", vertex);",
      "            visited[vertex] = 1;",
      "        }",
      "        Node* temp = graph->adjLists[vertex];",
      "        while (temp) {",
      "            if (!visited[temp->vertex]) {",
      "                stack[++top] = temp->vertex;",
      "            }",
      "            temp = temp->next;",
      "        }",
      "    }",
      "}",
      "  ",
      "  ",
      "int main() {",
      "    Graph* graph = createGraph();",
      "  ",
      "    dfs(graph, 1);",
      "  ",
      "    return 0;",
      "}"
    ],
    "C++": [
      "#include <iostream>",
      "#include <unordered_map>",
      "#include <vector>",
      "#include <stack>",
      "#include <set>",
      "  ",
      "using namespace std;",
      "  ",
      "class Graph {",
      "private:",
      "    unordered_map<int, vector<int>> graph;",
      "  ",
      "public:",
      "    // Non-recursive DFS using a stack",
      "    void dfs(int start) {",
      "        set<int> visited;",
      "        stack<int> s;",
      "        s.push(start);",
      "        while (!s.empty()) {",
      "            int vertex = s.top();",
      "            s.pop();",
      "            if (visited.find(vertex) == visited.end()) {",
      "                cout << vertex << \" \";",
      "                visited.insert(vertex);",
      "                for (auto neighbor = graph[vertex].rbegin(); neighbor != graph[vertex].rend(); ++neighbor) {",
      "                    if (visited.find(*neighbor) == visited.end()) {",
      "                        s.push(*neighbor);",
      "                    }",
      "                }",
      "            }",
      "        }",
      "    }",
      "};",
      "  ",
      "  ",
      "int main() {",
      "    Graph graph;",
      "  ",
      "    graph.dfs(10);",
      "    return 0;",
      "}"
    ]
  };
  
  export default codeSamples;
  