const explanations = {
    Python: {
      1: "Defining the linear_search function",
      2: "Iterating over each element in the list",
      3: "Checking if the current element is the target",
      4: "Element found, returning the index",
      5: "Element not found, returning -1",
      7: "Main execution block",
      8: "Initializing the list",
      9: "Setting the target element",
      10: "Calling the linear_search function",
      11: "Checking if the element is found",
      12: "Element found, printing the result",
      13: "Element not found, Executing else block",
      14: "Printing the result"
    },
    Java: {
      1: "Defining the LinearSearch class",
      2: "Defining the linearSearch method",
      3: "Iterating over each element in the list",
      4: "Checking if the current element is the target",
      5: "Element found, returning the index",
      8: "Element not found, returning -1",
      11: "Main method of the LinearSearch class",
      12: "Initializing the LinearSearch object",
      13: "Initializing the list",
      14: "Setting the target element",
      15: "Calling the linearSearch method",
      16: "Checking if the element is found",
      17: "Element found, printing the result",
      19: "Element not found, Executing else block",
      20: "Printing the result"
    },
    C: {
      1: "Including the standard input-output library",
      3: "Defining the linearSearch function",
      4: "Iterating over each element in the list",
      5: "Checking if the current element is the target",
      6: "Element found, returning the index",
      9: "Element not found, returning -1",
      12: "Main function of the program",
      13: "Initializing the list",
      14: "Calculating the size of the list",
      15: "Setting the target element",
      16: "Calling the linearSearch function",
      17: "Checking if the element is found",
      18: "Element found, printing the result",
      20: "Element not found, Executing else block",
      21: "Printing the result",
      23: "Returning 0 to indicate successful execution"
    },
    'C++': {
      1: "Including the iostream library",
      2: "Using the standard namespace",
      4: "Defining the linearSearch function",
      5: "Iterating over each element in the list",
      6: "Checking if the current element is the target",
      7: "Element found, returning the index",
      10: "Element not found, returning -1",
      13: "Main function of the program",
      14: "Initializing the list",
      15: "Calculating the size of the list",
      16: "Setting the target element",
      17: "Calling the linearSearch function",
      18: "Checking if the element is found",
      19: "Element found, printing the result",
      21: "Element not found, Executing else block",
      22: "Printing the result",
      24: "Returning 0 to indicate successful execution"
    }
};
  
export default explanations;
  