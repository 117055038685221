export const runStringEqualCheckAlgorithmPython = async (
    string1,
    string2,
    highlightLine,
    setVariables,
    setHighlightedVariables,
    setHighlightedStringIndices,
    customSleep,
  ) => {
    await highlightLine(0);
    await customSleep();
  
    await highlightLine(1);
    await customSleep();
    if (string1.length !== string2.length) {
      await highlightLine(2);
      await customSleep();
      return false;
    }
  
    for (let i = 0; i < string1.length; i++) {
      await highlightLine(4);
      setVariables({ i: { variable_name: 'i', value: i } });
      setHighlightedVariables(['i']);
      await customSleep();
      setHighlightedVariables([]);

      await highlightLine(5);
      setHighlightedStringIndices([{ stringName: 'string1', index: i, iteratorName: 'i' }, { stringName: 'string2', index: i, iteratorName: 'i' }]);
      await customSleep();
      setHighlightedStringIndices([]);
      if (string1[i] !== string2[i]) {
        await highlightLine(6);
        await customSleep();
        return false;
      }
    }
  
    await highlightLine(8);
    await customSleep();
    return true;
  };
  
  export const runStringEqualCheckAlgorithmJava = async (
    string1,
    string2,
    highlightLine,
    setVariables,
    setHighlightedVariables,
    setHighlightedStringIndices,
    customSleep
  ) => {
    await highlightLine(1);
    await customSleep();
  
    await highlightLine(2);
    await customSleep();
    if (string1.length !== string2.length) {
      await highlightLine(3);
      await customSleep();
      return false;
    }
  
    for (let i = 0; i < string1.length; i++) {
      await highlightLine(6);
      setVariables({ i: { variable_name: 'i', value: i } });
      setHighlightedVariables(['i']);
      await customSleep();
      setHighlightedVariables([]);

      await highlightLine(7);
      setHighlightedStringIndices([{ stringName: 'string1', index: i, iteratorName: 'i' }, { stringName: 'string2', index: i, iteratorName: 'i' }]);
      await customSleep();
      setHighlightedStringIndices([]);
      if (string1[i] !== string2[i]) {
        await highlightLine(8);
        await customSleep();
        return false;
      }
    }
  
    await highlightLine(12);
    await customSleep();
    return true;
  };
  
  export const runStringEqualCheckAlgorithmC = async (
    string1,
    string2,
    highlightLine,
    setVariables,
    setHighlightedVariables,
    setHighlightedStringIndices,
    customSleep
  ) => {
    await highlightLine(3);
    await customSleep();
  
    await highlightLine(4);
    await customSleep();
    if (string1.length !== string2.length) {
      await highlightLine(5);
      await customSleep();
      return false;
    }
  
    for (let i = 0; i < string1.length; i++) {
      await highlightLine(8);
      setVariables({ i: { variable_name: 'i', value: i } });
      setHighlightedVariables(['i']);
      await customSleep();
      setHighlightedVariables([]);

      await highlightLine(9);
      setHighlightedStringIndices([{ stringName: 'string1', index: i, iteratorName: 'i' }, { stringName: 'string2', index: i, iteratorName: 'i' }]);
      await customSleep();
      setHighlightedStringIndices([]);
      if (string1[i] !== string2[i]) {
        await highlightLine(10);
        await customSleep();
        return false;
      }
    }
  
    await highlightLine(14);
    await customSleep();
    return true;
  };
  
  export const runStringEqualCheckAlgorithmCpp = async (
    string1,
    string2,
    highlightLine,
    setVariables,
    setHighlightedVariables,
    setHighlightedStringIndices,
    customSleep
  ) => {
    await highlightLine(4);
    await customSleep();
  
    await highlightLine(5);
    await customSleep();
    if (string1.length !== string2.length) {
      await highlightLine(6);
      await customSleep();
      return false;
    }
  
    for (let i = 0; i < string1.length; i++) {
      await highlightLine(9);
      setVariables({ i: { variable_name: 'i', value: i } });
      setHighlightedVariables(['i']);
      await customSleep();
      setHighlightedVariables([]);

      await highlightLine(10);
      setHighlightedStringIndices([{ stringName: 'string1', index: i, iteratorName: 'i' }, { stringName: 'string2', index: i, iteratorName: 'i' }]);
      await customSleep();
      setHighlightedStringIndices([]);
      if (string1[i] !== string2[i]) {
        await highlightLine(11);
        await customSleep();
        return false;
      }
    }
  
    await highlightLine(15);
    await customSleep();
    return true;
  };
  