import { useState, useEffect, useRef } from 'react';
import explanations from './explanations';
import codeSamplesTemplate from './codeSamples';

export const useShortTypes = (language, daysInWeek, maxShortValue, minShortValue) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [callingLine, setCallingLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const delayRef = useRef(delay);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [arrayVariables, setArrayVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedArrayVariables, setHighlightedArrayVariables] = useState([]);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const [isMuted, setIsMuted] = useState(true);
  const isMutedRef = useRef(isMuted);
  const synth = window.speechSynthesis;


  const updateVariables = (newDaysInWeek, newMaxShortValue, newMinShortValue) => {
    setVariables({});

     // Dynamically update the code samples
     const updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
      line
        .replace(/daysInWeek = \d+/, `daysInWeek = ${newDaysInWeek}`)
        .replace(/maxShortValue = \d+/, `maxShortValue = ${newMaxShortValue}`)
        .replace(/minShortValue\s*=\s*-?\d+\s*;/, `minShortValue = ${newMinShortValue}`)
    );
    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setCallingLine(null);
    setVariables({});
    setLogs([]);
    setHighlightedVariables([]);
    setArrayVariables({});
    setHighlightedArrayVariables([]);
  };

  const speak = (message) => {
    return new Promise((resolve) => {
      if (isMutedRef.current) {
        resolve();
        return;
      }
      const utterance = new SpeechSynthesisUtterance(message);
      utterance.onend = resolve;
      synth.speak(utterance);
    });
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setCallingLine((prevCallingLine) => {
      if (prevCallingLine === lineNumber) {
        return null;
      }
      return prevCallingLine;
    });
    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async () => {
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runShortTypesJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(2);
    setVariables((vars) => ({ ...vars, daysInWeek: { variable_name: 'daysInWeek', value: daysInWeek } }));
    setHighlightedVariables(['daysInWeek']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(3);
    setVariables((vars) => ({ ...vars, maxShortValue: { variable_name: 'maxShortValue', value: maxShortValue } }));
    setHighlightedVariables(['maxShortValue']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(4);
    setVariables((vars) => ({ ...vars, minShortValue: { variable_name: 'minShortValue', value: minShortValue } }));
    setHighlightedVariables(['minShortValue']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(6);
    setHighlightedVariables(['daysInWeek']);
    await logMessage(`Days in a week: ${daysInWeek}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(8);
    setHighlightedVariables(['maxShortValue']);
    await logMessage(`Maximum short value: ${maxShortValue}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(10);
    setHighlightedVariables(['minShortValue']);
    await logMessage(`Minimum short value: ${minShortValue}`);
    await customSleep();
    setHighlightedVariables([]);

    await focusEndLine(10);
    setIsRunning(false);
  };

  const runShortTypesC = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(2);
    setVariables((vars) => ({ ...vars, daysInWeek: { variable_name: 'daysInWeek', value: daysInWeek } }));
    setHighlightedVariables(['daysInWeek']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(3);
    setVariables((vars) => ({ ...vars, maxShortValue: { variable_name: 'maxShortValue', value: maxShortValue } }));
    setHighlightedVariables(['maxShortValue']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(4);
    setVariables((vars) => ({ ...vars, minShortValue: { variable_name: 'minShortValue', value: minShortValue } }));
    setHighlightedVariables(['minShortValue']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(6);
    setHighlightedVariables(['daysInWeek']);
    await logMessage(`Days in a week: ${daysInWeek}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(8);
    setHighlightedVariables(['maxShortValue']);
    await logMessage(`Maximum short value: ${maxShortValue}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(10);
    setHighlightedVariables(['minShortValue']);
    await logMessage(`Minimum short value: ${minShortValue}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(12);
    await customSleep();

    await focusEndLine(10);
    setIsRunning(false);
  };

  const runShortTypesCpp = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(3);
    setVariables((vars) => ({ ...vars, daysInWeek: { variable_name: 'daysInWeek', value: daysInWeek } }));
    setHighlightedVariables(['daysInWeek']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(4);
    setVariables((vars) => ({ ...vars, maxShortValue: { variable_name: 'maxShortValue', value: maxShortValue } }));
    setHighlightedVariables(['maxShortValue']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(5);
    setVariables((vars) => ({ ...vars, minShortValue: { variable_name: 'minShortValue', value: minShortValue } }));
    setHighlightedVariables(['minShortValue']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(7);
    setHighlightedVariables(['daysInWeek']);
    await logMessage(`Days in a week: ${daysInWeek}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(9);
    setHighlightedVariables(['maxShortValue']);
    await logMessage(`Maximum short value: ${maxShortValue}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(11);
    setHighlightedVariables(['minShortValue']);
    await logMessage(`Minimum short value: ${minShortValue}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(13);
    await customSleep();

    await focusEndLine(14);
    setIsRunning(false);
  };

  const runShortTypes = async () => {
    if (language === 'Java') {
      await runShortTypesJava();
    } else if (language === 'C') {
      await runShortTypesC();
    } else if (language === 'C++') {
      await runShortTypesCpp();
    }
  };

  return {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    highlightedArrayVariables,
    isMuted,
    codeSamples,
    updateVariables,
    setHighlightedLine,
    setFocusedEndLine,
    setCallingLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setArrayVariables,
    setHighlightedVariables,
    setHighlightedArrayVariables,
    setIsMuted,
    runShortTypes
  };
};
