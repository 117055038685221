const explanations = {
  Python: {
    1: "Defining the Stack class",
    2: "Initializing the constructor with the size parameter",
    3: "Setting the maximum size of the stack",
    4: "Initializing the stack with empty values",
    5: "Setting the top of stack (tos) to -1",
    7: "Defining the push method to add elements to the stack",
    8: "Checking if there is space in the stack",
    9: "Stack has space, so incrementing tos",
    10: "Adding the data to the stack",
    11: "Calling display to print the stack elements",
    12: "No space in the Stack, so executing else block",
    13: "Printing message that the stack is full",
    15: "Defining the pop method to remove elements from the stack",
    16: "Checking if the stack is empty",
    17: "Printing message that the stack is empty",
    18: "The stack is not empty, so executing else block",
    19: "Removing the top element from the stack",
    20: "Decrementing tos",
    21: "Calling display to print the stack elements",
    23: "Defining the display method to show the stack",
    24: "Iterating through the stack to display elements",
    25: "Printing elemnt from the stack",
    26: "Printing a separator after displaying the stack",
    28: "Main execution block",
    29: "Creating a stack with a size of 3",
    31: "Attempting to pop an element from an empty stack",
    33: "Pushing value onto the stack",
    34: "Pushing value onto the stack",
    35: "Pushing value onto the stack",
    36: "Attempting to push value onto a full stack",
    38: "Popping the top element from the stack"
  },
  Java: {
    1: "Defining the Stack class",
    2: "Declaring private integer variable for size",
    3: "Declaring private array of integer variable for stack",
    4: "Declaring private integer variable for tos",
    6: "Initializing the constructor with the size parameter",
    7: "Setting the maximum size of the stack",
    8: "Initializing the stack",
    9: "Setting the top of stack (tos) to -1",
    12: "Defining the push method to add elements to the stack",
    13: "Checking if there is space in the stack",
    14: "Stack has space, so incrementing tos",
    15: "Adding the data to the stack",
    16: "Calling display to print the stack elements",
    17: "No space in the Stack, so executing else block",
    18: "Printing message that the stack is full",
    22: "Defining the pop method to remove elements from the stack",
    23: "Checking if the stack is empty",
    24: "Printing message that the stack is empty",
    25: "The stack is not empty, so executing else block",
    26: "Removing the top element from the stack",
    27: "Decrementing tos",
    28: "Calling display to print the stack elements",
    32: "Defining the display method to show the stack",
    33: "Iterating through the stack to display elements",
    34: "Printing elemnt from the stack",
    36: "Printing a separator after displaying the stack",
    39: "Main method to execute the stack operations",
    40: "Creating a stack with a size of 3",
    42: "Attempting to pop an element from an empty stack",
    44: "Pushing value onto the stack",
    45: "Pushing value onto the stack",
    46: "Pushing value onto the stack",
    47: "Attempting to push value onto a full stack",
    49: "Popping the top element from the stack"
  },
  C: {
    1: "Include the standard input-output library for functions like printf.",
    2: "Include the standard library for memory allocation functions.",
    4: "Define the maximum size of the stack using a macro.",
    6: "Declare an integer array to represent the stack.",
    7: "Setting the top of stack (tos) to -1 to indicate the stack is empty.",
    10: "Define the push function to add an element onto the stack.",
    11: "Check if the stack is full by comparing tos with MAX-1.",
    12: "Print an overflow message because the stack is full.",
    13: "The stack is not full so executing the else block.",
    14: "Increment the tos and add the new value to the stack.",
    19: "Define the pop function to remove element from the stack.",
    20: "Check if the stack is empty by checking if tos is -1.",
    21: "Print an underflow message because the stack is empty.",
    22: "Stack is not empty, so executing the else block.",
    23: "Removing the top element and Decrementing the tos pointer.",
    28: "Define the display function to print all stack elements.",
    29: "Check if the stack is empty.",
    30: "Print a message that Stack is empty.",
    31: "Stack is not empty, so executing else block.",
    32: "Printing message.",
    33: "Iterating through the stack to display elements",
    34: "Printing elemnt from the stack",
    39: "Main function to demonstrate stack operations.",
    40: "Push value onto the stack.",
    41: "Push value onto the stack.",
    42: "Push value onto the stack.",
    43: "Push value onto the stack.",
    45: "Display all elements in the stack.",
    47: "Pop element from the stack.",
    48: "Pop element from the stack.",
    50: "Display the remaining elements in the stack.",
    52: "Return 0 to indicate successful execution"
  },
  "C++": {
    1: "Include the standard input-output library for functions like cout.",
    4: "Define the maximum size of the stack using a macro.",
    6: "Define a Stack class.",
    8: "Declare the stack array as private members.",
    9: "Declare the tos or 'top of the stack' variable as private members.",
    11: "Define the constructor to initialize the stack.",
    12: "Initialize the tos variable to -1 to indicate the stack is empty.",
    15: "Define the push method to add an element onto the stack.",
    16: "Check if the stack is full by comparing tos with MAX-1.",
    17: "Print an overflow message because the stack is full.",
    18: "The stack is not full so executing the else block.",
    19: "Increment the tos and add the new value to the stack.",
    23: "Define the pop method to remove the top element.",
    24: "Check if the stack is empty by checking if tos is -1.",
    25: "Print an underflow message because the stack is empty.",
    26: "Stack is not empty, so executing the else block.",
    27: "Removing the top element and Decrementing the tos pointer.",
    31: "Define the display method to print all stack elements.",
    32: "Check if the stack is empty.",
    33: "Print a message that Stack is empty.",
    34: "Stack is not empty, so executing else block.",
    35: "Printing message.",
    36: "Iterating through the stack to display elements",
    37: "Printing elemnt from the stack",
    43: "Main function to demonstrate stack operations.",
    44: "Create an instance of the Stack class.",
    46: "Push value onto the stack.",
    47: "Push value onto the stack.",
    48: "Push value onto the stack.",
    49: "Push value onto the stack.",
    51: "Display all elements in the stack.",
    53: "Pop element from the stack.",
    54: "Pop element from the stack.",
    56: "Display the remaining elements in the stack.",
    58: "Return 0 to indicate successful execution"
  }
};

export default explanations;
