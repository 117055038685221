const codeSamples = {
  Python: [
    "from collections import deque",
    "  ",
    "class BinaryTree:",
    "    def __init__(self):",
    "        self.root = None",
    "  ",
    "    def level_order_traversal(self):",
    "        current = self.root",
    "        if current is None:",
    "            return",
    "  ",
    "        queue = deque([current])",
    "        while queue:",
    "            current = queue.popleft()",
    "            print(current.data, end=', ')",
    "            if current.left is not None:",
    "                queue.append(current.left)",
    "            if current.right is not None:",
    "                queue.append(current.right)",
    "  ",
    "  ",
    "if __name__ == '__main__':",
    "    binaryTree = BinaryTree()",
    "  ",
    "    binaryTree.level_order_traversal()",
  ],
  Java: [
    "import java.util.LinkedList;",
    "import java.util.Queue;",
    "  ",
    "class BinaryTree {",
    "    Node root;",
    "  ",
    "    BinaryTree() {",
    "        root = null;",
    "    }",
    "  ",
    "    void levelOrderTraversal() {",
    "        Node current = root;",
    "        if (current == null) {",
    "            return;",
    "        }",
    "  ",
    "        Queue<Node> queue = new LinkedList<>();",
    "        queue.add(current);",
    "        while (!queue.isEmpty()) {",
    "            current = queue.poll();",
    "            System.out.print(current.data + \", \");",
    "            if (current.left != null) {",
    "                queue.add(current.left);",
    "            }",
    "            if (current.right != null) {",
    "                queue.add(current.right);",
    "            }",
    "        }",
    "    }",
    "}",
    "  ",
    "public class Main {",
    "    public static void main(String[] args) {",
    "        BinaryTree binaryTree = new BinaryTree();",
    "  ",
    "        binaryTree.levelOrderTraversal();",
    "    }",
    "}",
  ],
  C: [
    "#include <stdio.h>",
    "#include <stdlib.h>",
    "  ",
    "struct Node {",
    "    int data;",
    "    struct Node* left;",
    "    struct Node* right;",
    "};",
    "  ",
    "void levelOrderTraversal(struct Node* root) {",
    "    if (root == NULL) {",
    "        return;",
    "    }",
    "  ",
    "    struct Node* queue[100];",
    "    int front = 0, rear = 0;",
    "  ",
    "    queue[rear++] = root;",
    "  ",
    "    while (front < rear) {",
    "        struct Node* current = queue[front++];",
    "  ",
    "        printf(\"%d, \", current->data);",
    "  ",
    "        if (current->left != NULL) {",
    "            queue[rear++] = current->left;",
    "        }",
    "  ",
    "        if (current->right != NULL) {",
    "            queue[rear++] = current->right;",
    "        }",
    "    }",
    "}",
    "  ",
    "int main() {",
    "    struct Node* root = NULL;",
    "    levelOrderTraversal(root);",
    "    return 0;",
    "}"
  ],
  'C++': [
    "#include <iostream>",
    "#include <queue>",
    "using namespace std;",
    "  ",
    "class BinaryTree {",
    "public:",
    "    Node* root;",
    "  ",
    "    BinaryTree() {",
    "        root = nullptr;",
    "    }",
    "  ",
    "    void levelOrderTraversal() {",
    "        Node* current = root;",
    "        if (current == nullptr) {",
    "            return;",
    "        }",
    "  ",
    "        queue<Node*> queue;",
    "        queue.push(current);",
    "        while (!queue.empty()) {",
    "            current = queue.front();",
    "            queue.pop();",
    "            cout << current->data << \", \";",
    "            if (current->left != nullptr) {",
    "                queue.push(current->left);",
    "            }",
    "            if (current->right != nullptr) {",
    "                queue.push(current->right);",
    "            }",
    "        }",
    "    }",
    "};",
    "  ",
    "int main() {",
    "    BinaryTree binaryTree;",
    "  ",
    "    binaryTree.levelOrderTraversal();",
    "    return 0;",
    "}"
  ],
};
  
  export default codeSamples;
  