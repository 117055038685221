import React, { useState } from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvas';
import AlgorithmDescription from '../AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';
import UpdateVariablesModal from './UpdateVariablesModal';

import { useBinarySearch } from './useBinarySearch';

const BinarySearch = ({ language, subTopicId }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [sortedList, setSortedList] = useState([2, 3, 4, 10, 40]);
  const [sortedListInput, setSortedListInput] = useState("2, 3, 4, 10, 40");
  const [targetElement, setTargetElement] = useState(10);

  const handleSortedList = (e) => {
    const input = e.target.value;
    setSortedListInput(input);

    // Parse the input string into an array of numbers
    const newArray = input.split(",").map((item) => parseFloat(item) || 0); // Default to 0 for invalid numbers
    setSortedList(newArray);
  };

  const {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    mid,
    highlightedVariables,
    highlightedArrayVariables,
    highlightedIndices,
    isMuted,
    codeSamples,
    setDelay,
    setIsPaused,
    setIsMuted,
    updateVariables,
    runBinarySearch
  } = useBinarySearch(language, sortedList, targetElement);


  const handleUpdateVariables = () => {
    updateVariables(sortedList, targetElement);
    setModalVisible(false);
  };

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6">
          <CodeDisplay
            codeLines={codeSamples || []}
            highlightedLine={highlightedLine}
            focusedEndLine={focusedEndLine}
            topic={"Binary Search Algorithm"}
            callingLine={callingLine}
            subTopicId={subTopicId}
            language={language}
            onOpenModal={() => setModalVisible(true)}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <h3 className="section-title">Canvas</h3>
            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runBinarySearch}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
            <VisualizationCanvas
              arrayVariables={arrayVariables}
              variables={variables}
              highlightedVariables={highlightedVariables}
              highlightedArrayVariables={highlightedArrayVariables}
              highlightedIndices={highlightedIndices}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
          <AlgorithmDescription />
      </div>
      <UpdateVariablesModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        sortedListInput={sortedListInput}
        handleSortedList={handleSortedList}
        targetElement={targetElement}
        setTargetElement={setTargetElement}
        handleUpdateVariables={handleUpdateVariables}
      />
    </div>
  );
};

export default BinarySearch;
