import React, { useState } from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvas';
import AlgorithmDescription from '../AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';
import UpdateVariablesModal from './UpdateVariablesModal';

import { useLinearSearch } from './useLinearSearch';

const LinearSearch = ({ language, subTopicId }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [list, setList] = useState([2, 3, 4, 10, 40]);
  const [listInput, setListInput] = useState("2, 3, 4, 10, 40");
  const [targetElement, setTargetElement] = useState(10);

  const handleList = (e) => {
    const input = e.target.value;
    setListInput(input);

    // Parse the input string into an array of numbers
    const newArray = input.split(",").map((item) => parseFloat(item) || 0); // Default to 0 for invalid numbers
    setList(newArray);
  };

  const {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    highlightedArrayVariables,
    highlightedIndices,
    isMuted,
    codeSamples,
    setDelay,
    setIsPaused,
    setIsMuted,
    updateVariables,
    runLinearSearch
  } = useLinearSearch(language, list, targetElement);

  const handleUpdateVariables = () => {
    updateVariables(list, targetElement);
    setModalVisible(false);
  };

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6">
          <CodeDisplay
            codeLines={codeSamples || []}
            highlightedLine={highlightedLine}
            focusedEndLine={focusedEndLine}
            topic={"Linear Search Algorithm"}
            callingLine={callingLine}
            subTopicId={subTopicId}
            language={language}
            onOpenModal={() => setModalVisible(true)}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <h3 className="section-title">Canvas</h3>
            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runLinearSearch}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
            <VisualizationCanvas
              arrayVariables={arrayVariables}
              variables={variables}
              highlightedVariables={highlightedVariables}
              highlightedArrayVariables={highlightedArrayVariables}
              highlightedIndices={highlightedIndices}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
          <AlgorithmDescription />
      </div>
      <UpdateVariablesModal
        isVisible={modalVisible}
        listInput={listInput}
        handleList={handleList}
        targetElement={targetElement}
        setTargetElement={setTargetElement}
        onSave={handleUpdateVariables}
        onClose={() => setModalVisible(false)}
      />
    </div>
  );
};

export default LinearSearch;
