const explanations = {
  Python: {
    1: "Define the Graph class to represent a graph structure.",
    2: "Executing the constructor method to initialize the graph object.",
    3: "Initialize an empty dictionary to represent the adjacency list of the graph.",
    5: "Executing the dfs (Depth-First Search) method starting from a specific vertex.",
    6: "Initialize a set to keep track of visited vertices.",
    7: "Initialize the stack with the starting vertex.",
    8: "Start a loop that continues as long as there are vertices in the stack.",
    9: "Pop the last vertex from the stack.",
    10: "Checking if the vertex has not been visited.",
    11: "Vertex has not visited yet, print the vertex as part of the DFS traversal.",
    12: "Mark the vertex as visited by adding it to the visited set.",
    13: "Iterate through the neighbors of the vertex in reverse order to maintain DFS behavior.",
    14: "Checking if the neighbor has not been visited.",
    15: "Neighbor has not visited yet, push it onto the stack.",
    18: "Main block to test the Graph class functionality.",
    19: "Creating an instance of the Graph class.",
    21: "Calling dfs method with a starting vertex to perfrom Depth-First Search.",
  },
  Java: {
    1: "Importing the necessary Java utilities for lists, sets, stacks, and queues.",
    3: "Defining the Graph class to represent the graph structure.",
    4: "A HashMap is used to store the adjacency list for each vertex.",
    6: "Constructor method to initialize the graph object.",
    7: "Initialize the graph as an empty HashMap.",
    10: "Executing the dfs (Depth-First Search) method starting from a specific vertex.",
    11: "Initialize a set to keep track of visited vertices.",
    12: "Initialize the stack to store vertices in DFS order for processing in a LIFO manner.",
    13: "Pusing the starting vertex to the stack.",
    14: "Start a loop that continues as long as there are vertices in the stack.",
    15: "Pop the last vertex from the stack.",
    16: "Checking if the vertex has not been visited.",
    17: "Vertex has not visited yet, print the vertex as part of the DFS traversal.",
    18: "Mark the vertex as visited by adding it to the visited set.",
    19: "Iterate through the neighbors of the vertex.",
    20: "Checking if the neighbor has not been visited.",
    21: "Neighbor has not visited yet, push it onto the stack.",
    29: "Define the Main class to test the DFS functionality.",
    30: "Main method to execute the program.",
    31: "Creating an instance of the Graph class.",
    33: "Calling dfs method with a starting vertex to perfrom Depth-First Search.",
  },
  C: {
    1: "Including the standard input-output library for printing to the console.",
    2: "Including the standard library for memory allocation and other utilities.",
    4: "Defining a macro MAX_VERTICES with a value of 6, representing the maximum number of vertices in the graph.",
    6: "Executing the dfs (Depth-First Search) function that takes a graph and a starting vertex as parameters.",
    7: "Declaring an array to keep track of visited vertices, initialized to 0 (unvisited).",
    8: "Declaring an array to represent the stack, which will store vertices during DFS traversal.",
    9: "Initializing the top variable to -1 to represent an empty stack.",
    11: "Pushing the starting vertex onto the stack by incrementing the top pointer.",
    13: "Starting a while loop that runs until the stack is empty (i.e., top is not less than 0).",
    14: "Popping the top vertex from the stack by decrementing the top pointer.",
    15: "Checking if the vertex has not been visited yet.",
    16: "Vertex not visited, printing the vertex to the console.",
    17: "Marking the vertex as visited by setting visited[vertex] to 1.",
    19: "Setting up a temp pointer to iterate over the adjacency list of the current vertex.",
    20: "Starting a while loop to traverse the adjacency list of the current vertex.",
    21: "Checking if the neighbor (adjacent vertex) has not been visited yet.",
    22: "Neighbor not visited, pushing the neighbor onto the stack by incrementing the top pointer.",
    24: "Moving to the next node in the adjacency list.",
    30: "Starting the main function.",
    31: "Creating a new graph using the createGraph function.",
    33: "Calling dfs function with a starting vertex to perfrom Depth-First Search.",
    35: "Returning 0 to indicate successful execution."
  },
  "C++": {
    1: "Including the input-output stream library for printing to the console.",
    2: "Including the unordered_map library to store the adjacency list of the graph.",
    3: "Including the vector library to store neighbors of each vertex.",
    4: "Including the stack library for implementing Depth-First Search (DFS).",
    5: "Including the set library to track visited nodes.",
    7: "Using the standard namespace to avoid using 'std::' prefix before standard library functions.",
    9: "Declaring the Graph class to represent the graph structure.",
    10: "Defining the private access specifier for class members.",
    11: "Declaring an unordered_map to store the adjacency list, where each vertex is a key and its neighbors are stored in a vector.",
    13: "Defining the public access specifier for public class members.",
    15: "Executing the dfs (Depth-First Search) method starting from a specific vertex.",
    16: "Initializing a set to track visited vertices.",
    17: "Initializing a stack to manage the DFS traversal.",
    18: "Pushing the starting vertex onto the stack.",
    19: "Starting a while loop that runs until the stack is empty.",
    20: "Fetching the vertex at the top of the stack.",
    21: "Removing the top element from the stack.",
    22: "Checking if the vertex has not been visited yet.",
    23: "Vertex has not visited yet, print the vertex as part of the DFS traversal.",
    24: "Marking the vertex as visited by inserting it into the set.",
    25: "Iterating over all neighbors of the current vertex in reverse order to maintain the DFS order.",
    26: "Checking if the neighbor has not been visited yet.",
    27: "Pushing the neighbor onto the stack for further exploration.",
    36: "Main method to test the Graph class functionality.",
    37: "Creating an instance of the Graph class.",
    39: "Calling dfs method with a starting vertex to perfrom Depth-First Search.",
    40: "Returning 0 to indicate successful execution."
  }  
};
  
  export default explanations;
  