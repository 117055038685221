import React from 'react';

const UpdateVariablesModal = ({ isVisible, onClose, arrayInput, handleChange }) => {
  if (!isVisible) return null;

  return (
    <div className="modal show d-block" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content modal-glass-avatar">
          <div className="modal-header">
            <h5 className="modal-title-update-variable">Update Variables</h5>
          </div>
          <div className="modal-body">
            <label>
              Variable arr:
              <input
                type="text"
                className="form-control custom-input"
                value={arrayInput}
                onChange={handleChange}
              />
            </label>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-dark"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={onClose}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateVariablesModal;
