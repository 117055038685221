const codeSamples = {
    Python: [
      "def is_palindrome(string):",
      "    string = string.lower()",
      "    left = 0",
      "    right = len(string) - 1",
      " ",
      "    while left < right:",
      "        if string[left] != string[right]:",
      "            return False",
      "        left += 1",
      "        right -= 1",
      " ",
      "    return True",
      " ",
      'if __name__ == "__main__":',
      '    string = "Racecar"',
      "    if is_palindrome(string):",
      '        print(f\'"{string}" is a palindrome.\')',
      "    else:",
      '        print(f\'"{string}" is not a palindrome.\')'
    ],
    Java: [
      "public class StringPalindrome {",
      "    public static boolean isPalindrome(String str) {",
      "        str = str.toLowerCase();",
      "        int left = 0, right = str.length() - 1;",
      " ",
      "        while (left < right) {",
      "            if (str.charAt(left) != str.charAt(right)) {",
      "                return false;",
      "            }",
      "            left++;",
      "            right--;",
      "        }",
      "        return true;",
      "    }",
      " ",
      "    public static void main(String[] args) {",
      '        String str = "Racecar";',
      "        if (isPalindrome(str)) {",
      '            System.out.println(str + " is a palindrome.");',
      "        } else {",
      '            System.out.println(str + " is not a palindrome.");',
      "        }",
      "    }",
      "}"
    ],
    C: [
      "#include <stdio.h>",
      "#include <string.h>",
      "#include <ctype.h>",
      " ",
      "int isPalindrome(char str[]) {",
      "    int left = 0;",
      "    int right = strlen(str) - 1;",
      " ",
      "    while (left < right) {",
      "        if (tolower(str[left]) != tolower(str[right])) {",
      "            return 0;",
      "        }",
      "        left++;",
      "        right--;",
      "    }",
      "    return 1;",
      "}",
      " ",
      "int main() {",
      '    char str[] = "Racecar";',
      "    if (isPalindrome(str)) {",
      '        printf("%s is a palindrome.\\n", str);',
      "    } else {",
      '        printf("%s is not a palindrome.\\n", str);',
      "    }",
      "    return 0;",
      "}"
    ],
    "C++": [
      "#include <iostream>",
      "#include <string>",
      "#include <cctype>",
      "using namespace std;",
      " ",
      "bool isPalindrome(char str[]) {",
      "    int left = 0;",
      "    int right = strlen(str) - 1;",
      " ",
      "    while (left < right) {",
      "        if (tolower(str[left]) != tolower(str[right])) {",
      "            return false;",
      "        }",
      "        left++;",
      "        right--;",
      "    }",
      "    return true;",
      "}",
      " ",
      "int main() {",
      '    char str[] = "Racecar";',
      "    if (isPalindrome(str)) {",
      '        cout << str << " is a palindrome." << endl;',
      "    } else {",
      '        cout << str << " is not a palindrome." << endl;',
      "    }",
      "    return 0;",
      "}"
    ]
  };
  
  export default codeSamples;
  