const codeSamples = {
  Python: [
    "if __name__ == '__main__':",
    "    numbers = [3, 5, 6, 11, 15]",
    "  ",
    "    for i in numbers:",
    "        if i % 3 == 0 and i % 5 == 0:",
    "            print('FizzBuzz')",
    "        elif i % 3 == 0:",
    "            print('Fizz')",
    "        elif i % 5 == 0:",
    "            print('Buzz')",
    "        else:",
    "            print(i)"
  ],
  Java: [
    "public class ForLoop {",
    "    public static void main(String[] args) {",
    "        int numbers[] = {3, 5, 6, 11, 15};",
    "  ",
    "        for (int i : numbers) {",
    "            if (i % 3 == 0 && i % 5 == 0) {",
    "                System.out.println('FizzBuzz');",
    "            } else if (i % 3 == 0) {",
    "                System.out.println('Fizz');",
    "            } else if (i % 5 == 0) {",
    "                System.out.println('Buzz');",
    "            } else {",
    "                System.out.println(i);",
    "            }",
    "        }",
    "    }",
    "}"
  ],
  C: [
    "#include <stdio.h>",
    "int main() {",
    "    int numbers[] = {3, 5, 6, 11, 15};",
    "  ",
    "    for (int i = 0; i < 5; i++) {",
    "        if (numbers[i] % 3 == 0 && numbers[i] % 5 == 0) {",
    "            printf('FizzBuzz\\n');",
    "        } else if (numbers[i] % 3 == 0) {",
    "            printf('Fizz\\n');",
    "        } else if (numbers[i] % 5 == 0) {",
    "            printf('Buzz\\n');",
    "        } else {",
    "            printf('%d\\n', numbers[i]);",
    "        }",
    "    }",
    "    return 0;",
    "}"
  ],
  "C++": [
    "#include <iostream>",
    "using namespace std;",
    "int main() {",
    "    int numbers[] = {3, 5, 6, 11, 15};",
    "  ",
    "    for (int i = 0; i < 5; i++) {",
    "        if (numbers[i] % 3 == 0 && numbers[i] % 5 == 0) {",
    "            cout << 'FizzBuzz' << endl;",
    "        } else if (numbers[i] % 3 == 0) {",
    "            cout << 'Fizz' << endl;",
    "        } else if (numbers[i] % 5 == 0) {",
    "            cout << 'Buzz' << endl;",
    "        } else {",
    "            cout << numbers[i] << endl;",
    "        }",
    "    }",
    "    return 0;",
    "}"
  ]
};

export default codeSamples;
