const explanations = {
  Python: {
    1: "Defining the Node class that represents a single element in the linked list.",
    2: "Executing the constructor method __init__ to initialize the node with the provided data.",
    3: "Assigning the data to the node's data attribute.",
    4: "Setting the next attribute to None, indicating that the node has no next element yet.",
    6: "Defining the LinkedList class that will manage the linked list.",
    7: "Executing the constructor method __init__ of the class LinkedList.",
    8: "Initializing the linked list with an empty head (i.e., the list is initially empty).",
    10: "Executing the insert method to insert a new node into the linked list.",
    11: "Creating a new node with the given data.",
    12: "Checking if the list is empty (i.e., head is None).",
    13: "The list is empty, the new node becomes the head of the list.",
    14: "Head is not empty, executing else block.",
    15: "Assign the head node to temp to begin traversing the linked list from the first node without modifying the head.",
    16: "Traversing through the list to find the last node.",
    17: "Continuing to move through the list until reaching the last node.",
    18: "Assigning the new node to the next of the last node, linking it to the list.",
    20: "Main block to test the LinkedList class functionality.",
    21: "Creating an instance of LinkedList.",
    23: "Inserting element into the LinkedList.",
    24: "Inserting element into the LinkedList.",
    25: "Inserting element into the LinkedList.",
  },
  Java: {
    1: "Defining the Node class that represents a single element in the linked list.",
    2: "Declaring an integer data variable to hold the value for the node.",
    3: "Declaring a next variable that will point to the next node in the linked list.",
    5: "Executing the Node constructor to initialize the node with data.",
    6: "Setting the node's data to the passed value.",
    7: "Setting the next pointer to null since it's a new node without a next node.",
    11: "Defining the LinkedList class that will manage the linked list.",
    12: "Declaring a head variable to keep track of the first node in the linked list.",
    14: "Executing the insert method to insert a new node into the linked list.",
    15: "Calling Node constructor for creating a new node.",
    16: "Checking if the head is empty.",
    17: "Head is empty, so the new node becomes the head of the list.",
    18: "Head is not empty, executing else block.",
    19: "Assign the head node to temp to begin traversing the linked list from the first node without modifying the head.",
    20: "Traversing through the list to find the last node.",
    21: "Continuing to move through the list until reaching the last node.",
    23: "Assigning the new node to the next of the last node, linking it to the list.",
    27: "Main method of LinkedList class.",
    28: "Creating an instance of LinkedList.",
    31: "Inserting element into the LinkedList.",
    32: "Inserting element into the LinkedList.",
    33: "Inserting element into the LinkedList.",
  },
  C: {
    1: "Including the standard input-output library.",
    2: "Including the standard library for memory allocation.",
    4: "Defining the Node structure to represent an element in the linked list.",
    5: "Declaring an integer data variable to hold the value of the node.",
    6: "Declaring a pointer to the next node in the list.",
    9: "Defining a global head pointer to track the start of the linked list.",
    11: "Executing the function to insert a new node into the linked list.",
    12: "Allocating memory for a new node.",
    13: "Assigning the data to the new node.",
    14: "Setting the next pointer to NULL as it's the last node.",
    16: "Checking if the list is empty (head is NULL).",
    17: "The list is empty, the new node becomes the head of the list.",
    18: "Head is not empty, executing else block.",
    19: "Assign the head node to temp to begin traversing the linked list from the first node without modifying the head.",
    20: "Traversing through the list to find the last node.",
    21: "Continuing to move through the list until reaching the last node.",
    23: "Assigning the new node to the next of the last node, linking it to the list.",
    27: "Main function of the linked list program.",
    28: "Inserting element into the LinkedList.",
    29: "Inserting element into the LinkedList.",
    30: "Inserting element into the LinkedList.",
    32: "Returning 0 to indicate successful execution.",
  },
  "C++": {
    1: "Including the iostream library for input-output operations.",
    2: "Using the standard namespace",
    4: "Defining the Node class to represent an element in the linked list.",
    6: "Declaring public the data variable to hold the node's value.",
    7: "Declaring a public pointer to the next node in the linked list.",
    9: "Executing the Node constructor to initialize the node with data.",
    10: "Setting the node's data to the passed value.",
    11: "Setting the next pointer to nullptr since it's a new node.",
    15: "Defining the LinkedList class to manage the linked list.",
    17: "Declaring a head pointer to track the start of the linked list.",
    19: "Executing the constructor method of the class LinkedList.",
    20: "Initializing the head pointer to nullptr (i.e., the list is initially empty).",
    23: "Executing the insert method to insert a new node into the linked list.",
    24: "Creating a new node with the given data.",
    25: "Checking if the list is empty (head is nullptr).",
    26: "The list is empty, the new node becomes the head of the list.",
    27: "Head is not empty, executing else block.",
    28: "Assign the head node to temp to begin traversing the linked list from the first node without modifying the head.",
    29: "Traversing through the list to find the last node.",
    30: "Continuing to move through the list until reaching the last node.",
    32: "Assigning the new node to the next of the last node, linking it to the list.",
    37: "Main function of the LinkedList class.",
    38: "Creating an instance of LinkedList.",
    40: "Inserting element into the LinkedList.",
    41: "Inserting element into the LinkedList.",
    42: "Inserting element into the LinkedList.",
    44: "Returning 0 to indicate successful execution.",
  },
};

export default explanations;
  