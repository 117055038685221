const codeSamples = {
    Python: [
      "class Node:",
      "    def __init__(self, data):",
      "        self.data = data",
      "        self.next = None",
      "  ",
      "class LinkedList:",
      "    def __init__(self):",
      "        self.head = None",
      "  ",
      "    def insert(self, data):",
      "        new_node = Node(data)",
      "        if self.head is None:",
      "            self.head = new_node",
      "        else:",
      "            temp = self.head",
      "            while temp.next:",
      "                temp = temp.next",
      "            temp.next = new_node",
      "  ",
      "if __name__ == '__main__':",
      "    list = LinkedList()",
      "  ",
      "    list.insert(10)",
      "    list.insert(20)",
      "    list.insert(30)",
    ],
    Java: [
      "class Node {",
      "    int data;",
      "    Node next;",
      "  ",
      "    Node(int data) {",
      "        this.data = data;",
      "        this.next = null;",
      "    }",
      "}",
      "  ",
      "class LinkedList {",
      "    Node head;",
      "  ",
      "    public void insert(int data) {",
      "        Node newNode = new Node(data);",
      "        if (head == null) {",
      "            head = newNode;",
      "        } else {",
      "            Node temp = head;",
      "            while (temp.next != null) {",
      "                temp = temp.next;",
      "            }",
      "            temp.next = newNode;",
      "        }",
      "    }",
      "  ",
      "    public static void main(String[] args) {",
      "        LinkedList list = new LinkedList();",
      "  ",
      "        // Insert elements into the linked list",
      "        list.insert(10);",
      "        list.insert(20);",
      "        list.insert(30);",
      "    }",
      "}"
    ],
    C: [
      "#include <stdio.h>",
      "#include <stdlib.h>",
      "  ",
      "struct Node {",
      "    int data;",
      "    struct Node* next;",
      "};",
      "  ",
      "struct Node* head = NULL;",
      "  ",
      "void insert(int data) {",
      "    struct Node* newNode = (struct Node*)malloc(sizeof(struct Node));",
      "    newNode->data = data;",
      "    newNode->next = NULL;",
      "  ",
      "    if (head == NULL) {",
      "        head = newNode;",
      "    } else {",
      "        struct Node* temp = head;",
      "        while (temp->next != NULL) {",
      "            temp = temp->next;",
      "        }",
      "        temp->next = newNode;",
      "    }",
      "}",
      "  ",
      "int main() {",
      "    insert(10);",
      "    insert(20);",
      "    insert(30);",
      "  ",
      "    return 0;",
      "}"
    ],
    "C++": [
      "#include <iostream>",
      "using namespace std;",
      "  ",
      "class Node {",
      "public:",
      "    int data;",
      "    Node* next;",
      "  ",
      "    Node(int data) {",
      "        this->data = data;",
      "        this->next = nullptr;",
      "    }",
      "};",
      "  ",
      "class LinkedList {",
      "public:",
      "    Node* head;",
      "  ",
      "    LinkedList() {",
      "        head = nullptr;",
      "    }",
      "  ",
      "    void insert(int data) {",
      "        Node* newNode = new Node(data);",
      "        if (head == nullptr) {",
      "            head = newNode;",
      "        } else {",
      "            Node* temp = head;",
      "            while (temp->next != nullptr) {",
      "                temp = temp->next;",
      "            }",
      "            temp->next = newNode;",
      "        }",
      "    }",
      "};",
      "  ",
      "int main() {",
      "    LinkedList list;",
      "  ",
      "    list.insert(10);",
      "    list.insert(20);",
      "    list.insert(30);",
      "  ",
      "    return 0;",
      "}"
    ]
  };
  
  export default codeSamples;
  