const explanations = {
    Python: {
        1: "Starting the Python script with the main block",
        2: "Comment indicating initial values",
        3: "Declaring and initializing variable 'a'",
        4: "Declaring and initializing variable 'b'",
        6: "Comment indicating the printing of initial values",
        7: "Printing the initial values of 'a' and 'b'",
        9: "Comment indicating the start of swapping using a temporary variable",
        10: "Storing the value of 'a' in temporary variable 'temp'",
        11: "Assigning the value of 'b' to 'a'",
        12: "Assigning the value of 'temp' to 'b', completing the swap",
        14: "Comment indicating the printing of values after swapping",
        15: "Printing the values of 'a' and 'b' after swapping"
    },
    Java: {
        1: "Starting the Main class definition",
        2: "Starting the main method, the entry point of the Java program",
        3: "Comment indicating initial values",
        4: "Declaring and initializing variable 'a'",
        5: "Declaring and initializing variable 'b'",
        7: "Comment indicating the printing of initial values",
        8: "Printing the initial values of 'a' and 'b'",
        10: "Comment indicating the start of swapping using a temporary variable",
        11: "Storing the value of 'a' in temporary variable 'temp'",
        12: "Assigning the value of 'b' to 'a'",
        13: "Assigning the value of 'temp' to 'b', completing the swap",
        15: "Comment indicating the printing of values after swapping",
        16: "Printing the values of 'a' and 'b' after swapping",
        18: "Ending the main method",
        19: "Ending the Main class definition"
    },
    C: {
        1: "Including the standard input-output header file",
        3: "Starting the main function, the entry point of the C program",
        4: "Comment indicating initial values",
        5: "Declaring and initializing variable 'a'",
        6: "Declaring and initializing variable 'b'",
        8: "Comment indicating the printing of initial values",
        9: "Printing the initial values of 'a' and 'b'",
        11: "Comment indicating the start of swapping using a temporary variable",
        12: "Storing the value of 'a' in temporary variable 'temp'",
        13: "Assigning the value of 'b' to 'a'",
        14: "Assigning the value of 'temp' to 'b', completing the swap",
        16: "Comment indicating the printing of values after swapping",
        17: "Printing the values of 'a' and 'b' after swapping",
        18: "Returning 0 to indicate successful execution",
        19: "Ending the main function"
    },
    'C++': {
        1: "Including the input-output stream header file",
        2: "Using the standard namespace",
        4: "Starting the main function, the entry point of the C++ program",
        5: "Comment indicating initial values",
        6: "Declaring and initializing variable 'a'",
        7: "Declaring and initializing variable 'b'",
        9: "Comment indicating the printing of initial values",
        10: "Printing the initial values of 'a' and 'b'",
        12: "Comment indicating the start of swapping using a temporary variable",
        13: "Storing the value of 'a' in temporary variable 'temp'",
        14: "Assigning the value of 'b' to 'a'",
        15: "Assigning the value of 'temp' to 'b', completing the swap",
        17: "Comment indicating the printing of values after swapping",
        18: "Printing the values of 'a' and 'b' after swapping",
        19: "Returning 0 to indicate successful execution",
        20: "Ending the main function"
    }
};

export default explanations;
