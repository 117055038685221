import React from 'react';

const UpdateVariablesModal = ({
  isVisible,
  onClose,
  letter,
  setLetter,
  digit,
  setDigit,
  specialChar,
  setSpecialChar,
  handleUpdateVariables,
}) => {
  if (!isVisible) return null;

  return (
    <div className="modal show d-block" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content modal-glass-avatar">
          <div className="modal-header">
            <h5 className="modal-title-update-variable">Update Variables</h5>
          </div>
          <div className="modal-body">
            <div className='row'>
                <div className='col-md-6'>
                    <label>
                        Variable letter:
                        <input
                            type="text"
                            className="form-control custom-input"
                            value={letter}
                            maxLength={1}
                            onChange={(e) => setLetter(e.target.value)}
                        />
                    </label>
                </div>
                <div className='col-md-6'>
                    <label>
                        Variable digit:
                        <input
                            type="number"
                            className="form-control custom-input"
                            value={digit}
                            min={0}
                            max={9}
                            onChange={(e) => setDigit(e.target.value)}
                        />
                    </label>
                </div>
              </div>
              <div className='row' style={{paddingTop: '20px'}}>
                <div className='col-md-6'>
                    <label>
                        Variable specialChar:
                        <input
                            type="text"
                            className="form-control custom-input"
                            value={specialChar}
                            maxLength={1}
                            onChange={(e) => setSpecialChar(e.target.value)}
                        />
                    </label>
                </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-dark"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleUpdateVariables}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateVariablesModal;
