const explanations = {
    Python: {
        1: "Starting the Python script with the main block",
        2: "Declaring and initializing variable 'x'",
        3: "Declaring and initializing variable 'y'",
        5: "Performing addition and storing the result",
        6: "Printing the result of the addition",
        8: "Performing subtraction and storing the result",
        9: "Printing the result of the subtraction",
        11: "Performing multiplication and storing the result",
        12: "Printing the result of the multiplication",
        14: "Performing division and handling division by zero",
        15: "Printing the result of the division",
        17: "Performing modulus operation and storing the result",
        18: "Printing the result of the modulus operation",
        20: "Performing exponentiation and storing the result",
        21: "Printing the result of the exponentiation",
        23: "Performing floor division and handling division by zero",
        24: "Printing the result of the floor division"
    },
    Java: {
        1: "Starting the Main class definition",
        2: "Starting the main method, the entry point of the Java program",
        3: "Declaring and initializing variable 'x'",
        4: "Declaring and initializing variable 'y'",
        6: "Performing addition and storing the result",
        7: "Printing the result of the addition",
        9: "Performing subtraction and storing the result",
        10: "Printing the result of the subtraction",
        12: "Performing multiplication and storing the result",
        13: "Printing the result of the multiplication",
        15: "Performing division and handling division by zero",
        16: "Printing the result of the division",
        18: "Performing modulus operation and storing the result",
        19: "Printing the result of the modulus operation",
        21: "Performing exponentiation and storing the result",
        22: "Printing the result of the exponentiation",
        24: "Performing floor division and handling division by zero",
        25: "Printing the result of the floor division",
        27: "Ending the main method",
        28: "Ending the Main class definition"
    },
    C: {
        1: "Including the standard input-output header file",
        2: "Including the math header file for advanced arithmetic operations",
        4: "Starting the main function, the entry point of the C program",
        5: "Declaring and initializing variable 'x'",
        6: "Declaring and initializing variable 'y'",
        8: "Performing addition and storing the result",
        9: "Printing the result of the addition",
        11: "Performing subtraction and storing the result",
        12: "Printing the result of the subtraction",
        14: "Performing multiplication and storing the result",
        15: "Printing the result of the multiplication",
        17: "Declaring float variable 'division'",
        18: "Checking if the division is not by zero",
        19: "Performing division and storing the result after typecasting to float",
        20: "Printing the result of the division",
        21: "Condition not satisfied, executing else block",
        22: "Printing the error message",
        25: "Performing modulus operation and storing the result",
        26: "Printing the result of the modulus operation",
        28: "Performing exponentiation and storing the result",
        29: "Printing the result of the exponentiation",
        31: "Declaring integer variable 'floorDivision'",
        32: "Checking if the division is not by zero",
        33: "Performing floor division and storing the result",
        34: "Printing the result of the floor division",
        35: "Condition not satisfied, executing else block",
        36: "Printing the error message",
        39: "Returning 0 to indicate successful execution",
        40: "Ending the main function"
    },
    'C++': {
        1: "Including the input-output stream header file",
        2: "Including the math header file for advanced arithmetic operations",
        4: "Starting the main function, the entry point of the C++ program",
        5: "Declaring and initializing variable 'x'",
        6: "Declaring and initializing variable 'y'",
        8: "Performing addition and storing the result",
        9: "Printing the result of the addition",
        11: "Performing subtraction and storing the result",
        12: "Printing the result of the subtraction",
        14: "Performing multiplication and storing the result",
        15: "Printing the result of the multiplication",
        17: "Performing division and handling division by zero",
        18: "Printing the result of the division",
        20: "Performing modulus operation and storing the result",
        21: "Printing the result of the modulus operation",
        23: "Performing exponentiation and storing the result",
        24: "Printing the result of the exponentiation",
        26: "Performing floor division and handling division by zero",
        27: "Printing the result of the floor division",
        29: "Returning 0 to indicate successful execution",
        30: "Ending the main function"
    }
};

export default explanations;
