const codeSamples = {
    Java: [
      "public class FloatTypes {",
      "    public static void main(String[] args) {",
      "        float piValue = 3.14159f;",
      "        float averageBodyTemperature = 36.6f;",
      "        float gravitationalAcceleration = 9.81f;",
      "  ",
      "        System.out.println('Value of Pi: ' + piValue);",
      "  ",
      "        System.out.println('Average human body temperature in Celsius: ' + averageBodyTemperature);",
      "  ",
      "        System.out.println('Gravitational acceleration in m/s²: ' + gravitationalAcceleration);",
      "    }",
      "}"
    ],
    C: [
      "#include <stdio.h>",
      "int main() {",
      "    float piValue = 3.14159;",
      "    float averageBodyTemperature = 36.6;",
      "    float gravitationalAcceleration = 9.81;",
      "  ",
      "    printf('Value of Pi: %.5f\\n', piValue);",
      "  ",
      "    printf('Average human body temperature in Celsius: %.1f\\n', averageBodyTemperature);",
      "  ",
      "    printf('Gravitational acceleration in m/s²: %.2f\\n', gravitationalAcceleration);",
      "  ",
      "    return 0;",
      "}"
    ],
    "C++": [
      "#include <iostream>",
      "using namespace std;",
      "int main() {",
      "    float piValue = 3.14159;",
      "    float averageBodyTemperature = 36.6;",
      "    float gravitationalAcceleration = 9.81;",
      "  ",
      "    cout << 'Value of Pi: ' << piValue << endl;",
      "  ",
      "    cout << 'Average human body temperature in Celsius: ' << averageBodyTemperature << endl;",
      "  ",
      "    cout << 'Gravitational acceleration in m/s²: ' << gravitationalAcceleration << endl;",
      "  ",
      "    return 0;",
      "}"
    ]
  };
  
  export default codeSamples;
  