const explanations = {
    Python: {
      1: "Defining the Queue class.",
      2: "Initializing the constructor with the size parameter.",
      3: "Setting the size of the queue.",
      4: "Initializing the queue with empty values",
      5: "Setting the front and rear pointers to -1.",
      7: "Defining the enqueue method to add elements to the queue.",
      8: "Checking if the queue is full.",
      9: "Queue is full, print the message.",
      10: "Queue has space, proceed to enqueue.",
      11: "Checking if the Queue is empty.",
      12: "Updating front pointer to 0.",
      13: "Updating rear pointer.",
      14: "Adding data to the queue.",
      16: "Defining the dequeue method to remove elements from the queue.",
      17: "Checking if the queue is empty.",
      18: "Queue is empty, print the message.",
      19: "Queue is not empty, executing the else block.",
      20: "Removing the data.",
      21: "Updating front pointer.",
      23: "Defining the display method to print queue elements.",
      24: "Checking if the queue is empty.",
      25: "Queue is empty, print the message.",
      26: "Queue is not empty, executing the else block.",
      27: "Executing print statement.",
      28: "Iterating through the queue to display elements.",
      29: "Printing element from the Queue.",
      30: "Printing a separator after displaying the Queue.",
      32: "Main block of execution.",
      33: "Creating a queue of size 3.",
      35: "Attempting to dequeue from an empty queue.",
      37: "Enqueue the value 10 into the queue.",
      38: "Enqueue the value 20 into the queue.",
      39: "Enqueue the value 30 into the queue.",
      40: "Attempting to enqueue into a full queue.",
      42: "Display the current state of the queue.",
      44: "Dequeue a value from the queue.",
      46: "Display the current state of the queue.",
    },
    Java: {
      1: "Defining the Queue class.",
      2: "Declaring private integer variable size.",
      3: "Declaring private array of integer variable queue.",
      4: "Declaring private integer variables front and rear.",
      6: "Initializing the constructor with the size parameter.",
      7: "Setting the size of the queue.",
      8: "Initializing the queue with empty values",
      9: "Setting the front and rear pointers to -1.",
      12: "Defining the enqueue method to add elements to the queue.",
      13: "Checking if the queue is full.",
      14: "Queue is full, print the message.",
      15: "Queue has space, proceed to enqueue.",
      16: "Checking if the Queue is empty.",
      17: "Updating front pointer to 0.",
      19: "Updating rear pointer.",
      20: "Adding data to the queue.",
      14: "Defining the dequeue method to remove elements from the queue.",
      25: "Checking if the queue is empty.",
      26: "Queue is empty, print the message.",
      27: "Queue is not empty, executing the else block.",
      28: "Removing the data.",
      29: "Updating front pointer.",
      33: "Defining the display method to print queue elements.",
      34: "Checking if the queue is empty.",
      35: "Queue is empty, print the message.",
      36: "Queue is not empty, executing the else block.",
      37: "Executing print statement.",
      38: "Iterating through the queue to display elements.",
      39: "Printing element from the Queue.",
      41: "Printing a separator after displaying the Queue.",
      45: "Main block of execution.",
      46: "Creating a queue of size 3.",
      47: "Attempting to dequeue from an empty queue.",
      49: "Enqueue the value 10 into the queue.",
      50: "Enqueue the value 20 into the queue.",
      51: "Enqueue the value 30 into the queue.",
      52: "Attempting to enqueue into a full queue.",
      54: "Display the current state of the queue.",
      56: "Dequeue a value from the queue.",
      58: "Display the current state of the queue."
    },
    C: {
      1: "Including the standard input-output library.",
      3: "Define the maximum size of the Queue using a macro.",
      5: "Declaring global array of integer variable queue.",
      6: "Declaring and Initializing global integer variables front, rear and size.",
      8: "Defining the enqueue method to add elements to the queue.",
      9: "Checking if the queue is full.",
      10: "Queue is full, print the message.",
      11: "Queue has space, proceed to enqueue.",
      12: "Checking if the Queue is empty.",
      13: "Updating front pointer to 0.",
      15: "Updating rear pointer.",
      16: "Adding data to the queue.",
      20: "Defining the dequeue method to remove elements from the queue.",
      21: "Checking if the queue is empty.",
      22: "Queue is empty, print the message.",
      23: "Queue is not empty, executing the else block.",
      24: "Removing the data.",
      25: "Updating front pointer.",
      29: "Defining the display method to print queue elements.",
      30: "Checking if the queue is empty.",
      31: "Queue is empty, print the message.",
      32: "Queue is not empty, executing the else block.",
      33: "Executing print statement.",
      34: "Iterating through the queue to display elements.",
      35: "Printing element from the Queue.",
      37: "Printing a separator after displaying the Queue.",
      41: "Main block of execution.",
      42: "Attempting to dequeue from an empty queue.",
      44: "Enqueue the value 10 into the queue.",
      45: "Enqueue the value 20 into the queue.",
      46: "Enqueue the value 30 into the queue.",
      47: "Attempting to enqueue into a full queue.",
      49: "Display the current state of the queue.",
      51: "Dequeue a value from the queue.",
      53: "Display the current state of the queue.",
      55: "Return 0 to indicate successful execution."
    },
    "C++": {
      1: "Include the standard input-output library for functions like cout.",
      4: "Define the maximum size of the Queue using a macro.",
      6: "Declaring global array of integer variable queue.",
      7: "Declaring and Initializing global integer variables front, rear and size.",
      9: "Defining the enqueue method to add elements to the queue.",
      10: "Checking if the queue is full.",
      11: "Queue is full, print the message.",
      12: "Queue has space, proceed to enqueue.",
      13: "Checking if the Queue is empty.",
      14: "Updating front pointer to 0.",
      16: "Updating rear pointer.",
      17: "Adding data to the queue.",
      21: "Defining the dequeue method to remove elements from the queue.",
      22: "Checking if the queue is empty.",
      23: "Queue is empty, print the message.",
      24: "Queue is not empty, executing the else block.",
      25: "Removing the data.",
      26: "Updating front pointer.",
      30: "Defining the display method to print queue elements.",
      31: "Checking if the queue is empty.",
      32: "Queue is empty, print the message.",
      33: "Queue is not empty, executing the else block.",
      34: "Executing print statement.",
      35: "Iterating through the queue to display elements.",
      36: "Printing element from the Queue.",
      38: "Printing a separator after displaying the Queue.",
      42: "Main block of execution.",
      43: "Attempting to dequeue from an empty queue.",
      45: "Enqueue the value 10 into the queue.",
      46: "Enqueue the value 20 into the queue.",
      47: "Enqueue the value 30 into the queue.",
      48: "Attempting to enqueue into a full queue.",
      50: "Display the current state of the queue.",
      52: "Dequeue a value from the queue.",
      54: "Display the current state of the queue.",
      56: "Return 0 to indicate successful execution."
    }
  };
  
  export default explanations;
  