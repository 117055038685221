const explanations = {
    Python: {
        1: "Defining the insertion_sort function that takes a list as an argument.",
        2: "Starting a for loop from the second element to the end of the list.",
        3: "Assigning the current element to the variable key.",
        4: "Setting j to the index of the element just before the current element.",
        5: "Entering a while loop to shift elements of the list that are greater than key to one position ahead.",
        6: "Shifting the element one position to the right.",
        7: "Decreasing j by 1 to move to the next element on the left.",
        8: "Placing the key in its correct position in the sorted part of the list.",
        9: "Returning the sorted list.",
        11: "Main execution block to run the script",
        12: "Defining a list with unsorted elements.",
        13: "Calling the insertion_sort function and storing the sorted list.",
        14: "Printing the sorted list."
    },
    Java: {
        1: "Defining the InsertionSort class.",
        2: "Defining the insertionSort method that takes an integer array list as an argument.",
        3: "Getting the length of the array list.",
        4: "Starting a for loop from the second element to the end of the array.",
        5: "Assigning the current element to the variable key.",
        6: "Setting j to the index of the element just before the current element.",
        7: "Entering a while loop to shift elements of the array that are greater than key to one position ahead.",
        8: "Shifting the element one position to the right.",
        9: "Decreasing j by 1 to move to the next element on the left.",
        11: "Placing the key in its correct position in the sorted part of the array.",
        13: "Returning the sorted array.",
        16: "Defining the main method to execute the insertion sort.",
        17: "Creating an instance of the InsertionSort class.",
        18: "Defining an integer array with unsorted elements.",
        19: "Calling the insertionSort method and storing the sorted array.",
        20: "Printing the sorted array.",
        21: "Iterating over the sorted array to print each element.",
        22: "Prining element from the Sorted Array."
    },
    C: {
        1: "Including the standard input-output library.",
        2: "Defining the insertionSort function that takes an integer array list and its length n as arguments.",
        3: "Declaring the variables i, key, and j.",
        4: "Starting a for loop from the second element to the end of the array.",
        5: "Assigning the current element to the variable key.",
        6: "Setting j to the index of the element just before the current element.",
        7: "Entering a while loop to shift elements of the array that are greater than key to one position ahead.",
        8: "Shifting the element one position to the right.",
        9: "Decreasing j by 1 to move to the next element on the left.",
        11: "Placing the key in its correct position in the sorted part of the array.",
        13: "Returning the sorted array.",
        16: "Defining the main function to execute the insertion sort.",
        17: "Defining an integer array with unsorted elements.",
        18: "Calculating the number of elements in the array.",
        19: "Calling the insertionSort function and storing the sorted array.",
        20: "Printing the sorted array.",
        21: "Iterating over the sorted array to print each element.",
        22: "Prining element from the Sorted Array.",
        23: "Returning 0 to indicate successful execution"
    },
    "C++": {
        1: "Including the iostream library.",
        2: "Using the std namespace.",
        3: "Defining the insertionSort function that takes an integer array list and its length n as arguments.",
        4: "Declaring the variables i, key, and j.",
        5: "Starting a for loop from the second element to the end of the array.",
        6: "Assigning the current element to the variable key.",
        7: "Setting j to the index of the element just before the current element.",
        8: "Entering a while loop to shift elements of the array that are greater than key to one position ahead.",
        9: "Shifting the element one position to the right.",
        10: "Decreasing j by 1 to move to the next element on the left.",
        12: "Placing the key in its correct position in the sorted part of the array.",
        14: "Returning the sorted array.",
        17: "Defining the main function to execute the insertion sort.",
        18: "Defining an integer array with unsorted elements.",
        19: "Calculating the number of elements in the array.",
        20: "Calling the insertionSort function and storing the sorted array.",
        21: "Printing the sorted array.",
        22: "Iterating over the sorted array to print each element.",
        23: "Prining element from the Sorted Array.",
        24: "Returning 0 to indicate successful execution"
    }
};

export default explanations;
