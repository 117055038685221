import React, { useState } from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvas';
import AlgorithmDescription from '../AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';
import UpdateVariablesModal from './UpdateVariablesModal';

import { useDoubleTypes } from './useDoubleTypes';

const DoubleTypes = ({ language, subTopicId }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [earthMass, setEarthMass] = useState(5.972e24);
  const [avogadroNumber, setAvogadroNumber] = useState(6.02214076e23);
  const [electronCharge, setElectronCharge] = useState(1.602176634e-19);

  const {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    highlightedArrayVariables,
    isMuted,
    codeSamples,
    setDelay,
    setIsPaused,
    setIsMuted,
    updateVariables,
    runDoubleTypes
  } = useDoubleTypes(
    language,
    earthMass,
    avogadroNumber,
    electronCharge
  );

  const handleUpdateVariables = () => {
    updateVariables(earthMass, avogadroNumber, electronCharge);
    setModalVisible(false);
  };

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6">
          <CodeDisplay
            codeLines={codeSamples || []}
            highlightedLine={highlightedLine}
            focusedEndLine={focusedEndLine}
            topic={"Double Types Example"}
            callingLine={callingLine}
            subTopicId={subTopicId}
            language={language}
            onOpenModal={() => setModalVisible(true)}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <h3 className="section-title">Canvas</h3>
            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runDoubleTypes}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
            <VisualizationCanvas
              variables={variables}
              arrayVariables={arrayVariables}
              highlightedVariables={highlightedVariables}
              highlightedArrayVariables={highlightedArrayVariables}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
          <AlgorithmDescription />
      </div>
      <UpdateVariablesModal
        isVisible={modalVisible}
        onClose={() => setModalVisible(false)}
        earthMass={earthMass}
        setEarthMass={setEarthMass}
        avogadroNumber={avogadroNumber}
        setAvogadroNumber={setAvogadroNumber}
        electronCharge={electronCharge}
        setElectronCharge={setElectronCharge}
        onSave={handleUpdateVariables}
      />
    </div>
  );
};

export default DoubleTypes;
