const explanations = {
    Python: {
      1: "Main execution block",
      2: "Declaring and initializing boolean variable is_hungry",
      3: "Declaring and initializing boolean variable has_food",
      5: "Checking if is_hungry and has_food are both True",
      6: "Printing 'Time to eat!' because conditions are met",
      7: "Executing else block, becuase the above condition is not satisfied",
      8: "Printing 'No food or not hungry.'",
      10: "Printing value of is_hungry",
      11: "Printing value of has_food"
    },
    Java: {
      1: "Defining the BooleanExample class",
      2: "Main method execution block",
      3: "Declaring and initializing boolean variable is_hungry",
      4: "Declaring and initializing boolean variable has_food",
      6: "Checking if is_hungry and has_food are both True",
      7: "Printing 'Time to eat!' because conditions are met",
      8: "Executing else block, becuase the above condition is not satisfied",
      9: "Printing 'No food or not hungry.'",
      12: "Printing value of isHungry",
      13: "Printing value of hasFood"
    },
    "C++": {
      4: "Declaring and initializing boolean variable is_hungry",
      5: "Declaring and initializing boolean variable has_food",
      7: "Checking if is_hungry and has_food are both True",
      8: "Printing 'Time to eat!' because conditions are met",
      9: "Executing else block, becuase the above condition is not satisfied",
      10: "Printing 'No food or not hungry.'",
      13: "Printing value of isHungry using boolalpha",
      14: "Printing value of hasFood using boolalpha",
      15: "Returning 0 to indicate successful execution"
    }
  };
  
  export default explanations;
  