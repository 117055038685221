const explanations = {
    Java: {
        1: "Declaring the EnumExample class",
        2: "Defining an enum called WorkDay with work days of the week",
        6: "Starting the main method",
        7: "Declaring a string input to hold the day name",
        9: "Executing try block",
        10: "Attempting to convert the string input to a WorkDay enum value",
        11: "Checking if today is Friday",
        12: "Printing the message",
        13: "today is not Friday, so executing else block",
        14: "Printing the message",
        16: "Catching the Illegal Argument Exception because the input is invalid",
        17: "Printing the exception message"
    },
    C: {
        1: "Including the input/output stream library",
        3: "Defining an enum called WorkDay with work days of the week",
        7: "Starting the main function",
        8: "Declaring and Initializing a WorkDay variable named today",
        10: "Checking if today is Friday",
        11: "Printing the message",
        12: "today is not Friday, so executing else block",
        13: "Printing the message",
        16: "Returning 0 to indicate successful execution"
    },
    'C++': {
        1: "Including the input/output stream library",
        3: "Defining an enum called WorkDay with work days of the week",
        7: "Starting the main function",
        8: "Declaring and Initializing a WorkDay variable named today",
        10: "Checking if today is Friday",
        11: "Printing the message",
        12: "today is not Friday, so executing else block",
        13: "Printing the message",
        16: "Returning 0 to indicate successful execution"
    }
};

export default explanations;
