const explanations = {
    Python: {
      1: "Import the deque class from the collections module to create a double-ended queue.",
      3: "Define the Node class to represent each element of the binary tree.",
      4: "Executing the constructor method __init__ to initialize a node that represents each element of the binary tree.",
      5: "Assigning the data to the node's data attribute.",
      6: "Setting the left pointer attribute to None.",
      7: "Setting the right pointer attribute to None.",
      9: "Define the BinaryTree class to manage the tree structure.",
      10: "Executing the constructor method __init__ of the class BinaryTree.",
      11: "Initializing the binary tree with an empty root (i.e., the tree is initially empty).",
      13: "Executing the add method to add a node to the binary tree.",
      14: "Create a new node with the provided data.",
      15: "Check if the tree is empty (i.e., root is None).",
      16: "The root is empty, the new node becomes the root of the tree.",
      17: "Root is not empty, executing else block.",
      18: "Initialize a deque (double-ended queue) with the root node to help traverse the tree.",
      19: "Start a while loop to iterate as long as there are nodes in the queue.",
      20: "Remove the first node from the queue and store it in the current variable.",
      21: "Checking if the left child of the current node is empty (None).",
      22: "Left child is empty, inserting the new node as the left child.",
      23: "Returning to stop further processing as the node has been successfully inserted.",
      24: "Left child is not empty, so executing the else block.", 
      25: "Append the left child of the current node to the queue for further traversal.",
      26: "Checking if the right child of the current node is empty (None).",
      27: "Right child is empty, inserting the new node as the right child.",
      28: "Returning to stop further processing as the node has been successfully inserted.",
      29: "Right child is not empty, so executing the else block.", 
      30: "Append the right child of the current node to the queue for further traversal.",
      32: "Main block to test the Binary Tree class functionality.",
      33: "Creating an instance of the BinaryTree class.",
      34: "Adding the first node to the BinaryTree.",
      35: "Adding a new node to the BinaryTree.",
      36: "Adding a new node to the BinaryTree.",
      37: "Adding a new node to the BinaryTree.",
      38: "Adding a new node to the BinaryTree.",
      39: "Adding a new node to the BinaryTree.",
      40: "Adding a new node to the BinaryTree.",
    },
    Java: {
      1: "Import the Queue classes from the java.util package to manage a queue of nodes.",
      2: "Import the LinkedList classes from the java.util package to manage a queue of nodes.",
      4: "Define the Node class to represent each element of the binary tree.",
      5: "Declare the data attribute to store the value of the node.",
      6: "Declare left and right pointers to represent the left and right child nodes.",
      8: "Executing the constructor method to initialize a new node.",
      9: "Assign the provided data to the node's data attribute.",
      10: "Set both left and right child pointers to null (i.e., the node has no children yet).",
      14: "Define the BinaryTree class to manage the binary tree structure.",
      15: "Declare the root attribute to represent the root node of the binary tree.",
      17: "Executing the constructor method to initialize the binary tree.",
      18: "Set the root to null, meaning the tree is initially empty.",
      21: "Executing the add method to add a new node with the given data to the binary tree.",
      22: "Creating a new node with the provided data.",
      23: "Checking if the tree is empty by verifying if the root is null.",
      24: "Root is null, set the new node as the root of the tree.",
      25: "Root is not empty, executing else block.",
      26: "Create a new queue using LinkedList, this will help to traverse the tree.",
      27: "Adding the root node to the queue to begin the level-order traversal.",
      29: "Starting a while loop that continues as long as the queue is not empty.",
      30: "Retrieve and remove the first node in the queue (FIFO) and store it in the current variable.",
      32: "Checking if the left child of the current node is null.",
      33: "Left child is null, inserting the new node as the left child.",
      34: "Return to stop further processing since the node has been inserted.",
      35: "Left child is not null, executing the else block.",
      36: "Adding the left child to the queue for further traversal.",
      39: "Checking if the right child of the current node is null.",
      40: "Right child is null, insert the new node as the right child.",
      41: "Return to stop further processing since the node has been inserted.",
      42: "Right child is not null, executing the else block.",
      43: "Adding the right child to the queue for further traversal.",
      50: "Main block to test the BinaryTree class functionality.",
      51: "Define the main method to execute the program.",
      52: "Creating an instance of the BinaryTree class.",
      53: "Adding the first node to the BinaryTree.",
      54: "Adding a new node to the BinaryTree.",
      55: "Adding a new node to the BinaryTree.",
      56: "Adding a new node to the BinaryTree.",
      57: "Adding a new node to the BinaryTree.",
      58: "Adding a new node to the BinaryTree.",
      59: "Adding a new node to the BinaryTree.",
    },
    "C++": {
      1: "Include the iostream header to allow input and output operations.",
      2: "Include the queue header to use the queue data structure for level-order traversal.",
      3: "Use the standard namespace to avoid writing 'std::' before standard functions like cout.",
      5: "Define the Node class to represent each element of the binary tree.",
      7: "Declare the data attribute to store the value of the node.",
      8: "Declare pointers to represent the left and right child nodes of the node.",
      9: "Executing the constructor method to initialize a new node with the given data.",
      10: "Assign the provided data to the node's data attribute.",
      11: "Set both left and right child pointers to null pointer, indicating no children initially.",
      15: "Define the BinaryTree class to manage the binary tree structure.",
      17: "Declare the root attribute to represent the root node of the binary tree.",
      18: "Executing the constructor method to initialize the binary tree.",
      19: "Set the root to null pointer, meaning the tree is initially empty.",
      22: "Executing the add method to add a new node with the given data to the binary tree.",
      23: "Creating a new node with the provided data using the Node constructor.",
      24: "Checking if the tree is empty by verifying if the root is null pointer.",
      25: "Root is null pointer, set the new node as the root of the tree.",
      26: "Root is not empty, executing else block.",
      27: "Create a new queue, this will help to traverse the tree.",
      28: "Adding the root node to the queue to start the level-order traversal.",
      30: "Starting a while loop that continues as long as the queue is not empty.",
      31: "Retrieve and store the first node from the queue in the current variable.",
      32: "Remove the first node from the queue.",
      34: "Checking if the left child of the current node is null pointer.",
      35: "Left child is null pointer, inserting the new node as the left child.",
      36: "Return to stop further processing since the node has been inserted.",
      37: "Left child is not null pointer, executing the else block.",
      38: "Adding the left child to the queue for further traversal.",
      41: "Checking if the right child of the current node is null pointer.",
      42: "Right child is null pointer, inserting the new node as the right child.",
      43: "Return to stop further processing since the node has been inserted.",
      44: "Right child is not null pointer, executing the else block.",
      45: "Adding the right child to the queue for further traversal.",
      52: "Main method to test the BinaryTree class functionality.",
      53: "Creating an instance of the BinaryTree class.",
      54: "Adding the first node to the BinaryTree.",
      55: "Adding a new node to the BinaryTree.",
      56: "Adding a new node to the BinaryTree.",
      57: "Adding a new node to the BinaryTree.",
      58: "Adding a new node to the BinaryTree.",
      59: "Adding a new node to the BinaryTree.",
      60: "Adding a new node to the BinaryTree.",
      61: "Returning 0 to indicate successful execution."
    },
    C: {
      1: "Include the stdio.h header to handle input and output functions like printf.",
      2: "Include the stdlib.h header for memory allocation functions like malloc.",
      4: "Define the Node struct to represent each element (node) of the binary tree.",
      5: "Declare the data attribute to store the value of the node.",
      6: "Declare a pointer to represent the left child of the node.",
      7: "Declare a pointer to represent the right child of the node.",
      10: "Executing the createNode function to allocate memory for a new node and initialize it.",
      11: "Allocate memory for the new node using malloc and cast the result to a Node pointer.",
      12: "Assign the provided data to the node's data attribute.",
      13: "Set the left child pointer to NULL (indicating no left child initially).",
      14: "Set the right child pointer to NULL (indicating no right child initially).",
      15: "Return the pointer to the newly created node.",
      18: "Executing the addNode function to add a new node with the given data to the binary tree.",
      19: "Creating a new node with the provided data using the createNode function.",
      20: "Checking if the tree is empty by verifying if the root is NULL.",
      21: "Root is NULL, set the new node as the root of the tree.",
      22: "Root is not empty, executing else block.",
      23: "Set the toot node as the current node of the binary tree.",
      24: "Declare a static array of Node pointers to serve as the queue.",
      25: "Initialize the front and rear indices of the queue.",
      26: "Add the root node to the queue to start the level-order traversal.",
      28: "Starting a while loop that continues as long as the front is less than rear.",
      29: "Fetch the node from the queue by accessing the element at the front and then increment the front.",
      31: "Checking if the left child of the current node is NULL.",
      32: "Left child is NULL, inserting the new node as the left child.",
      33: "Return to stop further processing since the node has been inserted.",
      34: "Left child is not NULL, executing the else block.",
      35: "Enqueue the left child of the current node for further traversal and increment the rear index.",
      38: "Checking if the right child of the current node is NULL.",
      39: "Right child is NULL, inserting the new node as the right child.",
      40: "Return to stop further processing since the node has been inserted.",
      41: "Right child is not NULL, executing the else block.",
      42: "Enqueue the right child of the current node for further traversal and increment the rear index.",
      48: "Main block to test the BinaryTree functionality.",
      49: "Initialize the root of the tree as NULL (indicating an empty tree).",
      50: "Adding the first node to the BinaryTree.",
      51: "Adding a new node to the BinaryTree.",
      52: "Adding a new node to the BinaryTree.",
      53: "Adding a new node to the BinaryTree.",
      54: "Adding a new node to the BinaryTree.",
      55: "Adding a new node to the BinaryTree.",
      56: "Adding a new node to the BinaryTree.",
      57: "Returning 0 to indicate successful execution."
    }
  };
  
  export default explanations;
  