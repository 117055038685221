const codeSamples = {
    Java: [
        "public class SwitchCaseExample {",
        "    public static void main(String[] args) {",
        "        int day = 3;",
        "        String dayName;",
        " ",
        "        // Switch case to determine the day of the week",
        "        switch (day) {",
        "            case 1:",
        "                dayName = \"Sunday\";",
        "                break;",
        "            case 2:",
        "                dayName = \"Monday\";",
        "                break;",
        "            case 3:",
        "                dayName = \"Tuesday\";",
        "                break;",
        "            case 4:",
        "                dayName = \"Wednesday\";",
        "                break;",
        "            case 5:",
        "                dayName = \"Thursday\";",
        "                break;",
        "            case 6:",
        "                dayName = \"Friday\";",
        "                break;",
        "            case 7:",
        "                dayName = \"Saturday\";",
        "                break;",
        "            default:",
        "                dayName = \"Invalid day\";",
        "                break;",
        "        }",
        " ",
        "        System.out.println(\"The day is: \" + dayName);",
        "    }",
        "}"
    ],
    C: [
        "#include <stdio.h>",
        " ",
        "int main() {",
        "    int day = 3;",
        "    char* dayName;",
        " ",
        "    // Switch case to determine the day of the week",
        "    switch (day) {",
        "        case 1:",
        "            dayName = \"Sunday\";",
        "            break;",
        "        case 2:",
        "            dayName = \"Monday\";",
        "            break;",
        "        case 3:",
        "            dayName = \"Tuesday\";",
        "            break;",
        "        case 4:",
        "            dayName = \"Wednesday\";",
        "            break;",
        "        case 5:",
        "            dayName = \"Thursday\";",
        "            break;",
        "        case 6:",
        "            dayName = \"Friday\";",
        "            break;",
        "        case 7:",
        "            dayName = \"Saturday\";",
        "            break;",
        "        default:",
        "            dayName = \"Invalid day\";",
        "            break;",
        "    }",
        " ",
        "    printf(\"The day is: %s\\n\", dayName);",
        "    return 0;",
        "}"
    ],
    'C++': [
        "#include <iostream>",
        "using namespace std;",
        " ",
        "int main() {",
        "    int day = 3;",
        "    string dayName;",
        " ",
        "    // Switch case to determine the day of the week",
        "    switch (day) {",
        "        case 1:",
        "            dayName = \"Sunday\";",
        "            break;",
        "        case 2:",
        "            dayName = \"Monday\";",
        "            break;",
        "        case 3:",
        "            dayName = \"Tuesday\";",
        "            break;",
        "        case 4:",
        "            dayName = \"Wednesday\";",
        "            break;",
        "        case 5:",
        "            dayName = \"Thursday\";",
        "            break;",
        "        case 6:",
        "            dayName = \"Friday\";",
        "            break;",
        "        case 7:",
        "            dayName = \"Saturday\";",
        "            break;",
        "        default:",
        "            dayName = \"Invalid day\";",
        "            break;",
        "    }",
        " ",
        "    cout << \"The day is: \" << dayName << endl;",
        "    return 0;",
        "}"
    ]
};

export default codeSamples;
