const explanations = {
  Python: {
    1: "Defining the Node class that represents a single element in the linked list.",
    2: "Executing the constructor method __init__ to initialize the node with the provided data.",
    3: "Assigning the passed data to the node's data attribute.",
    4: "Setting the next attribute to None, indicating that the node does not point to any other node yet.",
    6: "Defining the LinkedList class that manages the linked list.",
    7: "Executing the constructor method __init__ of the LinkedList class.",
    8: "Initializing the linked list with an empty head (i.e., the list is initially empty).",
    10: "Executing the display method that prints all elements of the linked list.",
    11: "Assigning the head of the list to temp to start traversing from the first node.",
    12: "Checking if the list is empty by checking if temp is None.",
    13: "Temp is None, so it prints 'The list is empty.' to indicate there are no elements.",
    14: "List is not empty, executing else block.",
    15: "Traversing through the list till temp become None.",
    16: "Prints the data of the current node.",
    17: "Moving to the next node by setting temp to temp.next.",
    19: "Main block to test the functionality of the LinkedList class.",
    20: "Creating an instance of LinkedList named list.",
    23: "Calling the display method on the list to print the contents of the linked list."
  },
  Java: {
    1: "Defining the Node class that represents a single element in the linked list.",
    2: "Declaring an integer data variable to hold the value for the node.",
    3: "Declaring a next variable that will point to the next node in the linked list.",
    5: "Executing the Node constructor to initialize the node with data.",
    6: "Setting the node's data to the passed value.",
    7: "Setting the next pointer to null since it's a new node without a next node.",
    11: "Defining the LinkedList class that will manage the linked list.",
    12: "Declaring a head variable to keep track of the first node in the linked list.",
    14: "Executing the display method that prints all elements of the linked list.",
    15: "Assigning the head of the list to temp to start traversing from the first node.",
    16: "Checking if the list is empty by checking if temp is null.",
    17: "Temp is null, so it prints 'The list is empty.' to indicate there are no elements.",
    18: "List is not empty, executing else block.",
    19: "Traversing through the list till temp become null.",
    20: "Prints the data of the current node.",
    21: "Moving to the next node by setting temp to temp.next.",
    26: "Main block to test the functionality of the LinkedList class.",
    27: "Creating an instance of LinkedList named list.",
    30: "Calling the display method on the list to print the contents of the linked list."
  },
  C: {
    1: "Including the standard input-output library.",
    2: "Including the standard library for memory allocation.",
    4: "Defining the Node structure to represent an element in the linked list.",
    5: "Declaring an integer data variable to hold the value of the node.",
    6: "Declaring a pointer to the next node in the list.",
    9: "Defining a global head pointer to track the start of the linked list.",
    11: "Executing the display function that prints all elements of the linked list.",
    12: "Assigning the head of the list to temp to start traversing from the first node.",
    13: "Checking if the list is empty by checking if temp is NULL.",
    14: "Temp is NULL, so it prints 'The list is empty.' to indicate there are no elements.",
    15: "List is not empty, executing else block.",
    16: "Traversing through the list till temp become NULL.",
    17: "Prints the data of the current node.",
    18: "Moving to the next node by setting temp to temp of next.",
    23: "Main block to test the functionality of the LinkedList.",
    25: "Calling the display function to print the contents of the linked list.",
    27: "Returning 0 to indicate successful execution.",
  },
  "C++": {
    1: "Including the iostream library for input-output operations.",
    2: "Using the standard namespace.",
    4: "Defining the Node class to represent an element in the linked list.",
    6: "Declaring public the data variable to hold the node's value.",
    7: "Declaring a public pointer to the next node in the linked list.",
    9: "Executing the Node constructor to initialize the node with data.",
    10: "Setting the node's data to the passed value.",
    11: "Setting the next pointer to nullptr since it's a new node.",
    15: "Defining the LinkedList class to manage the linked list.",
    17: "Declaring a head pointer to track the start of the linked list.",
    19: "Executing the constructor method of the class LinkedList.",
    20: "Initializing the head pointer to nullptr (i.e., the list is initially empty).",
    23: "Executing the display method that prints all elements of the linked list.",
    24: "Assigning the head of the list to temp to start traversing from the first node.",
    25: "Checking if the list is empty by checking if temp is null pointer.",
    26: "Temp is null pointer, so it prints 'The list is empty.' to indicate there are no elements.",
    27: "List is not empty, executing else block.",
    28: "Traversing through the list till temp become null.",
    29: "Prints the data of the current node.",
    30: "Moving to the next node by setting temp to temp of next.",
    36: "Main block to test the functionality of the LinkedList class.",
    37: "Creating an instance of LinkedList named list.",
    40: "Calling the display method on the list to print the contents of the linked list.",
    42: "Returning 0 to indicate successful execution.",
  },
};

export default explanations;
  