const codeSamples = {
  Python: [
    "if __name__ == '__main__':",
    "    arr = [1, 2, 3, 4, 5]",
    "    for i in range(len(arr)):",
    "        print(f'Element at index {i}: {arr[i]}')"
  ],
  Java: [
    "public class BasicArray {",
    "    public static void main(String[] args) {",
    "        int arr[] = {1, 2, 3, 4, 5};",
    "  ",
    "        for (int i = 0; i < arr.length; i++) {",
    "            System.out.println('Element at index ' + i + ': ' + arr[i]);",
    "        }",
    "    }",
    "}"
  ],
  C: [
    "#include <stdio.h>",
    "  ",
    "int main() {",
    "    int arr[] = {1, 2, 3, 4, 5};",
    "    int size = sizeof(arr) / sizeof(arr[0]);",
    "  ",
    "    for (int i = 0; i < size; i++) {",
    "        printf('Element at index %d: %d\\n', i, arr[i]);",
    "    }",
    "    return 0;",
    "}"
  ],
  "C++": [
    "#include <iostream>",
    "using namespace std;",
    "  ",
    "int main() {",
    "    int arr[] = {1, 2, 3, 4, 5};",
    "    int size = sizeof(arr) / sizeof(arr[0]);",
    "    for (int i = 0; i < size; i++) {",
    "        cout << 'Element at index ' << i << ': ' << arr[i] << endl;",
    "    }",
    "    return 0;",
    "}"
  ]
};

export default codeSamples;
