const explanations = {
    Python: {
      2: "Initialize the array with elements",
      3: "Calculate the length of the array",
      4: "Initialize the total sum to 0",
      5: "Iterate over each element using its index",
      6: "Add the element at the current index to the total sum",
      7: "Print the total sum"
    },
    Java: {
      3: "Initialize the array with elements",
      4: "Calculate the length of the array",
      5: "Initialize the total sum to 0",
      6: "Iterate over each element using its index",
      7: "Add the element at the current index to the total sum",
      9: "Print the total sum"
    },
    C: {
      4: "Initialize the array with elements",
      5: "Calculate the size of the array",
      6: "Initialize the total sum to 0",
      7: "Iterate over each element using its index",
      8: "Add the element at the current index to the total sum",
      10: "Print the total sum",
      11: "Return 0 to indicate successful execution"
    },
    "C++": {
      5: "Initialize the array with elements",
      6: "Calculate the size of the array",
      7: "Initialize the total sum to 0",
      8: "Iterate over each element using its index",
      9: "Add the element at the current index to the total sum",
      11: "Print the total sum",
      12: "Return 0 to indicate successful execution"
    }
  };
  
  export default explanations;
  