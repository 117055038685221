import React, { useState, useRef } from 'react';

const LoggerDrawer = ({ logs }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`${isOpen ? 'logger-drawer open' : 'logger-drawer-closed'}`}
      style={{ position: isOpen ? 'absolute' : 'relative' }}
    >
      <div
        className="toggle-icon"
        onClick={toggleDrawer}
      >
        <i className={`fa ${isOpen ? 'fa-chevron-down' : 'fa-chevron-up'}`} />
      </div>
      {isOpen && (
        <div 
          className="logger-content"
          style={{
            display: logs.length === 0 ? 'flex' : 'block',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%'
          }}
        >
          {logs.length > 0 ? (
            logs.slice(0).reverse().map((log, index) => (
              <div key={index} className="logger-line">{log}</div>
            ))
          ) : (
            <p style={{ textAlign: 'center' }}>
              Explanations for each line of code will be displayed here.
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default LoggerDrawer;
