const explanations = {
    Python: {
      1: "Defining the binary_search function",
      2: "Initializing low and high pointers",
      3: "Starting the while loop to search for the target element",
      4: "Calculating the middle index",
      5: "Getting the element at the middle index",
      6: "Checking if the middle element is the target element",
      7: "Element found, returning the index",
      8: "Middle element is less than the target, discarding the left half",
      9: "Updating low pointer",
      10: "Middle element is greater than the target, discarding the right half",
      11: "Updating high pointer",
      12: "Element not found, returning -1",
      14: "Main execution block",
      15: "Initializing the sorted list",
      16: "Setting the target element",
      17: "Calling the binary_search function",
      18: "Checking if the element is found",
      19: "Element found, printing the result",
      20: "Element not found, Executing else block",
      21: "Printing the result"
    },
    Java: {
      1: "Defining the BinarySearch class",
      2: "Defining the binarySearch method",
      3: "Initializing low and high pointers",
      4: "Starting the while loop to search for the target element",
      5: "Calculating the middle index",
      6: "Checking if the middle element is the target element",
      7: "Element found, returning the index",
      9: "Middle element is less than the target, discarding the left half",
      10: "Updating low pointer",
      12: "Middle element is greater than the target, discarding the right half",
      13: "Updating high pointer",
      16: "Element not found, returning -1",
      18: "Main method of the BinarySearch class",
      19: "Initializing the BinarySearch object",
      20: "Initializing the sorted array",
      21: "Setting the target element",
      22: "Calling the binarySearch method",
      23: "Checking if the element is found",
      24: "Element found, printing the result",
      26: "Element not found, Executing else block",
      27: "Printing the result"
    },
    C: {
      1: "Including the standard input-output library",
      2: "",
      3: "Defining the binarySearch function",
      4: "Initializing low and high pointers",
      5: "Starting the while loop to search for the target element",
      6: "Calculating the middle index",
      7: "Checking if the middle element is the target element",
      8: "Element found, returning the index",
      10: "Middle element is less than the target, discarding the left half",
      11: "Updating low pointer",
      13: "Middle element is greater than the target, discarding the right half",
      14: "Updating high pointer",
      17: "Element not found, returning -1",
      20: "Main function of the program",
      21: "Initializing the sorted array",
      22: "Calculating the size of the array",
      23: "Setting the target element",
      24: "Calling the binarySearch function",
      25: "Checking if the element is found",
      26: "Element found, printing the result",
      28: "Element not found, Executing else block",
      29: "Printing the result",
      31: "Returning 0 to indicate successful execution"
    },
    'C++': {
      1: "Including the iostream library",
      2: "Using the standard namespace",
      3: "",
      4: "Defining the binarySearch function",
      5: "Initializing low and high pointers",
      6: "Starting the while loop to search for the target element",
      7: "Calculating the middle index",
      8: "Checking if the middle element is the target element",
      9: "Element found, returning the index",
      11: "Middle element is less than the target, discarding the left half",
      12: "Updating low pointer",
      14: "Middle element is greater than the target, discarding the right half",
      15: "Updating high pointer",
      18: "Element not found, returning -1",
      21: "Main function of the program",
      22: "Initializing the sorted array",
      23: "Calculating the size of the array",
      24: "Setting the target element",
      25: "Calling the binarySearch function",
      26: "Checking if the element is found",
      27: "Element found, printing the result",
      29: "Element not found, Executing else block",
      30: "Printing the result",
      32: "Returning 0 to indicate successful execution"
    }
  };
  
  export default explanations;
  