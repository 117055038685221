const explanations = {
    Python: {
        1: "Starting the Python script with the main block",
        2: "Declaring and initializing variable 'number'",
        4: "Checking if 'number' is greater than 0",
        5: "Printing 'The number is positive'",
        6: "Checking if 'number' is less than 0",
        7: "Printing 'The number is negative'",
        8: "Executing else block, becuase none of the above conditions are satisfied",
        9: "Printing 'The number is zero'",
    },
    Java: {
        1: "Starting the Main class definition",
        2: "Starting the main method, the entry point of the Java program",
        3: "Declaring and initializing variable 'number'",
        5: "Checking if 'number' is greater than 0",
        6: "Printing 'The number is positive'",
        7: "Checking if 'number' is less than 0",
        8: "Printing 'The number is negative'",
        9: "Executing else block, becuase none of the above conditions are satisfied",
        10: "Printing 'The number is zero'",
        11: "Ending the main method",
        12: "Ending the Main class definition"
    },
    C: {
        1: "Including the standard input-output header file",
        3: "Starting the main function, the entry point of the C program",
        4: "Declaring and initializing variable 'number'",
        6: "Checking if 'number' is greater than 0",
        7: "Printing 'The number is positive'",
        8: "Checking if 'number' is less than 0",
        9: "Printing 'The number is negative'",
        10: "Executing else block, becuase none of the above conditions are satisfied",
        11: "Printing 'The number is zero'",
        13: "Returning 0 to indicate successful execution",
    },
    'C++': {
        1: "Including the input-output stream header file",
        3: "Starting the main function, the entry point of the C++ program",
        5: "Declaring and initializing variable 'number'",
        7: "Checking if 'number' is greater than 0",
        8: "Printing 'The number is positive'",
        9: "Checking if 'number' is less than 0",
        10: "Printing 'The number is negative'",
        11: "Executing else block, becuase none of the above conditions are satisfied",
        12: "Printing 'The number is zero'",
        14: "Returning 0 to indicate successful execution",
    }
};

export default explanations;
