const explanations = {
    Java: {
      1: "Defining the IntegerTypes class",
      2: "Main method execution block",
      3: "Declaring and initializing integer variable moonLandingYear",
      4: "Declaring and initializing integer variable olympicGoldMedals",
      5: "Declaring and initializing integer variable highestMountainHeightMeters",
      6: "Declaring and initializing integer variable lowestTemperatureRecordedCelsius",
      8: "Printing the year of the first moon landing",
      10: "Printing the number of Olympic gold medals won by Michael Phelps",
      12: "Printing the height of Mount Everest in meters",
      14: "Printing the lowest temperature recorded on Earth (Celsius)"
    },
    C: {
      3: "Declaring and initializing integer variable moonLandingYear",
      4: "Declaring and initializing integer variable olympicGoldMedals",
      5: "Declaring and initializing integer variable highestMountainHeightMeters",
      6: "Declaring and initializing integer variable lowestTemperatureRecordedCelsius",
      8: "Printing the year of the first moon landing",
      10: "Printing the number of Olympic gold medals won by Michael Phelps",
      12: "Printing the height of Mount Everest in meters",
      14: "Printing the lowest temperature recorded on Earth (Celsius)",
      16: "Returning 0 to indicate successful execution"
    },
    "C++": {
      4: "Declaring and initializing integer variable moonLandingYear",
      5: "Declaring and initializing integer variable olympicGoldMedals",
      6: "Declaring and initializing integer variable highestMountainHeightMeters",
      7: "Declaring and initializing integer variable lowestTemperatureRecordedCelsius",
      9: "Printing the year of the first moon landing",
      11: "Printing the number of Olympic gold medals won by Michael Phelps",
      13: "Printing the height of Mount Everest in meters",
      15: "Printing the lowest temperature recorded on Earth (Celsius)",
      17: "Returning 0 to indicate successful execution"
    }
  };
  
  export default explanations;
  