const codeSamples = {
    Python: [
        "if __name__ == '__main__':",
        "    number = 10",
        "  ",
        "    if number > 0:",
        "        print('The number is positive.')",
        "    elif number < 0:",
        "        print('The number is negative.')",
        "    else:",
        "        print('The number is zero.')"
    ],
    Java: [
        "public class Main {",
        "    public static void main(String[] args) {",
        "        int number = 10;",
        "  ",
        "        if (number > 0) {",
        "            System.out.println('The number is positive.');",
        "        } else if (number < 0) {",
        "            System.out.println('The number is negative.');",
        "        } else {",
        "            System.out.println('The number is zero.');",
        "        }",
        "    }",
        "}"
    ],
    C: [
        "#include <stdio.h>",
        "  ",
        "int main() {",
        "    int number = 10;",
        "  ",
        "    if (number > 0) {",
        "        printf('The number is positive.\\n');",
        "    } else if (number < 0) {",
        "        printf('The number is negative.\\n');",
        "    } else {",
        "        printf('The number is zero.\\n');",
        "    }",
        "    return 0;",
        "}"
    ],
    'C++': [
        "#include <iostream>",
        "using namespace std;",
        "  ",
        "int main() {",
        "    int number = 10;",
        "  ",
        "    if (number > 0) {",
        "        cout << 'The number is positive.' << endl;",
        "    } else if (number < 0) {",
        "        cout << 'The number is negative.' << endl;",
        "    } else {",
        "        cout << 'The number is zero.' << endl;",
        "    }",
        "    return 0;",
        "}"
    ]
};

export default codeSamples;
