const explanations = {
    Java: {
        1: "Starting the SwitchCaseExample class definition",
        2: "Starting the main method, the entry point of the Java program",
        3: "Declaring and initializing the variable 'day'",
        4: "Declaring a string variable 'dayName' to store the day of the week",
        6: "Comment indicating the start of the switch-case block",
        7: "Switch statement to determine the day of the week based on 'day'",
        8: "Case for day 1",
        9: "Assign 'Sunday' to 'dayName'",
        10: "Breaking out of the switch-case block",
        11: "Case for day 2",
        12: "Assign 'Monday' to 'dayName'",
        13: "Breaking out of the switch-case block",
        14: "Case for day 3",
        15: "Assign 'Tuesday' to 'dayName'",
        16: "Breaking out of the switch-case block",
        17: "Case for day 4",
        18: "Assign 'Wednesday' to 'dayName'",
        19: "Breaking out of the switch-case block",
        20: "Case for day 5",
        21: "Assign 'Thursday' to 'dayName'",
        22: "Breaking out of the switch-case block",
        23: "Case for day 6",
        24: "Assign 'Friday' to 'dayName'",
        25: "Breaking out of the switch-case block",
        26: "Case for day 7",
        27: "Assign 'Saturday' to 'dayName'",
        28: "Breaking out of the switch-case block",
        29: "Default case for invalid day",
        30: "Assign 'Invalid day' to 'dayName'",
        31: "Breaking out of the switch-case block",
        34: "Printing the result of the switch-case block"
    },
    C: {
        1: "Including the standard input-output header file",
        3: "Starting the main function, the entry point of the C program",
        4: "Declaring and initializing the variable 'day'",
        5: "Declaring a char pointer 'dayName' to store the day of the week",
        7: "Comment indicating the start of the switch-case block",
        8: "Switch statement to determine the day of the week based on 'day'",
        9: "Case for day 1",
        10: "Assign 'Sunday' to 'dayName'",
        11: "Breaking out of the switch-case block",
        12: "Case for day 2",
        13: "Assign 'Monday' to 'dayName'",
        14: "Breaking out of the switch-case block",
        15: "Case for day 3",
        16: "Assign 'Tuesday' to 'dayName'",
        17: "Breaking out of the switch-case block",
        18: "Case for day 4",
        19: "Assign 'Wednesday' to 'dayName'",
        20: "Breaking out of the switch-case block",
        21: "Case for day 5",
        22: "Assign 'Thursday' to 'dayName'",
        23: "Breaking out of the switch-case block",
        24: "Case for day 6",
        25: "Assign 'Friday' to 'dayName'",
        26: "Breaking out of the switch-case block",
        27: "Case for day 7",
        28: "Assign 'Saturday' to 'dayName'",
        29: "Breaking out of the switch-case block",
        30: "Default case for invalid day",
        31: "Assign 'Invalid day' to 'dayName'",
        32: "Breaking out of the switch-case block",
        35: "Printing the result of the switch-case block",
        36: "Returning 0 to indicate successful execution",
    },
    'C++': {
        1: "Including the input-output stream header file",
        2: "Using the standard namespace",
        4: "Starting the main function, the entry point of the C++ program",
        5: "Declaring and initializing the variable 'day'",
        6: "Declaring a string variable 'dayName' to store the day of the week",
        8: "Comment indicating the start of the switch-case block",
        9: "Switch statement to determine the day of the week based on 'day'",
        10: "Case for day 1",
        11: "Assign 'Sunday' to 'dayName'",
        12: "Breaking out of the switch-case block",
        13: "Case for day 2",
        14: "Assign 'Monday' to 'dayName'",
        15: "Breaking out of the switch-case block",
        16: "Case for day 3",
        17: "Assign 'Tuesday' to 'dayName'",
        18: "Breaking out of the switch-case block",
        19: "Case for day 4",
        20: "Assign 'Wednesday' to 'dayName'",
        21: "Breaking out of the switch-case block",
        22: "Case for day 5",
        23: "Assign 'Thursday' to 'dayName'",
        24: "Breaking out of the switch-case block",
        25: "Case for day 6",
        26: "Assign 'Friday' to 'dayName'",
        27: "Breaking out of the switch-case block",
        28: "Case for day 7",
        29: "Assign 'Saturday' to 'dayName'",
        30: "Breaking out of the switch-case block",
        31: "Default case for invalid day",
        32: "Assign 'Invalid day' to 'dayName'",
        33: "Breaking out of the switch-case block",
        36: "Printing the result of the switch-case block",
        37: "Returning 0 to indicate successful execution",
    }
};

export default explanations;
