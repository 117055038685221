const explanations = {
    Python: {
      2: "Initialize the string",
      3: "Iterate over each character using its index",
      4: "Print the character at the current index"
    },
    Java: {
      3: "Initialize the string",
      4: "Iterate over each character using its index",
      5: "Print the character at the current index"
    },
    C: {
      5: "Initialize the string",
      6: "Calculate the size of the string",
      7: "Iterate over each character using its index",
      8: "Print the character at the current index",
      10: "Return 0 to indicate successful execution"
    },
    "C++": {
      6: "Initialize the string",
      7: "Calculate the size of the string",
      8: "Iterate over each character using its index",
      9: "Print the character at the current index",
      11: "Return 0 to indicate successful execution"
    }
  };
  
  export default explanations;
  