import React, { useState } from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvasForGraph';
import AlgorithmDescription from '../AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';
import UpdateVariablesModal from './UpdateVariablesModal';

import { useGraph } from './useGraph';

const Graph = ({ language, subTopicId }) => {
  const maxVertices = 6; // For C Languuage Only

  const [modalVisible, setModalVisible] = useState(false);
  const [graphInput, setGraphInput] = useState([[10, 20], [10, 25], [20, 42], [25, 42], [25, 50]]);
  const [edgeDirection, setEdgeDirection] = useState([false, false, false, false, true]);
  const [firstEdges, setFirstEdges] = useState("10, 20");
  const [secondEdges, setSecondEdges] = useState("10, 25");
  const [thirdEdges, setThirdEdges] = useState("20, 42");
  const [fourthtEdges, setFourthtEdges] = useState("25, 42");
  const [fifthEdges, setFifthEdges] = useState("25, 50");

  const [graphInputForC, setGraphInputForC] = useState([[1, 2], [1, 3], [2, 4], [3, 4], [3, 5]]);
  const [firstEdgesForC, setFirstEdgesForC] = useState("1, 2");
  const [secondEdgesForC, setSecondEdgesForC] = useState("1, 3");
  const [thirdEdgesForC, setThirdEdgesForC] = useState("2, 4");
  const [fourthtEdgesForC, setFourthtEdgesForC] = useState("3, 4");
  const [fifthEdgesForC, setFifthEdgesForC] = useState("3, 5");

  const edges = [firstEdges, secondEdges, thirdEdges, fourthtEdges, fifthEdges];
  const edgesForC = [firstEdgesForC, secondEdgesForC, thirdEdgesForC, fourthtEdgesForC, fifthEdgesForC];

  const [inputError, setInputError] = useState("");

  const handleChange = (e, index) => {
    const input = e.target.value;
    if (index === 0) {
      setFirstEdges(input);
    } else if (index === 1) {
      setSecondEdges(input);
    } else if (index === 2) {
      setThirdEdges(input);
    } else if (index === 3) {
      setFourthtEdges(input);
    } else if (index === 4) {
      setFifthEdges(input);
    }

    // Parse the input string into an array of numbers
    const newEdge = input.split(",").map((item) => parseInt(item.trim()) || 0); // Default to 0 for invalid numbers

    // Update the graphInput state dynamically based on the index
    setGraphInput((prevGraphInput) => {
      const updatedGraphInput = [...prevGraphInput];
      updatedGraphInput[index] = newEdge;
      return updatedGraphInput;
    });
  };

  const handleChangeForC = (e, index) => {
    const input = e.target.value;

    // setInputError("");
    //   if (!(input >= 1 && input <=5)) {
    //     setInputError("The value must be between 1 and 5 as defined by MAX_VERTICES.");
    //     return;
    //   }

    if (index === 0) {
      setFirstEdgesForC(input);
    } else if (index === 1) {
      setSecondEdgesForC(input);
    } else if (index === 2) {
      setThirdEdgesForC(input);
    } else if (index === 3) {
      setFourthtEdgesForC(input);
    } else if (index === 4) {
      setFifthEdgesForC(input);
    }

    // Parse the input string into an array of numbers
    const newEdge = input.split(",").map((item) => parseInt(item.trim()) || 0); // Default to 0 for invalid numbers

    // Update the graphInput state dynamically based on the index
    setGraphInputForC((prevGraphInput) => {
      const updatedGraphInput = [...prevGraphInput];
      updatedGraphInput[index] = newEdge;
      return updatedGraphInput;
    });
  };
  

  const {
    highlightedLine,
    highlightedMultipleLines,
    focusedEndLine,
    callingLine,
    secondCallingLine,
    thirdCallingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    graphVariables,
    highlightedGraphVariables,
    variables,
    adjList,
    highlightedVariables,
    mapVariables,
    highlightedMapVariables,
    highlightedMapIndex,
    mapVariablesForC,
    highlightedMapVariablesForC,
    highlightedMapIndexForC,
    highlightedLineArrow,
    nodeVariables,
    highlightNodeAddress,
    highlightedNodeVariables,
    highlightDataPart,
    highlightNextAddressPart,
    isMuted,
    codeSamples,
    setDelay,
    setIsPaused,
    setIsMuted,
    updateVariables,
    runGraph
  } = useGraph(language, graphInput, edgeDirection, graphInputForC, maxVertices);

  const handleUpdateVariables = () => {
    if (language === "C") {
      updateVariables(graphInputForC, edgeDirection);
    } else {
      updateVariables(graphInput, edgeDirection);
    }
    setModalVisible(false);
  };

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6">
          <CodeDisplay
            codeLines={codeSamples || []}
            highlightedLine={highlightedLine}
            highlightedMultipleLines={highlightedMultipleLines}
            focusedEndLine={focusedEndLine}
            topic={"Graph"}
            callingLine={callingLine}
            secondCallingLine={secondCallingLine}
            thirdCallingLine={thirdCallingLine}
            subTopicId={subTopicId}
            language={language}
            onOpenModal={() => setModalVisible(true)}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <h3 className="section-title">Canvas</h3>
            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runGraph}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
            <VisualizationCanvas
              graphVariables={graphVariables}
              highlightedGraphVariables={highlightedGraphVariables}
              variables={variables}
              highlightedVariables={highlightedVariables}
              adjList={adjList}
              mapVariables={mapVariables}
              highlightedMapVariables={highlightedMapVariables}
              highlightedMapIndex={highlightedMapIndex}
              mapVariablesForC={mapVariablesForC}
              highlightedMapVariablesForC={highlightedMapVariablesForC}
              highlightedMapIndexForC={highlightedMapIndexForC}
              highlightedLineArrow={highlightedLineArrow}
              nodeVariables={nodeVariables}
              highlightNodeAddress={highlightNodeAddress}
              highlightedNodeVariables={highlightedNodeVariables}
              highlightDataPart={highlightDataPart}
              highlightNextAddressPart={highlightNextAddressPart}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
          <AlgorithmDescription />
      </div>
      <UpdateVariablesModal
        isVisible={modalVisible}
        language={language}
        edgeDirection={edgeDirection}
        edges={edges}
        edgesForC={edgesForC}
        handleChange={handleChange}
        handleChangeForC={handleChangeForC}
        setEdgeDirection={setEdgeDirection}
        handleSave={handleUpdateVariables}
        handleClose={() => setModalVisible(false)}
        inputError={inputError}
      />
    </div>
  );
};

export default Graph;
