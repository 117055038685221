import React from 'react';
import PropTypes from 'prop-types';

const UpdateVariablesModal = ({
  isVisible,
  language,
  edgeDirection,
  edges,
  edgesForC,
  handleChange,
  handleChangeForC,
  setEdgeDirection,
  handleSave,
  handleClose,
  inputError
}) => {
  if (!isVisible) return null;

  const renderEdgeInput = (label, index, value, onChangeHandler) => (
    <div className="col-md-6" style={{paddingTop: '20px'}}>
      <div className="row">
        <div className="col-md-7">
          <label>
            {label}:
            <input
              type="text"
              className="form-control custom-input"
              value={value}
              onChange={(e) => onChangeHandler(e, index)}
            />
          </label>
        </div>
        <div className="col-md-5">
          <label>&nbsp;</label>
          <select
            className="form-control custom-input custom-dropdown"
            value={edgeDirection[index]}
            onChange={(e) => {
              setEdgeDirection((prev) => {
                const updated = [...prev];
                updated[index] = e.target.value === 'true';
                return updated;
              });
            }}
          >
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        </div>
      </div>
    </div>
  );

  return (
    <div className="modal show d-block" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content modal-glass-avatar">
          <div className="modal-header">
            <h5 className="modal-title-update-variable">Update Variables</h5>
          </div>
          <div className="modal-body">
            <div className="row">
              {edges.map((edge, index) =>
                renderEdgeInput(
                  `Edge ${index + 1}`,
                  index,
                  language === 'C' ? edgesForC[index] : edge,
                  language === 'C' ? handleChangeForC : handleChange
                )
              )}
            </div>
            {inputError && inputError.length > 0 && (
              <div className="row">
                <div className="col-12 text-danger">{inputError}</div>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-dark" onClick={handleClose}>
              Cancel
            </button>
            <button type="button" className="btn btn-success" onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

UpdateVariablesModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  edgeDirection: PropTypes.arrayOf(PropTypes.bool).isRequired,
  edges: PropTypes.arrayOf(PropTypes.string).isRequired,
  edgesForC: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleChangeForC: PropTypes.func.isRequired,
  setEdgeDirection: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  inputError: PropTypes.string
};

export default UpdateVariablesModal;
