const explanations = {
    Python: {
        3: "Starting the Python script with the main block",
        4: "Declaring and initializing variable 'a'",
        5: "Declaring and initializing variable 'b'",
        7: "Printing the values of variables 'a' and 'b' using the format method"
    },
    Java: {
        3: "Starting the Main class definition",
        4: "Starting the main method, the entry point of the Java program",
        5: "Declaring and initializing variable 'a'",
        6: "Declaring and initializing variable 'b'",
        7: "Blank line for separation",
        8: "Printing the values of variables 'a' and 'b' using the System.out.println method",
        9: "Ending the main method",
        10: "Ending the Main class definition"
    },
    C: {
        3: "Including the standard input-output header file",
        4: "Blank line for separation",
        5: "Starting the main function, the entry point of the C program",
        6: "Declaring and initializing variable 'a'",
        7: "Declaring and initializing variable 'b'",
        8: "Blank line for separation",
        9: "Printing the values of variables 'a' and 'b' using the printf function",
        10: "Returning 0 to indicate successful execution",
        11: "Ending the main function"
    },
    'C++': {
        3: "Including the input-output stream header file",
        4: "Blank line for separation",
        5: "Starting the main function, the entry point of the C++ program",
        6: "Declaring and initializing variable 'a'",
        7: "Declaring and initializing variable 'b'",
        8: "Blank line for separation",
        9: "Printing the values of variables 'a' and 'b' using the std::cout stream",
        10: "Returning 0 to indicate successful execution",
        11: "Ending the main function"
    }
};

export default explanations;
