import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ContactUs = () => {
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactMessage, setContactMessage] = useState('');
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [contactLoading, setContactLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessageType, setModalMessageType] = useState('Success');
  const [modalMessage, setModalMessage] = useState('');

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleRatingChange = (value) => setRating(value);
  const handleFeedbackChange = (event) => setFeedback(event.target.value);
  const handleContactEmailChange = (event) => setContactEmail(event.target.value);
  const handleContactMessageChange = (event) => setContactMessage(event.target.value);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleFeedbackSubmit = async (event) => {
    event.preventDefault();
    setFeedbackLoading(true);

    await delay(500);

    if (!(rating > 1)) {
        setModalMessageType('Fail');
        setModalMessage('Give us some stars to unlock the feedback portal!');
        setFeedbackLoading(false);
        setShowModal(true);
        return;
    }

    const token = localStorage.getItem('userToken');
    if (!token) {
      setModalMessageType('Fail');
      setModalMessage('Log in to roast us—or give nice feedback, your call!');
      setFeedbackLoading(false);
      setShowModal(true);
      return;
    }

    const data = {
      rating,
      feedback_text: feedback,
    };

    try {
      const response = await axios.post(`${apiUrl}/api/feedback/`, data, {
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });

      setModalMessageType('Success');
      setModalMessage(response.data.message || 'Feedback submitted successfully.');
      setFeedback('');
      setRating(0);
    } catch (error) {
      setModalMessageType('Fail');
      setModalMessage('Try again!');
    } finally {
      setFeedbackLoading(false);
      setShowModal(true);
    }
  };

  const handleContactSubmit = async (event) => {
    event.preventDefault();
    setContactLoading(true);

    await delay(500);

    const data = {
        user_email: contactEmail,
        message: contactMessage,
    };

    try {
      const response = await axios.post(`${apiUrl}/api/contact-us/`, data, {
        headers: { 'Content-Type': 'application/json' },
      });

      setModalMessageType('Success');
      setModalMessage(response.data.message || 'Message sent successfully.');
      setContactEmail('');
      setContactMessage('');
    } catch (error) {
      setModalMessageType('Fail');
      setModalMessage('Try again!');
    } finally {
      setContactLoading(false);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalMessage('');
  };

  useEffect(() => {
    document.title = `Contact Us | Coding Canvas`;
  }, []);

  return (
    <main className="container">
      <section className="text-center mb-5">
        <h1 className="mb-3">Get in Touch</h1>
        <p>We’d love to hear from you! Reach out for support, feedback, or inquiries.</p>
      </section>

      <div className="row justify-content-center">
        {/* Contact Form */}
        <div className="col-lg-6 mb-4 d-flex">
          <div className="card shadow-lg border-2 rounded-3 w-100 d-flex flex-column">
            <div className="card-body p-4 d-flex flex-column">
              <h2 className="card-title text-center mb-4">Contact Us</h2>
              <form onSubmit={handleContactSubmit} className="flex-grow-1 d-flex flex-column">
                <div className="mb-3">
                  <label htmlFor="contactEmail" className="form-label fw-bold">Email</label>
                  <input
                    type="email"
                    style={{ backgroundColor: 'white' }}
                    className="form-control rounded-pill shadow-sm"
                    id="contactEmail"
                    placeholder="Enter your email"
                    value={contactEmail}
                    onChange={handleContactEmailChange}
                    required
                  />
                </div>
                <div className="mb-3 flex-grow-1">
                  <label htmlFor="contactMessage" className="form-label fw-bold">Message</label>
                  <textarea
                    style={{ backgroundColor: 'white', color: 'black' }}
                    className="form-control rounded-3 shadow-sm"
                    id="contactMessage"
                    rows="4"
                    placeholder="Type your message here..."
                    value={contactMessage}
                    onChange={handleContactMessageChange}
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-dark w-100 rounded-pill shadow-sm mt-auto"
                  disabled={contactLoading}
                >
                  {contactLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Send Message'}
                </button>
              </form>
            </div>
          </div>
        </div>

        {/* Feedback Form */}
        <div className="col-lg-6 mb-4 d-flex">
          <div className="card shadow-lg border-2 rounded-3 w-100 d-flex flex-column">
            <div className="card-body p-4 d-flex flex-column">
              <h2 className="card-title text-center mb-4">Your Feedback</h2>
              <p className="text-center text-muted">Rate your experience with us:</p>
              <div className="d-flex justify-content-center mb-4">
                {[1, 2, 3, 4, 5].map((star) => (
                  <button
                    key={star}
                    type="button"
                    onClick={() => handleRatingChange(star)}
                    className={`btn ${rating >= star ? 'btn-dark' : 'btn-outline-dark'} mx-1 rounded-circle`}
                    style={{ width: '40px', height: '40px' }}
                  >
                    {star}
                  </button>
                ))}
              </div>
              <form onSubmit={handleFeedbackSubmit} className="flex-grow-1 d-flex flex-column">
                <div className="mb-3">
                  <textarea
                    style={{ backgroundColor: 'white', color: 'black' }}
                    className="form-control rounded-3 shadow-sm"
                    placeholder="Leave additional feedback here..."
                    value={feedback}
                    onChange={handleFeedbackChange}
                    rows="4"
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-dark w-100 rounded-pill shadow-sm mt-auto"
                  disabled={feedbackLoading}
                >
                  {feedbackLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Submit Feedback'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      {showModal && (
        <div className="modal show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content modal-glass-avatar">
              <div className="modal-body text-center">
                {modalMessageType === 'Success' ? (
                  <i className="fas fa-check-circle text-success fa-3x mb-3"></i>
                ) : (
                  <i className="fas fa-times-circle text-danger fa-3x mb-3"></i>
                )}
                <p>{modalMessage}</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default ContactUs;
