const codeSamples = {
    Python: [
        "def insertion_sort(list):",
        "    for i in range(1, len(list)):",
        "        key = list[i]",
        "        j = i - 1",
        "        while j >= 0 and key < list[j]:",
        "            list[j + 1] = list[j]",
        "            j -= 1",
        "        list[j + 1] = key",
        "    return list",
        "  ",
        "if __name__ == '__main__':",
        "    list = [64, 25, 12, 22, 11]",
        "    sorted_list = insertion_sort(list)",
        "    print(f'Sorted list: {sorted_list}')"
    ],
    Java: [
        "public class InsertionSort {",
        "    void insertionSort(int list[]) {",
        "        int n = list.length;",
        "        for (int i = 1; i < n; ++i) {",
        "            int key = list[i];",
        "            int j = i - 1;",
        "            while (j >= 0 && list[j] > key) {",
        "                list[j + 1] = list[j];",
        "                j = j - 1;",
        "            }",
        "            list[j + 1] = key;",
        "        }",
        "        return list;",
        "    }",
        "  ",
        "    public static void main(String args[]) {",
        "        InsertionSort ob = new InsertionSort();",
        "        int list[] = {64, 25, 12, 22, 11};",
        "        int sortedList[] = ob.insertionSort(list);",
        "        System.out.println('Sorted list:');",
        "        for (int itr = 0; itr < sortedList.length; itr++)",
        "            System.out.print(sortedList[itr] + ' ');",
        "    }",
        "}"
    ],
    C: [
        "#include <stdio.h>",
        "void insertionSort(int list[], int n) {",
        "    int i, key, j;",
        "    for (i = 1; i < n; i++) {",
        "        key = list[i];",
        "        j = i - 1;",
        "        while (j >= 0 && list[j] > key) {",
        "            list[j + 1] = list[j];",
        "            j = j - 1;",
        "        }",
        "        list[j + 1] = key;",
        "    }",
        "    return list;",
        "}",
        "  ",
        "int main() {",
        "    int list[] = {64, 25, 12, 22, 11};",
        "    int n = sizeof(list)/sizeof(list[0]);",
        "    int sortedList[] = insertionSort(list, n);",
        "    printf('Sorted list: \\n');",
        "    for (int itr=0; itr < n; itr++)",
        "        printf('%d ', sortedList[itr]);",
        "    return 0;",
        "}"
    ],
    "C++": [
        "#include <iostream>",
        "using namespace std;",
        "void insertionSort(int list[], int n) {",
        "    int i, key, j;",
        "    for (i = 1; i < n; i++) {",
        "        key = list[i];",
        "        j = i - 1;",
        "        while (j >= 0 && list[j] > key) {",
        "            list[j + 1] = list[j];",
        "            j = j - 1;",
        "        }",
        "        list[j + 1] = key;",
        "    }",
        "    return list;",
        "}",
        "  ",
        "int main() {",
        "    int list[] = {64, 25, 12, 22, 11};",
        "    int n = sizeof(list)/sizeof(list[0]);",
        "    int sortedList[] = insertionSort(list, n);",
        "    cout << 'Sorted list: ';",
        "    for (int itr = 0; itr < n; itr++)",
        "        cout << sortedList[itr] << ' ';",
        "    return 0;",
        "}"
    ]
  };
  
  export default codeSamples;
  