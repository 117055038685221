const codeSamples = {
    Python: [
      "class Node:",
      "    def __init__(self, data):",
      "        self.data = data",
      "        self.left = None",
      "        self.right = None",
      "  ",
      "class BinarySearchTree:",
      "    def __init__(self):",
      "        self.root = None",
      "  ",
      "    def add(self, data):",
      "        node = Node(data)",
      "        if self.root is None:",
      "            self.root = node",
      "        else:",
      "            current = self.root",
      "            while True:",
      "                parent = current",
      "                if data < current.data:",
      "                    current = current.left",
      "                    if current is None:",
      "                        parent.left = node",
      "                        return",
      "                else:",
      "                    current = current.right",
      "                    if current is None:",
      "                        parent.right = node",
      "                        return",
      "  ",
      "if __name__ == '__main__':",
      "    binarySearchTree = BinarySearchTree()",
      "    binarySearchTree.add(20)",
      "    binarySearchTree.add(15)",
      "    binarySearchTree.add(30)",
      "    binarySearchTree.add(10)",
      "    binarySearchTree.add(17)",
      "    binarySearchTree.add(25)",
      "    binarySearchTree.add(40)",
    ],
    Java: [
      "class Node {",
      "    int data;",
      "    Node left, right;",
      "  ",
      "    Node(int data) {",
      "        this.data = data;",
      "        left = right = null;",
      "    }",
      "}",
      "  ",
      "class BinarySearchTree {",
      "    Node root;",
      "  ",
      "    BinarySearchTree() {",
      "        root = null;",
      "    }",
      "  ",
      "    void add(int data) {",
      "        Node node = new Node(data);",
      "        if (root == null) {",
      "            root = node;",
      "        } else {",
      "            Node current = root;",
      "            while (true) {",
      "                Node parent = current;",
      "                if (data < current.data) {",
      "                    current = current.left;",
      "                    if (current == null) {",
      "                        parent.left = node;",
      "                        return;",
      "                    }",
      "                } else {",
      "                    current = current.right;",
      "                    if (current == null) {",
      "                        parent.right = node;",
      "                        return;",
      "                    }",
      "                }",
      "            }",
      "        }",
      "    }",
      "}",
      "  ",
      "public class Main {",
      "    public static void main(String[] args) {",
      "        BinarySearchTree binarySearchTree = new BinarySearchTree();",
      "        binarySearchTree.add(20);",
      "        binarySearchTree.add(15);",
      "        binarySearchTree.add(30);",
      "        binarySearchTree.add(10);",
      "        binarySearchTree.add(17);",
      "        binarySearchTree.add(25);",
      "        binarySearchTree.add(40);",
      "    }",
      "}",
    ],
    "C++": [
      "#include <iostream>",
      "using namespace std;",
      "  ",
      "class Node {",
      "public:",
      "    int data;",
      "    Node* left, *right;",
      "    Node(int data) {",
      "        this->data = data;",
      "        left = right = nullptr;",
      "    }",
      "};",
      "  ",
      "class BinarySearchTree {",
      "public:",
      "    Node* root;",
      "    BinarySearchTree() {",
      "        root = nullptr;",
      "    }",
      "  ",
      "    void add(int data) {",
      "        Node* node = new Node(data);",
      "        if (root == nullptr) {",
      "            root = node;",
      "        } else {",
      "            Node* current = root;",
      "            while (true) {",
      "                Node* parent = current;",
      "                if (data < current->data) {",
      "                    current = current->left;",
      "                    if (current == nullptr) {",
      "                        parent->left = node;",
      "                        return;",
      "                    }",
      "                } else {",
      "                    current = current->right;",
      "                    if (current == nullptr) {",
      "                        parent->right = node;",
      "                        return;",
      "                    }",
      "                }",
      "            }",
      "        }",
      "    }",
      "};",
      "  ",
      "int main() {",
      "    BinarySearchTree binarySearchTree;",
      "    binarySearchTree.add(20);",
      "    binarySearchTree.add(15);",
      "    binarySearchTree.add(30);",
      "    binarySearchTree.add(10);",
      "    binarySearchTree.add(17);",
      "    binarySearchTree.add(25);",
      "    binarySearchTree.add(40);",
      "    return 0;",
      "}"
    ],
    C: [
      "#include <stdio.h>",
      "#include <stdlib.h>",
      "  ",
      "struct Node {",
      "    int data;",
      "    struct Node* left;",
      "    struct Node* right;",
      "};",
      "  ",
      "struct Node* createNode(int data) {",
      "    struct Node* node = (struct Node*)malloc(sizeof(struct Node));",
      "    node->data = data;",
      "    node->left = NULL;",
      "    node->right = NULL;",
      "    return node;",
      "}",
      "  ",
      "void addNode(struct Node** root, int data) {",
      "    struct Node* newNode = createNode(data);",
      "    if (*root == NULL) {",
      "        *root = newNode;",
      "    } else {",
      "        struct Node* current = *root;",
      "        while (1) {",
      "            struct Node* parent = current;",
      "            if (data < current->data) {",
      "                current = current->left;",
      "                if (current == NULL) {",
      "                    parent->left = newNode;",
      "                    return;",
      "                }",
      "            } else {",
      "                current = current->right;",
      "                if (current == NULL) {",
      "                    parent->right = newNode;",
      "                    return;",
      "                }",
      "            }",
      "        }",
      "    }",
      "}",
      "  ",
      "int main() {",
      "    struct Node* root = NULL;",
      "    addNode(&root, 20);",
      "    addNode(&root, 15);",
      "    addNode(&root, 30);",
      "    addNode(&root, 10);",
      "    addNode(&root, 17);",
      "    addNode(&root, 25);",
      "    addNode(&root, 40);",
      "    return 0;",
      "}"
    ]
  };
  
  export default codeSamples;
  