const codeSamples = {
    Java: [
        "public class EnumExample {",
        "    public enum WorkDay {",
        "        MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY",
        "    }",
        "  ",
        "    public static void main(String[] args) {",
        "        String input = \"THURSDAY\";",
        "  ",
        "        try {",
        "            WorkDay today = WorkDay.valueOf(input);",
        "            if (today == WorkDay.FRIDAY) {",
        "                System.out.println(\"Thank God! It Is Friday.\");",
        "            } else {",
        "                System.out.println(\"Time to work!\");",
        "            }",
        "        } catch (IllegalArgumentException e) {",
        "            System.out.println(\"Invalid day entered. Please enter a valid day of the week.\");",
        "        }",
        "  ",
        "    }",
        "}"
    ],
    C: [
        "#include <stdio.h>",
        "  ",
        "enum WorkDay {",
        "    MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY",
        "};",
        "  ",
        "int main() {",
        "    enum WorkDay today = THURSDAY;",
        "  ",
        "    if (today == FRIDAY) {",
        "        printf(\"Thank God! It Is Friday.\");",
        "    } else {",
        "        printf(\"Time to work!\");",
        "    }",
        "  ",
        "    return 0;",
        "}"
    ],
    'C++': [
        "#include <iostream>",
        "  ",
        "enum WorkDay {",
        "    MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY",
        "};",
        "  ",
        "int main() {",
        "    WorkDay today = THURSDAY;",
        "  ",
        "    if (today == FRIDAY) {",
        "        std::cout << \"Thank God! It Is Friday.\" << std::endl;",
        "    } else {",
        "        std::cout << \"Time to work!\" << std::endl;",
        "    }",
        "  ",
        "    return 0;",
        "}"
    ]
};

export default codeSamples;
