const codeSamples = {
    Java: [
        "interface Animal {",
        "    void sound();",
        "}",
        "  ",
        "class Dog implements Animal {",
        "    private String favoriteToy;",
        "  ",
        "    public Dog() {",
        "        this.favoriteToy = \"Teddy\";",
        "    }",
        "  ",
        "    public void sound() {",
        "        System.out.println(\"Woof! My favorite toy is: \" + favoriteToy);",
        "    }",
        "}",
        "  ",
        "public class Main {",
        "    public static void main(String[] args) {",
        "        Dog myDog = new Dog();",
        "        myDog.sound();",
        "    }",
        "}"
    ]
};

export default codeSamples;
