import React, { useState } from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvas';
import AlgorithmDescription from '../AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';
import UpdateVariablesModal from './UpdateVariablesModal';

import { useLongTypes } from './useLongTypes';

const LongTypes = ({ language, subTopicId }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [minSignedLongValue, setMinSignedLongValue] = useState(-9223372036854775808n);
  const [maxSignedLongValue, setMaxSignedLongValue] = useState(9223372036854775807n);
  const [maxUnsignedLongValue, setMaxUnsignedLongValue] = useState(18446744073709551615n);

  const {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    highlightedArrayVariables,
    isMuted,
    codeSamples,
    setDelay,
    setIsPaused,
    setIsMuted,
    runLongTypes
  } = useLongTypes(
    language,
    minSignedLongValue,
    maxSignedLongValue,
    maxUnsignedLongValue
  );

  const handleUpdateVariables = () => {
    setModalVisible(false);
  };

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6">
          <CodeDisplay
            codeLines={codeSamples || []}
            highlightedLine={highlightedLine}
            focusedEndLine={focusedEndLine}
            topic={"Long Types Example"}
            callingLine={callingLine}
            subTopicId={subTopicId}
            language={language}
            onOpenModal={() => setModalVisible(true)}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <h3 className="section-title">Canvas</h3>
            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runLongTypes}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
            <VisualizationCanvas
              variables={variables}
              arrayVariables={arrayVariables}
              highlightedVariables={highlightedVariables}
              highlightedArrayVariables={highlightedArrayVariables}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
          <AlgorithmDescription />
      </div>
      <UpdateVariablesModal
        isVisible={modalVisible}
        onClose={() => setModalVisible(false)}
        minSignedLongValue={minSignedLongValue}
        setMinSignedLongValue={setMinSignedLongValue}
        maxSignedLongValue={maxSignedLongValue}
        setMaxSignedLongValue={setMaxSignedLongValue}
        maxUnsignedLongValue={maxUnsignedLongValue}
        setMaxUnsignedLongValue={setMaxUnsignedLongValue}
        language={language}
      />
    </div>
  );
};

export default LongTypes;
