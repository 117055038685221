const explanations = {
  Python: {
    1: "Define the BinaryTree class to represent the binary tree structure.",
    2: "Initialize the BinaryTree class with a constructor method __init__.",
    3: "Create an attribute 'root' to represent the root of the tree, initially set to None.",
    5: "Executing the method preorder_traversal to perform a preorder traversal of the binary tree.",
    6: "Set the current node to the root of the binary tree.",
    7: "Checking if the current node (root) is None (i.e., the tree is empty).",
    8: "Tree is empty, returning from the method without doing anything.",
    10: "Create a stack to keep track of nodes during the traversal, starting with the root node.",
    11: "Starting a loop that continues as long as the stack is not empty.",
    12: "Pop a node from the stack and set it as the current node.",
    13: "Print the data of the current node.",
    14: "Checking if the current node has a right child.",
    15: "Right child exists, push it onto the stack.",
    16: "Checking if the current node has a left child.",
    17: "Left child exists, push it onto the stack.",
    20: "Main block to test the BinaryTree class functionality.",
    21: "Creating an instance of the BinaryTree class.",
    23: "Calling the preorder_traversal method to traverse the tree and print its elements in preorder."
  },
  Java: {
    1: "Import the Stack class from the java.util package to use for storing nodes during traversal.",
    3: "Define the BinaryTree class to represent the binary tree structure.",
    4: "Declare a 'root' attribute to represent the root of the tree, initially set to null.",
    6: "Define the constructor BinaryTree() to initialize the tree.",
    7: "Set the 'root' of the tree to null, indicating an empty tree.",
    10: "Executing the preorderTraversal method to perform a pre-order traversal of the binary tree.",
    11: "Set the current node to the root of the binary tree.",
    12: "Checking if the current node (root) is null (i.e., the tree is empty).",
    13: "Tree is empty, returning from the method without further processing.",
    16: "Creating an empty stack to keep track of nodes during traversal.",
    17: "Pushing the root node onto the stack to start the traversal.",
    18: "Starting a loop that continues as long as the stack is not empty.",
    19: "Pop a node from the stack and set it as the current node.",
    20: "Print the data of the current node.",
    21: "Checking if the current node has a right child.",
    22: "Right child exists, push it onto the stack.",
    24: "Checking if the current node has a left child.",
    25: "Left child exists, push it onto the stack.",
    31: "Define the Main class to test the BinaryTree class functionality.",
    32: "Main method to execute the program.",
    33: "Create an instance of the BinaryTree class.",
    35: "Calling the preorderTraversal method to traverse the tree and print its elements in pre-order."
  },
  C: {
    1: "Include the stdio.h header for input and output functions like printf.",
    2: "Include the stdlib.h header for memory allocation functions.",
    4: "Define the Node struct to represent each element of the binary tree.",
    5: "Declare the data attribute to store the value of the node.",
    6: "Declare a pointer to represent the left child of the node.",
    7: "Declare a pointer to represent the right child of the node.",
    10: "Executing the preorderTraversal function to perform a pre-order traversal of the binary tree.",
    11: "Checking if the root is NULL to handle an empty tree.",
    12: "Tree is empty, returning from the function without further processing.",
    15: "Declare an array to represent the stack for holding nodes during traversal.",
    16: "Initialize the top of the stack to -1 (indicating an empty stack).",
    18: "Push the root node onto the stack to start the traversal.",
    20: "Starting a while loop that continues as long as the stack is not empty.",
    21: "Pop the top node from the stack and set it as the current node.",
    22: "Print the data of the current node as part of the pre-order traversal.",
    24: "Checking if the right child of the current node exists.",
    25: "Right child exists, push it onto the stack.",
    28: "Checking if the left child of the current node exists.",
    29: "Left child exists, push it onto the stack.",
    34: "Main function to test the preorderTraversal function.",
    35: "Initialize the root of the tree as NULL (indicating an empty tree).",
    36: "Calling the preorderTraversal function to perform the traversal starting from the root.",
    37: "Returning 0 to indicate successful program termination."
  },
  'C++': {
    1: "Include the standard input-output stream library.",
    2: "Include the stack library to use the stack data structure for storing nodes during traversal.",
    3: "Using the standard namespace.",
    5: "Define the BinaryTree class to represent the structure of the binary tree.",
    6: "Use public access specifier to make the following members accessible from outside the class.",
    7: "Declare a pointer to the root node.",
    9: "Executing the constructor to initialize the binary tree.",
    10: "Set the root to null pointer initially.",
    13: "Executing the preorderTraversal method to perform a pre-order traversal of the binary tree.",
    14: "Set the current node to the root of the binary tree.",
    15: "Checking if the current node (root) is null pointer (i.e., the tree is empty).",
    16: "Tree is empty, returning from the method without further processing.",
    19: "Creating an empty stack to keep track of nodes during traversal.",
    20: "Pushing the root node onto the stack to start the traversal.",
    21: "Starting a loop that continues as long as the stack is not empty.",
    22: "Pop a node from the stack and set it as the current node.",
    23: "Removing the top node from the stack to process it.",
    24: "Print the data of the current node.",
    25: "Checking if the current node has a right child.",
    26: "Right child exists, push it onto the stack.",
    28: "Checking if the current node has a left child.",
    29: "Left child exists, push it onto the stack.",
    35: "Main method to test the BinaryTree class functionality.",
    36: "Create an instance of the BinaryTree class.",
    38: "Calling the preorderTraversal method to traverse the tree and print its elements in pre-order.",
    39: "Returning 0 to indicate successful execution of the program.",
  },
};
  
export default explanations;
  