const explanations = {
    C: {
        1: "Including standard I/O header for C",
        3: "Defining a structure Student using typedef",
        4: "Declaring a character array to store the name",
        5: "Declaring an integer for the age",
        6: "Declaring an integer for the grade",
        8: "Starting main function",
        9: "Creating a variable of Structure type 'Student' named 'student1'",
        11: "Safely copies the string 'Alice' into the name field of student1",
        12: "Setting age in the structure",
        14: "Printing student name from the structure",
        15: "Printing student age from the structure",
        17: "Returning 0 to indicate successful execution"
    },
    'C++': {
        1: "Including the iostream library for input-output operations",
        2: "Using the standard namespace",
        4: "Defining a structure named 'Person'",
        5: "Declaring a string variable 'name' to store the person's name",
        6: "Declaring an integer variable 'age' to store the person's age",
        8: "Defining the member function 'displayInfo' to display the person's information",
        9: "Printing the person's name",
        10: "Printing the person's age",
        13: "Defining the member function 'setDetails' to set the person's details",
        14: "Assigning the provided name to the structure's name variable",
        15: "Assigning the provided age to the structure's age variable",
        19: "Starting the main function",
        20: "Creating a variable of Structure type 'Person' named 'person1'",
        21: "Setting the details of 'person1' using the 'setDetails' function",
        22: "Displaying the information of 'person1' using the 'displayInfo' function",
        24: "Returning 0 to indicate successful execution"
    }
};

export default explanations;
