const codeSamples = {
    Python: [
        "if __name__ == '__main__':",
        "    x = 12",
        "    y = 5",
        " ",
        "    addition = x + y",
        "    print(f'Addition: {x} + {y} = {addition}')",
        " ",
        "    subtraction = x - y",
        "    print(f'Subtraction: {x} - {y} = {subtraction}')",
        " ",
        "    multiplication = x * y",
        "    print(f'Multiplication: {x} * {y} = {multiplication}')",
        " ",
        "    division = x / y if y != 0 else 'Error: Division by zero is undefined.'",
        "    print(f'Division: {x} / {y} = {division}')",
        " ",
        "    modulus = x % y",
        "    print(f'Modulus: {x} % {y} = {modulus}')",
        " ",
        "    exponentiation = x ** y",
        "    print(f'Exponentiation: {x} ** {y} = {exponentiation}')",
        " ",
        "    floor_division = x // y if y != 0 else 'Error: Division by zero is undefined.'",
        "    print(f'Floor Division: {x} // {y} = {floor_division}')"
    ],
    Java: [
        "public class Main {",
        "    public static void main(String[] args) {",
        "        int x = 12;",
        "        int y = 5;",
        " ",
        "        int addition = x + y;",
        "        System.out.println(\"Addition: \" + addition);",
        " ",
        "        int subtraction = x - y;",
        "        System.out.println(\"Subtraction: \" + subtraction);",
        " ",
        "        int multiplication = x * y;",
        "        System.out.println(\"Multiplication: \" + multiplication);",
        " ",
        "        String division = (y != 0) ? String.valueOf((float) x / y) : \"Error: Division by zero is undefined.\";",
        "        System.out.println(\"Division: \" + division);",
        " ",
        "        int modulus = x % y;",
        "        System.out.println(\"Modulus: \" + modulus);",
        " ",
        "        double exponentiation = Math.pow(x, y);",
        "        System.out.println(\"Exponentiation: \" + exponentiation);",
        " ",
        "        String floorDivision = (y != 0) ? String.valueOf(x / y) : \"Error: Division by zero is undefined.\";",
        "        System.out.println(\"Floor Division: \" + floorDivision);",
        "    }",
        "}"
    ],
    C: [
        "#include <stdio.h>",
        "#include <math.h>",
        " ",
        "int main() {",
        "    int x = 12;",
        "    int y = 5;",
        " ",
        "    int addition = x + y;",
        "    printf(\"Addition: %d\\n\", addition);",
        " ",
        "    int subtraction = x - y;",
        "    printf(\"Subtraction: %d\\n\", subtraction);",
        " ",
        "    int multiplication = x * y;",
        "    printf(\"Multiplication: %d\\n\", multiplication);",
        " ",
        "    float division;",
        "    if (y != 0) {",
        "        division = (float)x / y;",
        "        printf(\"Division: %.2f\\n\", division);",
        "    } else {",
        "        printf(\"Err: Division by zero is undefined.\\n\");",
        "    }",
        " ",
        "    int modulus = x % y;",
        "    printf(\"Modulus: %d\\n\", modulus);",
        " ",
        "    double exponentiation = pow(x, y);",
        "    printf(\"Exponentiation: %f\\n\", exponentiation);",
        " ",
        "    int floorDivision;",
        "    if (y != 0) {",
        "        floorDivision = x / y;",
        "        printf(\"Floor Division: %d\\n\", floorDivision);",
        "    } else {",
        "        printf(\"Err: Division by zero is undefined.\\n\");",
        "    }",
        " ",
        "    return 0;",
        "}"
    ],
    'C++': [
        "#include <iostream>",
        "#include <cmath>",
        " ",
        "int main() {",
        "    int x = 12;",
        "    int y = 5;",
        " ",
        "    int addition = x + y;",
        "    std::cout << \"Addition: \" << addition << std::endl;",
        " ",
        "    int subtraction = x - y;",
        "    std::cout << \"Subtraction: \" << subtraction << std::endl;",
        " ",
        "    int multiplication = x * y;",
        "    std::cout << \"Multiplication: \" << multiplication << std::endl;",
        " ",
        "    std::string division = (y != 0) ? std::to_string((float)x / y) : \"Error: Division by zero is undefined.\";",
        "    std::cout << \"Division: \" << division << std::endl;",
        " ",
        "    int modulus = x % y;",
        "    std::cout << \"Modulus: \" << modulus << std::endl;",
        " ",
        "    double exponentiation = pow(x, y);",
        "    std::cout << \"Exponentiation: \" << exponentiation << std::endl;",
        " ",
        "    std::string floorDivision = (y != 0) ? std::to_string(x / y) : \"Error: Division by zero is undefined.\";",
        "    std::cout << \"Floor Division: \" << floorDivision << std::endl;",
        " ",
        "    return 0;",
        "}"
    ]
};

export default codeSamples;
