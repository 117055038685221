const explanations = {
  Python: {
    2: "Initialize the array",
    3: "Iterate over each element using its index",
    4: "Print the element at the current index"
  },
  Java: {
    3: "Initialize the array",
    5: "Iterate over each element using its index",
    6: "Print the element at the current index"
  },
  C: {
    4: "Initialize the array",
    5: "Calculate the size of the array",
    7: "Iterate over each element using its index",
    8: "Print the element at the current index",
    10: "Return 0 to indicate successful execution"
  },
  "C++": {
    5: "Initialize the array",
    6: "Calculate the size of the array",
    7: "Iterate over each element using its index",
    8: "Print the element at the current index",
    10: "Return 0 to indicate successful execution"
  }
};

export default explanations;
