const codeSamples = {
    Java: [
      "public class DoWhileLoop {",
      "    public static void main(String[] args) {",
      "        int count = 0;",
      "        int maxCount = 5;",
      "  ",
      "        do {",
      "            System.out.println('Count is: ' + count);",
      "            count++;",
      "        } while (count < maxCount);",
      "    }",
      "}"
    ],
    C: [
      "#include <stdio.h>",
      "int main() {",
      "    int count = 0;",
      "    int maxCount = 5;",
      "  ",
      "    do {",
      "        printf('Count is: %d\\n', count);",
      "        count++;",
      "    } while (count < maxCount);",
      "  ",
      "    return 0;",
      "}"
    ],
    "C++": [
      "#include <iostream>",
      "using namespace std;",
      "int main() {",
      "    int count = 0;",
      "    int maxCount = 5;",
      "  ",
      "    do {",
      "        cout << 'Count is: ' << count << endl;",
      "        count++;",
      "    } while (count < maxCount);",
      "  ",
      "    return 0;",
      "}"
    ]
  };
  
  export default codeSamples;
  