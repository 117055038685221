const explanations = {
  Python: {
    1: "Defining the Node class that represents a single element in the linked list.",
    2: "Executing the constructor method __init__ to initialize the node with the provided data.",
    3: "Assigning the passed data to the node's data attribute.",
    4: "Setting the next attribute to None, indicating that the node does not point to any other node yet.",
    6: "Defining the LinkedList class that manages the linked list.",
    7: "Executing the constructor method __init__ of the LinkedList class.",
    8: "Initializing the linked list with an empty head (i.e., the list is initially empty).",
    10: "Entering the update method to change a node's data in the list.",
    11: "Checking if the list is empty by verifying if the head node is None.",
    12: "Head is None, print a message indicating that the list is empty.",
    13: "Exiting from the method.",
    15: "Assigning the head of the list to a temp varaible to start traversing from the first node.",
    16: "Traversing through the list till temp become None.",
    17: "Checking if the current node's data matches oldData, which needs to be replaced with newData.",
    18: "The condition is met, proceeding to update the data.",
    19: "Printing a message indicating that the node is updated.",
    20: "Exiting from the method.",
    21: "Condition not satisfied. Moving to the next node in the list by setting temp to temp.next.",
    22: "Printing a message indicating that the node is not found in the list.",
    24: "Main block to test the functionality of the LinkedList class.",
    25: "Creating an instance of LinkedList named list.",
    27: "Calling the update method on the list to replace an old value of a node with a specified new value."
  },
  Java: {
    1: "Defining the Node class that represents a single element in the linked list.",
    2: "Declaring an integer data variable to hold the value for the node.",
    3: "Declaring a next variable that will point to the next node in the linked list.",
    5: "Executing the Node constructor to initialize the node with data.",
    6: "Setting the node's data to the passed value.",
    7: "Setting the next pointer to null since it's a new node without a next node.",
    11: "Defining the LinkedList class that will manage the linked list.",
    12: "Declaring a head variable to keep track of the first node in the linked list.",
    14: "Entering the update method to change a node's data in the list.",
    15: "Checking if the list is empty by verifying if the head node is null.",
    16: "Head is null, print a message indicating that the list is empty.",
    17: "Exiting from the method.",
    20: "Assigning the head of the list to a temp varaible to start traversing from the first node.",
    21: "Traversing through the list till temp become null.",
    22: "Checking if the current node's data matches oldData, which needs to be replaced with newData.",
    23: "The condition is met, proceeding to update the data.",
    24: "Printing a message indicating that the node is updated.",
    25: "Exiting from the method.",
    27: "Condition not satisfied. Moving to the next node in the list by setting temp to temp.next.",
    29: "Printing a message indicating that the node is not found in the list.",
    32: "Main block to test the functionality of the LinkedList class.",
    33: "Creating an instance of LinkedList named list.",
    35: "Calling the update method on the list to replace an old value of a node with a specified new value."
  },
  C: {
    1: "Including the standard input-output library.",
    2: "Including the standard library for memory allocation.",
    4: "Defining the Node structure to represent an element in the linked list.",
    5: "Declaring an integer data variable to hold the value of the node.",
    6: "Declaring a pointer to the next node in the list.",
    9: "Defining a global head pointer to track the start of the linked list.",
    11: "Entering the update function to change a node's data in the list.",
    12: "Checking if the list is empty by verifying if the head node is NULL.",
    13: "Head is NULL, print a message indicating that the list is empty.",
    14: "Exiting from the method.",
    17: "Assigning the head of the list to a temp varaible to start traversing from the first node.",
    18: "Traversing through the list till temp become NULL.",
    19: "Checking if the current node's data matches oldData, which needs to be replaced with newData.",
    20: "The condition is met, proceeding to update the data.",
    21: "Printing a message indicating that the node is updated.",
    22: "Exiting from the method.",
    24: "Condition not satisfied. Moving to the next node by setting temp to temp of next.",
    26: "Printing a message indicating that the node is not found in the list.",
    29: "Main function to test the functionality of the LinkedList.",
    30: "Calling the update function to replace an old value of a node with a specified new value.",
    32: "Returning 0 to indicate successful execution.",
  },
  "C++": {
    1: "Including the iostream library for input-output operations.",
    2: "Using the standard namespace.",
    4: "Defining the Node class to represent an element in the linked list.",
    6: "Declaring public the data variable to hold the node's value.",
    7: "Declaring a public pointer to the next node in the linked list.",
    9: "Executing the Node constructor to initialize the node with data.",
    10: "Setting the node's data to the passed value.",
    11: "Setting the next pointer to nullptr since it's a new node.",
    15: "Defining the LinkedList class to manage the linked list.",
    17: "Declaring a head pointer to track the start of the linked list.",
    19: "Executing the constructor method of the class LinkedList.",
    20: "Initializing the head pointer to nullptr (i.e., the list is initially empty).",
    23: "Entering the update method to change a node's data in the list.",
    24: "Checking if the list is empty by verifying if the head node is null pointer.",
    25: "Head is null pointer, print a message indicating that the list is empty.",
    26: "Exiting from the method.",
    29: "Assigning the head of the list to a temp varaible to start traversing from the first node.",
    30: "Traversing through the list till temp become null pointer.",
    31: "Checking if the current node's data matches oldData, which needs to be replaced with newData.",
    32: "The condition is met, proceeding to update the data.",
    33: "Printing a message indicating that the node is updated.",
    34: "Exiting from the method.",
    36: "Condition not satisfied. Moving to the next node in the list by setting temp to temp.next.",
    38: "Printing a message indicating that the node is not found in the list.",
    42: "Main method to test the functionality of the LinkedList class.",
    43: "Creating an instance of LinkedList named list.",
    45: "Calling the update method on the list to replace an old value of a node with a specified new value.",
    47: "Returning 0 to indicate successful execution.",
  },
};

export default explanations;
  