import React, { useState } from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvasForLinkedList';
import AlgorithmDescription from '../AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';
import UpdateVariablesModal from './UpdateVariablesModal';

import { useLinkedListUpdate } from './useLinkedListUpdate';

const LinkedListUpdate = ({ language, subTopicId }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [data, setData] = useState([10, 20, 30]);
  const [dataInput, setDataInput] = useState("10, 20, 30");

  const [oldData, setOldData] = useState(20);
  const [newData, setNewData] = useState(25);

  const handleData = (e) => {
    const input = e.target.value;
    setDataInput(input);

    // Parse the input string into an array of numbers
    const newArray = input.split(",").map((item) => parseFloat(item) || 0); // Default to 0 for invalid numbers
    setData(newArray);
  };

  const {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    nodeVariables,
    headVariables,
    highlightedVariables,
    highlightedHeadVariables,
    highlightedNodeVariables,
    highlightDataPart,
    highlightNextAddressPart,
    highlightNodeAddress,
    isMuted,
    codeSamples,
    setDelay,
    setIsPaused,
    setIsMuted,
    updateVariables,
    runLinkedList
  } = useLinkedListUpdate(language, data, oldData, newData);

  const handleUpdateVariables = () => {
    updateVariables(oldData, newData);
    setModalVisible(false);
  };

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6">
          <CodeDisplay
            codeLines={codeSamples || []}
            highlightedLine={highlightedLine}
            focusedEndLine={focusedEndLine}
            topic={"Linked List Update"}
            callingLine={callingLine}
            subTopicId={subTopicId}
            language={language}
            onOpenModal={() => setModalVisible(true)}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <h3 className="section-title">Canvas</h3>
            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runLinkedList}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
            <VisualizationCanvas
              variables={variables}
              highlightedVariables={highlightedVariables}
              nodeVariables={nodeVariables}
              highlightedNodeVariables={highlightedNodeVariables}
              highlightDataPart={highlightDataPart}
              highlightNextAddressPart={highlightNextAddressPart}
              highlightNodeAddress={highlightNodeAddress}
              headVariables={headVariables}
              highlightedHeadVariables={highlightedHeadVariables}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
          <AlgorithmDescription />
      </div>
      <UpdateVariablesModal
        isVisible={modalVisible}
        onClose={() => setModalVisible(false)}
        dataInput={dataInput}
        handleData={handleData}
        oldData={oldData}
        setOldData={setOldData}
        newData={newData}
        setNewData={setNewData}
        onSave={handleUpdateVariables}
      />
    </div>
  );
};

export default LinkedListUpdate;
