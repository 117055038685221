export const runPalindromeAlgorithmPython = async (
    string,
    highlightLine,
    setVariables,
    setHighlightedVariables,
    setStringVariables,
    setHighlightedStringIndices,
    setHighlightedStringVariables,
    customSleep,
  ) => {
    await highlightLine(0);
    await customSleep();
  
    await highlightLine(1);
    const lowerString = string.toLowerCase();
    // setStringVariables((vars) => ({ ...vars, '': { variable_name: '', value: string } }));
    // setStringVariables((vars) => ({ ...vars, string: { variable_name: 'string', value: lowerString } }));
    setStringVariables({ string: { variable_name: 'string', value: lowerString } });
    setHighlightedStringVariables(['string']);
    await customSleep();
    setHighlightedStringVariables([]);
  
    await highlightLine(2);
    let left = 0;
    setVariables({ left: { variable_name: 'left', value: left } });
    setHighlightedVariables(['left']);
    await customSleep();
    setHighlightedVariables([]);
  
    await highlightLine(3);
    let right = lowerString.length - 1;
    setVariables((vars) => ({ ...vars, right: { variable_name: 'right', value: right } }));
    setHighlightedVariables(['right']);
    await customSleep();
    setHighlightedVariables([]);
  
    if (!(left < right)) {
        await highlightLine(5);
        setHighlightedVariables(['left', 'right']);
        await customSleep();
        setHighlightedVariables([]);
    }

    while (left < right) {
      await highlightLine(5);
      setHighlightedVariables(['left', 'right']);
      await customSleep();
      setHighlightedVariables([]);

      await highlightLine(6);
      setHighlightedStringIndices([{ stringName: 'string', index: left }, { stringName: 'string', index: right }]);
      await customSleep();
      setHighlightedStringIndices([]);
      if (lowerString[left] !== lowerString[right]) {
        await highlightLine(7);
        await customSleep();
        return false;
      }
  
      await highlightLine(8);
      left += 1;
      setVariables((vars) => ({ ...vars, left: { variable_name: 'left', value: left } }));
      setHighlightedVariables(['left']);
      await customSleep();
      setHighlightedVariables([]);
        
      await highlightLine(9);
      right -= 1;
      setVariables((vars) => ({ ...vars, right: { variable_name: 'right', value: right } }));
      setHighlightedVariables(['right']);
      await customSleep();
      setHighlightedVariables([]);
    }
  
    await highlightLine(11);
    await customSleep();
    return true;
  };
  
  export const runPalindromeAlgorithmJava = async (
    string,
    highlightLine,
    setVariables,
    setHighlightedVariables,
    setStringVariables,
    setHighlightedStringIndices,
    setHighlightedStringVariables,
    customSleep
  ) => {
    await highlightLine(1);
    await customSleep();
  
    await highlightLine(2);
    const lowerString = string.toLowerCase();
    setStringVariables({ str: { variable_name: 'str', value: lowerString } });
    setHighlightedStringVariables(['str']);
    await customSleep();
    setHighlightedStringVariables([]);
  
    await highlightLine(3);
    let left = 0;
    let right = lowerString.length - 1;
    setVariables({ left: { variable_name: 'left', value: left } });
    setVariables((vars) => ({ ...vars, right: { variable_name: 'right', value: right } }));
    setHighlightedVariables(['left', 'right']);
    await customSleep();
    setHighlightedVariables([]);
  
    if (!(left < right)) {
        await highlightLine(5);
        setHighlightedVariables(['left', 'right']);
        await customSleep();
        setHighlightedVariables([]);
    }

    while (left < right) {
      await highlightLine(5);
      setHighlightedVariables(['left', 'right']);
      await customSleep();
      setHighlightedVariables([]);

      await highlightLine(6);
      setHighlightedStringIndices([{ stringName: 'str', index: left }, { stringName: 'str', index: right }]);
      await customSleep();
      setHighlightedStringIndices([]);
      if (lowerString[left] !== lowerString[right]) {
        await highlightLine(7);
        await customSleep();
        return false;
      }
  
      await highlightLine(9);
      left += 1;
      setVariables((vars) => ({ ...vars, left: { variable_name: 'left', value: left } }));
      setHighlightedVariables(['left']);
      await customSleep();
      setHighlightedVariables([]);
        
      await highlightLine(10);
      right -= 1;
      setVariables((vars) => ({ ...vars, right: { variable_name: 'right', value: right } }));
      setHighlightedVariables(['right']);
      await customSleep();
      setHighlightedVariables([]);
    }
  
    await highlightLine(12);
    await customSleep();
    return true;
  };
  
  export const runPalindromeAlgorithmC = async (
    string,
    highlightLine,
    setVariables,
    setHighlightedVariables,
    setStringVariables,
    setHighlightedStringIndices,
    setHighlightedStringVariables,
    customSleep
  ) => {
    await highlightLine(4);
    await customSleep();
  
    await highlightLine(5);
    let left = 0;
    setVariables({ left: { variable_name: 'left', value: left } });
    setHighlightedVariables(['left']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(6);
    let right = string.length - 1;
    setVariables((vars) => ({ ...vars, right: { variable_name: 'right', value: right } }));
    setHighlightedVariables(['right']);
    await customSleep();
    setHighlightedVariables([]);
  
    if (!(left < right)) {
        await highlightLine(8);
        setHighlightedVariables(['left', 'right']);
        await customSleep();
        setHighlightedVariables([]);
    }

    while (left < right) {
      await highlightLine(8);
      setHighlightedVariables(['left', 'right']);
      await customSleep();
      setHighlightedVariables([]);

      await highlightLine(9);
      setHighlightedStringIndices([{ stringName: 'str', index: left }, { stringName: 'str', index: right }]);
      let leftChar = string[left];
      let rightChar = string[right]
      string = string.substring(0, left) + leftChar.toLowerCase() + string.substring(left + 1);
      string = string.substring(0, right) + rightChar.toLowerCase() + string.substring(right + 1);
      setStringVariables({ str: { variable_name: 'str', value: string } });
      await customSleep();
      setHighlightedStringIndices([]);
      if (string[left] !== string[right]) {
        string = string.substring(0, left) + leftChar + string.substring(left + 1);
        string = string.substring(0, right) + rightChar + string.substring(right + 1);
        setStringVariables({ str: { variable_name: 'str', value: string } });

        await highlightLine(10);
        await customSleep();
        return false;
      }

      string = string.substring(0, left) + leftChar + string.substring(left + 1);
      string = string.substring(0, right) + rightChar + string.substring(right + 1);
      setStringVariables({ str: { variable_name: 'str', value: string } });
  
      await highlightLine(12);
      left += 1;
      setVariables((vars) => ({ ...vars, left: { variable_name: 'left', value: left } }));
      setHighlightedVariables(['left']);
      await customSleep();
      setHighlightedVariables([]);
        
      await highlightLine(13);
      right -= 1;
      setVariables((vars) => ({ ...vars, right: { variable_name: 'right', value: right } }));
      setHighlightedVariables(['right']);
      await customSleep();
      setHighlightedVariables([]);
    }
  
    await highlightLine(15);
    await customSleep();
    return true;
  };
  
  export const runPalindromeAlgorithmCpp = async (
    string,
    highlightLine,
    setVariables,
    setHighlightedVariables,
    setStringVariables,
    setHighlightedStringIndices,
    setHighlightedStringVariables,
    customSleep
  ) => {
    await highlightLine(5);
    await customSleep();
  
    await highlightLine(6);
    let left = 0;
    setVariables({ left: { variable_name: 'left', value: left } });
    setHighlightedVariables(['left']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(7);
    let right = string.length - 1;
    setVariables((vars) => ({ ...vars, right: { variable_name: 'right', value: right } }));
    setHighlightedVariables(['right']);
    await customSleep();
    setHighlightedVariables([]);
  
    if (!(left < right)) {
        await highlightLine(9);
        setHighlightedVariables(['left', 'right']);
        await customSleep();
        setHighlightedVariables([]);
    }

    while (left < right) {
      await highlightLine(9);
      setHighlightedVariables(['left', 'right']);
      await customSleep();
      setHighlightedVariables([]);

      await highlightLine(10);
      setHighlightedStringIndices([{ stringName: 'str', index: left }, { stringName: 'str', index: right }]);
      let leftChar = string[left];
      let rightChar = string[right]
      string = string.substring(0, left) + leftChar.toLowerCase() + string.substring(left + 1);
      string = string.substring(0, right) + rightChar.toLowerCase() + string.substring(right + 1);
      setStringVariables({ str: { variable_name: 'str', value: string } });
      await customSleep();
      setHighlightedStringIndices([]);
      if (string[left] !== string[right]) {
        string = string.substring(0, left) + leftChar + string.substring(left + 1);
        string = string.substring(0, right) + rightChar + string.substring(right + 1);
        setStringVariables({ str: { variable_name: 'str', value: string } });

        await highlightLine(11);
        await customSleep();
        return false;
      }

      string = string.substring(0, left) + leftChar + string.substring(left + 1);
      string = string.substring(0, right) + rightChar + string.substring(right + 1);
      setStringVariables({ str: { variable_name: 'str', value: string } });
  
      await highlightLine(13);
      left += 1;
      setVariables((vars) => ({ ...vars, left: { variable_name: 'left', value: left } }));
      setHighlightedVariables(['left']);
      await customSleep();
      setHighlightedVariables([]);
        
      await highlightLine(14);
      right -= 1;
      setVariables((vars) => ({ ...vars, right: { variable_name: 'right', value: right } }));
      setHighlightedVariables(['right']);
      await customSleep();
      setHighlightedVariables([]);
    }
  
    await highlightLine(16);
    await customSleep();
    return true;
  };
  