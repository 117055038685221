const explanations = {
  Python: {
    1: "Import the deque class from the collections module to create a double-ended queue.",
    3: "Define the BinaryTree class to represent the binary tree structure.",
    4: "Initialize the BinaryTree class with a constructor method __init__.",
    5: "Create an attribute 'root' to represent the root of the tree, initially set to None.",
    7: "Executing the level_order_traversal method to perform a level-order traversal of the binary tree.",
    8: "Set the current node to the root of the binary tree.",
    9: "Checking if the current node (root) is None (i.e., the tree is empty).",
    10: "Tree is empty, returning from the method without doing anything.",
    12: "Creating a queue to keep track of nodes during traversal, starting with the root node.",
    13: "Starting a loop that continues as long as the queue is not empty.",
    14: "Pop the first node from the queue and set it as the current node.",
    15: "Printing the data of the current node.",
    16: "Checking if the current node has a left child.",
    17: "Left child exists, appending it to the queue.",
    18: "Checking if the current node has a right child.",
    19: "Right child exists, appending it to the queue.",
    22: "Check if the script is being run directly (not imported).",
    23: "Creating an instance of the BinaryTree class.",
    25: "Calling the level_order_traversal method to traverse the tree and print its elements in level order."
  },
  Java: {
    1: "Import the LinkedList class to use as a queue for storing nodes during traversal.",
    2: "Import the Queue interface from the java.util package to define the queue.",
    4: "Define the BinaryTree class to represent the binary tree structure.",
    5: "Declare a 'root' attribute to represent the root of the tree, initially set to null.",
    7: "Define the constructor BinaryTree() to initialize the tree.",
    8: "Set the root of the tree to null, indicating an empty tree.",
    11: "Executing the levelOrderTraversal method to perform a level-order traversal of the binary tree.",
    12: "Set the current node to the root of the binary tree.",
    13: "Checking if the current node (root) is null (i.e., the tree is empty).",
    14: "Tree is empty, returning from the method without further processing.",
    17: "Create a queue to keep track of nodes during the level-order traversal.",
    18: "Adding the root node to the queue to start the traversal.",
    19: "Starting a loop that continues as long as the queue is not empty.",
    20: "Removing a node from the queue and set it as the current node.",
    21: "Print the data of the current node.",
    22: "Checking if the current node has a left child.",
    23: "Left child exists, add it to the queue.",
    25: "Checking if the current node has a right child.",
    26: "Right child exists, add it to the queue.",
    32: "Define the Main class to test the BinaryTree class functionality.",
    33: "Main method to execute the program.",
    34: "Create an instance of the BinaryTree class.",
    36: "Calling the levelOrderTraversal method to traverse the tree and print its elements level by level."
  },
  C: {
    1: "Include the stdio.h header for input and output functions like printf.",
    2: "Include the stdlib.h header for memory allocation functions.",
    4: "Define the Node struct to represent each element of the binary tree.",
    5: "Declare the data attribute to store the value of the node.",
    6: "Declare a pointer to represent the left child of the node.",
    7: "Declare a pointer to represent the right child of the node.",
    10: "Executing the levelOrderTraversal function to perform a level-order traversal of the binary tree.",
    11: "Checking if the root is NULL to handle an empty tree.",
    12: "Tree is empty, returning from the function without further processing.",
    15: "Declare an array to represent the queue for holding nodes during traversal.",
    16: "Initialize the front and rear indices of the queue.",
    18: "Add the root node to the queue to start the traversal.",
    20: "Starting a while loop that continues as long as there are nodes in the queue.",
    21: "Dequeue the first node from the queue and set it as the current node.",
    23: "Print the data of the current node as part of the level-order traversal.",
    25: "Checking if the left child of the current node exists.",
    26: "Left child exists, enqueue it by adding it to the queue.",
    29: "Checking if the right child of the current node exists.",
    30: "Right child exists, enqueue it by adding it to the queue.",
    34: "Main function to test the levelOrderTraversal function.",
    36: "Initialize the root of the tree as NULL (indicating an empty tree).",
    37: "Calling the levelOrderTraversal function to perform the traversal starting from the root.",
    38: "Returning 0 to indicate successful program termination."
  },
  'C++': {
    1: "Include the standard input-output stream library.",
    2: "Include the queue library to use the queue data structure for storing nodes during traversal.",
    3: "Using the standard namespace.",
    5: "Define the BinaryTree class to represent the structure of the binary tree.",
    6: "Use public access specifier to make the following members accessible from outside the class.",
    7: "Declare a pointer to the root node.",
    9: "Executing the constructor to initialize the binary tree.",
    10: "Set the root to null pointer initially.",
    13: "Executing the levelOrderTraversal method to perform a level-order traversal of the binary tree.",
    14: "Set the current node to the root of the binary tree.",
    15: "Checking if the current node (root) is null pointer (i.e., the tree is empty).",
    16: "Tree is empty, returning from the method without further processing.",
    19: "Create a queue to keep track of nodes during the level-order traversal.",
    20: "Adding the root node to the queue to start the traversal.",
    21: "Starting a loop that continues as long as the queue is not empty.",
    22: "Fetching the first node from the queue and set it as the current node.",
    23: "Removing the first node from the queue to process it.",
    24: "Print the data of the current node.",
    25: "Checking if the current node has a left child.",
    26: "Left child exists, add it to the queue.",
    28: "Checking if the current node has a right child.",
    29: "Right child exists, add it to the queue.",
    35: "Main method to test the BinaryTree class functionality.",
    36: "Create an instance of the BinaryTree class.",
    38: "Calling the levelOrderTraversal method to traverse the tree and print its elements level by level.",
    39: "Returning 0 to indicate successful execution of the program.",
  },
};
  
export default explanations;
  