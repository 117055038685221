const codeSamples = {
  Python: [
    "from collections import deque",
    "  ",
    "class Node:",
    "    def __init__(self, data):",
    "        self.data = data",
    "        self.left = None",
    "        self.right = None",
    "  ",
    "class BinaryTree:",
    "    def __init__(self):",
    "        self.root = None",
    "  ",
    "    def add(self, data):",
    "        node = Node(data)",
    "        if self.root is None:",
    "            self.root = node",
    "        else:",
    "            queue = deque([self.root])",
    "            while queue:",
    "                current = queue.popleft()",
    "                if current.left is None:",
    "                    current.left = node",
    "                    return",
    "                else:",
    "                    queue.append(current.left)",
    "                if current.right is None:",
    "                    current.right = node",
    "                    return",
    "                else:",
    "                    queue.append(current.right)",
    "  ",
    "if __name__ == '__main__':",
    "    binaryTree = BinaryTree()",
    "    binaryTree.add(1)",
    "    binaryTree.add(2)",
    "    binaryTree.add(3)",
    "    binaryTree.add(4)",
    "    binaryTree.add(5)",
    "    binaryTree.add(6)",
    "    binaryTree.add(7)",
  ],
  Java: [
    "import java.util.LinkedList;",
    "import java.util.Queue;",
    "  ",
    "class Node {",
    "    int data;",
    "    Node left, right;",
    "  ",
    "    public Node(int data) {",
    "        this.data = data;",
    "        left = right = null;",
    "    }",
    "}",
    "  ",
    "class BinaryTree {",
    "    Node root;",
    "  ",
    "    public BinaryTree() {",
    "        root = null;",
    "    }",
    "  ",
    "    public void add(int data) {",
    "        Node node = new Node(data);",
    "        if (root == null) {",
    "            root = node;",
    "        } else {",
    "            Queue<Node> queue = new LinkedList<>();",
    "            queue.add(root);",
    "  ",
    "            while (!queue.isEmpty()) {",
    "                Node current = queue.poll();",
    "  ",
    "                if (current.left == null) {",
    "                    current.left = node;",
    "                    return;",
    "                } else {",
    "                    queue.add(current.left);",
    "                }",
    "  ",
    "                if (current.right == null) {",
    "                    current.right = node;",
    "                    return;",
    "                } else {",
    "                    queue.add(current.right);",
    "                }",
    "            }",
    "        }",
    "    }",
    "}",
    "  ",
    "public class Main {",
    "    public static void main(String[] args) {",
    "        BinaryTree binaryTree = new BinaryTree();",
    "        binaryTree.add(1);",
    "        binaryTree.add(2);",
    "        binaryTree.add(3);",
    "        binaryTree.add(4);",
    "        binaryTree.add(5);",
    "        binaryTree.add(6);",
    "        binaryTree.add(7);",
    "    }",
    "}",
  ],
  "C++": [
    "#include <iostream>",
    "#include <queue>",
    "using namespace std;",
    "  ",
    "class Node {",
    "public:",
    "    int data;",
    "    Node* left, *right;",
    "    Node(int data) {",
    "        this->data = data;",
    "        left = right = nullptr;",
    "    }",
    "};",
    "  ",
    "class BinaryTree {",
    "public:",
    "    Node* root;",
    "    BinaryTree() {",
    "        root = nullptr;",
    "    }",
    "  ",
    "    void add(int data) {",
    "        Node* node = new Node(data);",
    "        if (root == nullptr) {",
    "            root = node;",
    "        } else {",
    "            queue<Node*> q;",
    "            q.push(root);",
    "  ",
    "            while (!q.empty()) {",
    "                Node* current = q.front();",
    "                q.pop();",
    "  ",
    "                if (current->left == nullptr) {",
    "                    current->left = node;",
    "                    return;",
    "                } else {",
    "                    q.push(current->left);",
    "                }",
    "  ",
    "                if (current->right == nullptr) {",
    "                    current->right = node;",
    "                    return;",
    "                } else {",
    "                    q.push(current->right);",
    "                }",
    "            }",
    "        }",
    "    }",
    "};",
    "  ",
    "int main() {",
    "    BinaryTree binaryTree;",
    "    binaryTree.add(1);",
    "    binaryTree.add(2);",
    "    binaryTree.add(3);",
    "    binaryTree.add(4);",
    "    binaryTree.add(5);",
    "    binaryTree.add(6);",
    "    binaryTree.add(7);",
    "    return 0;",
    "}",
  ],
  C: [
    "#include <stdio.h>",
    "#include <stdlib.h>",
    "  ",
    "struct Node {",
    "    int data;",
    "    struct Node* left;",
    "    struct Node* right;",
    "};",
    "  ",
    "struct Node* createNode(int data) {",
    "    struct Node* node = (struct Node*)malloc(sizeof(struct Node));",
    "    node->data = data;",
    "    node->left = NULL;",
    "    node->right = NULL;",
    "    return node;",
    "}",
    "  ",
    "void addNode(struct Node** root, int data) {",
    "    struct Node* newNode = createNode(data);",
    "    if (*root == NULL) {",
    "        *root = newNode;",
    "    } else {",
    "        struct Node* current = *root;",
    "        struct Node* queue[100];",
    "        int front = 0, rear = 0;",
    "        queue[rear++] = current;",
    "  ",
    "        while (front < rear) {",
    "            current = queue[front++];",
    "  ",
    "            if (current->left == NULL) {",
    "                current->left = newNode;",
    "                return;",
    "            } else {",
    "                queue[rear++] = current->left;",
    "            }",
    "  ",
    "            if (current->right == NULL) {",
    "                current->right = newNode;",
    "                return;",
    "            } else {",
    "                queue[rear++] = current->right;",
    "            }",
    "        }",
    "    }",
    "}",
    "  ",
    "int main() {",
    "    struct Node* root = NULL;",
    "    addNode(&root, 1);",
    "    addNode(&root, 2);",
    "    addNode(&root, 3);",
    "    addNode(&root, 4);",
    "    addNode(&root, 5);",
    "    addNode(&root, 6);",
    "    addNode(&root, 7);",
    "    return 0;",
    "}",
  ]
};

export default codeSamples;
