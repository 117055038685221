const codeSamples = {
    C: [
      "#include <stdio.h>",
      " ",
      "union MagicalBox {",
      "    int number;",
      "    char letter;",
      "    const char* candy;",
      "};",
      " ",
      "int main() {",
      "    union MagicalBox box;",
      " ",
      "    // Putting a number in the box",
      "    box.number = 7;",
      "    printf(\"The box contains the number: %d\\n\", box.number);",
      " ",
      "    // Now let's put a letter in the box",
      "    box.letter = 'A';",
      "    printf(\"The box now contains the letter: %c\\n\", box.letter);",
      " ",
      "    // Finally, let's put some candy in the box",
      "    box.candy = \"Lollipop\";",
      "    printf(\"The box now contains: %s\\n\", box.candy);",
      " ",
      "    // But what happens to the number and the letter?",
      "    printf(\"Oops! The number is now: %d\\n\", box.number);",
      "    printf(\"And the letter is now: %c\\n\", box.letter);",
      " ",
      "    return 0;",
      "}"
    ],
    'C++': [
      "#include <iostream>",
      " ",
      "union MagicalBox {",
      "    int number;",
      "    char letter;",
      "    const char* candy;",
      "};",
      " ",
      "int main() {",
      "    MagicalBox box;",
      " ",
      "    // Putting a number in the box",
      "    box.number = 7;",
      "    std::cout << \"The box contains the number: \" << box.number << std::endl;",
      " ",
      "    // Now let's put a letter in the box",
      "    box.letter = 'A';",
      "    std::cout << \"The box now contains the letter: \" << box.letter << std::endl;",
      " ",
      "    // Finally, let's put some candy in the box",
      "    box.candy = \"Lollipop\";",
      "    std::cout << \"The box now contains: \" << box.candy << std::endl;",
      " ",
      "    // But what happens to the number and the letter?",
      "    std::cout << \"Oops! The number is now: \" << box.number << std::endl;",
      "    std::cout << \"And the letter is now: \" << box.letter << std::endl;",
      " ",
      "    return 0;",
      "}"
    ],
  };
  
  export default codeSamples;
  