const explanations = {
    Python: {
        1: "Defining the Dog class",
        2: "The constructor method for the Dog class",
        3: "Assigning the name passed to the constructor to the class field name",
        5: "Executing The bark method",
        6: "Printing the dog's name followed by 'says: Woof!'",
        8: "Executing the sleep method",
        9: "Printing the dog's name followed by 'is sleeping... Zzz...'",
        11: "Starting the script execution",
        12: "Creating an instance of Dog with the name 'buddy' using the constructor that takes a String parameter.",
        14: "Calling the bark method",
        15: "Calling the sleep method"
    },
    Java: {
        1: "Defining the Dog class",
        2: "Declaring a private field name of type String",
        4: "The constructor method for the Dog class",
        5: "Assigning the name passed to the constructor to the class field name",
        8: "Executing The bark method",
        9: "Printing the dog's name followed by 'says: Woof!'",
        12: "Executing the sleep method",
        13: "Printing the dog's name followed by 'is sleeping... Zzz...'",
        16: "Executing the main method",
        17: "Creating an instance of Dog with the name 'buddy' using the constructor that takes a String parameter.",
        19: "Calling the bark method",
        20: "Calling the sleep method"
    },
    'C++': {
        4: "Defining the Dog class",
        6: "Declaring a private field name of type String",
        9: "The constructor method for the Dog class that initializes the name member variable",
        10: "Assigning the name passed to the constructor to the class field name",
        11: "Executing The bark method",
        12: "Printing the dog's name followed by 'says: Woof!'",
        15: "Executing the sleep method",
        16: "Printing the dog's name followed by 'is sleeping... Zzz...'",
        20: "Executing the main method",
        21: "Creating an instance of Dog with the name 'buddy' using the constructor that takes a String parameter.",
        23: "Calling the bark method",
        24: "Calling the sleep method",
        26: "Returning 0 to indicate successful execution"
    }
};

export default explanations;
