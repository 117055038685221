const explanations = {
    C: {
      1: "Including the standard input/output header for C",
      3: "Creating a typedef named 'CakeCount' for 'unsigned int'",
      5: "Starting the main function",
      6: "Declaring a variable 'cakesBakedToday' of type 'CakeCount' and assigning it an unsigned integer value.",
      7: "Declaring a variable 'cakesBakedYesterday' of type 'CakeCount' and assigning it an unsigned integer value.",
      9: "Printing the value of 'cakesBakedToday'",
      10: "Printing the value of 'cakesBakedYesterday'",
      12: "Returning 0 to indicate successful execution"
    },
    'C++': {
      1: "Including the iostream library for input-output operations",
      3: "Creating a typedef named 'CakeCount' for 'unsigned int'",
      5: "Starting the main function",
      6: "Declaring a variable 'cakesBakedToday' of type 'CakeCount' and assigning it an unsigned integer value.",
      7: "Declaring a variable 'cakesBakedYesterday' of type 'CakeCount' and assigning it an unsigned integer value.",
      9: "Printing the value of 'cakesBakedToday'",
      10: "Printing the value of 'cakesBakedYesterday'",
      12: "Returning 0 to indicate successful execution"
    }
  };
  
  export default explanations;
  