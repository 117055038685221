const explanations = {
    Java: {
      3: "Declaring and initializing short variable daysInWeek",
      4: "Declaring and initializing short variable maxShortValue",
      5: "Declaring and initializing short variable minShortValue",
      7: "Printing the number of days in a week",
      9: "Printing the maximum short value",
      11: "Printing the minimum short value"
    },
    C: {
      3: "Declaring and initializing short variable daysInWeek",
      4: "Declaring and initializing short variable maxShortValue",
      5: "Declaring and initializing short variable minShortValue",
      7: "Printing the number of days in a week",
      9: "Printing the maximum short value",
      11: "Printing the minimum short value",
      13: "Returning 0 to indicate successful execution"
    },
    "C++": {
      4: "Declaring and initializing short variable daysInWeek",
      5: "Declaring and initializing short variable maxShortValue",
      6: "Declaring and initializing short variable minShortValue",
      8: "Printing the number of days in a week",
      10: "Printing the maximum short value",
      12: "Printing the minimum short value",
      14: "Returning 0 to indicate successful execution"
    }
  };
  
  export default explanations;
  