export const dogConstructorForJava = async (
    highlightLine,
    setStringVariables,
    setHighlightedStringVariables,
    customSleep,
    initialClass
  ) => {

    await highlightLine(7);
    await customSleep();

    await highlightLine(8);
    setStringVariables((vars) => ({ ...vars, favoriteToy: { variable_name: 'favoriteToy', value: initialClass.favoriteToy } }));
    setHighlightedStringVariables(['favoriteToy']);
    await customSleep();
    setHighlightedStringVariables([]);
  };
  
export const dogSoundFunctionForJava = async (
    highlightLine,
    setHighlightedStringVariables,
    customSleep,
    logMessage,
    initialClass
) => {
    await highlightLine(11);
    await customSleep();

    await highlightLine(12);
    setHighlightedStringVariables(['favoriteToy']);
    await logMessage(`Woof! My favorite toy is: ${initialClass.favoriteToy}`);
    await customSleep();
    setHighlightedStringVariables([]);
};
