const codeSamples = {
    Python: [
        "# Variable Declaration and Initialization",
        "  ",
        "if __name__ == '__main__':",
        "    a = 10",        
        "    b = 15",        
        "  ",
        "    print(\"a = {}, b = {}\".format(a, b))"
    ],
    Java: [
        "// Variable Declaration and Initialization",
        "  ",
        "public class Main {",
        "    public static void main(String[] args) {",
        "        int a = 10;",        
        "        int b = 15;",        
        "  ",
        "        System.out.println(\"a = \" + a + \", b = \" + b);",
        "    }",
        "}"
    ],
    C: [
        "// Variable Declaration and Initialization",
        "  ",
        "#include <stdio.h>",
        "  ",
        "int main() {",
        "    int a = 10;",        
        "    int b = 15;",        
        "  ",
        "    printf(\"a = %d, b = %d\\n\", a, b);",
        "    return 0;",
        "}"
    ],
    "C++": [
        "// Variable Declaration and Initialization",
        "  ",
        "#include <iostream>",
        "  ",
        "int main() {",
        "    int a = 10;",        
        "    int b = 15;",        
        "  ",
        "    std::cout << \"a = \" << a << \", b = \" << b << std::endl;",
        "    return 0;",
        "}"
    ]
};

export default codeSamples;
