const codeSamples = {
    Python: [
      "class Node:",
      "    def __init__(self, data):",
      "        self.data = data",
      "        self.next = None",
      "  ",
      "class LinkedList:",
      "    def __init__(self):",
      "        self.head = None",
      "  ",
      "    def delete(self, data):",
      "        if self.head is None:",
      "            print('The list is empty.')",
      "            return",
      "  ",
      "        if self.head.data == data:",
      "            self.head = self.head.next",
      "            print(f'{data} deleted from the list.')",
      "            return",
      "  ",
      "        temp = self.head",
      "        while temp.next is not None and temp.next.data != data:",
      "            temp = temp.next",
      "        if temp.next is None:",
      "            print(f'{data} not found in the list.')",
      "        else:",
      "            temp.next = temp.next.next",
      "            print(f'{data} deleted from the list.')",
      "  ",
      "if __name__ == '__main__':",
      "    list = LinkedList()",
      "  ",
      "    list.delete(30)"
    ],
    Java: [
      "class Node {",
      "    int data;",
      "    Node next;",
      "  ",
      "    Node(int data) {",
      "        this.data = data;",
      "        this.next = null;",
      "    }",
      "}",
      "  ",
      "class LinkedList {",
      "    Node head;",
      "  ",
      "    public void delete(int data) {",
      "        if (head == null) {",
      "            System.out.println(\"The list is empty.\");",
      "            return;",
      "        }",
      "  ",
      "        if (head.data == data) {",
      "            head = head.next;",
      "            System.out.println(data + \" deleted from the list.\");",
      "            return;",
      "        }",
      "  ",
      "        Node temp = head;",
      "        while (temp.next != null && temp.next.data != data) {",
      "            temp = temp.next;",
      "        }",
      "        if (temp.next == null) {",
      "            System.out.println(data + \" not found in the list.\");",
      "        } else {",
      "            temp.next = temp.next.next;",
      "            System.out.println(data + \" deleted from the list.\");",
      "        }",
      "    }",
      "  ",
      "    public static void main(String[] args) {",
      "        LinkedList list = new LinkedList();",
      "        list.delete(30);",
      "    }",
      "}"
    ],
    C: [
      "#include <stdio.h>",
      "#include <stdlib.h>",
      "  ",
      "struct Node {",
      "    int data;",
      "    struct Node* next;",
      "};",
      "  ",
      "struct Node* head = NULL;",
      "  ",
      "void delete(int data) {",
      "    if (head == NULL) {",
      "        printf(\"The list is empty.\\n\");",
      "        return;",
      "    }",
      "  ",
      "    if (head->data == data) {",
      "        struct Node* temp = head;",
      "        head = head->next;",
      "        free(temp);",
      "        printf(\"%d deleted from the list.\\n\", data);",
      "        return;",
      "    }",
      "  ",
      "    struct Node* temp = head;",
      "    while (temp->next != NULL && temp->next->data != data) {",
      "        temp = temp->next;",
      "    }",
      "  ",
      "    if (temp->next == NULL) {",
      "        printf(\"%d not found in the list.\\n\", data);",
      "    } else {",
      "        struct Node* toDelete = temp->next;",
      "        temp->next = temp->next->next;",
      "        free(toDelete);",
      "        printf(\"%d deleted from the list.\\n\", data);",
      "    }",
      "}",
      "  ",
      "int main() {",
      "    // Delete node from the linked list",
      "    delete(30);",
      "  ",
      "    return 0;",
      "}"
    ],
    "C++": [
      "#include <iostream>",
      "using namespace std;",
      "  ",
      "class Node {",
      "public:",
      "    int data;",
      "    Node* next;",
      "  ",
      "    Node(int data) {",
      "        this->data = data;",
      "        this->next = nullptr;",
      "    }",
      "};",
      "  ",
      "class LinkedList {",
      "public:",
      "    Node* head;",
      "  ",
      "    LinkedList() {",
      "        head = nullptr;",
      "    }",
      "  ",
      "    void deleteNode(int data) {",
      "        if (head == nullptr) {",
      "            std::cout << \"The list is empty.\" << std::endl;",
      "            return;",
      "        }",
      "  ",
      "        if (head->data == data) {",
      "            Node* temp = head;",
      "            head = head->next;",
      "            delete temp;",
      "            std::cout << data << \" deleted from the list.\" << std::endl;",
      "            return;",
      "        }",
      "  ",
      "        Node* temp = head;",
      "        while (temp->next != nullptr && temp->next->data != data) {",
      "            temp = temp->next;",
      "        }",
      "        if (temp->next == nullptr) {",
      "            std::cout << data << \" not found in the list.\" << std::endl;",
      "        } else {",
      "            Node* toDelete = temp->next;",
      "            temp->next = temp->next->next;",
      "            delete toDelete;",
      "            std::cout << data << \" deleted from the list.\" << std::endl;",
      "        }",
      "    }",
      "  ",
      "};",
      "  ",
      "int main() {",
      "    LinkedList list;",
      "  ",
      "    list.deleteNode(30);",
      "  ",
      "    return 0;",
      "}"
    ]
  };
  
  export default codeSamples;
  