import { useState, useEffect, useRef } from 'react';
import { linkedListJava, linkedListPython, linkedListCpp, linkedListC } from './linkedListAlgorithms';
import explanations from './explanations';
import codeSamplesTemplate from './codeSamples';

export const useLinkedList = (language, data) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [highlightedMultipleLines, setHighlightedMultipleLines] = useState([]);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [callingLine, setCallingLine] = useState(null);
  const [secondCallingLine, setSecondCallingLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const delayRef = useRef(delay);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [nodeVariables, setNodeVariables] = useState({});
  const [headVariables, setHeadVariables] = useState({});
  const [highlightedHeadVariables, setHighlightedHeadVariables] = useState([]);
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedNodeVariables, setHighlightedNodeVariables] = useState([]);
  const [highlightDataPart, setHighlightDataPart] = useState([]);
  const [highlightNextAddressPart, setHighlightNextAddressPart] = useState([]);
  const [highlightNodeAddress, setHighlightNodeAddress] = useState([]);
  const [isMuted, setIsMuted] = useState(true);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const isMutedRef = useRef(isMuted);
  const synth = window.speechSynthesis;


  const updateVariables = (newData) => {
    setVariables({});
    resetState();

    let updatedCodeSamples = "";
    let index = 0;

     // Dynamically update the code samples
     updatedCodeSamples = codeSamplesTemplate[language].map((line) => {
      return line.replace(/insert\s*\(\s*\d+\s*\)/g, () => {
        const newValue = newData[index] || 0; // Fallback to 0 if out of range
        index++;
        return `insert(${newValue})`;
      });
    });

    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setHighlightedMultipleLines([]);
    setFocusedEndLine(null);
    setCallingLine(null);
    setSecondCallingLine(null);
    setVariables({});
    setNodeVariables({});
    setHeadVariables({});
    setLogs([]);
    setHighlightedVariables([]);
    setHighlightedNodeVariables([]);
    setHighlightedHeadVariables([]);
    setHighlightDataPart([]);
    setHighlightNextAddressPart([]);
    setHighlightNodeAddress([]);
  };

  const speak = (message) => {
    return new Promise((resolve) => {
      if (isMutedRef.current) {
        resolve();
        return;
      }
      const utterance = new SpeechSynthesisUtterance(message);
      utterance.onend = resolve;
      synth.speak(utterance);
    });
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setCallingLine((prevCallingLine) => {
      if (prevCallingLine === lineNumber) {
        return null;
      }
      return prevCallingLine;
    });

    setSecondCallingLine((prevCallingLine) => {
      if (prevCallingLine === lineNumber) {
        return null;
      }
      return prevCallingLine;
    });

    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const highlightCallingLine = async (lineNumber) => {
    setCallingLine(lineNumber);
  };

  const highlightSecondCallingLine = async (lineNumber) => {
    setSecondCallingLine(lineNumber);
  };

  const highlightMultipleLines = async (lineNumbers) => {
    setHighlightedMultipleLines(lineNumbers);
    let lineNumber = lineNumbers[0];
    
    if (lineNumber) {
      await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
    }
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async (time=null) => {
    let start = Date.now();
    let ms;
    if (time === null) {
        ms = delayRef.current;
    } else {
        ms = time;
    }
    
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runLinkedListPython = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(20);
    await customSleep();

    await highlightCallingLine(20);
    await highlightLine(6);
    await customSleep();

    await highlightLine(7);
    setHeadVariables((vars) => ({ ...vars, 
      head: { variable_name: 'head', value: 'None' }
    }));
    setHighlightedHeadVariables(['head']);
    await customSleep();
    await highlightLine(20);
    await customSleep();
    setHighlightedHeadVariables([]);

    await linkedListPython(
      data[0],
      data[1],
      data[2],
      setVariables,
      setHighlightedVariables,
      setNodeVariables,
      setHeadVariables,
      setHighlightedNodeVariables,
      setHighlightedHeadVariables,
      setHighlightDataPart,
      setHighlightNextAddressPart,
      setHighlightNodeAddress,
      highlightLine,
      highlightCallingLine,
      highlightSecondCallingLine,
      customSleep
    );

    await focusEndLine(24);
    setIsRunning(false);
  };

  const runLinkedListJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(27);
    setHeadVariables((vars) => ({ ...vars, 
      head: { variable_name: 'head', value: 'null' }
    }));
    setHighlightedHeadVariables(['head']);
    await customSleep();
    setHighlightedHeadVariables([]);

    await linkedListJava(
      data[0],
      data[1],
      data[2],
      setVariables,
      setHighlightedVariables,
      setNodeVariables,
      setHeadVariables,
      setHighlightedNodeVariables,
      setHighlightedHeadVariables,
      setHighlightDataPart,
      setHighlightNextAddressPart,
      setHighlightNodeAddress,
      highlightLine,
      highlightCallingLine,
      highlightSecondCallingLine,
      customSleep
    );

    await focusEndLine(34);
    setIsRunning(false);
  };

  const runLinkedListC = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(26);
    await customSleep();

    await highlightCallingLine(26);
    await highlightLine(8);
    setHeadVariables((vars) => ({ ...vars, 
      head: { variable_name: 'head', value: 'NULL' }
    }));
    setHighlightedHeadVariables(['head']);
    await customSleep();
    await highlightLine(26);
    await customSleep();
    setHighlightedHeadVariables([]);

    await linkedListC(
      data[0],
      data[1],
      data[2],
      setVariables,
      setHighlightedVariables,
      setNodeVariables,
      setHeadVariables,
      setHighlightedNodeVariables,
      setHighlightedHeadVariables,
      setHighlightDataPart,
      setHighlightNextAddressPart,
      setHighlightNodeAddress,
      highlightLine,
      highlightMultipleLines,
      highlightCallingLine,
      highlightSecondCallingLine,
      customSleep
    );

    await highlightLine(31);
    await customSleep();

    await focusEndLine(32);
    setIsRunning(false);
  };

  const runLinkedListCpp = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(37);
    await customSleep();

    await highlightCallingLine(37);
    await highlightLine(18);
    await customSleep();

    await highlightLine(19);
    setHeadVariables((vars) => ({ ...vars, 
      head: { variable_name: 'head', value: 'nullptr' }
    }));
    setHighlightedHeadVariables(['head']);
    await customSleep();
    await highlightLine(37);
    await customSleep();
    setHighlightedHeadVariables([]);

    await linkedListCpp(
      data[0],
      data[1],
      data[2],
      setVariables,
      setHighlightedVariables,
      setNodeVariables,
      setHeadVariables,
      setHighlightedNodeVariables,
      setHighlightedHeadVariables,
      setHighlightDataPart,
      setHighlightNextAddressPart,
      setHighlightNodeAddress,
      highlightLine,
      highlightCallingLine,
      highlightSecondCallingLine,
      customSleep
    );

    await highlightLine(43);
    await customSleep();

    await focusEndLine(44);
    setIsRunning(false);
  };

  const runLinkedList = async () => {
    if (language === 'Python') {
      await runLinkedListPython();
    } else if (language === 'Java') {
      await runLinkedListJava();
    } else if (language === 'C') {
      await runLinkedListC();
    } else if (language === 'C++') {
      await runLinkedListCpp();
    }
  };

  return {
    highlightedLine,
    highlightedMultipleLines,
    focusedEndLine,
    callingLine,
    secondCallingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    nodeVariables,
    headVariables,
    highlightedVariables,
    highlightedNodeVariables,
    highlightedHeadVariables,
    highlightDataPart,
    highlightNextAddressPart,
    highlightNodeAddress,
    isMuted,
    codeSamples,
    updateVariables,
    setHighlightedLine,
    setHighlightedMultipleLines,
    setFocusedEndLine,
    setCallingLine,
    setSecondCallingLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setNodeVariables,
    setHeadVariables,
    setHighlightedVariables,
    setHighlightedNodeVariables,
    setHighlightedHeadVariables,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    setIsMuted,
    runLinkedList
  };
};
