const codeSamples = {
    Java: [
      "public class DoubleTypes {",
      "    public static void main(String[] args) {",
      "        double earthMass = 5.972e24;",
      "        double avogadroNumber = 6.02214076e23;",
      "        double electronCharge = 1.602176634e-19;",
      "  ",
      "        System.out.println('Earth\'s mass in kilograms: ' + earthMass);",
      "  ",
      "        System.out.println('Avogadro\'s number: ' + avogadroNumber);",
      "  ",
      "        System.out.println('Electron charge in coulombs: ' + electronCharge);",
      "    }",
      "}"
    ],
    C: [
      "#include <stdio.h>",
      "int main() {",
      "    double earthMass = 5.972e24;",
      "    double avogadroNumber = 6.02214076e23;",
      "    double electronCharge = 1.602176634e-19;",
      "  ",
      "    printf('Earth\'s mass in kilograms: %.3e\\n', earthMass);",
      "  ",
      "    printf('Avogadro\'s number: %.2e\\n', avogadroNumber);",
      "  ",
      "    printf('Electron charge in coulombs: %.3e\\n', electronCharge);",
      "  ",
      "    return 0;",
      "}"
    ],
    "C++": [
      "#include <iostream>",
      "using namespace std;",
      "int main() {",
      "    double earthMass = 5.972e24;",
      "    double avogadroNumber = 6.02214076e23;",
      "    double electronCharge = 1.602176634e-19;",
      "  ",
      "    cout << 'Earth\'s mass in kilograms: ' << earthMass << endl;",
      "  ",
      "    cout << 'Avogadro\'s number: ' << avogadroNumber << endl;",
      "  ",
      "    cout << 'Electron charge in coulombs: ' << electronCharge << endl;",
      "  ",
      "    return 0;",
      "}"
    ]
  };
  
  export default codeSamples;
  