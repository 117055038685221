const codeSamples = {
  Python: [
    "class BinaryTree:",
    "    def __init__(self):",
    "        self.root = None",
    "  ",
    "    def inorder_traversal(self):",
    "        current = self.root",
    "        if current is None:",
    "            return",
    "  ",
    "        stack = []",
    "        while current is not None or len(stack) > 0:",
    "            while current is not None:",
    "                stack.append(current)",
    "                current = current.left",
    "            current = stack.pop()",
    "            print(current.data, end=', ')",
    "            current = current.right",
    "  ",
    "  ",
    "if __name__ == '__main__':",
    "    binaryTree = BinaryTree()",
    "  ",
    "    binaryTree.inorder_traversal()",
  ],
  Java: [
    "import java.util.Stack;",
    "  ",
    "class BinaryTree {",
    "    Node root;",
    "  ",
    "    BinaryTree() {",
    "        root = null;",
    "    }",
    "  ",
    "    void inorderTraversal() {",
    "        Node current = root;",
    "        if (current == null) {",
    "            return;",
    "        }",
    "  ",
    "        Stack<Node> stack = new Stack<>();",
    "        while (current != null || !stack.isEmpty()) {",
    "            while (current != null) {",
    "                stack.push(current);",
    "                current = current.left;",
    "            }",
    "            current = stack.pop();",
    "            System.out.print(current.data + \", \");",
    "            current = current.right;",
    "        }",
    "    }",
    "}",
    "  ",
    "public class Main {",
    "    public static void main(String[] args) {",
    "        BinaryTree binaryTree = new BinaryTree();",
    "  ",
    "        binaryTree.inorderTraversal();",
    "    }",
    "}",
  ],
  C: [
    "#include <stdio.h>",
    "#include <stdlib.h>",
    "  ",
    "struct Node {",
    "    int data;",
    "    struct Node* left;",
    "    struct Node* right;",
    "};",
    "  ",
    "void inorderTraversal(struct Node* root) {",
    "    struct Node* stack[100];",
    "    int top = -1;",
    "    struct Node* current = root;",
    "  ",
    "    while (current != NULL || top >= 0) {",
    "        while (current != NULL) {",
    "            stack[++top] = current;",
    "            current = current->left;",
    "        }",
    "        current = stack[top--];",
    "        printf(\"%d, \", current->data);",
    "        current = current->right;",
    "    }",
    "}",
    "  ",
    "int main() {",
    "    struct Node* root = NULL;",
    "    inorderTraversal(root);",
    "    return 0;",
    "}"
  ],  
  "C++": [
    "#include <iostream>",
    "#include <stack>",
    "using namespace std;",
    "  ",
    "class BinaryTree {",
    "public:",
    "    Node* root;",
    "  ",
    "    BinaryTree() {",
    "        root = nullptr;",
    "    }",
    "  ",
    "    void inorderTraversal() {",
    "        Node* current = root;",
    "        if (current == nullptr) {",
    "            return;",
    "        }",
    "  ",
    "        stack<Node*> stack;",
    "        while (current != nullptr || !stack.empty()) {",
    "            while (current != nullptr) {",
    "                stack.push(current);",
    "                current = current->left;",
    "            }",
    "            current = stack.top();",
    "            stack.pop();",
    "            cout << current->data << \", \";",
    "            current = current->right;",
    "        }",
    "    }",
    "};",
    "  ",
    "int main() {",
    "    BinaryTree binaryTree;",
    "  ",
    "    binaryTree.inorderTraversal();",
    "  ",
    "    return 0;",
    "}"
  ]
};
  
  export default codeSamples;
  