const explanations = {
  Python: {
    1: "Define the BinaryTree class to represent the binary tree structure.",
    2: "Initialize the BinaryTree class with a constructor method __init__.",
    3: "Create an attribute 'root' to represent the root of the tree, initially set to None.",
    5: "Executing the postorder_traversal method to perform a post-order traversal of the binary tree.",
    6: "Set the current node to the root of the binary tree.",
    7: "Checking if the current node (root) is None (i.e., the tree is empty).",
    8: "Tree is empty, returning from the method without doing anything.",
    10: "Creating stack1 to keep track of nodes during traversal, starting with the root node.",
    11: "Creating stack2 to store nodes in post-order sequence.",
    12: "Starting a loop that continues as long as stack1 is not empty.",
    13: "Pop a node from stack1 and set it as the current node.",
    14: "Append the current node to stack2 to store it for post-order traversal.",
    15: "Checking if the current node has a left child.",
    16: "Left child exists, push it onto stack1.",
    17: "Checking if the current node has a right child.",
    18: "Right child exists, push it onto stack1.",
    20: "Start another loop that continues as long as stack2 is not empty.",
    21: "Pop a node from stack2 and set it as the current node.",
    22: "Printing the data of the current node.",
    25: "Check if the script is being run directly (not imported).",
    26: "Create an instance of the BinaryTree class.",
    28: "Calling the postorder_traversal method to traverse the tree and print its elements in post-order."
  },
  Java: {
    1: "Import the Stack class from the java.util package to use for storing nodes during traversal.",
    3: "Define the BinaryTree class to represent the binary tree structure.",
    4: "Declare a 'root' attribute to represent the root of the tree, initially set to null.",
    6: "Define the constructor BinaryTree() to initialize the tree.",
    7: "Set the 'root' of the tree to null, indicating an empty tree.",
    10: "Executing the postorderTraversal method to perform a post-order traversal of the binary tree.",
    11: "Set the current node to the root of the binary tree.",
    12: "Checking if the current node (root) is null (i.e., the tree is empty).",
    13: "Tree is empty, returning from the method without further processing.",
    16: "Creating the first stack (stack1) to keep track of nodes during traversal.",
    17: "Creating the second stack (stack2) to store nodes in post-order.",
    18: "Pushing the root node onto stack1 to start the traversal.",
    19: "Starting a loop that continues as long as stack1 is not empty.",
    20: "Pop a node from stack1 and set it as the current node.",
    21: "Pushing the current node onto stack2 for post-order processing.",
    22: "Checking if the current node has a left child.",
    23: "Left child exists, push it onto stack1.",
    25: "Checking if the current node has a right child.",
    26: "Right child exists, push it onto stack1.",
    30: "Starting a loop that continues as long as stack2 is not empty.",
    31: "Pop a node from stack2 and set it as the current node.",
    32: "Print the data of the current node.",
    37: "Define the Main class to test the BinaryTree class functionality.",
    38: "Main method to execute the program.",
    39: "Create an instance of the BinaryTree class.",
    41: "Calling the postorderTraversal method to traverse the tree and print its elements in post-order."
  },
  C: {
    1: "Include the stdio.h header for input and output functions like printf.",
    2: "Include the stdlib.h header for memory allocation functions.",
    4: "Define the Node struct to represent each element of the binary tree.",
    5: "Declare the data attribute to store the value of the node.",
    6: "Declare a pointer to represent the left child of the node.",
    7: "Declare a pointer to represent the right child of the node.",
    10: "Executing the postorderTraversal function to perform a post-order traversal of the binary tree.",
    11: "Checking if the root is NULL to handle an empty tree.",
    12: "Tree is empty, returning from the function without further processing.",
    15: "Creating the first stack (stack1) to keep track of nodes during traversal.",
    16: "Creating the second stack (stack2) to store nodes in post-order.",
    17: "Initialize the top indices of both stacks to -1 (indicating empty stacks).",
    19: "Push the root node onto stack1 to start the traversal.",
    21: "Starting a while loop that continues as long as stack1 is not empty.",
    22: "Pop the top node from stack1 and set it as the current node.",
    23: "Push the current node onto stack2 to build the reverse post-order sequence.",
    25: "Checking if the left child of the current node exists.",
    26: "Left child exists, push it onto stack1.",
    29: "Checking if the right child of the current node exists.",
    30: "Right child exists, push it onto stack1.",
    34: "Starting a loop that continues as long as stack2 is not empty.",
    35: "Pop the top node from stack2 and set it as the current node.",
    36: "Print the data of the current node as part of the post-order traversal.",
    40: "Main function to test the postorderTraversal function.",
    41: "Initialize the root of the tree as NULL (indicating an empty tree).",
    42: "Calling the postorderTraversal function to perform the traversal starting from the root.",
    43: "Returning 0 to indicate successful program termination."
  },
  'C++': {
    1: "Include the standard input-output stream library.",
    2: "Include the stack library to use the stack data structure for storing nodes during traversal.",
    3: "Using the standard namespace.",
    5: "Define the BinaryTree class to represent the structure of the binary tree.",
    6: "Use public access specifier to make the following members accessible from outside the class.",
    7: "Declare a pointer to the root node.",
    9: "Executing the constructor to initialize the binary tree.",
    10: "Set the root to null pointer initially.",
    13: "Executing the postorderTraversal method to perform a post-order traversal of the binary tree.",
    14: "Set the current node to the root of the binary tree.",
    15: "Checking if the current node (root) is null pointer (i.e., the tree is empty).",
    16: "Tree is empty, returning from the method without further processing.",
    19: "Creating the first stack (stack1) to keep track of nodes during traversal.",
    20: "Creating the second stack (stack2) to store nodes in post-order.",
    21: "Pushing the root node onto stack1 to start the traversal.",
    22: "Starting a loop that continues as long as stack1 is not empty.",
    23: "Pop a node from stack1 and set it as the current node.",
    24: "Removing the top node from the stack to process it.",
    25: "Pushing the current node onto stack2 for post-order processing.",
    26: "Checking if the current node has a left child.",
    27: "Left child exists, push it onto stack1.",
    29: "Checking if the current node has a right child.",
    30: "Right child exists, push it onto stack1.",
    34: "Starting a loop that continues as long as stack2 is not empty.",
    35: "Pop a node from stack2 and set it as the current node.",
    36: "Removing the top node from the stack to process it.",
    37: "Print the data of the current node.",
    42: "Main method to test the BinaryTree class functionality.",
    43: "Create an instance of the BinaryTree class.",
    45: "Calling the postorderTraversal method to traverse the tree and print its elements in post-order.",
    46: "Returning 0 to indicate successful execution of the program.",
  },
};
  
export default explanations;
  