import { useState, useEffect, useRef } from 'react';
import { 
  graphPython,
  graphJava,
  graphCpp,
  graphC,
  graphBFSPython,
  graphBFSJava,
  graphBFSCpp,
  graphBFSC
 } from './graphAlgorithms';
import explanations from './explanations';
import codeSamplesTemplate from './codeSamples';

export const useGraph = (language, graphInput, edgeDirection, graphInputForC, maxVertices, start, startForC) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [highlightedMultipleLines, setHighlightedMultipleLines] = useState([]);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [callingLine, setCallingLine] = useState(null);
  const [secondCallingLine, setSecondCallingLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const delayRef = useRef(delay);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [graphVariables, setGraphVariables] = useState({});
  const [highlightedGraphVariables, setHighlightedGraphVariables] = useState([]);
  const [mapVariables, setmapVariables] = useState({});
  const [highlightedMapVariables, setHighlightedMapVariables] = useState([]);
  const [highlightedMapIndex, setHighlightedMapIndex] = useState([]);
  const [mapVariablesForC, setMapVariablesForC] = useState({});
  const [highlightedMapVariablesForC, setHighlightedMapVariablesForC] = useState([]);
  const [highlightedMapIndexForC, setHighlightedMapIndexForC] = useState([]);
  const [highlightedLineArrow, setHighlightedLineArrow] = useState([]);
  const [setsVariables, setSetsVariables] = useState({});
  const [highlightedSetVariables, setHighlightedSetVariables] = useState([]);
  const [highlightedSetIndex, setHighlightedSetIndex] = useState([]);
  const [stackVariables, setStackVariables] = useState({});
  const [highlightedStackVariables, setHighlightedStackVariables] = useState([]);
  const [highlightedStackIndex, setHighlightedStackIndex] = useState([]);
  const [strikeThroughStackIndex, setStrikeThroughStackIndex] = useState([]);
  const [nodeVariables, setNodeVariables] = useState({});
  const [highlightedNodeVariables, setHighlightedNodeVariables] = useState([]);
  const [highlightNodeAddress, setHighlightNodeAddress] = useState([]);
  const [highlightDataPart, setHighlightDataPart] = useState([]);
  const [highlightNextAddressPart, setHighlightNextAddressPart] = useState([]);
  const [adjList, setAdjList] = useState([]);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const [isMuted, setIsMuted] = useState(true);
  const isMutedRef = useRef(isMuted);
  const synth = window.speechSynthesis;
  const hasRun = useRef(false);
  const [globalGraphInstance, setGlobalGraphInstance] = useState(null);


  const updateVariables = (newStart) => {
    resetState();
    createGraphVisual(language);
    
    // Dynamically update the code samples
    let updatedCodeSamples = "";
  
    if (language === "C") {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(/bfs\(graph,\s*\d+\)/, `bfs(graph, ${newStart})`)
      );
    } else {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(/bfs\(\d+\)/, `bfs(${newStart})`)
      );
    }
  
    // Update the state
    setCodeSamples(updatedCodeSamples);
  };

  const createGraphVisual = (language) => {
    if (language === 'Python') {
      createGraphPython();
    } else if (language === 'Java') {
      createGraphJava();
    } else if (language === 'C') {
      createGraphC();
    } else if (language === 'C++') {
      createGraphCpp();
    }
  }

  useEffect(() => {
    if (!hasRun.current) {
      createGraphVisual(language);
      hasRun.current = true;
    }
  }, []);

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setHighlightedMultipleLines([]);
    setFocusedEndLine(null);
    setCallingLine(null);
    setSecondCallingLine(null);
    setGraphVariables({});
    setLogs([]);
    setHighlightedGraphVariables([]);
    setVariables({});
    setHighlightedVariables([]);
    setmapVariables({});
    setHighlightedMapVariables([]);
    setHighlightedMapIndex([]);
    setMapVariablesForC({});
    setHighlightedMapVariablesForC([]);
    setHighlightedMapIndexForC([]);
    setHighlightedLineArrow([]);
    setSetsVariables({});
    setHighlightedSetVariables([]);
    setHighlightedSetIndex([]);
    setStackVariables({});
    setHighlightedStackVariables([]);
    setHighlightedStackIndex([]);
    setStrikeThroughStackIndex([]);
    setNodeVariables({});
    setHighlightedNodeVariables([]);
    setHighlightNodeAddress([]);
    setHighlightDataPart([]);
    setHighlightNextAddressPart([]);
    setAdjList([]);
  };

  const speak = (message) => {
    return new Promise((resolve) => {
      if (isMutedRef.current) {
        resolve();
        return;
      }
      const utterance = new SpeechSynthesisUtterance(message);
      utterance.onend = resolve;
      synth.speak(utterance);
    });
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setCallingLine((prevCallingLine) => {
      if (prevCallingLine === lineNumber) {
        return null;
      }
      return prevCallingLine;
    });

    setSecondCallingLine((prevCallingLine) => {
      if (prevCallingLine === lineNumber) {
        return null;
      }
      return prevCallingLine;
    });

    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const highlightCallingLine = async (lineNumber) => {
    setCallingLine(lineNumber);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async (time=null) => {
    let start = Date.now();
    let ms;
    if (time === null) {
        ms = delayRef.current;
    } else {
        ms = time;
    }
    
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  // Run for Python
  const createGraphPython = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);
    
    const graph = await graphPython(
      graphInput,
      edgeDirection,
      setGraphVariables,
      setmapVariables
    );
    
    setGlobalGraphInstance(graph);

    setIsRunning(false);
  };

  const runBFSPython = async () => {
    setFocusedEndLine(null);
    setLogs([]);
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(22);
    await customSleep();

    await highlightCallingLine(22)
    await graphBFSPython(
      start,
      globalGraphInstance,
      highlightLine,
      setVariables,
      setHighlightedVariables,
      setHighlightedGraphVariables,
      setSetsVariables,
      setHighlightedMapIndex,
      setHighlightedSetIndex,
      setHighlightedSetVariables,
      setStackVariables,
      setHighlightedStackVariables,
      setHighlightedStackIndex,
      logMessage,
      customSleep
    );
    await highlightLine(22);
    await customSleep();
    
    await focusEndLine(22);
    setIsRunning(false);
  };

  // Run for Java
  const createGraphJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);
    
    const graph = await graphJava(
      graphInput,
      edgeDirection,
      setGraphVariables,
      setmapVariables
    );
    
    setGlobalGraphInstance(graph);

    setIsRunning(false);
  };

  const runBFSJava = async () => {
    setFocusedEndLine(null);
    setLogs([]);
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(31);
    await customSleep();

    await highlightCallingLine(31)
    await graphBFSJava(
      start,
      globalGraphInstance,
      highlightLine,
      setVariables,
      setHighlightedVariables,
      setHighlightedGraphVariables,
      setSetsVariables,
      setHighlightedMapIndex,
      setHighlightedSetIndex,
      setHighlightedSetVariables,
      setStackVariables,
      setHighlightedStackVariables,
      setHighlightedStackIndex,
      logMessage,
      customSleep
    );
    await highlightLine(31);
    await customSleep();
    
    await focusEndLine(33);
    setIsRunning(false);
  };

  // Run for Cpp
  const createGraphCpp = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);
    
    const graph = await graphCpp(
      graphInput,
      edgeDirection,
      setGraphVariables,
      setmapVariables
    );
    
    setGlobalGraphInstance(graph);

    setIsRunning(false);
  };

  const runBFSCpp = async () => {
    setFocusedEndLine(null);
    setLogs([]);
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(36);
    await customSleep();

    await highlightCallingLine(36)
    await graphBFSCpp(
      start,
      globalGraphInstance,
      highlightLine,
      setVariables,
      setHighlightedVariables,
      setHighlightedGraphVariables,
      setSetsVariables,
      setHighlightedMapIndex,
      setHighlightedSetIndex,
      setHighlightedSetVariables,
      setStackVariables,
      setHighlightedStackVariables,
      setHighlightedStackIndex,
      logMessage,
      customSleep
    );
    await highlightLine(36);
    await customSleep();

    await highlightLine(37);
    await customSleep();
    
    await focusEndLine(38);
    setIsRunning(false);
  };

  // Run for C
  const createGraphC = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);
    
    const graph = await graphC(
      maxVertices,
      graphInputForC,
      edgeDirection,
      setGraphVariables,
      setVariables,
      setAdjList,
      setMapVariablesForC,
      setNodeVariables
    );
    
    setGlobalGraphInstance(graph);

    setIsRunning(false);
  };

  const runBFSC = async () => {
    setFocusedEndLine(null);
    setLogs([]);
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(3);
    setVariables((vars) => ({ ...vars, 
      MAX_VERTICES: { variable_name: 'MAX_VERTICES', value: maxVertices }
    }));
    setHighlightedVariables(['MAX_VERTICES']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(31);
    await customSleep();

    await highlightCallingLine(31)
    await graphBFSC(
      startForC,
      globalGraphInstance,
      highlightLine,
      setVariables,
      setHighlightedVariables,
      setHighlightedGraphVariables,
      setHighlightedMapVariablesForC,
      setStackVariables,
      setHighlightedStackVariables,
      setHighlightedStackIndex,
      setStrikeThroughStackIndex,
      setHighlightedMapIndexForC,
      setHighlightedNodeVariables,
      setNodeVariables,
      setHighlightDataPart,
      setHighlightNextAddressPart,
      setHighlightNodeAddress,
      logMessage,
      customSleep
    );
    await highlightLine(31);
    await customSleep();

    await highlightLine(33);
    await customSleep();
    
    await focusEndLine(34);
    setIsRunning(false);
  };


  const runGraph = async () => {
    if (language === 'Python') {
      await runBFSPython();
    } else if (language === 'Java') {
      await runBFSJava();
    } else if (language === 'C') {
      await runBFSC();
    } else if (language === 'C++') {
      await runBFSCpp();
    }
  };

  return {
    highlightedLine,
    highlightedMultipleLines,
    focusedEndLine,
    callingLine,
    secondCallingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    graphVariables,
    highlightedGraphVariables,
    variables,
    highlightedVariables,
    adjList,
    mapVariables,
    highlightedMapVariables,
    highlightedMapIndex,
    mapVariablesForC,
    highlightedMapVariablesForC,
    highlightedMapIndexForC,
    highlightedLineArrow,
    setsVariables,
    highlightedSetVariables,
    highlightedSetIndex,
    stackVariables,
    highlightedStackVariables,
    highlightedStackIndex,
    strikeThroughStackIndex,
    nodeVariables,
    highlightedNodeVariables,
    highlightNodeAddress,
    highlightDataPart,
    highlightNextAddressPart,
    isMuted,
    codeSamples,
    updateVariables,
    setHighlightedLine,
    setHighlightedMultipleLines,
    setFocusedEndLine,
    setCallingLine,
    setSecondCallingLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setGraphVariables,
    setHighlightedGraphVariables,
    setIsMuted,
    runGraph
  };
};
