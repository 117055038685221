const explanations = {
    Python: {
        1: "Defining the merge_sort function, which will implement the merge sort algorithm",
        2: "Checking if the array has more than one element (base case for recursion)",
        3: "Finding the middle index of the array to split it into two halves",
        4: "Creating the left subarray L",
        5: "Creating the right subarray R",
        7: "Recursively calling merge_sort on the left half L",
        8: "Recursively calling merge_sort on the right half R",
        10: "Initializing pointers i, j, and k for merging the two halves",
        12: "Starting a loop to merge elements from L and R back into the original array",
        13: "Comparing the current elements of L and R",
        14: "Placing the smaller element from L into the original array",
        15: "Incrementing the index i for L to move to the next element",
        16: "The comparison is false, so the else block is executed",
        17: "Placing the smaller element from R into the original array",
        18: "Incrementing the index j for R to move to the next element",
        19: "Incrementing the index k for the original array",
        21: "Starting a loop to copy any remaining elements from L into the original array",
        22: "Copying the remaining element from L to the original array",
        23: "Incrementing the index i for L",
        24: "Incrementing the index k for the original array",
        26: "Starting a loop to copy any remaining elements from R into the original array",
        27: "Copying the remaining element from R to the original array",
        28: "Incrementing the index j for R",
        29: "Incrementing the index k for the original array",
        31: "Checking if the script is being run directly",
        32: "Defining and initializing the array to be sorted",
        33: "Calling merge_sort on the array",
        34: "Printing the sorted array"
    },
    Java: {
        1: "Importing the Arrays utility class for printing the array",
        3: "Defining the MergeSort class",
        5: "Defining the mergeSort method that implements the merge sort algorithm",
        6: "Checking if the array has more than one element (base case for recursion)",
        7: "Finding the middle index of the array to split it into two halves",
        9: "Creating the left subarray L",
        10: "Creating the right subarray R",
        12: "Copying elements from the original array to the left subarray L",
        13: "Copying elements from the original array to the right subarray R",
        15: "Recursively calling mergeSort on the left subarray L",
        16: "Recursively calling mergeSort on the right subarray R",
        18: "Initializing pointers i, j, and k for merging the two halves",
        19: "Starting a loop to merge elements from L and R back into the original array",
        20: "Comparing the current elements of L and R",
        21: "Placing the smaller element from L into the original array and incrementing both k and i.",
        22: "The comparison is false, so the else block is executed",
        23: "Placing the smaller element from R into the original array and incrementing both k and j.",
        27: "Starting a loop to copy any remaining elements from L into the original array",
        28: "Copying the remaining elements from L to the original array and incrementing both k and i.",
        31: "Starting a loop to copy any remaining elements from R into the original array",
        32: "Copying the remaining elements from R to the original array and incrementing both k and j.",
        37: "Defining the main method",
        38: "Creating and initializing the array to be sorted",
        39: "Calling mergeSort on the array",
        40: "Printing the sorted array using Arrays.toString"
    },
    C: {
        1: "Including the standard I/O header for printing the array",
        2: "Including the string manipulation header for memcpy",
        3: "Including the standard library header for dynamic memory management",
        5: "Defining the merge_sort function that implements the merge sort algorithm",
        6: "Checking if the array has more than one element (base case for recursion)",
        7: "Finding the middle index of the array to split it into two halves",
        9: "Allocating memory for the left subarray L",
        10: "Allocating memory for the right subarray R",
        12: "Copying elements from the original array to the left subarray L using memcpy",
        13: "Copying elements from the original array to the right subarray R using memcpy",
        15: "Recursively calling merge_sort on the left subarray L",
        16: "Recursively calling merge_sort on the right subarray R",
        18: "Initializing pointers i, j, and k for merging the two halves",
        20: "Starting a loop to merge elements from L and R back into the original array",
        21: "Comparing the current elements of L and R",
        22: "Placing the smaller element from L into the original array and incrementing both k and i.",
        23: "The comparison is false, so the else block is executed",
        24: "Placing the smaller element from R into the original array and incrementing both k and j.",
        28: "Starting a loop to copy any remaining elements from L into the original array",
        29: "Copying the remaining elements from L to the original array and incrementing both k and i.",
        32: "Starting a loop to copy any remaining elements from R into the original array",
        33: "Copying the remaining elements from R to the original array and incrementing both k and j.",
        36: "Freeing the allocated memory for L",
        37: "Freeing the allocated memory for R",
        41: "Defining the main function",
        42: "Creating and initializing the array to be sorted",
        43: "Calculating the number of elements in the array using sizeof",
        45: "Calling merge_sort on the array",
        47: "Printing the sorted array",
        48: "Iterates through the sorted array to print each element.",
        49: "Printing element from the sorted vector.",
        51: "Returns 0 to indicate successful execution."
    },
    "C++": {
        1: "Including the input/output stream header for printing the array",
        2: "Including the vector header for dynamic arrays",
        4: "Defining the merge_sort function that implements the merge sort algorithm",
        5: "Checking if the array has more than one element (base case for recursion)",
        6: "Finding the middle index of the array to split it into two halves",
        7: "Creating the left subarray L using a vector",
        8: "Creating the right subarray R using a vector",
        10: "Recursively calling merge_sort on the left subarray L",
        11: "Recursively calling merge_sort on the right subarray R",
        13: "Initializing pointers i, j, and k for merging the two halves",
        15: "Starting a loop to merge elements from L and R back into the original array",
        16: "Comparing the current elements of L and R",
        17: "Placing the smaller element from L into the original array and incrementing both k and i.",
        18: "The comparison is false, so the else block is executed",
        19: "Placing the smaller element from R into the original array and incrementing both k and j.",
        23: "Starting a loop to copy any remaining elements from L into the original array",
        24: "Copying the remaining elements from L to the original array and incrementing both k and i.",
        27: "Starting a loop to copy any remaining elements from R into the original array",
        28: "Copying the remaining elements from R to the original array and incrementing both k and j.",
        33: "Defining the main function",
        34: "Creating and initializing the vector to be sorted",
        35: "Calling merge_sort on the vector",
        36: "Printing the sorted vector",
        37: "Iterates through the sorted vector to print each element.",
        38: "Printing element from the sorted vector.",
        40: "Returns 0 to indicate successful execution."
    }
};

export default explanations;
