// Helper function to generate memory addresses (for visualization)
function generateMemoryAddress() {
    return '0x' + Math.floor(Math.random() * 0xFFF).toString(16);
}

class BinaryTreeNode {
    constructor(data, address) {
      this.data = data;
      this.left = null;
      this.right = null;
      this.address = address;
    }
}
  
class BinaryTreeForPython {
    constructor() {
      this.root = null;
    }
  
    // Method to add a new node to the tree
    add = async (
        data,
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    ) => {
        setVariables((vars) => ({ ...vars, 
            data: { variable_name: 'data', value: data }
        }));
        
        let nodeAddress = generateMemoryAddress();
        let newNode = new BinaryTreeNode(data, nodeAddress);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: '', left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'None', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'None', right: 'None'},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setNodeVariables((vars) => ({ ...vars, 
        [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'None', right: 'None'},
            address: newNode.address,
            parent: newNode.parent
        }
        }));
        
        if (this.root === null) {
            this.root = newNode;
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: 'None', child: newNode.address }
            }));
            
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: newNode.address, child: newNode.address }
            }));

            // Emptying vaiables
            setVariables(() => ({}));
        } else {
            let current = this.root;

            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current ? current.address : 'None'}
            }));

            while (true) {
                let parent = current;
                setRootVariables((vars) => ({ ...vars, 
                    parent: { variable_name: 'parent', value: parent.address }
                }));
                
                if (data < current.data) {
                    current = current.left;
                    setRootVariables((vars) => ({ ...vars, 
                        current: { 
                            variable_name: 'current',
                            value: current ? current.address : 'None'
                        }
                    }));
                    
                    if (current === null) {
                        setSingleNodeVariable(() => ({}));

                        parent.left = newNode;
                        setNodeVariables((vars) => ({ ...vars, 
                            [parent.address]: { 
                                variable_name: parent.address,
                                value: {data: parent.data, left: newNode.address, right: parent.right ? parent.right.address : 'None'},
                                address: parent.address,
                                parent: parent.parent
                            }
                        }));
                        
                        // Delete temp from headVariables
                        setRootVariables(() => ({
                            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                        }));

                        // Emptying vaiables
                        setVariables(() => ({}));
                        return;
                    }
                } else {
                    current = current.right;
                    setRootVariables((vars) => ({ ...vars, 
                        current: { 
                            variable_name: 'current',
                            value: current ? current.address : 'None'
                        }
                    }));

                    if (current === null) {
                        setSingleNodeVariable(() => ({}));

                        parent.right = newNode;
                        setNodeVariables((vars) => ({ ...vars, 
                            [parent.address]: { 
                                variable_name: parent.address,
                                value: {data: parent.data, left: parent.left ? parent.left.address : 'None', right: newNode.address},
                                address: parent.address,
                                parent: parent.parent
                            }
                        }));
                        
                        // Delete temp from headVariables
                        setRootVariables(() => ({
                            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                        }));

                        // Emptying vaiables
                        setVariables(() => ({}));
                        return;
                    }
                }
            }
        }
    };
  
    // Postorder Traversal
    postorderTraversal = async (
        setRootVariables,
        setHighlightedRootVariables,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        logMessage,
        highlightLine,
        customSleep
    ) => {

        await highlightLine(4);
        await customSleep();

        await highlightLine(5);
        let current = this.root;
        setRootVariables((vars) => ({ ...vars, 
            current: { variable_name: 'current', value: current.address}
        }));
        setHighlightedRootVariables(['current']);
        await customSleep();
        setHighlightedRootVariables([]);

        await highlightLine(6);
        setHighlightedRootVariables(['current']);
        await customSleep();
        setHighlightedRootVariables([]);
        if (current === null) {
            await highlightLine(7);
            await customSleep();
            return;
        }

        await highlightLine(9);
        setHighlightedRootVariables(['current']);
        let stack1 = [current];
        let _stack1 = [current.address];
        setStackVariables({ stack1: { variable_name: 'stack1', value: _stack1 } });
        setHighlightedStackVariables(['stack1']);
        await customSleep(1200);
        setHighlightedStackVariables([]);
        setHighlightedStackIndex([{ stackName: 'stack1', index: _stack1.length - 1 }]);
        await customSleep();
        setHighlightedStackIndex([]);
        setHighlightedRootVariables([]);
  
        await highlightLine(10);
        let stack2 = [];
        let _stack2 = [];
        setStackVariables((vars) => ({ ...vars, stack2: { variable_name: 'stack2', value: _stack2 }}));
        setHighlightedStackVariables(['stack2']);
        await customSleep();
        setHighlightedStackVariables([]);

    
        while (stack1.length > 0) {
            await highlightLine(11);
            setHighlightedStackVariables(['stack1']);
            await customSleep();
            setHighlightedStackVariables([]);

            await highlightLine(12);
            setHighlightedRootVariables(['current']);
            setHighlightedStackIndex([{ stackName: 'stack1', index: _stack1.length - 1 }]);
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: ''}
            }));
            await customSleep(1200);
            current = stack1.pop();
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current ? current.address : 'None'}
            }));
            _stack1.pop();
            setStackVariables((vars) => ({ ...vars, stack1: { variable_name: 'stack1', value: _stack1 }}));
            setHighlightNodeAddress([`${current.address}`]);
            await customSleep();
            setHighlightedRootVariables([]);
            setHighlightedStackIndex([]);
            setHighlightNodeAddress([]);

            await highlightLine(13);
            setHighlightedRootVariables(['current']);
            setHighlightNodeAddress([`${current.address}`]);
            _stack2.push("");
            setStackVariables((vars) => ({ ...vars, stack2: { variable_name: 'stack2', value: _stack2 }}));
            setHighlightedStackIndex([{ stackName: 'stack2', index: _stack2.length - 1 }]);
            await customSleep(1200);
            stack2.push(current);
            _stack2[_stack2.length - 1] = current ? current.address : 'None';
            setStackVariables((vars) => ({ ...vars, stack2: { variable_name: 'stack2', value: _stack2 }}));
            await customSleep();
            setHighlightedRootVariables([]);
            setHighlightedStackIndex([]);
            setHighlightNodeAddress([]);
    
            await highlightLine(14);
            setHighlightedRootVariables(['current']);
            setHighlightLeftPart([`${current.address}`]);
            await customSleep();
            setHighlightLeftPart([]);
            setHighlightedRootVariables([]);
            if (current.left !== null) {
                await highlightLine(15);
                setHighlightedRootVariables(['current']);
                setHighlightLeftPart([`${current.address}`]);
                _stack1.push("");
                setStackVariables((vars) => ({ ...vars, stack1: { variable_name: 'stack1', value: _stack1 }}));
                setHighlightedStackIndex([{ stackName: 'stack1', index: _stack1.length - 1 }]);
                await customSleep(1200);
                stack1.push(current.left);
                _stack1[_stack1.length - 1] = current.left ? current.left.address : 'None';
                setStackVariables((vars) => ({ ...vars, stack1: { variable_name: 'stack1', value: _stack1 }}));
                await customSleep();
                setHighlightedRootVariables([]);
                setHighlightedStackIndex([]);
                setHighlightLeftPart([]);
            }

            await highlightLine(16);
            setHighlightedRootVariables(['current']);
            setHighlightRightPart([`${current.address}`]);
            await customSleep();
            setHighlightRightPart([]);
            setHighlightedRootVariables([]);
            if (current.right !== null) {
                await highlightLine(17);
                setHighlightedRootVariables(['current']);
                setHighlightRightPart([`${current.address}`]);
                _stack1.push("");
                setStackVariables((vars) => ({ ...vars, stack1: { variable_name: 'stack1', value: _stack1 }}));
                setHighlightedStackIndex([{ stackName: 'stack1', index: _stack1.length - 1 }]);
                await customSleep(1200);
                stack1.push(current.right);
                _stack1[_stack1.length - 1] = current.right ? current.right.address : 'None';
                setStackVariables((vars) => ({ ...vars, stack1: { variable_name: 'stack1', value: _stack1 }}));
                await customSleep();
                setHighlightedRootVariables([]);
                setHighlightedStackIndex([]);
                setHighlightRightPart([]);
            }

            if (!(stack1.length > 0)) {
                await highlightLine(11);
                setHighlightedStackVariables(['stack1']);
                await customSleep();
                setHighlightedStackVariables([]);
            }
        }
    
        while (stack2.length > 0) {
            await highlightLine(19);
            setHighlightedStackVariables(['stack2']);
            await customSleep();
            setHighlightedStackVariables([]);

            await highlightLine(20);
            setHighlightedRootVariables(['current']);
            setHighlightedStackIndex([{ stackName: 'stack2', index: _stack2.length - 1 }]);
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: ''}
            }));
            await customSleep(1200);
            current = stack2.pop();
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current ? current.address : 'None'}
            }));
            _stack2.pop();
            setStackVariables((vars) => ({ ...vars, stack2: { variable_name: 'stack2', value: _stack2 }}));
            setHighlightNodeAddress([`${current.address}`]);
            await customSleep();
            setHighlightedRootVariables([]);
            setHighlightedStackIndex([]);
            setHighlightNodeAddress([]);
            
            await highlightLine(21);
            setHighlightDataPart([`${current.address}`]);
            setHighlightNodeAddress([`${current.address}`]);
            await logMessage(current.data);
            await customSleep();
            setHighlightDataPart([]);
            setHighlightNodeAddress([]);

            if (!(stack2.length > 0)) {
                await highlightLine(19);
                setHighlightedStackVariables(['stack2']);
                await customSleep();
                setHighlightedStackVariables([]);
            }
        }
        setStackVariables({});
    };
}

export const binaryTreePython = async (
    data,
    setNodeVariables,
    setSingleNodeVariable,
    setVariables,
    setRootVariables
  ) => {
    let binaryTree = new BinaryTreeForPython();
  
    await binaryTree.add(
        data[0],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[1],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[2],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[3],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[4],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[5],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[6],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );

    return binaryTree;
};

export const binaryTreePostorderTraversalPython = async (
    globalBinaryTree,
    setRootVariables,
    setHighlightedRootVariables,
    setHighlightLeftPart,
    setHighlightDataPart,
    setHighlightRightPart,
    setHighlightNodeAddress,
    setStackVariables,
    setHighlightedStackVariables,
    setHighlightedStackIndex,
    logMessage,
    highlightLine,
    customSleep
  ) => {
    await globalBinaryTree.postorderTraversal(
        setRootVariables,
        setHighlightedRootVariables,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        logMessage,
        highlightLine,
        customSleep
    );
}


class BinaryTreeForJava {
    constructor() {
      this.root = null;
    }
  
    // Method to add a new node to the tree
    add = async (
        data,
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    ) => {
        setVariables((vars) => ({ ...vars, 
            data: { variable_name: 'data', value: data }
        }));
        
        let nodeAddress = generateMemoryAddress();
        let newNode = new BinaryTreeNode(data, nodeAddress);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: '', left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'null', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'null', right: 'null'},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setNodeVariables((vars) => ({ ...vars, 
        [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'null', right: 'null'},
            address: newNode.address,
            parent: newNode.parent
        }
        }));
        
        if (this.root === null) {
            this.root = newNode;
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: 'null', child: newNode.address }
            }));
            
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: newNode.address, child: newNode.address }
            }));

            // Emptying vaiables
            setVariables(() => ({}));
        } else {
            let current = this.root;

            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current ? current.address : 'null'}
            }));

            while (true) {
                let parent = current;
                setRootVariables((vars) => ({ ...vars, 
                    parent: { variable_name: 'parent', value: parent.address }
                }));
                
                if (data < current.data) {
                    current = current.left;
                    setRootVariables((vars) => ({ ...vars, 
                        current: { 
                            variable_name: 'current',
                            value: current ? current.address : 'null'
                        }
                    }));
                    
                    if (current === null) {
                        setSingleNodeVariable(() => ({}));

                        parent.left = newNode;
                        setNodeVariables((vars) => ({ ...vars, 
                            [parent.address]: { 
                                variable_name: parent.address,
                                value: {data: parent.data, left: newNode.address, right: parent.right ? parent.right.address : 'null'},
                                address: parent.address,
                                parent: parent.parent
                            }
                        }));
                        
                        // Delete temp from headVariables
                        setRootVariables(() => ({
                            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                        }));

                        // Emptying vaiables
                        setVariables(() => ({}));
                        return;
                    }
                } else {
                    current = current.right;
                    setRootVariables((vars) => ({ ...vars, 
                        current: { 
                            variable_name: 'current',
                            value: current ? current.address : 'null'
                        }
                    }));

                    if (current === null) {
                        setSingleNodeVariable(() => ({}));

                        parent.right = newNode;
                        setNodeVariables((vars) => ({ ...vars, 
                            [parent.address]: { 
                                variable_name: parent.address,
                                value: {data: parent.data, left: parent.left ? parent.left.address : 'null', right: newNode.address},
                                address: parent.address,
                                parent: parent.parent
                            }
                        }));
                        
                        // Delete temp from headVariables
                        setRootVariables(() => ({
                            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                        }));

                        // Emptying vaiables
                        setVariables(() => ({}));
                        return;
                    }
                }
            }
        }
    };
  
    // Postorder Traversal
    postorderTraversal = async (
        setRootVariables,
        setHighlightedRootVariables,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        logMessage,
        highlightLine,
        customSleep
    ) => {

        await highlightLine(9);
        await customSleep();

        await highlightLine(10);
        let current = this.root;
        setRootVariables((vars) => ({ ...vars, 
            current: { variable_name: 'current', value: current.address}
        }));
        setHighlightedRootVariables(['current']);
        await customSleep();
        setHighlightedRootVariables([]);

        await highlightLine(11);
        setHighlightedRootVariables(['current']);
        await customSleep();
        setHighlightedRootVariables([]);
        if (current === null) {
            await highlightLine(12);
            await customSleep();
            return;
        }

        await highlightLine(15);
        let stack1 = [];
        let _stack1 = [];
        setStackVariables({ stack1: { variable_name: 'stack1', value: _stack1 } });
        setHighlightedStackVariables(['stack1']);
        await customSleep();
        setHighlightedStackVariables([]);
  
        await highlightLine(16);
        let stack2 = [];
        let _stack2 = [];
        setStackVariables((vars) => ({ ...vars, stack2: { variable_name: 'stack2', value: _stack2 }}));
        setHighlightedStackVariables(['stack2']);
        await customSleep();
        setHighlightedStackVariables([]);

        await highlightLine(17);
        setHighlightedRootVariables(['current']);
        _stack1 = [""];
        setStackVariables((vars) => ({ ...vars, stack1: { variable_name: 'stack1', value: _stack1 }}));
        setHighlightedStackIndex([{ stackName: 'stack1', index: _stack1.length - 1 }]);
        await customSleep(1200);
        stack1 = [current];
        _stack1 = [current.address];
        setStackVariables((vars) => ({ ...vars, stack1: { variable_name: 'stack1', value: _stack1 }}));
        await customSleep();
        setHighlightedStackIndex([]);
        setHighlightedRootVariables([]);

    
        while (stack1.length > 0) {
            await highlightLine(18);
            setHighlightedStackVariables(['stack1']);
            await customSleep();
            setHighlightedStackVariables([]);

            await highlightLine(19);
            setHighlightedRootVariables(['current']);
            setHighlightedStackIndex([{ stackName: 'stack1', index: _stack1.length - 1 }]);
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: ''}
            }));
            await customSleep(1200);
            current = stack1.pop();
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current ? current.address : 'None'}
            }));
            _stack1.pop();
            setStackVariables((vars) => ({ ...vars, stack1: { variable_name: 'stack1', value: _stack1 }}));
            setHighlightNodeAddress([`${current.address}`]);
            await customSleep();
            setHighlightedRootVariables([]);
            setHighlightedStackIndex([]);
            setHighlightNodeAddress([]);

            await highlightLine(20);
            setHighlightedRootVariables(['current']);
            setHighlightNodeAddress([`${current.address}`]);
            _stack2.push("");
            setStackVariables((vars) => ({ ...vars, stack2: { variable_name: 'stack2', value: _stack2 }}));
            setHighlightedStackIndex([{ stackName: 'stack2', index: _stack2.length - 1 }]);
            await customSleep(1200);
            stack2.push(current);
            _stack2[_stack2.length - 1] = current ? current.address : 'None';
            setStackVariables((vars) => ({ ...vars, stack2: { variable_name: 'stack2', value: _stack2 }}));
            await customSleep();
            setHighlightedRootVariables([]);
            setHighlightedStackIndex([]);
            setHighlightNodeAddress([]);
    
            await highlightLine(21);
            setHighlightedRootVariables(['current']);
            setHighlightLeftPart([`${current.address}`]);
            await customSleep();
            setHighlightLeftPart([]);
            setHighlightedRootVariables([]);
            if (current.left !== null) {
                await highlightLine(22);
                setHighlightedRootVariables(['current']);
                setHighlightLeftPart([`${current.address}`]);
                _stack1.push("");
                setStackVariables((vars) => ({ ...vars, stack1: { variable_name: 'stack1', value: _stack1 }}));
                setHighlightedStackIndex([{ stackName: 'stack1', index: _stack1.length - 1 }]);
                await customSleep(1200);
                stack1.push(current.left);
                _stack1[_stack1.length - 1] = current.left ? current.left.address : 'None';
                setStackVariables((vars) => ({ ...vars, stack1: { variable_name: 'stack1', value: _stack1 }}));
                await customSleep();
                setHighlightedRootVariables([]);
                setHighlightedStackIndex([]);
                setHighlightLeftPart([]);
            }

            await highlightLine(24);
            setHighlightedRootVariables(['current']);
            setHighlightRightPart([`${current.address}`]);
            await customSleep();
            setHighlightRightPart([]);
            setHighlightedRootVariables([]);
            if (current.right !== null) {
                await highlightLine(25);
                setHighlightedRootVariables(['current']);
                setHighlightRightPart([`${current.address}`]);
                _stack1.push("");
                setStackVariables((vars) => ({ ...vars, stack1: { variable_name: 'stack1', value: _stack1 }}));
                setHighlightedStackIndex([{ stackName: 'stack1', index: _stack1.length - 1 }]);
                await customSleep(1200);
                stack1.push(current.right);
                _stack1[_stack1.length - 1] = current.right ? current.right.address : 'None';
                setStackVariables((vars) => ({ ...vars, stack1: { variable_name: 'stack1', value: _stack1 }}));
                await customSleep();
                setHighlightedRootVariables([]);
                setHighlightedStackIndex([]);
                setHighlightRightPart([]);
            }

            if (!(stack1.length > 0)) {
                await highlightLine(18);
                setHighlightedStackVariables(['stack1']);
                await customSleep();
                setHighlightedStackVariables([]);
            }
        }
    
        while (stack2.length > 0) {
            await highlightLine(29);
            setHighlightedStackVariables(['stack2']);
            await customSleep();
            setHighlightedStackVariables([]);

            await highlightLine(30);
            setHighlightedRootVariables(['current']);
            setHighlightedStackIndex([{ stackName: 'stack2', index: _stack2.length - 1 }]);
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: ''}
            }));
            await customSleep(1200);
            current = stack2.pop();
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current ? current.address : 'None'}
            }));
            _stack2.pop();
            setStackVariables((vars) => ({ ...vars, stack2: { variable_name: 'stack2', value: _stack2 }}));
            setHighlightNodeAddress([`${current.address}`]);
            await customSleep();
            setHighlightedRootVariables([]);
            setHighlightedStackIndex([]);
            setHighlightNodeAddress([]);
            
            await highlightLine(31);
            setHighlightDataPart([`${current.address}`]);
            setHighlightNodeAddress([`${current.address}`]);
            await logMessage(current.data);
            await customSleep();
            setHighlightDataPart([]);
            setHighlightNodeAddress([]);

            if (!(stack2.length > 0)) {
                await highlightLine(29);
                setHighlightedStackVariables(['stack2']);
                await customSleep();
                setHighlightedStackVariables([]);
            }
        }
        setStackVariables({});
    };
}

export const binaryTreeJava = async (
    data,
    setNodeVariables,
    setSingleNodeVariable,
    setVariables,
    setRootVariables
  ) => {
    let binaryTree = new BinaryTreeForJava();
  
    await binaryTree.add(
        data[0],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[1],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[2],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[3],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[4],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[5],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[6],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );

    return binaryTree;
};

export const binaryTreePostorderTraversalJava = async (
    globalBinaryTree,
    setRootVariables,
    setHighlightedRootVariables,
    setHighlightLeftPart,
    setHighlightDataPart,
    setHighlightRightPart,
    setHighlightNodeAddress,
    setStackVariables,
    setHighlightedStackVariables,
    setHighlightedStackIndex,
    logMessage,
    highlightLine,
    customSleep
  ) => {
    await globalBinaryTree.postorderTraversal(
        setRootVariables,
        setHighlightedRootVariables,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        logMessage,
        highlightLine,
        customSleep
    );
}


class BinaryTreeForCpp {
    constructor() {
      this.root = null;
    }
  
    // Method to add a new node to the tree
    add = async (
        data,
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    ) => {
        setVariables((vars) => ({ ...vars, 
            data: { variable_name: 'data', value: data }
        }));
        
        let nodeAddress = generateMemoryAddress();
        let newNode = new BinaryTreeNode(data, nodeAddress);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: '', left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));
        
        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'nullptr', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'nullptr', right: 'nullptr'},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setNodeVariables((vars) => ({ ...vars, 
        [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'nullptr', right: 'nullptr'},
            address: newNode.address,
            parent: newNode.parent
        }
        }));
        
        if (this.root === null) {
            this.root = newNode;
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: 'nullptr' }
            }));
            
            setSingleNodeVariable(() => ({}));
            
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: newNode.address, child: newNode.address }
            }));

            // Emptying vaiables
            setVariables(() => ({}));
        } else {
            let current = this.root;
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current ? current.address : 'nullptr'}
            }));

            while (true) {
                let parent = current;
                setRootVariables((vars) => ({ ...vars, 
                    parent: { variable_name: 'parent', value: parent.address }
                }));
                
                if (data < current.data) {
                    current = current.left;
                    setRootVariables((vars) => ({ ...vars, 
                        current: { 
                            variable_name: 'current',
                            value: current ? current.address : 'nullptr'
                        }
                    }));
                    
                    if (current === null) {
                        setSingleNodeVariable(() => ({}));

                        parent.left = newNode;
                        setNodeVariables((vars) => ({ ...vars, 
                            [parent.address]: { 
                                variable_name: parent.address,
                                value: {data: parent.data, left: newNode.address, right: parent.right ? parent.right.address : 'nullptr'},
                                address: parent.address,
                                parent: parent.parent
                            }
                        }));

                        // Delete temp from headVariables
                        setRootVariables(() => ({
                            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                        }));

                        // Emptying vaiables
                        setVariables(() => ({}));
                        return;
                    }
                } else {
                    current = current.right;
                    setRootVariables((vars) => ({ ...vars, 
                        current: { 
                            variable_name: 'current',
                            value: current ? current.address : 'nullptr'
                        }
                    }));
                    
                    if (current === null) {
                        setSingleNodeVariable(() => ({}));

                        parent.right = newNode;
                        setNodeVariables((vars) => ({ ...vars, 
                            [parent.address]: { 
                                variable_name: parent.address,
                                value: {data: parent.data, left: parent.left ? parent.left.address : 'nullptr', right: newNode.address},
                                address: parent.address,
                                parent: parent.parent
                            }
                        }));

                        // Delete temp from headVariables
                        setRootVariables(() => ({
                            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                        }));

                        // Emptying vaiables
                        setVariables(() => ({}));
                        return;
                    }
                }
            }
        }
    };

    // Postorder Traversal
    postorderTraversal = async (
        setRootVariables,
        setHighlightedRootVariables,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        logMessage,
        highlightLine,
        customSleep
    ) => {

        await highlightLine(12);
        await customSleep();

        await highlightLine(13);
        let current = this.root;
        setRootVariables((vars) => ({ ...vars, 
            current: { variable_name: 'current', value: current.address}
        }));
        setHighlightedRootVariables(['current']);
        await customSleep();
        setHighlightedRootVariables([]);

        await highlightLine(14);
        setHighlightedRootVariables(['current']);
        await customSleep();
        setHighlightedRootVariables([]);
        if (current === null) {
            await highlightLine(15);
            await customSleep();
            return;
        }

        await highlightLine(18);
        let stack1 = [];
        let _stack1 = [];
        setStackVariables({ stack1: { variable_name: 'stack1', value: _stack1 } });
        setHighlightedStackVariables(['stack1']);
        await customSleep();
        setHighlightedStackVariables([]);
  
        await highlightLine(19);
        let stack2 = [];
        let _stack2 = [];
        setStackVariables((vars) => ({ ...vars, stack2: { variable_name: 'stack2', value: _stack2 }}));
        setHighlightedStackVariables(['stack2']);
        await customSleep();
        setHighlightedStackVariables([]);

        await highlightLine(20);
        setHighlightedRootVariables(['current']);
        _stack1 = [""];
        setStackVariables((vars) => ({ ...vars, stack1: { variable_name: 'stack1', value: _stack1 }}));
        setHighlightedStackIndex([{ stackName: 'stack1', index: _stack1.length - 1 }]);
        await customSleep(1200);
        stack1 = [current];
        _stack1 = [current.address];
        setStackVariables((vars) => ({ ...vars, stack1: { variable_name: 'stack1', value: _stack1 }}));
        await customSleep();
        setHighlightedStackIndex([]);
        setHighlightedRootVariables([]);

    
        while (stack1.length > 0) {
            await highlightLine(21);
            setHighlightedStackVariables(['stack1']);
            await customSleep();
            setHighlightedStackVariables([]);

            await highlightLine(22);
            setHighlightedRootVariables(['current']);
            setHighlightedStackIndex([{ stackName: 'stack1', index: _stack1.length - 1 }]);
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: ''}
            }));
            await customSleep(1200);
            current = stack1[stack1.length - 1];
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current ? current.address : 'nullptr'}
            }));
            setHighlightNodeAddress([`${current.address}`]);
            await customSleep();
            setHighlightedRootVariables([]);
            setHighlightedStackIndex([]);
            setHighlightNodeAddress([]);

            await highlightLine(23);
            setHighlightedStackIndex([{ stackName: 'stack1', index: _stack1.length - 1 }]);
            await customSleep(1200);
            stack1.pop();
            _stack1.pop();
            setStackVariables((vars) => ({ ...vars, stack1: { variable_name: 'stack1', value: _stack1 }}));
            setHighlightNodeAddress([`${current.address}`]);
            await customSleep();
            setHighlightedStackIndex([]);
            setHighlightNodeAddress([]);

            await highlightLine(24);
            setHighlightedRootVariables(['current']);
            setHighlightNodeAddress([`${current.address}`]);
            _stack2.push("");
            setStackVariables((vars) => ({ ...vars, stack2: { variable_name: 'stack2', value: _stack2 }}));
            setHighlightedStackIndex([{ stackName: 'stack2', index: _stack2.length - 1 }]);
            await customSleep(1200);
            stack2.push(current);
            _stack2[_stack2.length - 1] = current ? current.address : 'nullptr';
            setStackVariables((vars) => ({ ...vars, stack2: { variable_name: 'stack2', value: _stack2 }}));
            await customSleep();
            setHighlightedRootVariables([]);
            setHighlightedStackIndex([]);
            setHighlightNodeAddress([]);
    
            await highlightLine(25);
            setHighlightedRootVariables(['current']);
            setHighlightLeftPart([`${current.address}`]);
            await customSleep();
            setHighlightLeftPart([]);
            setHighlightedRootVariables([]);
            if (current.left !== null) {
                await highlightLine(26);
                setHighlightedRootVariables(['current']);
                setHighlightLeftPart([`${current.address}`]);
                _stack1.push("");
                setStackVariables((vars) => ({ ...vars, stack1: { variable_name: 'stack1', value: _stack1 }}));
                setHighlightedStackIndex([{ stackName: 'stack1', index: _stack1.length - 1 }]);
                await customSleep(1200);
                stack1.push(current.left);
                _stack1[_stack1.length - 1] = current.left ? current.left.address : 'nullptr';
                setStackVariables((vars) => ({ ...vars, stack1: { variable_name: 'stack1', value: _stack1 }}));
                await customSleep();
                setHighlightedRootVariables([]);
                setHighlightedStackIndex([]);
                setHighlightLeftPart([]);
            }

            await highlightLine(28);
            setHighlightedRootVariables(['current']);
            setHighlightRightPart([`${current.address}`]);
            await customSleep();
            setHighlightRightPart([]);
            setHighlightedRootVariables([]);
            if (current.right !== null) {
                await highlightLine(29);
                setHighlightedRootVariables(['current']);
                setHighlightRightPart([`${current.address}`]);
                _stack1.push("");
                setStackVariables((vars) => ({ ...vars, stack1: { variable_name: 'stack1', value: _stack1 }}));
                setHighlightedStackIndex([{ stackName: 'stack1', index: _stack1.length - 1 }]);
                await customSleep(1200);
                stack1.push(current.right);
                _stack1[_stack1.length - 1] = current.right ? current.right.address : 'nullptr';
                setStackVariables((vars) => ({ ...vars, stack1: { variable_name: 'stack1', value: _stack1 }}));
                await customSleep();
                setHighlightedRootVariables([]);
                setHighlightedStackIndex([]);
                setHighlightRightPart([]);
            }

            if (!(stack1.length > 0)) {
                await highlightLine(21);
                setHighlightedStackVariables(['stack1']);
                await customSleep();
                setHighlightedStackVariables([]);
            }
        }
    
        while (stack2.length > 0) {
            await highlightLine(33);
            setHighlightedStackVariables(['stack2']);
            await customSleep();
            setHighlightedStackVariables([]);

            await highlightLine(34);
            setHighlightedRootVariables(['current']);
            setHighlightedStackIndex([{ stackName: 'stack2', index: _stack2.length - 1 }]);
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: ''}
            }));
            await customSleep(1200);
            current = stack2[stack2.length - 1];
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current ? current.address : 'nullptr'}
            }));
            setHighlightNodeAddress([`${current.address}`]);
            await customSleep();
            setHighlightedRootVariables([]);
            setHighlightedStackIndex([]);
            setHighlightNodeAddress([]);

            await highlightLine(35);
            setHighlightedStackIndex([{ stackName: 'stack2', index: _stack2.length - 1 }]);
            await customSleep(1200);
            stack2.pop();
            _stack2.pop();
            setStackVariables((vars) => ({ ...vars, stack2: { variable_name: 'stack2', value: _stack2 }}));
            setHighlightNodeAddress([`${current.address}`]);
            await customSleep();
            setHighlightedStackIndex([]);
            setHighlightNodeAddress([]);
            
            await highlightLine(36);
            setHighlightDataPart([`${current.address}`]);
            setHighlightNodeAddress([`${current.address}`]);
            await logMessage(current.data);
            await customSleep();
            setHighlightDataPart([]);
            setHighlightNodeAddress([]);

            if (!(stack2.length > 0)) {
                await highlightLine(33);
                setHighlightedStackVariables(['stack2']);
                await customSleep();
                setHighlightedStackVariables([]);
            }
        }
        setStackVariables({});
    };

}

export const binaryTreeCpp = async (
    data,
    setNodeVariables,
    setSingleNodeVariable,
    setVariables,
    setRootVariables
  ) => {
    let binaryTree = new BinaryTreeForCpp();
  
    await binaryTree.add(
        data[0],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[1],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[2],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[3],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[4],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[5],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[6],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );

    return binaryTree;
};

export const binaryTreePostorderTraversalCpp = async (
    globalBinaryTree,
    setRootVariables,
    setHighlightedRootVariables,
    setHighlightLeftPart,
    setHighlightDataPart,
    setHighlightRightPart,
    setHighlightNodeAddress,
    setStackVariables,
    setHighlightedStackVariables,
    setHighlightedStackIndex,
    logMessage,
    highlightLine,
    customSleep
  ) => {
    await globalBinaryTree.postorderTraversal(
        setRootVariables,
        setHighlightedRootVariables,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        logMessage,
        highlightLine,
        customSleep
    );
}

class BinaryTreeForC {
    constructor() {
      this.root = null;
    }
  
    // Method to add a new node to the tree
    add = async (
        data,
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    ) => {
        setVariables((vars) => ({ ...vars, 
            data: { variable_name: 'data', value: data }
        }));
        
        let nodeAddress = generateMemoryAddress();
        let newNode = new BinaryTreeNode(data, nodeAddress);
        
        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: '', left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));
        
        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'NULL', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'NULL', right: 'NULL'},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setNodeVariables((vars) => ({ ...vars, 
        [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'NULL', right: 'NULL'},
            address: newNode.address,
            parent: newNode.parent
        }
        }));
        
        if (this.root === null) {
            this.root = newNode;
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: 'NULL' }
            }));
            
            // Delete single node variable
            setSingleNodeVariable(() => ({}));
            
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: newNode.address, child: newNode.address }
            }));

            // Emptying vaiables
            setVariables(() => ({}));
        } else {
            let current = this.root;
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current ? current.address : 'NULL'}
            }));

            while (true) {
                let parent = current;
                setRootVariables((vars) => ({ ...vars, 
                    parent: { variable_name: 'parent', value: parent.address }
                }));
                
                if (data < current.data) {
                    current = current.left;
                    setRootVariables((vars) => ({ ...vars, 
                        current: { 
                            variable_name: 'current',
                            value: current ? current.address : 'NULL'
                        }
                    }));
                    
                    if (current === null) {
                        setSingleNodeVariable(() => ({}));

                        parent.left = newNode;
                        setNodeVariables((vars) => ({ ...vars, 
                            [parent.address]: { 
                                variable_name: parent.address,
                                value: {data: parent.data, left: newNode.address, right: parent.right ? parent.right.address : 'NULL'},
                                address: parent.address,
                                parent: parent.parent
                            }
                        }));

                        // Delete temp from headVariables
                        setRootVariables(() => ({
                            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                        }));

                        // Emptying vaiables
                        setVariables(() => ({}));
                        return;
                    }
                } else {
                    current = current.right;
                    setRootVariables((vars) => ({ ...vars, 
                        current: { 
                            variable_name: 'current',
                            value: current ? current.address : 'NULL'
                        }
                    }));
                    
                    if (current === null) {
                        setSingleNodeVariable(() => ({}));

                        parent.right = newNode;
                        setNodeVariables((vars) => ({ ...vars, 
                            [parent.address]: { 
                                variable_name: parent.address,
                                value: {data: parent.data, left: parent.left ? parent.left.address : 'NULL', right: newNode.address},
                                address: parent.address,
                                parent: parent.parent
                            }
                        }));

                        // Delete temp from headVariables
                        setRootVariables(() => ({
                            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                        }));

                        // Emptying vaiables
                        setVariables(() => ({}));
                        return;
                    }
                }
            }
        }
    };

    // Postorder Traversal
    postorderTraversal = async (
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        setStrikeThroughStackIndex,
        logMessage,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    ) => {

        await highlightLine(9);
        await customSleep();

        await highlightLine(10);
        setHighlightedRootVariables(['root']);
        await customSleep();
        setHighlightedRootVariables([]);
        if (this.root === null) {
            await highlightLine(11);
            await customSleep();
            return;
        }

        await highlightLine(14);
        let stack1 = [];
        let _stack1 = [];
        setStackVariables({ stack1: { variable_name: 'stack1', value: _stack1 } });
        setHighlightedStackVariables(['stack1']);
        await customSleep();
        setHighlightedStackVariables([]);

        await highlightLine(15);
        let stack2 = [];
        let _stack2 = [];
        setStackVariables((vars) => ({ ...vars, stack2: { variable_name: 'stack2', value: _stack2 }}));
        setHighlightedStackVariables(['stack2']);
        await customSleep();
        setHighlightedStackVariables([]);

        await highlightLine(16);
        let top1 = -1;
        let top2 = -1;
        setVariables((vars) => ({ ...vars, 
            top1: { variable_name: 'top1', value: top1 },
            top2: { variable_name: 'top2', value: top2 }
        }));
        setHighlightedVariables(['top1', 'top2']);
        await customSleep();
        setHighlightedVariables([]);

        await highlightLine(18);
        setHighlightedVariables(['top1']);
        await customSleep(1200);
        top1 += 1;
        setVariables((vars) => ({ ...vars, 
            top1: { variable_name: 'top1', value: top1 }
        }));
        await customSleep(1200);
        setHighlightedVariables([]);
        setHighlightedRootVariables(['root']);
        setHighlightNodeAddress([`${this.root.address}`]);
        _stack1[top1] = " ";
        setStackVariables((vars) => ({ ...vars, stack1: { variable_name: 'stack1', value: _stack1 }}));
        setStrikeThroughStackIndex((prevState) =>
            prevState.filter(item => item.stackName !== 'stack1' || item.index !== top1)
        );
        setHighlightedStackIndex([{ stackName: 'stack1', index: top1 }]);
        await customSleep(1200);
        stack1[top1] = this.root;
        _stack1[top1] = this.root.address;
        setStackVariables((vars) => ({ ...vars, stack1: { variable_name: 'stack1', value: _stack1 }}));
        await customSleep();
        setHighlightedRootVariables([]);
        setHighlightedStackIndex([]);
        setHighlightNodeAddress([]);

    
        while (top1 >= 0) {
            await highlightLine(20);
            setHighlightedVariables(['top1']);
            await customSleep();
            setHighlightedVariables([]);

            await highlightLine(21);
            let current = " ";
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current.address}
            }));
            setHighlightedRootVariables(['current']);
            await customSleep(1200);
            setHighlightedStackIndex([{ stackName: 'stack1', index: top1 }]);
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: ''}
            }));
            await customSleep(1200);
            current = stack1[top1];
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current ? current.address : 'NULL'}
            }));
            setHighlightNodeAddress([`${current.address}`]);
            setHighlightedVariables(['top1']);
            await customSleep(1200);
            setHighlightedStackIndex([]);
            let strikeIndex = top1;
            setStrikeThroughStackIndex((prevState) => [
                ...prevState, 
                { stackName: 'stack1', index: strikeIndex }
            ]);
            top1 -= 1;
            setVariables((vars) => ({ ...vars, 
                top1: { variable_name: 'top1', value: top1 }
            }));
            await customSleep();
            setHighlightedRootVariables([]);
            setHighlightNodeAddress([]);
            setHighlightedVariables([]);

            await highlightLine(22);
            setHighlightedVariables(['top2']);
            await customSleep(1200);
            top2 += 1;
            setVariables((vars) => ({ ...vars, 
                top2: { variable_name: 'top2', value: top2 }
            }));
            await customSleep(1200);
            setHighlightedVariables([]);
            setHighlightedRootVariables(['current']);
            _stack2[top2] = " ";
            setStackVariables((vars) => ({ ...vars, stack2: { variable_name: 'stack2', value: _stack2 }}));
            setStrikeThroughStackIndex((prevState) =>
                prevState.filter(item => item.stackName !== 'stack2' || item.index !== top2)
            );
            setHighlightedStackIndex([{ stackName: 'stack2', index: top2 }]);
            await customSleep(1200);
            stack2[top2] = current;
            _stack2[top2] = current.address;
            setStackVariables((vars) => ({ ...vars, stack2: { variable_name: 'stack2', value: _stack2 }}));
            await customSleep();
            setHighlightedRootVariables([]);
            setHighlightedStackIndex([]);

            await highlightLine(24);
            setHighlightLeftPart([`${current.address}`]);
            await customSleep();
            setHighlightLeftPart([]);
            if (current.left !== null) {
                await highlightLine(25);
                setHighlightedVariables(['top1']);
                await customSleep(1200);
                top1 += 1;
                setVariables((vars) => ({ ...vars, 
                    top1: { variable_name: 'top1', value: top1 }
                }));
                await customSleep(1200);
                setHighlightedVariables([]);
                setHighlightedRootVariables(['current']);
                setHighlightLeftPart([`${current.address}`]);
                _stack1[top1] = " ";
                setStackVariables((vars) => ({ ...vars, stack1: { variable_name: 'stack1', value: _stack1 }}));
                setStrikeThroughStackIndex((prevState) =>
                    prevState.filter(item => item.stackName !== 'stack1' || item.index !== top1)
                );
                setHighlightedStackIndex([{ stackName: 'stack1', index: top1 }]);
                await customSleep(1200);
                stack1[top1] = current.left;
                _stack1[top1] = current.left ? current.left.address : 'NULL';
                setStackVariables((vars) => ({ ...vars, stack1: { variable_name: 'stack1', value: _stack1 }}));
                await customSleep();
                setHighlightedRootVariables([]);
                setHighlightedStackIndex([]);
                setHighlightLeftPart([]);
            }

            await highlightLine(28);
            setHighlightRightPart([`${current.address}`]);
            await customSleep();
            setHighlightRightPart([]);
            if (current.right !== null) {
                await highlightLine(29);
                setHighlightedVariables(['top1']);
                await customSleep(1200);
                top1 += 1;
                setVariables((vars) => ({ ...vars, 
                    top1: { variable_name: 'top1', value: top1 }
                }));
                await customSleep(1200);
                setHighlightedVariables([]);
                setHighlightedRootVariables(['current']);
                setHighlightRightPart([`${current.address}`]);
                _stack1[top1] = " ";
                setStackVariables((vars) => ({ ...vars, stack1: { variable_name: 'stack1', value: _stack1 }}));
                setStrikeThroughStackIndex((prevState) =>
                    prevState.filter(item => item.stackName !== 'stack1' || item.index !== top1)
                );
                setHighlightedStackIndex([{ stackName: 'stack1', index: top1 }]);
                await customSleep(1200);
                stack1[top1] = current.right;
                _stack1[top1] = current.right ? current.right.address : 'NULL';
                setStackVariables((vars) => ({ ...vars, stack1: { variable_name: 'stack1', value: _stack1 }}));
                await customSleep();
                setHighlightedRootVariables([]);
                setHighlightedStackIndex([]);
                setHighlightRightPart([]);
            }

            if (!(top1 >= 0)) {
                await highlightLine(20);
                setHighlightedVariables(['top1']);
                await customSleep();
                setHighlightedVariables([]);
            }
        }
    
        while (top2 >= 0) {
            await highlightLine(33);
            setHighlightedVariables(['top2']);
            await customSleep();
            setHighlightedVariables([]);

            await highlightLine(34);
            let current = " ";
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: " "}
            }));
            setHighlightedRootVariables(['current']);
            await customSleep(1200);
            setHighlightedStackIndex([{ stackName: 'stack2', index: top2 }]);
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: ''}
            }));
            await customSleep(1200);
            current = stack2[top2];
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current ? current.address : 'NULL'}
            }));
            setHighlightNodeAddress([`${current.address}`]);
            setHighlightedVariables(['top2']);
            await customSleep(1200);
            setHighlightedStackIndex([]);
            let strikeIndex = top2;
            setStrikeThroughStackIndex((prevState) => [
                ...prevState, 
                { stackName: 'stack2', index: strikeIndex }
            ]);
            top2 -= 1;
            setVariables((vars) => ({ ...vars, 
                top2: { variable_name: 'top2', value: top2 }
            }));
            await customSleep();
            setHighlightedRootVariables([]);
            setHighlightNodeAddress([]);
            setHighlightedVariables([]);

            await highlightLine(35);
            setHighlightDataPart([`${current.address}`]);
            setHighlightNodeAddress([`${current.address}`]);
            await logMessage(current.data);
            await customSleep();
            setHighlightDataPart([]);
            setHighlightNodeAddress([]);

            if (!(top2 >= 0)) {
                await highlightLine(33);
                setHighlightedVariables(['top2']);
                await customSleep();
                setHighlightedVariables([]);
            }
        }
        
        // Delete current from rootVariables
        setRootVariables(() => ({
            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
        }));
        // remove stack variable
        setStackVariables({});
        // remove variables
        setVariables({});
    };

}

export const binaryTreeC = async (
    data,
    setNodeVariables,
    setSingleNodeVariable,
    setVariables,
    setRootVariables,
  ) => {
    let binaryTree = new BinaryTreeForC();
  
    await binaryTree.add(
        data[0],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[1],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[2],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[3],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[4],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[5],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    
    await binaryTree.add(
        data[6],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables
    );
    return binaryTree;
};

export const binaryTreePostorderTraversalC = async (
    globalBinaryTree,
    setVariables,
    setRootVariables,
    setHighlightedVariables,
    setHighlightedRootVariables,
    setHighlightLeftPart,
    setHighlightDataPart,
    setHighlightRightPart,
    setHighlightNodeAddress,
    setStackVariables,
    setHighlightedStackVariables,
    setHighlightedStackIndex,
    setStrikeThroughStackIndex,
    logMessage,
    highlightLine,
    highlightSecondCallingLine,
    customSleep
  ) => {
    await globalBinaryTree.postorderTraversal(
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        setStrikeThroughStackIndex,
        logMessage,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    );
}
