export const insertionSortPython = async (list, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, customSleep) => {
    for (let i = 1; i < list.length; i++) {
      await highlightLine(1);
      setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
      setHighlightedVariables(['i']);
      await customSleep();
      setHighlightedVariables([]);
      
      await highlightLine(2);
      let key = list[i];
      setVariables((vars) => ({ ...vars, key: { variable_name: 'key', value: key } }));
      setHighlightedVariables(['key']);
      setHighlightedIndices([{ arrayName: 'list', index: i, iteratorName: 'i' }]);
      await customSleep();
      setHighlightedVariables([]);
      setHighlightedIndices([]);
      
      await highlightLine(3);
      let j = i - 1;
      setVariables((vars) => ({ ...vars, j: { variable_name: 'j', value: j } }));
      setHighlightedVariables(['j']);
      await customSleep();
      setHighlightedVariables([]);
      
      while (j >= 0 && list[j] > key) {
        await highlightLine(4);
        setHighlightedVariables(['j', 'key']);
        setHighlightedIndices([{ arrayName: 'list', index: j, iteratorName: 'j' }]);
        await customSleep();
        setHighlightedVariables([]);
        setHighlightedIndices([]);
        
        await highlightLine(5);
        list[j + 1] = list[j];
        setHighlightedIndices([{ arrayName: 'list', index: j + 1, iteratorName: 'j + 1' }, { arrayName: 'list', index: j, iteratorName: 'j' }]);
        await customSleep();
        setHighlightedIndices([]);
        
        await highlightLine(6);
        j = j - 1;
        setVariables((vars) => ({ ...vars, j: { variable_name: 'j', value: j }, i: { variable_name: 'i', value: i }}));
        setHighlightedVariables(['j']);
        await customSleep();
        setHighlightedVariables([]);
      }
      
      await highlightLine(7);
      list[j + 1] = key;
      setHighlightedIndices([{ arrayName: 'list', index: j + 1, iteratorName: 'j + 1' }]);
      setHighlightedVariables(['key']);
      await customSleep();
      setHighlightedIndices([]);
      setHighlightedVariables([]);
    }
  
    await highlightLine(8);
    await customSleep();
    setHighlightedIndices([]);
    return list;
  };
  
  export const insertionSortJava = async (list, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, customSleep) => {
    await highlightLine(2);
    let n = list.length;
    setVariables((vars) => ({ ...vars, n: { variable_name: 'n', value: n } }));
    setHighlightedVariables(['n']);
    await customSleep();
    setHighlightedVariables([]);

    for (let i = 1; i < n; i++) {      
      await highlightLine(3);
      setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
      setHighlightedVariables(['i', 'n']);
      await customSleep();
      setHighlightedVariables([]);
      
      await highlightLine(4);
      let key = list[i];
      setVariables((vars) => ({ ...vars, key: { variable_name: 'key', value: key } }));
      setHighlightedVariables(['key']);
      setHighlightedIndices([{ arrayName: 'list', index: i, iteratorName: 'i' }]);
      await customSleep();
      setHighlightedVariables([]);
      setHighlightedIndices([]);
      
      await highlightLine(5);
      let j = i - 1;
      setVariables((vars) => ({ ...vars, j: { variable_name: 'j', value: j }, i: { variable_name: 'i', value: i } }));
      setHighlightedVariables(['j']);
      await customSleep();
      setHighlightedVariables([]);
      
      while (j >= 0 && list[j] > key) {
        await highlightLine(6);
        setHighlightedIndices([{ arrayName: 'list', index: j, iteratorName: 'j' }]);
        setHighlightedVariables(['j', 'key']);
        await customSleep();
        setHighlightedIndices([]);
        setHighlightedVariables([]);

        await highlightLine(7);
        list[j + 1] = list[j];
        setHighlightedIndices([{ arrayName: 'list', index: j + 1, iteratorName: 'j + 1' }, { arrayName: 'list', index: j, iteratorName: 'j' }]);
        await customSleep();
        setHighlightedIndices([]);
        
        await highlightLine(8);
        j = j - 1;
        setVariables((vars) => ({ ...vars, j: { variable_name: 'j', value: j } }));
        setHighlightedVariables(['j']);
        await customSleep();
        setHighlightedVariables([]);
      }
      
      await highlightLine(10);
      list[j + 1] = key;
      setHighlightedIndices([{ arrayName: 'list', index: j + 1, iteratorName: 'j + 1' }]);
      setHighlightedVariables(['key']);
      await customSleep();
      setHighlightedIndices([]);
      setHighlightedVariables([]);
    }
  
    await highlightLine(12);
    await customSleep();
    setHighlightedIndices([]);
    return list;
  };
  
  export const insertionSortC = async (list, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, customSleep) => {
    await highlightLine(2);
    let i, key, j;
    setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i }, key: { variable_name: 'key', value: key }, j: { variable_name: 'j', value: j }}));
    setHighlightedVariables(['i', 'key', 'j']);
    await customSleep();
    setHighlightedVariables([]);

    let n = list.length;
    for (let i = 1; i < n; i++) {
      await highlightLine(3);
      setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
      setHighlightedVariables(['i', 'n']);
      await customSleep();
      setHighlightedVariables([]);
      
      await highlightLine(4);
      let key = list[i];
      setVariables((vars) => ({ ...vars, key: { variable_name: 'key', value: key } }));
      setHighlightedVariables(['key']);
      setHighlightedIndices([{ arrayName: 'list', index: i, iteratorName: 'i' }]);
      await customSleep();
      setHighlightedVariables([]);
      setHighlightedIndices([]);
      
      await highlightLine(5);
      let j = i - 1;
      setVariables((vars) => ({ ...vars, j: { variable_name: 'j', value: j }, i: { variable_name: 'i', value: i } }));
      setHighlightedVariables(['j']);
      await customSleep();
      setHighlightedVariables([]);
      
      while (j >= 0 && list[j] > key) {
        await highlightLine(6);
        setHighlightedIndices([{ arrayName: 'list', index: j, iteratorName: 'j' }]);
        setHighlightedVariables(['j', 'key']);
        await customSleep();
        setHighlightedIndices([]);
        setHighlightedVariables([]);

        await highlightLine(7);
        list[j + 1] = list[j];
        setHighlightedIndices([{ arrayName: 'list', index: j + 1, iteratorName: 'j + 1' }, { arrayName: 'list', index: j, iteratorName: 'j' }]);
        await customSleep();
        setHighlightedIndices([]);
        
        await highlightLine(8);
        j = j - 1;
        setVariables((vars) => ({ ...vars, j: { variable_name: 'j', value: j } }));
        setHighlightedVariables(['j']);
        await customSleep();
        setHighlightedVariables([]);
      }
      
      await highlightLine(10);
      list[j + 1] = key;
      setHighlightedIndices([{ arrayName: 'list', index: j + 1, iteratorName: 'j + 1' }]);
      setHighlightedVariables(['key']);
      await customSleep();
      setHighlightedIndices([]);
      setHighlightedVariables([]);
    }
  
    await highlightLine(12);
    await customSleep();
    setHighlightedIndices([]);
    return list;
  };
  
  export const insertionSortCpp = async (list, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, customSleep) => {
    await highlightLine(3);
    let i, key, j;
    setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i }, key: { variable_name: 'key', value: key }, j: { variable_name: 'j', value: j }}));
    setHighlightedVariables(['i', 'key', 'j']);
    await customSleep();
    setHighlightedVariables([]);

    let n = list.length;
    for (let i = 1; i < n; i++) {
      await highlightLine(4);
      setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
      setHighlightedVariables(['i', 'n']);
      await customSleep();
      setHighlightedVariables([]);
      
      await highlightLine(5);
      let key = list[i];
      setVariables((vars) => ({ ...vars, key: { variable_name: 'key', value: key } }));
      setHighlightedVariables(['key']);
      setHighlightedIndices([{ arrayName: 'list', index: i, iteratorName: 'i' }]);
      await customSleep();
      setHighlightedVariables([]);
      setHighlightedIndices([]);
      
      await highlightLine(6);
      let j = i - 1;
      setVariables((vars) => ({ ...vars, j: { variable_name: 'j', value: j }, i: { variable_name: 'i', value: i } }));
      setHighlightedVariables(['j']);
      await customSleep();
      setHighlightedVariables([]);
      
      while (j >= 0 && list[j] > key) {
        await highlightLine(7);
        setHighlightedIndices([{ arrayName: 'list', index: j, iteratorName: 'j' }]);
        setHighlightedVariables(['j', 'key']);
        await customSleep();
        setHighlightedIndices([]);
        setHighlightedVariables([]);

        await highlightLine(8);
        list[j + 1] = list[j];
        setHighlightedIndices([{ arrayName: 'list', index: j + 1, iteratorName: 'j + 1' }, { arrayName: 'list', index: j, iteratorName: 'j' }]);
        await customSleep();
        setHighlightedIndices([]);
        
        await highlightLine(9);
        j = j - 1;
        setVariables((vars) => ({ ...vars, j: { variable_name: 'j', value: j } }));
        setHighlightedVariables(['j']);
        await customSleep();
        setHighlightedVariables([]);
      }
      
      await highlightLine(11);
      list[j + 1] = key;
      setHighlightedIndices([{ arrayName: 'list', index: j + 1, iteratorName: 'j + 1' }]);
      setHighlightedVariables(['key']);
      await customSleep();
      setHighlightedIndices([]);
      setHighlightedVariables([]);
    }
  
    await highlightLine(13);
    await customSleep();
    setHighlightedIndices([]);
    return list;
  };
  