const explanations = {
  Python: {
    1: "Define the Graph class to represent a graph structure.",
    2: "Executing the constructor method to initialize the graph object.",
    3: "Initialize an empty dictionary to represent the adjacency list of the graph.",
    5: "Executing the add_edge method to add edges between vertices.",
    6: "Checking if the vertex is not already in the graph.",
    7: "Vertex not in the graph, so initialize an empty list for the vertex in the adjacency list to store its neighbors.",
    8: "Checking if the neighbor is not already present in the graph.",
    9: "Neighbor not in the graph, so initialize an empty list for the neighbor to store its connections.",
    11: "Adding the neighbor to the adjacency list of the vertex.",
    12: "Checking if the graph is undirected (i.e., directed is False).",
    13: "Graph is undirected, adding the vertex to the adjacency list of the neighbor as well.",
    16: "Main block to test the Graph class functionality.",
    17: "Creating an instance of the Graph class.",
    18: "Adding an undirected edge between given two vertices.",
    19: "Adding an undirected edge between given two vertices.",
    20: "Adding an undirected edge between given two vertices.",
    21: "Adding an undirected edge between given two vertices.",
    22: "Adding a directed edge between given two vertices."
  },
  Java: {
    1: "Importing the necessary Java utilities for lists, sets, stacks, and queues.",
    3: "Defining the Graph class to represent the graph structure.",
    4: "A HashMap is used to store the adjacency list for each vertex.",
    6: "Executing the constructor method.",
    7: "Initialize the graph as an empty HashMap.",
    10: "Executing the addEdge method to add edges between vertices.",
    11: "Add the vertex if it does not exist in the graph.",
    12: "Add the neighbot if it does not exist in the graph.",
    13: "Adding the neighbor to the vertex's adjacency list.",
    14: "Checking if the graph is undirected (i.e., directed is false).",
    15: "Graph is undirected, adding the vertex to the adjacency list of the neighbor as well.",
    20: "Define the Main class to test the Graph class functionality.",
    21: "Main method to execute the program.",
    22: "Creating an instance of the Graph class.",
    23: "Adding an undirected edge between given two vertices.",
    24: "Adding an undirected edge between given two vertices.",
    25: "Adding an undirected edge between given two vertices.",
    26: "Adding an undirected edge between given two vertices.",
    27: "Adding a directed edge between given two vertices."
  },
  C: {
    1: "Including the standard input-output library for printing to the console.",
    2: "Including the standard library for memory allocation and other utilities.",
    3: "Including the standard library for using boolean values.",
    5: "Defining a constant for the maximum number of vertices in the graph.",
    7: "Defining a structure for a node in the adjacency list. Each node stores a vertex and a pointer to the next node.",
    8: "Defining a structure for a node in the adjacency list. Each node stores a vertex and a pointer to the next node.",
    12: "Defining a structure for the graph. The graph stores an array of adjacency lists, one for each vertex.",
    13: "Defining a structure for the graph. The graph stores an array of adjacency lists, one for each vertex.",
    16: "Executing the createGraph function to create a new graph.",
    17: "Allocating memory for the graph.",
    18: "Iterating through all vertices from 0 to MAX_VERTICES to initialize the adjacency lists.",
    19: "Setting each adjacency list to NULL, indicating no edges are connected initially for each vertex.",
    21: "Returning the created graph.",
    24: "Executing the createNode function to create a new node in the adjacency list with a given vertex.",
    25: "Allocating memory for a new node.",
    26: "Setting the vertex value for the node.",
    27: "Initializing the next pointer of the new node to NULL.",
    28: "Returning the new node.",
    31: "Executing the addEdge function to add an edge between two vertices in the graph. The edge can be directed or undirected.",
    32: "Calling createNode function to create a new node for the neighbor.",
    33: "Setting the next pointer of the new node to point to the current adjacency list of the vertex.",
    34: "Inserting the new node at the beginning of the adjacency list of the vertex.",
    36: "Checking if the graph is undirected.",
    37: "The graph is undirected, Calling createNode function to create a new node for the vertex.",
    38: "Setting the next pointer of the new node to point to the current adjacency list of the neighbor.",
    39: "Inserting the new node at the beginning of the adjacency list of the neighbor.",
    44: "Starting the main function.",
    45: "Calling createGraph function to create a new graph.",
    47: "Adding an undirected edge between given two vertices.",
    48: "Adding an undirected edge between given two vertices.",
    49: "Adding an undirected edge between given two vertices.",
    50: "Adding an undirected edge between given two vertices.",
    51: "Adding a directed edge between given two vertices.",
    53: "Returning 0 to indicate successful execution."    
  },
  "C++": {
    1: "Including the input-output stream library for printing to the console.",
    2: "Including the unordered_map library to store the adjacency list of the graph.",
    3: "Including the vector library to store neighbors of each vertex.",
    5: "Using the standard namespace to avoid using 'std::' prefix before standard library functions.",
    7: "Declaring the Graph class to represent the graph structure.",
    8: "Defining private access specifier for private class members.",
    9: "Declaring an unordered_map to store the adjacency list, where each vertex is a key and its neighbors are stored in a vector.",
    11: "Defining public access specifier for public class members.",
    13: "Executing the addEdge method to add edges between vertices.",
    14: "Checking if the vertex is not already in the graph.",
    15: "Vertex is not in the graph, initializing an empty vector for it to store its neighbors.",
    17: "Checking if the neighbor is not already in the graph.",
    18: "Neighbor is not in the graph, initializing an empty vector for it to store its connections.",
    20: "Adding the neighbor to the adjacency list of the vertex.",
    21: "Checking if the edge is undirected (i.e., directed is false).",
    22: "Graph is undirected, adding the vertex to the adjacency list of the neighbor.",
    28: "Main function to test the Graph class functionality.",
    29: "Creating an instance of the Graph class.",
    30: "Adding an undirected edge between given two vertices.",
    31: "Adding an undirected edge between given two vertices.",
    32: "Adding an undirected edge between given two vertices.",
    33: "Adding an undirected edge between given two vertices.",
    34: "Adding a directed edge between given two vertices.",
    36: "Returning 0 to indicate successful execution."
  }  
};
  
  export default explanations;
  