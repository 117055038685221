const explanations = {
    Python: {
      2: "Declaring and initializing variable str1",
      3: "Declaring and initializing variable str2",
      4: "Declaring and initializing variable result_plus as an empty string",
      6: "Loop through each character in str1",
      7: "Append each character from str1 to result_plus",
      9: "Loop through each character in str2",
      10: "Append each character from str2 to result_plus",
      12: "Print the final concatenated result"
    },
    Java: {
      3: "Declaring and initializing variable str1",
      4: "Declaring and initializing variable str2",
      5: "Create a StringBuilder for resultPlus",
      7: "Loop through each character in str1",
      8: "Append each character from str1 to resultPlus",
      11: "Loop through each character in str2",
      12: "Append each character from str2 to resultPlus",
      15: "Print the final concatenated result"
    },
    C: {
      4: "Declaring and initializing character array variable str1",
      5: "Declaring and initializing character array variable str2",
      6: "Declaring and initializing variable resultPlus as an empty char array",
      8: "Declaring and initializing integer variable currentIndex",
      10: "Loop through each character in str1",
      11: "Append each character from str1 to resultPlus",
      12: "Incrementing index counter",
      15: "Loop through each character in str2",
      16: "Append each character from str2 to resultPlus",
      17: "Incrementing index counter",
      20: "Print the final concatenated result",
      21: "Return 0 to indicate successful execution"
    },
    "C++": {
      6: "Declaring and initializing variable str1",
      7: "Declaring and initializing variable str2",
      8: "Declaring and initializing variable resultPlus as an empty string",
      10: "Loop through each character in str1",
      11: "Append each character from str1 to resultPlus",
      14: "Loop through each character in str2",
      15: "Append each character from str2 to resultPlus",
      18: "Print the final concatenated result",
      19: "Return 0 to indicate successful execution"
    }
  };
  
  export default explanations;
  