const generateMemoryAddress = () => {
  return '0x' + Math.floor(Math.random() * 0xFFF).toString(16);
};

class Node {
  constructor(data, nodeAddress) {
    this.data = data;
    this.next = null;
    this.address = nodeAddress;
    this.parent = null;
  }
}

class LinkedListForJava {
  constructor() {
      this.head = null;
  }

  // Method to insert a new node at the end of the list
  insert = async(
    data,
    setNodeVariables,
    setHeadVariables
  ) => {
    
    let nodeAddress = generateMemoryAddress();
    let newNode = new Node(data, nodeAddress);

    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: '', next: ''},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: ''},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: 'null'},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    
    if (this.head === null) {
        this.head = newNode;

        setHeadVariables((vars) => ({ ...vars, 
          head: { variable_name: 'head', value: nodeAddress }
        }));

        newNode.parent = 'head';
        setNodeVariables((vars) => ({ ...vars, 
          [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, next: 'null'},
            address: newNode.address,
            parent: newNode.parent
          }
        }));
    } else {
        let temp = this.head;
        setHeadVariables((vars) => ({ ...vars, 
          temp: { variable_name: 'temp', value: temp.address, child: temp.address }
        }));
        
        while (temp.next !== null) {
            temp = temp.next;

            setHeadVariables((vars) => ({ ...vars, 
              temp: { variable_name: 'temp', value: temp.address, child: temp.address }
            }));
        }

        temp.next = newNode;
        newNode.parent = temp.address;

        setNodeVariables((vars) => ({ ...vars, 
          [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, next: 'null'},
            address: newNode.address,
            parent: newNode.parent
          }
        }));
        
        setNodeVariables((vars) => ({ ...vars, 
          [temp.address]: { 
            variable_name: temp.address,
            value: {data: temp.data, next: newNode.address},
            address: temp.address,
            parent: temp.parent
          }
        }));
        
        // Delete temp from headVariables
        setHeadVariables(() => ({
          head: { variable_name: 'head', value: this.head.address }
        }));
    }
  }

  // Method to delete a node by its value
  delete = async (
    data,
    setVariables,
    setNodeVariables,
    setHeadVariables,
    highlightLine,
    customSleep,
    logMessage,
    setHighlightedVariables,
    setHighlightedNodeVariables,
    setHighlightedHeadVariables,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    setHighlightDataPart
  ) => {
    await highlightLine(13);
    setVariables((vars) => ({ ...vars, 
      data: { variable_name: 'data', value: data }
    }));
    setHighlightedVariables(['data']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(14);
    setHighlightedHeadVariables(['head']);
    await customSleep();
    setHighlightedHeadVariables([]);
    if (this.head === null) {
      await highlightLine(15);
      await logMessage("The list is empty.");
      await customSleep();

      await highlightLine(16);
      await customSleep();
      return;
    }
    
    let temp = this.head;

    await highlightLine(19);
    setHighlightedVariables(['data']);
    setHighlightDataPart([`${temp.address}`]);
    await customSleep();
    setHighlightedVariables([]);
    setHighlightDataPart([]);
    if (temp.data === data) {
      await highlightLine(20);
      setHighlightedHeadVariables(['head']);
      setHighlightNextAddressPart([`${this.head.address}`]);
      this.head = temp.next;

      setHeadVariables(() => ({
        head: { variable_name: 'head', value: this.head ? this.head.address : 'null' }
      }));

      await customSleep(1000);

      setNodeVariables((vars) => ({
        ...vars,
        [this.head.address]: {
          variable_name: this.head.address,
          value: { data: this.head.data, next: this.head.next ? this.head.next.address : 'null' },
          address: this.head.address,
          parent: 'head'
        }
      }));

      setNodeVariables((vars) => ({
        ...vars,
        [temp.address]: {
          variable_name: temp.address,
          value: { data: temp.data, next: temp.next ? temp.next.address : 'null' },
          address: temp.address,
          parent: null,
          positionDown: true
        }
      }));

      await customSleep(1000);
      setHighlightNextAddressPart([]);
      setHighlightedHeadVariables([]);

      setHighlightedNodeVariables([`${temp.address}`]);
      await customSleep(1000);

      setNodeVariables((vars) => {
        const newVars = { ...vars };
        delete newVars[temp.address];
        return newVars;
      });
      await customSleep();
      setHighlightedNodeVariables([]);

      await highlightLine(21);
      setHighlightedVariables(['data']);
      await logMessage(`${data} deleted from the list.`);
      await customSleep();
      setHighlightedVariables([]);

      // Emptying vaiables
      setVariables(() => ({}));

      await highlightLine(22);
      await customSleep();
      return;
    }

    await highlightLine(25);
    setHeadVariables((vars) => ({ ...vars, 
      temp: { variable_name: 'temp', value: temp.address, child: temp.address }
    }));
    setHighlightedHeadVariables(['temp']);
    await customSleep();
    setHighlightedHeadVariables([]);

    if(!(temp.next !== null && temp.next.data !== data)) {
      await highlightLine(26);
      setHighlightedVariables(['data']);
      setHighlightNextAddressPart([`${temp.address}`]);
      setHighlightDataPart([`${temp.next.address}`]);
      await customSleep();
      setHighlightNextAddressPart([]);
      setHighlightDataPart([]);
      setHighlightedVariables([]);
    }

    while (temp.next !== null && temp.next.data !== data) {
      await highlightLine(26);
      setHighlightedVariables(['data']);
      setHighlightNextAddressPart([`${temp.address}`]);
      setHighlightDataPart([`${temp.next.address}`]);
      await customSleep();
      setHighlightNextAddressPart([]);
      setHighlightDataPart([]);
      setHighlightedVariables([]);

      await highlightLine(27);
      setHighlightNextAddressPart([`${temp.address}`]);
      setHighlightedHeadVariables(['temp']);
      await customSleep(1000);
      temp = temp.next;
      setHeadVariables((vars) => ({ ...vars, 
        temp: { variable_name: 'temp', value: temp.address, child: temp.address }
      }));
      await customSleep();
      setHighlightedHeadVariables([]);
      setHighlightNextAddressPart([]);

      if(!((temp.next !== null && temp.next.data !== data))) {
        await highlightLine(26);
        setHighlightedVariables(['data']);
        setHighlightNextAddressPart([`${temp.address}`]);
        setHighlightDataPart([`${temp.next ? temp.next.address : ''}`]);
        await customSleep();
        setHighlightNextAddressPart([]);
        setHighlightDataPart([]);
        setHighlightedVariables([]);
      }
    }

    await highlightLine(29);
    setHighlightNextAddressPart([`${temp.address}`]);
    await customSleep();
    setHighlightNextAddressPart([]);
    if (temp.next === null) {
      await highlightLine(30);
      await logMessage(`${data} not found in the list.`);
      await customSleep();
    } else {
      await highlightLine(31);
      await customSleep();

      await highlightLine(32);
      setHighlightNextAddressPart([`${temp.address}`, `${temp.next.address}`]);
      await customSleep(1000);
      setHighlightNextAddressPart([`${temp.address}`]);
      await customSleep(700);
      let deleteNodeAddress = temp.next.address;
      let deleteNode = temp.next;
      temp.next = temp.next.next;

      setNodeVariables((vars) => ({
        ...vars,
        [temp.address]: {
          variable_name: temp.address,
          value: { data: temp.data, next: temp.next ? temp.next.address : 'null' },
          address: temp.address,
          parent: temp.parent
        }
      }));

      if (temp.next !== null) {
        setNodeVariables((vars) => ({
          ...vars,
          [temp.next.address]: {
            variable_name: temp.next.address,
            value: { data: temp.next.data, next: temp.next.next ? temp.next.next.address : 'null' },
            address: temp.next.address,
            parent: temp.address
          }
        }));
      }

      setNodeVariables((vars) => ({
        ...vars,
        [deleteNode.address]: {
          variable_name: deleteNode.address,
          value: { data: deleteNode.data, next: deleteNode.next ? deleteNode.next.address : 'None' },
          address: deleteNode.address,
          parent: null,
          positionDown: true
        }
      }));
      await customSleep(1000);

      setHighlightedNodeVariables([`${deleteNodeAddress}`]);
      await customSleep(1000);
      setNodeVariables((vars) => {
        const newVars = { ...vars };
        delete newVars[deleteNodeAddress];
        return newVars;
      });
      await customSleep();
      setHighlightNextAddressPart([]);

      await highlightLine(33);
      setHighlightedVariables(['data']);
      await logMessage(`${data} deleted from the list.`);
      await customSleep();
      setHighlightedVariables([]);
    }

    // Delete temp from headVariables
    setHeadVariables(() => ({
      head: { variable_name: 'head', value: this.head.address }
    }));

    // Emptying vaiables
    setVariables(() => ({}));
    
  }
}


export const linkedListJava = async (
  data,
  setNodeVariables,
  setHeadVariables
) => {
  let list = new LinkedListForJava();

  await list.insert(
    data[0],
    setNodeVariables,
    setHeadVariables
  );
  
  await list.insert(
    data[1],
    setNodeVariables,
    setHeadVariables
  );
  
  await list.insert(
    data[2],
    setNodeVariables,
    setHeadVariables
  );

  await list.insert(
    data[3],
    setNodeVariables,
    setHeadVariables
  );

  return list;
};

export const linkedListDeletionJava = async (
  data,
  globalList,
  setVariables,
  setNodeVariables,
  setHeadVariables,
  highlightLine,
  highlightCallingLine,
  customSleep,
  logMessage,
  setHighlightedVariables,
  setHighlightedNodeVariables,
  setHighlightedHeadVariables,
  setHighlightNextAddressPart,
  setHighlightNodeAddress,
  setHighlightDataPart
) => {
  // Display the linked list
  await highlightLine(39);
  await customSleep();

  await highlightCallingLine(39);
  await globalList.delete(
    data,
    setVariables,
    setNodeVariables,
    setHeadVariables,
    highlightLine,
    customSleep,
    logMessage,
    setHighlightedVariables,
    setHighlightedNodeVariables,
    setHighlightedHeadVariables,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    setHighlightDataPart
  );
  await highlightCallingLine(null);
  await highlightLine(39);
  await customSleep();
  
};


class LinkedListForPython {
  constructor() {
      this.head = null;
  }

  // Method to insert a new node at the end of the list
  insert = async(
    data,
    setNodeVariables,
    setHeadVariables
  ) => {
    
    let nodeAddress = generateMemoryAddress();
    let newNode = new Node(data, nodeAddress);

    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: '', next: ''},
        address: newNode.address,
        parent: newNode.parent
      }
    }));

    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: ''},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: 'None'},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    
    if (this.head === null) {
        this.head = newNode;

        setHeadVariables((vars) => ({ ...vars, 
          head: { variable_name: 'head', value: nodeAddress }
        }));

        newNode.parent = 'head';
        setNodeVariables((vars) => ({ ...vars, 
          [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, next: 'None'},
            address: newNode.address,
            parent: newNode.parent
          }
        }));
    } else {
        let temp = this.head;

        while (temp.next !== null) {
            temp = temp.next;
        }

        temp.next = newNode;
        newNode.parent = temp.address;

        setNodeVariables((vars) => ({ ...vars, 
          [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, next: 'None'},
            address: newNode.address,
            parent: newNode.parent
          }
        }));
        
        setNodeVariables((vars) => ({ ...vars, 
          [temp.address]: { 
            variable_name: temp.address,
            value: {data: temp.data, next: newNode.address},
            address: temp.address,
            parent: temp.parent
          }
        }));
        
        // Delete temp from headVariables
        setHeadVariables(() => ({
          head: { variable_name: 'head', value: this.head.address }
        }));
    }
  }

  // Method to delete a node by its value
  delete = async (
    data,
    setVariables,
    setNodeVariables,
    setHeadVariables,
    highlightLine,
    customSleep,
    logMessage,
    setHighlightedVariables,
    setHighlightedNodeVariables,
    setHighlightedHeadVariables,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    setHighlightDataPart
  ) => {
    await highlightLine(9);
    setVariables((vars) => ({ ...vars, 
      data: { variable_name: 'data', value: data }
    }));
    setHighlightedVariables(['data']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(10);
    setHighlightedHeadVariables(['head']);
    await customSleep();
    setHighlightedHeadVariables([]);
    if (this.head === null) {
      await highlightLine(11);
      await logMessage("The list is empty.");
      await customSleep();

      await highlightLine(12);
      await customSleep();
      return;
    }
    
    let temp = this.head;

    await highlightLine(14);
    setHighlightedVariables(['data']);
    setHighlightDataPart([`${temp.address}`]);
    await customSleep();
    setHighlightedVariables([]);
    setHighlightDataPart([]);
    if (temp.data === data) {
      await highlightLine(15);
      setHighlightedHeadVariables(['head']);
      setHighlightNextAddressPart([`${this.head.address}`]);
      this.head = temp.next;

      setHeadVariables(() => ({
        head: { variable_name: 'head', value: this.head ? this.head.address : 'null' }
      }));

      await customSleep(1000);

      setNodeVariables((vars) => ({
        ...vars,
        [this.head.address]: {
          variable_name: this.head.address,
          value: { data: this.head.data, next: this.head.next ? this.head.next.address : 'null' },
          address: this.head.address,
          parent: 'head'
        }
      }));

      setNodeVariables((vars) => ({
        ...vars,
        [temp.address]: {
          variable_name: temp.address,
          value: { data: temp.data, next: temp.next ? temp.next.address : 'null' },
          address: temp.address,
          parent: null,
          positionDown: true
        }
      }));

      await customSleep(1000);
      setHighlightNextAddressPart([]);
      setHighlightedHeadVariables([]);

      setHighlightedNodeVariables([`${temp.address}`]);
      await customSleep(1000);
      setNodeVariables((vars) => {
        const newVars = { ...vars };
        delete newVars[temp.address];
        return newVars;
      });
      await customSleep();
      setHighlightedNodeVariables([]);

      await highlightLine(16);
      setHighlightedVariables(['data']);
      await logMessage(`${data} deleted from the list.`);
      await customSleep();
      setHighlightedVariables([]);

      // Emptying vaiables
      setVariables(() => ({}));

      await highlightLine(17);
      await customSleep();
      return;
    }

    await highlightLine(19);
    setHeadVariables((vars) => ({ ...vars, 
      temp: { variable_name: 'temp', value: temp.address, child: temp.address }
    }));
    setHighlightedHeadVariables(['temp']);
    await customSleep();
    setHighlightedHeadVariables([]);

    if(!(temp.next !== null && temp.next.data !== data)) {
      await highlightLine(20);
      setHighlightedVariables(['data']);
      setHighlightNextAddressPart([`${temp.address}`]);
      setHighlightDataPart([`${temp.next.address}`]);
      await customSleep();
      setHighlightNextAddressPart([]);
      setHighlightDataPart([]);
      setHighlightedVariables([]);
    }

    while (temp.next !== null && temp.next.data !== data) {
      await highlightLine(20);
      setHighlightedVariables(['data']);
      setHighlightNextAddressPart([`${temp.address}`]);
      setHighlightDataPart([`${temp.next.address}`]);
      await customSleep();
      setHighlightNextAddressPart([]);
      setHighlightDataPart([]);
      setHighlightedVariables([]);

      await highlightLine(21);
      setHighlightNextAddressPart([`${temp.address}`]);
      setHighlightedHeadVariables(['temp']);
      await customSleep(1000);
      temp = temp.next;
      setHeadVariables((vars) => ({ ...vars, 
        temp: { variable_name: 'temp', value: temp.address, child: temp.address }
      }));
      await customSleep();
      setHighlightedHeadVariables([]);
      setHighlightNextAddressPart([]);

      if(!((temp.next !== null && temp.next.data !== data))) {
        await highlightLine(20);
        setHighlightedVariables(['data']);
        setHighlightNextAddressPart([`${temp.address}`]);
        setHighlightDataPart([`${temp.next ? temp.next.address : ''}`]);
        await customSleep();
        setHighlightNextAddressPart([]);
        setHighlightDataPart([]);
        setHighlightedVariables([]);
      }
    }

    await highlightLine(22);
    setHighlightNextAddressPart([`${temp.address}`]);
    await customSleep();
    setHighlightNextAddressPart([]);
    if (temp.next === null) {
      await highlightLine(23);
      await logMessage(`${data} not found in the list.`);
      await customSleep();
    } else {
      await highlightLine(24);
      await customSleep();

      await highlightLine(25);
      setHighlightNextAddressPart([`${temp.address}`, `${temp.next.address}`]);
      await customSleep(1000);
      setHighlightNextAddressPart([`${temp.address}`]);
      await customSleep(700);
      let deleteNodeAddress = temp.next.address;
      let deleteNode = temp.next;
      temp.next = temp.next.next;

      setNodeVariables((vars) => ({
        ...vars,
        [temp.address]: {
          variable_name: temp.address,
          value: { data: temp.data, next: temp.next ? temp.next.address : 'None' },
          address: temp.address,
          parent: temp.parent
        }
      }));

      if (temp.next !== null) {
        setNodeVariables((vars) => ({
          ...vars,
          [temp.next.address]: {
            variable_name: temp.next.address,
            value: { data: temp.next.data, next: temp.next.next ? temp.next.next.address : 'None' },
            address: temp.next.address,
            parent: temp.address
          }
        }));
      }

      setNodeVariables((vars) => ({
        ...vars,
        [deleteNode.address]: {
          variable_name: deleteNode.address,
          value: { data: deleteNode.data, next: deleteNode.next ? deleteNode.next.address : 'None' },
          address: deleteNode.address,
          parent: null,
          positionDown: true
        }
      }));
      await customSleep(1000);

      setHighlightedNodeVariables([`${deleteNodeAddress}`]);
      await customSleep(1000);
      setNodeVariables((vars) => {
        const newVars = { ...vars };
        delete newVars[deleteNodeAddress];
        return newVars;
      });
      await customSleep();
      setHighlightNextAddressPart([]);

      await highlightLine(26);
      setHighlightedVariables(['data']);
      await logMessage(`${data} deleted from the list.`);
      await customSleep();
      setHighlightedVariables([]);
    }

    // Delete temp from headVariables
    setHeadVariables(() => ({
      head: { variable_name: 'head', value: this.head.address }
    }));

    // Emptying vaiables
    setVariables(() => ({}));
    
  }

}

export const linkedListPython = async (
  data,
  setNodeVariables,
  setHeadVariables
) => {
  let list = new LinkedListForPython();

  await list.insert(
    data[0],
    setNodeVariables,
    setHeadVariables
  );
  
  await list.insert(
    data[1],
    setNodeVariables,
    setHeadVariables
  );
  
  await list.insert(
    data[2],
    setNodeVariables,
    setHeadVariables
  );

  await list.insert(
    data[3],
    setNodeVariables,
    setHeadVariables
  );

  return list;
};

export const linkedListDeletionPython = async (
  data,
  globalList,
  setVariables,
  setNodeVariables,
  setHeadVariables,
  highlightLine,
  highlightCallingLine,
  customSleep,
  logMessage,
  setHighlightedVariables,
  setHighlightedNodeVariables,
  setHighlightedHeadVariables,
  setHighlightNextAddressPart,
  setHighlightNodeAddress,
  setHighlightDataPart
) => {
  // Display the linked list
  await highlightLine(31);
  await customSleep();

  await highlightCallingLine(31);
  await globalList.delete(
    data,
    setVariables,
    setNodeVariables,
    setHeadVariables,
    highlightLine,
    customSleep,
    logMessage,
    setHighlightedVariables,
    setHighlightedNodeVariables,
    setHighlightedHeadVariables,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    setHighlightDataPart
  );
  await highlightCallingLine(null);
  await highlightLine(31);
  await customSleep();
  
};

class LinkedListForCpp {
  constructor() {
      this.head = null;
  }

  // Method to insert a new node at the end of the list
  insert = async(
    data,
    setNodeVariables,
    setHeadVariables
  ) => {
    
    let nodeAddress = generateMemoryAddress();
    let newNode = new Node(data, nodeAddress);

    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: '', next: ''},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: ''},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: 'nullptr'},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    
    if (this.head === null) {
      this.head = newNode;

      setHeadVariables((vars) => ({ ...vars, 
        head: { variable_name: 'head', value: nodeAddress }
      }));

      newNode.parent = 'head';
      setNodeVariables((vars) => ({ ...vars, 
        [newNode.address]: { 
          variable_name: newNode.address,
          value: {data: newNode.data, next: 'nullptr'},
          address: newNode.address,
          parent: newNode.parent
        }
      }));
    } else {
        let temp = this.head;
        setHeadVariables((vars) => ({ ...vars, 
          temp: { variable_name: 'temp', value: temp.address, child: temp.address }
        }));

        while (temp.next !== null) {
          temp = temp.next;

          setHeadVariables((vars) => ({ ...vars, 
            temp: { variable_name: 'temp', value: temp.address, child: temp.address }
          }));
        }

        temp.next = newNode;
        newNode.parent = temp.address;

        setNodeVariables((vars) => ({ ...vars, 
          [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, next: 'nullptr'},
            address: newNode.address,
            parent: newNode.parent
          }
        }));
        
        setNodeVariables((vars) => ({ ...vars, 
          [temp.address]: { 
            variable_name: temp.address,
            value: {data: temp.data, next: newNode.address},
            address: temp.address,
            parent: temp.parent
          }
        }));
        
        // Delete temp from headVariables
        setHeadVariables(() => ({
          head: { variable_name: 'head', value: this.head.address }
        }));
    }
  }

  // Method to delete a node by its value
  delete = async (
    data,
    setVariables,
    setNodeVariables,
    setHeadVariables,
    highlightLine,
    customSleep,
    logMessage,
    setHighlightedVariables,
    setHighlightedNodeVariables,
    setHighlightedHeadVariables,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    setHighlightDataPart
  ) => {
    await highlightLine(22);
    setVariables((vars) => ({ ...vars, 
      data: { variable_name: 'data', value: data }
    }));
    setHighlightedVariables(['data']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(23);
    setHighlightedHeadVariables(['head']);
    await customSleep();
    setHighlightedHeadVariables([]);
    if (this.head === null) {
      await highlightLine(24);
      await logMessage("The list is empty.");
      await customSleep();

      await highlightLine(25);
      await customSleep();
      return;
    }
    
    let temp = this.head;

    await highlightLine(28);
    setHighlightedVariables(['data']);
    setHighlightDataPart([`${temp.address}`]);
    await customSleep();
    setHighlightedVariables([]);
    setHighlightDataPart([]);
    if (temp.data === data) {
      await highlightLine(29);
      setHeadVariables((vars) => ({ ...vars, 
        temp: { variable_name: 'temp', value: temp.address, child: temp.address }
      }));
      setHighlightedHeadVariables(['temp']);
      await customSleep();
      setHighlightedHeadVariables([]);

      await highlightLine(30);
      setHighlightedHeadVariables(['head']);
      setHighlightNextAddressPart([`${this.head.address}`]);
      this.head = temp.next;

      setHeadVariables((vars) => ({ ...vars, 
        head: { variable_name: 'head', value: this.head ? this.head.address : 'null' }
      }));

      await customSleep(1000);

      setNodeVariables((vars) => ({
        ...vars,
        [this.head.address]: {
          variable_name: this.head.address,
          value: { data: this.head.data, next: this.head.next ? this.head.next.address : 'null' },
          address: this.head.address,
          parent: 'head'
        }
      }));

      setNodeVariables((vars) => ({
        ...vars,
        [temp.address]: {
          variable_name: temp.address,
          value: { data: temp.data, next: temp.next.address},
          address: temp.address,
          parent: null,
          positionDown: true
        }
      }));
      await customSleep();
      setHighlightedHeadVariables([]);
      setHighlightNextAddressPart([]);

      await highlightLine(31);
      setHighlightedNodeVariables([`${temp.address}`]);
      setHighlightedHeadVariables(['temp']);
      await customSleep(1000);
      setHighlightedHeadVariables([]);
      setHighlightedNodeVariables([]);
      setNodeVariables((vars) => {
        const newVars = { ...vars };
        delete newVars[temp.address];
        return newVars;
      });

      // Delete temp from headVariables
      setHeadVariables(() => ({
        head: { variable_name: 'head', value: this.head.address }
      }));
      await customSleep();

      await highlightLine(32);
      setHighlightedVariables(['data']);
      await logMessage(`${data} deleted from the list.`);
      await customSleep();
      setHighlightedVariables([]);

      // Emptying vaiables
      setVariables(() => ({}));

      await highlightLine(33);
      await customSleep();
      return;
    }

    await highlightLine(36);
    setHeadVariables((vars) => ({ ...vars, 
      temp: { variable_name: 'temp', value: temp.address, child: temp.address }
    }));
    setHighlightedHeadVariables(['temp']);
    await customSleep();
    setHighlightedHeadVariables([]);

    if(!(temp.next !== null && temp.next.data !== data)) {
      await highlightLine(37);
      setHighlightedVariables(['data']);
      setHighlightNextAddressPart([`${temp.address}`]);
      setHighlightDataPart([`${temp.next.address}`]);
      await customSleep();
      setHighlightNextAddressPart([]);
      setHighlightDataPart([]);
      setHighlightedVariables([]);
    }

    while (temp.next !== null && temp.next.data !== data) {
      await highlightLine(37);
      setHighlightedVariables(['data']);
      setHighlightNextAddressPart([`${temp.address}`]);
      setHighlightDataPart([`${temp.next.address}`]);
      await customSleep();
      setHighlightNextAddressPart([]);
      setHighlightDataPart([]);
      setHighlightedVariables([]);

      await highlightLine(38);
      setHighlightNextAddressPart([`${temp.address}`]);
      setHighlightedHeadVariables(['temp']);
      await customSleep(1000);
      temp = temp.next;
      setHeadVariables((vars) => ({ ...vars, 
        temp: { variable_name: 'temp', value: temp.address, child: temp.address }
      }));
      await customSleep();
      setHighlightedHeadVariables([]);
      setHighlightNextAddressPart([]);

      if(!((temp.next !== null && temp.next.data !== data))) {
        await highlightLine(37);
        setHighlightedVariables(['data']);
        setHighlightNextAddressPart([`${temp.address}`]);
        setHighlightDataPart([`${temp.next ? temp.next.address : ''}`]);
        await customSleep();
        setHighlightNextAddressPart([]);
        setHighlightDataPart([]);
        setHighlightedVariables([]);
      }
    }

    await highlightLine(40);
    setHighlightNextAddressPart([`${temp.address}`]);
    await customSleep();
    setHighlightNextAddressPart([]);
    if (temp.next === null) {
      await highlightLine(41);
      await logMessage(`${data} not found in the list.`);
      await customSleep();
    } else {
      await highlightLine(42);
      await customSleep();

      await highlightLine(43);
      let toDelete = temp.next;
      setHeadVariables((vars) => ({ ...vars, 
        toDelete: { variable_name: 'toDelete', value: temp.next.address, child: temp.next.address }
      }));
      setHighlightedHeadVariables(['temp']);
      await customSleep();
      setHighlightedHeadVariables([]);

      await highlightLine(44);
      setHighlightNextAddressPart([`${temp.address}`, `${temp.next.address}`]);
      await customSleep(1000);
      setHighlightNextAddressPart([`${temp.address}`]);
      await customSleep(700);
      let deleteNode = temp.next.address;
      temp.next = temp.next.next;

      setNodeVariables((vars) => ({
        ...vars,
        [temp.address]: {
          variable_name: temp.address,
          value: { data: temp.data, next: temp.next ? temp.next.address : 'nullptr' },
          address: temp.address,
          parent: temp.parent
        }
      }));

      if (temp.next !== null) {
        setNodeVariables((vars) => ({
          ...vars,
          [temp.next.address]: {
            variable_name: temp.next.address,
            value: { data: temp.next.data, next: temp.next.next ? temp.next.next.address : 'nullptr' },
            address: temp.next.address,
            parent: temp.address
          }
        }));
      }

      setNodeVariables((vars) => ({
        ...vars,
        [toDelete.address]: {
          variable_name: toDelete.address,
          value: { data: toDelete.data, next: toDelete.next ? toDelete.next.address : 'nullptr'},
          address: toDelete.address,
          parent: null,
          positionDown: true
        }
      }));

      await customSleep();
      setHighlightNextAddressPart([]);

      await highlightLine(45);
      setHighlightedNodeVariables([`${deleteNode}`]);
      setHighlightedHeadVariables(['toDelete']);
      await customSleep(1000);
      setHighlightedHeadVariables([]);
      setHighlightedNodeVariables([]);
      setNodeVariables((vars) => {
        const newVars = { ...vars };
        delete newVars[deleteNode];
        return newVars;
      });
      // Delete temp from headVariables
      setHeadVariables(() => ({
        head: { variable_name: 'head', value: this.head.address },
        temp: { variable_name: 'temp', value: temp.address }
      }));
      await customSleep();

      await highlightLine(46);
      setHighlightedVariables(['data']);
      await logMessage(`${data} deleted from the list.`);
      await customSleep();
      setHighlightedVariables([]);
    }

    // Delete temp from headVariables
    setHeadVariables(() => ({
      head: { variable_name: 'head', value: this.head.address }
    }));

    // Emptying vaiables
    setVariables(() => ({}));
    
  }
}

export const linkedListCpp = async (
  data,
  setNodeVariables,
  setHeadVariables
) => {
  let list = new LinkedListForCpp();

  await list.insert(
    data[0],
    setNodeVariables,
    setHeadVariables
  );
  
  await list.insert(
    data[1],
    setNodeVariables,
    setHeadVariables
  );
  
  await list.insert(
    data[2],
    setNodeVariables,
    setHeadVariables
  );

  await list.insert(
    data[3],
    setNodeVariables,
    setHeadVariables
  );

  return list;
};

export const linkedListDeletionCpp= async (
  data,
  globalList,
  setVariables,
  setNodeVariables,
  setHeadVariables,
  highlightLine,
  highlightCallingLine,
  customSleep,
  logMessage,
  setHighlightedVariables,
  setHighlightedNodeVariables,
  setHighlightedHeadVariables,
  setHighlightNextAddressPart,
  setHighlightNodeAddress,
  setHighlightDataPart
) => {
  // Display the linked list
  await highlightLine(55);
  await customSleep();

  await highlightCallingLine(55);
  await globalList.delete(
    data,
    setVariables,
    setNodeVariables,
    setHeadVariables,
    highlightLine,
    customSleep,
    logMessage,
    setHighlightedVariables,
    setHighlightedNodeVariables,
    setHighlightedHeadVariables,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    setHighlightDataPart
  );
  await highlightCallingLine(null);
  await highlightLine(55);
  await customSleep();

  await highlightLine(57);
  await customSleep();
  
};


class LinkedListForC {
  constructor() {
      this.head = null;
  }

  // Method to insert a new node at the end of the list
  insert = async(
    data,
    setNodeVariables,
    setHeadVariables
  ) => {
    
    let nodeAddress = generateMemoryAddress();
    let newNode = new Node(data, nodeAddress);
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: '', next: ''},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: ''},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: 'NULL'},
        address: newNode.address,
        parent: newNode.parent
      }
    }));
    
    if (this.head === null) {
        this.head = newNode;

        setHeadVariables((vars) => ({ ...vars, 
          head: { variable_name: 'head', value: nodeAddress }
        }));

        newNode.parent = 'head';
        setNodeVariables((vars) => ({ ...vars, 
          [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, next: 'NULL'},
            address: newNode.address,
            parent: newNode.parent
          }
        }));
    } else {
      let temp = this.head;
      setHeadVariables((vars) => ({ ...vars, 
        temp: { variable_name: 'temp', value: temp.address, child: temp.address }
      }));

      while (temp.next !== null) {
        temp = temp.next;
        
        setHeadVariables((vars) => ({ ...vars, 
          temp: { variable_name: 'temp', value: temp.address, child: temp.address }
        }));
      }

      temp.next = newNode;
      newNode.parent = temp.address;

      setNodeVariables((vars) => ({ ...vars, 
        [newNode.address]: { 
          variable_name: newNode.address,
          value: {data: newNode.data, next: 'NULL'},
          address: newNode.address,
          parent: newNode.parent
        }
      }));
      
      setNodeVariables((vars) => ({ ...vars, 
        [temp.address]: { 
          variable_name: temp.address,
          value: {data: temp.data, next: newNode.address},
          address: temp.address,
          parent: temp.parent
        }
      }));
      
      // Delete temp from headVariables
      setHeadVariables(() => ({
        head: { variable_name: 'head', value: this.head.address }
      }));
    }
  }

  // Method to delete a node by its value
  delete = async (
    data,
    setVariables,
    setNodeVariables,
    setHeadVariables,
    highlightLine,
    customSleep,
    logMessage,
    setHighlightedVariables,
    setHighlightedNodeVariables,
    setHighlightedHeadVariables,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    setHighlightDataPart
  ) => {
    await highlightLine(10);
    setVariables((vars) => ({ ...vars, 
      data: { variable_name: 'data', value: data }
    }));
    setHighlightedVariables(['data']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(11);
    setHighlightedHeadVariables(['head']);
    await customSleep();
    setHighlightedHeadVariables([]);
    if (this.head === null) {
      await highlightLine(12);
      await logMessage("The list is empty.");
      await customSleep();

      await highlightLine(13);
      await customSleep();
      return;
    }
    
    let temp = this.head;

    await highlightLine(16);
    setHighlightedVariables(['data']);
    setHighlightDataPart([`${temp.address}`]);
    await customSleep();
    setHighlightedVariables([]);
    setHighlightDataPart([]);
    if (temp.data === data) {
      await highlightLine(17);
      setHeadVariables((vars) => ({ ...vars, 
        temp: { variable_name: 'temp', value: temp.address, child: temp.address }
      }));
      setHighlightedHeadVariables(['temp']);
      await customSleep();
      setHighlightedHeadVariables([]);

      await highlightLine(18);
      setHighlightedHeadVariables(['head']);
      setHighlightNextAddressPart([`${this.head.address}`]);
      this.head = temp.next;

      setHeadVariables((vars) => ({ ...vars, 
        head: { variable_name: 'head', value: this.head ? this.head.address : 'null' }
      }));

      await customSleep(1000);

      setNodeVariables((vars) => ({
        ...vars,
        [this.head.address]: {
          variable_name: this.head.address,
          value: { data: this.head.data, next: this.head.next ? this.head.next.address : 'null' },
          address: this.head.address,
          parent: 'head'
        }
      }));

      setNodeVariables((vars) => ({
        ...vars,
        [temp.address]: {
          variable_name: temp.address,
          value: { data: temp.data, next: temp.next.address},
          address: temp.address,
          parent: null,
          positionDown: true
        }
      }));
      await customSleep();
      setHighlightedHeadVariables([]);
      setHighlightNextAddressPart([]);

      await highlightLine(19);
      setHighlightedNodeVariables([`${temp.address}`]);
      setHighlightedHeadVariables(['temp']);
      await customSleep(1000);
      setHighlightedHeadVariables([]);
      setHighlightedNodeVariables([]);
      setNodeVariables((vars) => {
        const newVars = { ...vars };
        delete newVars[temp.address];
        return newVars;
      });

      // Delete temp from headVariables
      setHeadVariables(() => ({
        head: { variable_name: 'head', value: this.head.address }
      }));
      await customSleep();

      await highlightLine(20);
      setHighlightedVariables(['data']);
      await logMessage(`${data} deleted from the list.`);
      await customSleep();
      setHighlightedVariables([]);

      // Emptying vaiables
      setVariables(() => ({}));

      await highlightLine(21);
      await customSleep();
      return;
    }

    await highlightLine(24);
    setHeadVariables((vars) => ({ ...vars, 
      temp: { variable_name: 'temp', value: temp.address, child: temp.address }
    }));
    setHighlightedHeadVariables(['temp']);
    await customSleep();
    setHighlightedHeadVariables([]);

    if(!(temp.next !== null && temp.next.data !== data)) {
      await highlightLine(25);
      setHighlightedVariables(['data']);
      setHighlightNextAddressPart([`${temp.address}`]);
      setHighlightDataPart([`${temp.next.address}`]);
      await customSleep();
      setHighlightNextAddressPart([]);
      setHighlightDataPart([]);
      setHighlightedVariables([]);
    }

    while (temp.next !== null && temp.next.data !== data) {
      await highlightLine(25);
      setHighlightedVariables(['data']);
      setHighlightNextAddressPart([`${temp.address}`]);
      setHighlightDataPart([`${temp.next.address}`]);
      await customSleep();
      setHighlightNextAddressPart([]);
      setHighlightDataPart([]);
      setHighlightedVariables([]);

      await highlightLine(26);
      setHighlightNextAddressPart([`${temp.address}`]);
      setHighlightedHeadVariables(['temp']);
      await customSleep(1000);
      temp = temp.next;
      setHeadVariables((vars) => ({ ...vars, 
        temp: { variable_name: 'temp', value: temp.address, child: temp.address }
      }));
      await customSleep();
      setHighlightedHeadVariables([]);
      setHighlightNextAddressPart([]);

      if(!((temp.next !== null && temp.next.data !== data))) {
        await highlightLine(25);
        setHighlightedVariables(['data']);
        setHighlightNextAddressPart([`${temp.address}`]);
        setHighlightDataPart([`${temp.next ? temp.next.address : ''}`]);
        await customSleep();
        setHighlightNextAddressPart([]);
        setHighlightDataPart([]);
        setHighlightedVariables([]);
      }
    }

    await highlightLine(29);
    setHighlightNextAddressPart([`${temp.address}`]);
    await customSleep();
    setHighlightNextAddressPart([]);
    if (temp.next === null) {
      await highlightLine(30);
      await logMessage(`${data} not found in the list.`);
      await customSleep();
    } else {
      await highlightLine(31);
      await customSleep();

      await highlightLine(32);
      let toDelete = temp.next;
      setHeadVariables((vars) => ({ ...vars, 
        toDelete: { variable_name: 'toDelete', value: temp.next.address, child: temp.next.address }
      }));
      setHighlightedHeadVariables(['temp']);
      await customSleep();
      setHighlightedHeadVariables([]);

      await highlightLine(33);
      setHighlightNextAddressPart([`${temp.address}`, `${temp.next.address}`]);
      await customSleep(1000);
      setHighlightNextAddressPart([`${temp.address}`]);
      await customSleep(700);
      let deleteNode = temp.next.address;
      temp.next = temp.next.next;

      setNodeVariables((vars) => ({
        ...vars,
        [temp.address]: {
          variable_name: temp.address,
          value: { data: temp.data, next: temp.next ? temp.next.address : 'nullptr' },
          address: temp.address,
          parent: temp.parent
        }
      }));

      if (temp.next !== null) {
        setNodeVariables((vars) => ({
          ...vars,
          [temp.next.address]: {
            variable_name: temp.next.address,
            value: { data: temp.next.data, next: temp.next.next ? temp.next.next.address : 'nullptr' },
            address: temp.next.address,
            parent: temp.address
          }
        }));
      }

      setNodeVariables((vars) => ({
        ...vars,
        [toDelete.address]: {
          variable_name: toDelete.address,
          value: { data: toDelete.data, next: toDelete.next ? toDelete.next.address : 'nullptr'},
          address: toDelete.address,
          parent: null,
          positionDown: true
        }
      }));

      await customSleep();
      setHighlightNextAddressPart([]);

      await highlightLine(34);
      setHighlightedNodeVariables([`${deleteNode}`]);
      setHighlightedHeadVariables(['toDelete']);
      await customSleep(1000);
      setHighlightedHeadVariables([]);
      setHighlightedNodeVariables([]);
      setNodeVariables((vars) => {
        const newVars = { ...vars };
        delete newVars[deleteNode];
        return newVars;
      });
      // Delete temp from headVariables
      setHeadVariables(() => ({
        head: { variable_name: 'head', value: this.head.address },
        temp: { variable_name: 'temp', value: temp.address }
      }));
      await customSleep();

      await highlightLine(35);
      setHighlightedVariables(['data']);
      await logMessage(`${data} deleted from the list.`);
      await customSleep();
      setHighlightedVariables([]);
    }

    // Delete temp from headVariables
    setHeadVariables(() => ({
      head: { variable_name: 'head', value: this.head.address }
    }));

    // Emptying vaiables
    setVariables(() => ({}));
    
  }
}

export const linkedListC = async (
  data,
  setNodeVariables,
  setHeadVariables
) => {
  let list = new LinkedListForC();

  await list.insert(
    data[0],
    setNodeVariables,
    setHeadVariables
  );
  
  await list.insert(
    data[1],
    setNodeVariables,
    setHeadVariables
  );
  
  await list.insert(
    data[2],
    setNodeVariables,
    setHeadVariables
  );

  await list.insert(
    data[3],
    setNodeVariables,
    setHeadVariables
  );

  return list;
};

export const linkedListDeletionC= async (
  data,
  globalList,
  setVariables,
  setNodeVariables,
  setHeadVariables,
  highlightLine,
  highlightCallingLine,
  customSleep,
  logMessage,
  setHighlightedVariables,
  setHighlightedNodeVariables,
  setHighlightedHeadVariables,
  setHighlightNextAddressPart,
  setHighlightNodeAddress,
  setHighlightDataPart
) => {
  // Display the linked list
  await highlightLine(41);
  await customSleep();

  await highlightCallingLine(41);
  await globalList.delete(
    data,
    setVariables,
    setNodeVariables,
    setHeadVariables,
    highlightLine,
    customSleep,
    logMessage,
    setHighlightedVariables,
    setHighlightedNodeVariables,
    setHighlightedHeadVariables,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    setHighlightDataPart
  );
  await highlightCallingLine(null);
  await highlightLine(41);
  await customSleep();

  await highlightLine(43);
  await customSleep();
  
};